export enum StaticContentType {
   ABOUT_US = 'ABOUT_US',
   ACCEPT_TERMS_AND_AGREEMENTS_CHEF = 'ACCEPT_TERMS_AND_AGREEMENTS_CHEF',
   AFTER_FORGOT_PASS_INFO = 'AFTER_FORGOT_PASS_INFO',
   BECOME_A_CHEF = 'BECOME_A_CHEF',
   BECOME_A_PICK_UP_POINT = 'BECOME_A_PICK_UP_POINT',
   CONTACT_US = 'CONTACT_US',
   COOKIES_BANNER = 'COOKIES_BANNER',
   COOKIES_POLICY = 'COOKIES_POLICY',
   CREATE_CHEF_INFO = 'CREATE_CHEF_INFO',
   CREATE_CHEF_STEP_0 = 'CREATE_CHEF_STEP_0',
   CREATE_CHEF_STEP_1 = 'CREATE_CHEF_STEP_1',
   CREATE_CHEF_STEP_2 = 'CREATE_CHEF_STEP_2',
   CREATE_CHEF_STEP_3 = 'CREATE_CHEF_STEP_3',
   DELETE_MY_ACCOUNT = 'DELETE_MY_ACCOUNT',
   PRIVACY_POLICY = 'PRIVACY_POLICY',
   SUCCESS_CONFIRM_EMAIL = 'SUCCESS_CONFIRM_EMAIL',
   TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
}
