import React, { lazy, Suspense } from 'react'
import { SuperBallsLoadingIndicator } from '@/layout/common/LoadingIndicator'
import { HiOutlinePaperAirplane } from 'react-icons/hi2'

const LexicalEditorImpl = lazy(() => import('@/layout/common/lexical-editor/LexicalEditorImpl'))

export interface LexicalEditorDesktopProps {
   droppedFile?: any
   placeholder?: any
   photoType?: string
   imagesURLs?: string[]
   deleteImageByIdx?: (index: number) => void
   initialHtml?: string
   htmlStateUpdateFunction: (html: string, contentLength: number, hasImage: boolean) => void
   clearEditorContent?: (clearContent: () => void) => void
   addImageEnabled?: boolean
   showFontSizeOptions?: boolean
   loadingF?: (loading: boolean) => void
   triggerAddPollF?: () => void
   addedMentionUsers?: any[]
   clearAddedMentionUser?: () => void
   showAlignmentOptions?: boolean
   showColorOptions?: boolean
   showFullWidthBreakLine?: boolean
   onUserTyping?: () => void
}

export interface LexicalEditorRef {
   insertDescriptionAndTags: (description: string, tags: any[]) => void
}

export const LexicalEditorWrapper = ({
   ref,
   droppedFile = null,
   placeholder = null,
   initialHtml = null,
   htmlStateUpdateFunction,
   clearEditorContent,
   addImageEnabled = false,
   showFontSizeOptions = false,
   loadingF = (loading: boolean) => {},
   triggerAddPollF = undefined,
   addedMentionUsers = [],
   clearAddedMentionUser = () => {},
   showAlignmentOptions = false,
   showColorOptions = false,
   showFullWidthBreakLine = false,
   onUserTyping,
}: LexicalEditorDesktopProps & { ref?: React.RefObject<LexicalEditorRef> }) => {
   return (
      <Suspense fallback={<SuperBallsLoadingIndicator />}>
         <LexicalEditorImpl
            ref={ref}
            droppedFile={droppedFile}
            placeholder={placeholder}
            initialHtml={initialHtml}
            onUserTyping={onUserTyping}
            htmlStateUpdateFunction={htmlStateUpdateFunction}
            clearEditorContent={clearEditorContent}
            addImageEnabled={addImageEnabled}
            showFontSizeOptions={showFontSizeOptions}
            loadingF={loadingF}
            triggerAddPollF={triggerAddPollF}
            addedMentionUsers={addedMentionUsers}
            clearAddedMentionUser={clearAddedMentionUser}
            showAlignmentOptions={showAlignmentOptions}
            showColorOptions={showColorOptions}
            showFullWidthBreakLine={showFullWidthBreakLine}
         />
      </Suspense>
   )
}

export const SubmitLexicalButton = ({ loading, isValid, onSubmit }) => {
   return (
      <>
         <div className='ml-2 flex flex-col justify-end'>
            <HiOutlinePaperAirplane
               type='submit'
               className={`mr-3 mb-1 h-5 w-5 ${
                  // todo big todo !!!!
                  loading
                     ? 'hover:cursor-wait'
                     : isValid
                       ? 'hover:cursor-pointer hover:text-red-600'
                       : 'cursor-not-allowed text-gray-600'
               }`}
               onClick={onSubmit}
            />
         </div>
         {loading && (
            <div className='absolute top-0 right-0 mr-[50px]'>
               <SuperBallsLoadingIndicator />
            </div>
         )}
      </>
   )
}
