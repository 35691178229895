import { useTranslation } from 'react-i18next'
import { CustomAddress } from '@/model/custom-address.model'
import React from 'react'
import { MdOpenInNew } from 'react-icons/md'
import { isDesktop } from 'react-device-detect'

interface ChefAddressProps {
   address: CustomAddress
   phoneNumber: string
   pickUpPoint: boolean
   name?: string | undefined
   pickUpPointId?: string | undefined
}

const ChefAddress = ({ address, phoneNumber, pickUpPoint, name, pickUpPointId }: ChefAddressProps) => {
   const { t } = useTranslation()

   // Define the address fields and their corresponding translation keys
   const addressFields = [
      { key: 'businessName', label: 'chef_add_page.business_name' }, // Updated
      { key: 'hotelName', label: 'chef_add_page.hotel_name' }, // Updated
      { key: 'buildingName', label: 'chef_add_page.building_name' }, // Updated
      { key: 'buildingType', label: 'chef_add_page.building_type' }, // Correct
      { key: 'aptOrUnitOrFloor', label: 'chef_add_page.apartment_unit_floor' }, // Updated
      { key: 'suiteOrFloor', label: 'chef_add_page.suite_floor' }, // Updated
      { key: 'roomAndFloor', label: 'chef_add_page.room_and_floor' }, // Updated
      { key: 'entryCode', label: 'chef_add_page.entry_code' }, // Updated
      { key: 'additionalAddressInfo', label: 'chef_add_page.additionalAddressInfo' }, // Correct
      { key: 'additionalDetails', label: 'chef_add_page.additionalDetails' }, // Correct
      { key: 'road', label: 'chef_add_page.street' }, // Correct
      { key: 'town', label: 'chef_add_page.city' }, // Updated ('town' mapped to 'city')
      { key: 'country', label: 'chef_add_page.country' }, // Correct
      { key: 'specialInstructions', label: 'chef_add_page.special_instructions' }, // Correct
   ]

   return (
      <div className={`text-sm leading-relaxed`}>
         <div className='mb-2 text-base font-semibold'>
            {t(pickUpPoint ? 'payment_confirmation.pick_up_point_address' : 'payment_confirmation.chef_address')}:
         </div>

         {name && (
            <div className='mb-1 flex flex-row items-center'>
               <strong>{t('chef_add_page.name')}:&nbsp;</strong>
               <a
                  href={'/chef/' + pickUpPointId}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='flex flex-row items-center hover:cursor-pointer'
               >
                  <span>{name}</span>
                  <MdOpenInNew className='ml-1 h-4 w-4' />
               </a>
            </div>
         )}

         {address &&
            addressFields.map(field =>
               address[field.key] ? (
                  <div key={field.key} className='mb-1 text-gray-800'>
                     {field.label === 'chef_add_page.building_type' ? (
                        <div>
                           <strong>{t(field.label)}:</strong> {t(`chef_add_page.${address[field.key].toLowerCase()}`)}
                        </div>
                     ) : (
                        <div>
                           <strong>{t(field.label)}:</strong> {address[field.key]}
                        </div>
                     )}
                  </div>
               ) : null,
            )}

         {phoneNumber && (
            <div className='mb-1'>
               <strong>{t('chef_add_page.phone_number')}:</strong> <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </div>
         )}
      </div>
   )
}

export default ChefAddress
