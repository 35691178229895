import { EditOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FloatingUIWrapper from '../common/FloatingUIComponent'
import { LoadingIndicator } from '@/layout/common/LoadingIndicator'
import { MdOutlineMoreVert, MdOutlineShare } from 'react-icons/md'
import { ReportModal } from '@/layout/more_actions/ReportModal'
import { useNavigate } from 'react-router-dom'
import { isAdmin } from '@/util/auth-util'
import { useAppSelector } from '@/config/store'

export interface MoreActionsChefProps {
   chefId: string
   suspended: boolean
   starred: boolean
   setShowShareModal: (showQRCode: boolean) => void
   deleteFromFavouritesFunction?: (commentId: string) => void
   pickUpPoint?: boolean | undefined
}

export const MoreActionsChef = ({ chefId, setShowShareModal, suspended, pickUpPoint }: MoreActionsChefProps) => {
   const { t } = useTranslation()
   const navigate = useNavigate()
   const [loading, setLoading] = useState(false)
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const [showReportModal, setShowReportModal] = useState(false)

   const canManage = loggedAccount?.id == chefId || isAdmin(loggedAccount)

   function getInnerElement() {
      return (
         <div className='more-actions-items'>
            {canManage && (
               <div>
                  <button
                     className='more-actions-item'
                     onClick={() => {
                        if (pickUpPoint) navigate(`/edit-pick-up-point/${chefId}`)
                        else navigate(`/edit-chef/${chefId}`)
                     }}
                  >
                     <EditOutlined className='mr-2 h-5 w-5' />
                     {t('chef_more_actions.edit')}
                  </button>
               </div>
            )}

            {/*{canManage && (*/}
            {/*   <div>*/}
            {/*      <button*/}
            {/*         className='more-actions-item'*/}
            {/*         onClick={() => {*/}
            {/*            setChefSuspendUnsuspendModalOpen(true)*/}
            {/*         }}*/}
            {/*      >*/}
            {/*         <GiNightSleep className='mr-2 h-5 w-5 pt-1' />*/}
            {/*         {t(chefSuspended ? 'chef_more_actions.unsuspend' : 'chef_more_actions.suspend')}*/}
            {/*      </button>*/}
            {/*   </div>*/}
            {/*)}*/}

            <div>
               <div
                  className='more-actions-item'
                  onClick={event => {
                     event.preventDefault()
                     event.stopPropagation()
                     setShowShareModal(true)
                  }}
               >
                  <MdOutlineShare className='mr-2 h-5 w-5 pt-1' />
                  {t('chef_more_actions.share')}
               </div>
            </div>
         </div>
      )
   }

   return (
      <div>
         <FloatingUIWrapper
            zIndex={1001}
            menuButton={({ ref, onClick }) => (
               <div
                  className='mr-2'
                  onClick={event => {
                     event.stopPropagation() // Prevent click event from propagating
                     event.preventDefault() // Prevent default action
                     onClick(event)
                  }}
                  ref={ref}
               >
                  <MdOutlineMoreVert
                     className={
                        'tab-hover h-[33px] min-h-[33px] w-[33px] min-w-[33px] rounded-full bg-white p-1 text-gray-800 hover:cursor-pointer hover:text-gray-800'
                     }
                  />
               </div>
            )}
         >
            {getInnerElement()}
         </FloatingUIWrapper>
         <ReportModal
            showModal={showReportModal}
            entityId={chefId}
            type={'chef'}
            onCancel={() => setShowReportModal(false)}
         />
         {/*todo suspend/unsuspend feature*/}
         {/*<ConfirmModal*/}
         {/*   confirmTranslated={t(*/}
         {/*      chefSuspended ? 'chef_more_actions.confirm_unsuspend_info' : 'chef_more_actions.confirm_suspend_info',*/}
         {/*   )}*/}
         {/*   headerTranslated={t(*/}
         {/*      chefSuspended*/}
         {/*         ? 'chef_more_actions.confirm_unsuspend_header'*/}
         {/*         : 'chef_more_actions.confirm_suspend_header',*/}
         {/*   )}*/}
         {/*   showModal={chefSuspendUnsuspendModalOpen}*/}
         {/*   onConfirm={async () => {*/}
         {/*      setLoading(true)*/}
         {/*      try {*/}
         {/*         await unsuspendChef(chefId)*/}
         {/*         setChefSuspended(!chefSuspended)*/}
         {/*         toast.success(*/}
         {/*            t(chefSuspended ? 'chef_more_actions.unsuspend_success' : 'chef_more_actions.suspend_success'),*/}
         {/*         )*/}
         {/*      } catch (error) {*/}
         {/*         toast.error(t('chef_more_actions.suspend_unsuspend_failed'))*/}
         {/*      } finally {*/}
         {/*         setLoading(false)*/}
         {/*         setChefSuspendUnsuspendModalOpen(false)*/}
         {/*      }*/}
         {/*   }}*/}
         {/*   onCancel={() => setChefSuspendUnsuspendModalOpen(false)}*/}
         {/*/>*/}

         {loading && <LoadingIndicator />}
      </div>
   )
}

export default MoreActionsChef
