import React from 'react'
import { IPost } from '@/model/post.model'

export function getLowestQualityPicture(post: IPost) {
   return post.pictureMD ? post.pictureMD : post.picture
}

export function getNumberOfSigns(descLength: number, maxDescrLength: number) {
   const isTooBig = descLength > maxDescrLength
   return (
      <p className={'text mt-0.5 mr-2.5 mb-0 text-right' + (isTooBig ? ' red-deep-text' : ' text-color-normal')}>
         {descLength + '/' + maxDescrLength}
      </p>
   )
}

export function getNumberOfSignsWithValidation(descr, maxDescrLength: number, nullComponent = '') {
   if (descr == null || descr.toString().length == 0) {
      return nullComponent
   }
   const descLength = descr.toString().length
   return getNumberOfSigns(descLength, maxDescrLength)
}

export const formatPrice = (priceInCents: number) => {
   const priceInEuros = priceInCents / 100
   return priceInEuros.toFixed(2).replace(/\.00$/, '')
}
