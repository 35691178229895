import React, { useRef } from 'react'
import { DietaryPreference, SortByChef } from '@/service/search'
import { DropdownFilter } from '@/layout/common/DropdownFilter'
import { useTranslation } from 'react-i18next'
import { isDesktop, isMobile } from 'react-device-detect'
import { ChefFilters } from '@/util/UrlUtils'
import { DeliveryType } from '@/model/enumerations/delivery-type.model'
import { CuisineType } from '@/model/enumerations/cousine-type'

interface ChefFiltersComponentProps {
   filters: ChefFilters
   setFilters: (filters: ChefFilters) => void
   availableCuisines: string[]
   loading?: boolean
}

const ChefFiltersComponent = React.memo(
   ({ filters, setFilters, availableCuisines, loading }: ChefFiltersComponentProps) => {
      const { t } = useTranslation()

      const handleDeliveryChange = (value: string, action: 'select' | 'deselect') => {
         const deliveryList = filters?.delivery || []

         const updatedPrice =
            action === 'select' ? [...deliveryList, value] : deliveryList.filter(item => item !== value)

         setFilters({
            ...filters,
            delivery: updatedPrice,
         })
      }

      const handleCuisineChange = (value: string, action: 'select' | 'deselect') => {
         const cuisine = filters?.cuisine || []
         const updatedCuisine = action === 'select' ? [...cuisine, value] : cuisine.filter(item => item !== value)

         setFilters({
            ...filters,
            cuisine: updatedCuisine,
         })
      }

      const handleDietaryChange = (value: string, action: 'select' | 'deselect') => {
         const dietary = filters?.dietary || []
         const updatedDietary = action === 'select' ? [...dietary, value] : dietary.filter(item => item !== value)

         setFilters({
            ...filters,
            dietary: updatedDietary,
         })
      }

      const handleSortChange = (value: string) => {
         setFilters({
            ...filters,
            sort: value,
         })
      }

      return (
         <div className={`flex flex-col ${isMobile ? 'space-y-4' : 'mb-2 space-y-2'}`}>
            <div
               id={'chef-filters'}
               className={`mb-0.5 flex w-full flex-wrap text-xs select-none ${isMobile ? 'ml-4 w-full gap-2 space-x-1' : ''}`}
            >
               <DropdownFilter
                  selectedOptions={filters?.delivery}
                  options={DeliveryType}
                  onSelect={value => handleDeliveryChange(value, 'select')}
                  onDeselect={value => handleDeliveryChange(value, 'deselect')}
                  label={t('chef_map.filter_delivery')}
                  translatePrefix='filters'
                  multiple={true}
               />

               <DropdownFilter
                  selectedOptions={filters?.dietary}
                  options={DietaryPreference}
                  onSelect={value => handleDietaryChange(value, 'select')}
                  onDeselect={value => handleDietaryChange(value, 'deselect')}
                  label={t('chef_map.filter_dietary')}
                  translatePrefix='filters'
                  multiple={true}
               />
               <DropdownFilter
                  selectedOptions={[filters?.sort]}
                  options={SortByChef}
                  onSelect={handleSortChange}
                  label={t('chef_map.filter_sort')}
                  translatePrefix='filters'
                  defaultOption={'POPULAR'}
               />
            </div>

            <CuisineFilter
               selectedCuisines={filters?.cuisine}
               availableCuisines={availableCuisines}
               onChange={(c, action) => {
                  console.log('handleCuisineChange', c, action)
                  handleCuisineChange(c, action)
               }}
               loading={loading}
            />
         </div>
      )
   },
)

export default ChefFiltersComponent

interface CuisineFilterProps {
   selectedCuisines: string[]
   availableCuisines: string[]
   onChange: (c: string, action: any) => void
   loading?: boolean
}

const CuisineFilter: React.FC<CuisineFilterProps> = ({ selectedCuisines, availableCuisines, onChange, loading }) => {
   const { t } = useTranslation()
   const scrollContainerRef = useRef<HTMLDivElement>(null)
   const isDraggingRef = useRef(false)
   const startXRef = useRef(0)
   const scrollLeftRef = useRef(0)
   const [isAtStart, setIsAtStart] = React.useState(true)
   const [isAtEnd, setIsAtEnd] = React.useState(false)

   React.useEffect(() => {
      // Small delay to ensure content has been rendered
      const timer = setTimeout(() => {
         handleScroll()
      }, 100)

      return () => clearTimeout(timer)
   }, [availableCuisines])

   // Unified handler for starting drag (mouse or touch)
   const handleStart = (e: React.MouseEvent | React.TouchEvent) => {
      isDraggingRef.current = true
      const pageX = 'touches' in e ? e.touches[0].pageX : e.pageX
      startXRef.current = pageX - (scrollContainerRef.current?.offsetLeft || 0)
      scrollLeftRef.current = scrollContainerRef.current?.scrollLeft || 0
   }

   // Unified handler for moving during drag (mouse or touch)
   const handleMove = (e: React.MouseEvent | React.TouchEvent) => {
      if (!isDraggingRef.current || !scrollContainerRef.current) return
      e.preventDefault() // Prevent default scrolling behavior
      const pageX = 'touches' in e ? e.touches[0].pageX : e.pageX
      const x = pageX - (scrollContainerRef.current.offsetLeft || 0)
      const walk = (x - startXRef.current) * 2 // Adjust scroll speed
      scrollContainerRef.current.scrollLeft = scrollLeftRef.current - walk
   }

   // Unified handler for ending drag (mouse or touch)
   const handleEnd = () => {
      isDraggingRef.current = false
   }

   // Handle scroll position to update button visibility
   const handleScroll = () => {
      if (!scrollContainerRef.current) return
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current
      setIsAtStart(scrollLeft === 0)
      setIsAtEnd(scrollLeft + clientWidth >= scrollWidth - 1)
   }

   React.useEffect(() => {
      const scrollContainer = scrollContainerRef.current
      if (scrollContainer) {
         scrollContainer.addEventListener('scroll', handleScroll)
         handleScroll() // Initialize button visibility on mount
         return () => scrollContainer.removeEventListener('scroll', handleScroll)
      }
   }, [])

   return (
      <div className={`relative flex items-center ${isMobile ? 'mt-2 mb-2.5' : ''}`}>
         {!isAtStart && (
            <button
               onClick={() => {
                  setIsAtStart(true)
                  scrollContainerRef.current?.scrollTo({ left: 0, behavior: 'smooth' })
               }}
               className='absolute left-0 z-10 mt-[2px] flex h-7 w-7 items-center justify-center rounded-full border-[1px] border-black/70 bg-white shadow-lg hover:cursor-pointer'
            >
               &#8249;
            </button>
         )}

         <div
            ref={scrollContainerRef}
            className={`flex cursor-grab ${isDesktop ? 'mt-1 mb-1' : ''} overflow-x-hidden whitespace-nowrap hover:cursor-pointer active:cursor-grabbing`}
            onMouseDown={handleStart}
            onMouseMove={handleMove}
            onMouseUp={handleEnd}
            onMouseLeave={handleEnd}
            onTouchStart={handleStart}
            onTouchMove={handleMove}
            onTouchEnd={handleEnd}
         >
            {Object.values(CuisineType)
               .filter(cuisine => availableCuisines.includes(cuisine))
               .map((cuisine, index) => {
                  const isSelected = selectedCuisines?.includes(cuisine)
                  return (
                     <button
                        key={cuisine}
                        onClick={() => onChange(cuisine, isSelected ? 'deselect' : 'select')}
                        className={`m-1 mr-1.5 rounded-full py-1.5 text-xs shadow-md hover:cursor-pointer ${index == 0 && isMobile ? 'ml-4' : 'ml-0'} ${index == Object.values(CuisineType).length - 1 ? 'mr-4' : ''} ${isSelected ? 'bg-customPurple text-white' : 'bg-white'} ${isMobile ? 'px-2 py-2' : 'px-3 py-1.5'}`}
                     >
                        {t('cuisine.' + cuisine)}
                     </button>
                  )
               })}
         </div>

         {!isAtEnd && (
            <button
               onClick={() => {
                  setIsAtEnd(false)
                  const scrollWidth = scrollContainerRef.current?.scrollWidth || 0
                  const clientWidth = scrollContainerRef.current?.clientWidth || 0
                  scrollContainerRef.current?.scrollTo({
                     left: scrollWidth - clientWidth,
                     behavior: 'smooth',
                  })
               }}
               className='absolute right-0 z-10 flex h-7 w-7 items-center justify-center rounded-full border-[1px] border-black/70 bg-white shadow-lg hover:cursor-pointer'
            >
               &#8250;
            </button>
         )}
      </div>
   )
}
