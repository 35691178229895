import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageMode } from '@/layout/common/lexical-editor/lexical-util/ImageNode'
import { AbstractWebModal } from '@/layout/common/AbstractWebModal'
import { PhotoType } from '@/model/enumerations/photo-type'
import { savePhotoToCDN } from '@/service/photo/photo-reducer'
import { CDN_ENDPOINT } from '@/env'

interface InsertImageModalProps {
   showModal: boolean
   onClose: () => void
   onSubmit: (
      altText: string,
      imgUrl: string,
      imageMode: ImageMode,
      roundness: ImageRoundness,
      fromFile: boolean,
   ) => void
   altOptional?: boolean
}

export enum ImageRoundness {
   NONE = 'rounded-none',
   SMALL = 'rounded-xs',
   MEDIUM = 'rounded-md',
   LARGE = 'rounded-lg',
   XL = 'rounded-xl',
   XL2 = 'rounded-2xl',
   FULL = 'rounded-full',
}

export const InsertImageModal = ({ showModal, onClose, onSubmit, altOptional = false }: InsertImageModalProps) => {
   const { t } = useTranslation()
   const [imgUrl, setImgUrl] = useState('')
   const [altText, setAltText] = useState('')

   const [fromFile, setFromFile] = useState(false)
   const [imagePosition, setImagePosition] = useState(ImageMode.FULL_WIDTH)
   const [imageRounded, setImageRounded] = useState<ImageRoundness>(ImageRoundness.NONE)

   const handleChangeAltText = event => {
      const value = event.target.value
      setAltText(value)
   }

   const handleChangeImgUrl = event => {
      const value = event.target.value
      setImgUrl(value)
   }

   return (
      <>
         {showModal && (
            <AbstractWebModal onClose={onClose} title={t('lexical.url_image')}>
               <div className='flex w-full flex-col items-center justify-center'>
                  {/* Optional overlay */}
                  <div className='mt-4 mb-6 flex items-center justify-start space-x-6'>
                     <label className='flex cursor-pointer items-center space-x-2 text-lg font-medium'>
                        <input
                           type='radio'
                           value='url'
                           checked={!fromFile}
                           onChange={() => setFromFile(false)}
                           className='h-5 w-5 cursor-pointer accent-blue-500'
                        />
                        <span>{t('lexical.use_url')}</span>
                     </label>
                     <label className='flex cursor-pointer items-center space-x-2 text-lg font-medium'>
                        <input
                           type='radio'
                           value='file'
                           checked={fromFile}
                           onChange={() => setFromFile(true)}
                           className='h-5 w-5 cursor-pointer accent-blue-500'
                        />
                        <span>{t('lexical.use_file')}</span>
                     </label>
                  </div>
                  {fromFile ? (
                     <div className='mb-4 w-full'>
                        <label className='mb-2 block text-gray-700 dark:text-gray-300' htmlFor='imageFileInput'>
                           {t('lexical.file_image')}
                        </label>
                        <input
                           id='imageFileInput'
                           type='file'
                           accept='image/*'
                           className='focus:shadow-outline w-full appearance-none rounded-sm border px-3 py-2 leading-tight text-gray-700 shadow-sm focus:outline-hidden dark:text-gray-800'
                           onChange={e => {
                              if (e.target.files && e.target.files[0]) {
                                 const photoProps = {
                                    photo: e.target.files[0],
                                    entityId: null,
                                    photoType: PhotoType.FOR_POST_LG,
                                 }
                                 savePhotoToCDN(photoProps, progressEvent => {
                                    const percentCompleted = Math.round(
                                       (progressEvent.loaded * 100) / progressEvent.total,
                                    )
                                 }).then(res => {
                                    console.log('Res img url ', res.data)
                                    setImgUrl(res.data)
                                 })
                              }
                           }}
                        />
                        {imgUrl && (
                           <div className='mt-4'>
                              <p className='text-sm text-gray-500'>
                                 {t('lexical.selected_file')}: {imgUrl.split('/').pop()}
                              </p>
                              <img src={CDN_ENDPOINT + imgUrl} alt={altText} className='mt-2 h-auto max-w-full' />
                           </div>
                        )}
                     </div>
                  ) : (
                     <>
                        <div className='w-full'>
                           <div className='mb-4'>
                              <label className='mb-2 block text-gray-700 dark:text-gray-300' htmlFor='altTextInput'>
                                 {t('lexical.image_url')}
                              </label>
                              <input
                                 id='imageUrlInput'
                                 className='focus:shadow-outline w-full appearance-none rounded-sm border px-3 py-2 leading-tight text-gray-700 shadow-sm focus:outline-hidden dark:text-gray-800'
                                 value={imgUrl}
                                 onChange={handleChangeImgUrl}
                              />
                           </div>
                           <div className='mb-4'>
                              <label className='mb-2 block text-gray-700 dark:text-gray-300' htmlFor='altTextInput'>
                                 {t('lexical.alt_text')}
                              </label>
                              <input
                                 id='altTextInput'
                                 className='focus:shadow-outline w-full appearance-none rounded-sm border px-3 py-2 leading-tight text-gray-700 shadow-sm focus:outline-hidden dark:text-gray-800'
                                 value={altText}
                                 onChange={handleChangeAltText}
                                 placeholder={t('lexical.alt_text_placeholder')}
                              />
                           </div>
                           <div className='flex justify-end'>
                              <button
                                 onClick={onClose}
                                 className='mr-2 rounded-sm bg-gray-500 px-4 py-2 text-white hover:bg-gray-600 dark:bg-gray-600 dark:hover:bg-gray-700'
                              >
                                 Cancel
                              </button>
                              <button
                                 type='button'
                                 onClick={() => {
                                    onSubmit(altText, imgUrl, imagePosition, imageRounded, fromFile)
                                    setAltText('')
                                    setImgUrl('')
                                 }}
                                 disabled={!imgUrl}
                                 className={`rounded-sm bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 ${!imgUrl ? 'cursor-not-allowed opacity-50' : ''}`}
                              >
                                 OK
                              </button>
                           </div>
                        </div>
                     </>
                  )}

                  <div className='mb-4 w-full'>
                     <label className='mb-2 block text-gray-700 dark:text-gray-300'>
                        {t('lexical.image_position')}
                     </label>
                     <div className='flex space-x-2'>
                        <button
                           type='button'
                           onClick={() => setImagePosition(ImageMode.LEFT)}
                           className={`rounded-sm px-4 py-2 ${imagePosition === ImageMode.LEFT ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-300'}`}
                        >
                           {t('lexical.position_left')}
                        </button>
                        <button
                           type='button'
                           onClick={() => setImagePosition(ImageMode.RIGHT)}
                           className={`rounded-sm px-4 py-2 ${imagePosition === ImageMode.RIGHT ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-300'}`}
                        >
                           {t('lexical.position_right')}
                        </button>
                        <button
                           type='button'
                           onClick={() => setImagePosition(ImageMode.FULL_WIDTH)}
                           className={`rounded-sm px-4 py-2 ${imagePosition === ImageMode.FULL_WIDTH ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-300'}`}
                        >
                           {t('lexical.position_full_width')}
                        </button>
                     </div>
                  </div>
                  <div className='mb-4 w-full'>
                     <label className='mb-2 block text-gray-700 dark:text-gray-300'>{t('lexical.image_rounded')}</label>
                     <div className='flex flex-row flex-wrap space-x-2'>
                        {Object.values(ImageRoundness).map(roundness => (
                           <button
                              key={roundness}
                              type='button'
                              onClick={() => setImageRounded(roundness)}
                              className={`mb-1 rounded px-4 py-2 ${
                                 imageRounded === roundness
                                    ? 'bg-blue-500 text-white'
                                    : 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-300'
                              }`}
                           >
                              {roundness}
                           </button>
                        ))}
                     </div>
                  </div>
                  {/*  todo add confirm button at the bottom right*/}
                  <div className='mt-4 flex w-full justify-end'>
                     <button
                        type='button'
                        onClick={() => {
                           onSubmit(altText, imgUrl, imagePosition, imageRounded, fromFile)
                           setAltText('')
                           setImgUrl('')
                        }}
                        disabled={!imgUrl}
                        className={`rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 ${
                           !imgUrl ? 'cursor-not-allowed opacity-50' : ''
                        }`}
                     >
                        {t('common.confirm')}
                     </button>
                  </div>
               </div>
            </AbstractWebModal>
         )}
      </>
   )
}
