import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import FoodForm from '@/layout/food/add-edit/FoodForm'
import { getFoodById } from '@/service/food.service'

export const EditFood = () => {
   const { foodId } = useParams()
   const [food, setFood] = useState(null)

   useEffect(() => {
      if (foodId) {
         getFoodById(foodId).then(response => {
            setFood(response.data)
         })
      }
   }, [])

   return (
      <>
         <FoodForm food={food} />
      </>
   )
}

export default EditFood
