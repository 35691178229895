import { Offer, OfferType } from '@/model/order/food.model'
import { useTranslation } from 'react-i18next'
import { days, getCurrentDayName } from '@/util/date-utils'
import dayjs from 'dayjs'
import { isMobile } from 'react-device-detect'

interface FoodAvailabilitySectionProps {
   offer?: Offer
}

export const FoodAvailabilitySection = ({ offer }: FoodAvailabilitySectionProps) => {
   const { t } = useTranslation()

   const renderOperatingHours = () => {
      if (!offer.operatingHours) return null

      // Helper function to get available days based on amountServed > amountSold
      const getAvailableDays = (): string[] => {
         return days.filter(day => {
            const { amountServed, amountSold } = offer.operatingHours![day] || {}
            return amountServed > amountSold
         })
      }

      // Helper function to check if the available days form a single consecutive group
      const isSingleConsecutiveGroup = (availableDays: string[]): boolean => {
         if (availableDays.length === 0) return false

         // Sort the available days based on their order in the 'days' array
         const sortedIndices = availableDays.map(day => days.indexOf(day)).sort((a, b) => a - b)

         // Check if all days are consecutive
         for (let i = 1; i < sortedIndices.length; i++) {
            if (sortedIndices[i] !== sortedIndices[i - 1] + 1) {
               return false
            }
         }

         return true
      }

      // Helper function to render a day range
      const renderDayRange = (startDay: string, endDay: string) => (
         <div
            key={`${startDay}-${endDay}`}
            className='mt-1 mr-1 w-fit rounded-lg bg-gray-500 px-1.5 py-1.5 text-xs text-white'
         >
            {`${t(`days.${startDay.charAt(0) + startDay.slice(1)}`)} - ${t(`days.${endDay.charAt(0) + endDay.slice(1)}`)}`}
         </div>
      )

      // Get all available days
      const availableDays = getAvailableDays()

      // Check if available days form a single consecutive group
      if (isSingleConsecutiveGroup(availableDays)) {
         const startDay = availableDays[0]
         const endDay = availableDays[availableDays.length - 1]
         return [renderDayRange(startDay, endDay)]
      }

      // Existing logic if not a single consecutive group
      const dayName = getCurrentDayName()
      const currentDayIndex = days.indexOf(dayName)
      const result = []

      const renderAvailability = (dayName: string, label: string) => {
         const { amountServed, amountSold } = offer.operatingHours![dayName] || {}
         if (amountServed > amountSold) {
            return (
               <div key={dayName} className='mt-1 mr-1 w-fit rounded-lg bg-gray-500 px-1.5 py-1 text-xs text-white'>
                  {label}
               </div>
            )
         }
         return null
      }

      // Check today
      result.push(renderAvailability(dayName, t('days.TODAY')))

      // Check the next days
      for (let i = 1; i < 7; i++) {
         const nextDayIndex = (currentDayIndex + i) % 7
         const nextDayName = days[nextDayIndex]
         const label = i === 1 ? t('days.TOMORROW') : t(`days.${nextDayName}`)
         result.push(renderAvailability(nextDayName, label))
      }

      // Filter out null values and limit to 5 entries
      return result.filter(Boolean).slice(0, 5)
   }

   const renderOneTimeOffers = () => {
      if (!offer?.oneTimeOffers || offer.oneTimeOffers.length === 0) return null

      return offer.oneTimeOffers.slice(0, 3).map((oneTimeOffer, index) => {
         const offerDate = dayjs(oneTimeOffer.date)
         const today = dayjs()

         if (offerDate.isBefore(today, 'day')) {
            return null
         }

         const tomorrow = today.add(1, 'day')

         let label

         if (offerDate.isSame(today, 'day')) {
            label = t('days.TODAY')
         } else if (offerDate.isSame(tomorrow, 'day')) {
            label = t('days.TOMORROW')
         } else if (offerDate.isBefore(today.add(7, 'day'))) {
            // If the date is within the next 7 days, use the day name
            label = t(`days.${offerDate.format('dddd').toLowerCase()}`)
            // name (e.g.,
            // Monday)
         } else {
            // Otherwise, show the short date
            label = offerDate.format('DD-MM-YYYY')
         }

         return (
            <div
               key={index}
               className={`w-fit bg-gray-500 text-white ${isMobile ? 'mr-0.5 px-1 text-[10px]' : 'mr-1 px-1.5 text-xs'} mt-1 rounded-xl py-1`}
            >
               {label}
            </div>
         )
      })
   }

   switch (offer?.offerType) {
      case OfferType.FIXED_OFFER:
         return <div className='flex-start flex flex-row flex-wrap'>{renderOperatingHours()}</div>
      case OfferType.ONE_TIME:
         return <div className='flex flex-col'>{renderOneTimeOffers()}</div>
      case OfferType.UNAVAILABLE:
      case undefined:
      default:
         return (
            <div className='flex flex-col hover:cursor-pointer'>
               <span
                  className={`${isMobile ? 'py-1 text-xs' : 'py-1.5 text-sm'} w-fit rounded-xl px-1 hover:bg-gray-200`}
               >
                  {t('single_food_view.unavailable')}
               </span>
            </div>
         )
   }
}
