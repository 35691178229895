import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import React, { startTransition, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FloatingLabelInput } from '@/layout/common/FloatingLabelInput'
import {
   activateFood,
   createFood,
   deactivateFood,
   deleteFood,
   getFoodsForChef,
   updateFood,
} from '@/service/food.service'
import { PicturesWall } from '@/layout/post/PictureWall'
import { PhotoType } from '@/model/enumerations/photo-type'
import { Food, OfferType } from '@/model/order/food.model'
import { FoodOfferSection } from '@/layout/chef/FoodOfferSection'
import { CDN_ENDPOINT } from '@/env'
import { getChefById } from '@/service/chef.service'
import { getOfferInfoChefForDeliveryType } from '@/util/food-availability-utils'
import { DeliveryType } from '@/model/enumerations/delivery-type.model'
import { Switch } from 'antd'
import { Chef } from '@/model/chef.model'
import { getPickUpPointsByIds } from '@/service/pick-up-point.service'
import { isAdmin } from '@/util/auth-util'
import { useAppSelector } from '@/config/store'
import { formatPrice } from '@/util/text-utils'
import { RcFile } from 'antd/es/upload/interface'
import { isMobile } from 'react-device-detect'

const convertEurosToCents = (priceInEuros: number): number => Math.round(priceInEuros * 100)
const convertCentsToEuros = (priceInCents: number): number => parseFloat((priceInCents / 100).toFixed(2))

interface FoodFormProps {
   chefId?: string
   food?: Food
}

export const FoodForm = ({ chefId, food }: FoodFormProps) => {
   const { t } = useTranslation()
   const navigate = useNavigate()
   const [foodRequestCompleted, setFoodRequestCompleted] = useState(false)
   const [surpriseBag, setSurpriseBag] = useState(false)

   const {
      setValue,
      getValues,
      register,
      watch,
      trigger,
      handleSubmit,
      control,
      formState: { errors, isValid },
   } = useForm<Food>({
      mode: 'all',
   })

   const loggedAccount = useAppSelector(state => state.authentication.account)
   const [imageUrl, setImageUrl] = useState<string>(null)
   // so far used only for pick up points
   const [chef, setChef] = useState<Chef>(null)
   const [surpriseBagMode, setSurpriseBugMode] = useState(false)
   const [chefWithFoods, setChefWithFoods] = useState<Map<Chef, Food[]>>(new Map())

   const handleFoodSelection = (foodId: string, isChecked: boolean) => {
      const currentRealFoodWithQuantity = watch('realFoodWithQuantity') || {}
      const updatedRealFoodWithQuantity = { ...currentRealFoodWithQuantity }

      if (isChecked) {
         updatedRealFoodWithQuantity[foodId] = 1 // Default quantity
      } else {
         delete updatedRealFoodWithQuantity[foodId]
      }
      console.log('updatedRealFoodWithQuantity', updatedRealFoodWithQuantity)
      setValue('realFoodWithQuantity', updatedRealFoodWithQuantity)
   }

   const handleQuantityChange = (foodId: string, quantity: number) => {
      const currentRealFoodWithQuantity = watch('realFoodWithQuantity') || {}
      const updatedRealFoodWithQuantity = { ...currentRealFoodWithQuantity }

      if (quantity > 0) {
         updatedRealFoodWithQuantity[foodId] = quantity
      } else {
         delete updatedRealFoodWithQuantity[foodId]
      }

      setValue('realFoodWithQuantity', updatedRealFoodWithQuantity)
   }

   function getChef(chefIdForFood?: string) {
      getChefById(chefIdForFood || chefId).then(response => {
         setChef(response.data)
         console.log('chef', response.data)
         if (response.data.pickUpPoint) {
            if (!response.data?.chefsOrdPointsIds || response.data.chefsOrdPointsIds.length === 0) return

            getPickUpPointsByIds(response.data.chefsOrdPointsIds).then(response => {
               let chefForPoints = new Map<Chef, Food[]>()
               let promises = response.data.map(async (chef: Chef) => {
                  const foodResponse = await getFoodsForChef(chef.id)
                  chefForPoints.set(chef, foodResponse.data)
               })
               Promise.all(promises).then(() => {
                  setChefWithFoods(chefForPoints)
               })
            })
         }

         let deliveryType = response.data.deliveryTypes.includes(DeliveryType.PICKUP)
            ? DeliveryType.PICKUP
            : response.data.deliveryTypes.includes(DeliveryType.DELIVER_TO_PICKUP_POINT)
              ? DeliveryType.DELIVER_TO_PICKUP_POINT
              : DeliveryType.DINE_IN
         const hoursBasedOnChef = getOfferInfoChefForDeliveryType(response.data, deliveryType)

         if (hoursBasedOnChef && !chefIdForFood) {
            setValue('offer.offerType', OfferType.FIXED_OFFER)
            // @ts-ignore
            setValue('offer.operatingHours', hoursBasedOnChef)
         }
      })
   }

   useEffect(() => {
      if (food) {
         setValue('id', food.id)
         setValue('chefId', food.chefId)
         setValue('name', food.name)
         setValue('description', food.description)
         setValue('price', convertCentsToEuros(food.price))
         setValue('image', food.image)
         setValue('offer', food.offer)
         setValue('surpriseBag', food.surpriseBag)
         setValue('realFoodWithQuantity', food.realFoodWithQuantity)
         setSurpriseBag(food.surpriseBag)
         setSurpriseBugMode(food.surpriseBag)

         setImageUrl(CDN_ENDPOINT + food.image)
         setFoodRequestCompleted(true)
         getChef(food.chefId)
         trigger()
      } else if (chefId) {
         getChef()
      }
   }, [food, setValue, surpriseBag])

   useEffect(() => {
      if (foodRequestCompleted) trigger()
   }, [foodRequestCompleted, trigger])

   const onSubmit = useCallback(
      async (formData: Food) => {
         startTransition(() => {
            const formattedData = {
               ...formData,
               price: convertEurosToCents(formData.price),
            }
            if (food) {
               updateFood(formattedData).then(() => {
                  console.log('Food updated successfully:', formattedData)
                  navigate(`/chef/${formData.chefId}`)
               })
            } else {
               createFood({ ...formattedData, chefId }).then(() => {
                  console.log('New food created successfully:', formattedData)
                  navigate(`/chef/${chefId}`)
               })
            }
         })
      },
      [food, chefId, navigate, isValid],
   )

   // Replace the onInvalid function with the following:
   const onInvalid = (errors: Record<string, any>) => {
      console.log('Form submission prevented due to invalid fields:')
      Object.entries(errors).forEach(([field, error]) => {
         console.log(`Field "${field}" is missing or invalid: ${error.message}`)
      })
   }

   const realFoodWithQuantity = watch('realFoodWithQuantity') || {}

   return (
      <div
         className={`rounded-normal background-normal flex flex-col space-y-4 p-1.5 sm:p-4 lg:px-8 ${isMobile ? 'mt-[60px]' : 'mt-[70px]'}`}
      >
         <h1 className='text-center text-2xl font-bold'>{t(food ? 'food_add_page.edit' : 'food_add_page.add')}</h1>
         <form onSubmit={handleSubmit(onSubmit, onInvalid)} className='space-y-4'>
            {chef?.pickUpPoint && isAdmin(loggedAccount) && (
               <label className='rounded-normal flex items-center space-x-1 bg-gray-100 p-2 py-3 sm:space-x-2.5'>
                  <span>{t('chef_add_page.surprise_bug')}</span>
                  <Switch
                     size={window.innerWidth >= 340 ? 'default' : 'small'}
                     checked={surpriseBagMode}
                     onChange={() => {
                        setSurpriseBugMode(!surpriseBagMode)
                        setValue('surpriseBag', !surpriseBagMode)
                     }}
                  />
               </label>
            )}
            <FloatingLabelInput<Food>
               label={t('food_add_page.name')}
               name='name'
               type='text'
               register={register}
               rules={{ required: t('error.required') }}
               watch={watch}
               errors={errors}
            />
            <FloatingLabelInput<Food>
               label={t('food_add_page.description')}
               name='description'
               type='text'
               register={register}
               rules={{ required: t('error.required') }}
               watch={watch}
               errors={errors}
               allowEnter={true}
            />
            <div className='mt-3.5 mb-3 ml-0.5 flex flex-row flex-wrap'>
               <PicturesWall
                  beforeUpload={(file: RcFile) => {
                     return true
                  }}
                  onImageSaved={imageId => {
                     console.log('imageId, ', imageId)
                     setValue('image', imageId)
                  }}
                  predefinedImageUrl={imageUrl}
                  photoType={PhotoType.FOR_FOOD}
                  placeholderText={t('food_add_page.image')}
               />
            </div>
            <FloatingLabelInput<Food>
               label={t('food_add_page.price')}
               name='price'
               type='number'
               register={register}
               rules={{ required: t('error.required') }}
               watch={watch}
               errors={errors}
               step='0.01'
            />
            <FoodOfferSection
               getValues={getValues}
               watch={watch}
               errors={errors}
               setValue={setValue}
               control={control}
            />
            {food && (
               <div className='mb-4 flex gap-x-2'>
                  <button
                     type='button'
                     className='rounded-md bg-red-500 p-2 font-semibold text-white hover:bg-red-600'
                     onClick={() => {
                        deleteFood(food.id).then(() => {
                           navigate(`/chef/${food.chefId}`)
                        })
                     }}
                  >
                     {t('food_add_page.delete')}
                  </button>
                  <button
                     type='button'
                     className='rounded-md bg-yellow-500 p-2 font-semibold text-white hover:bg-yellow-600'
                     onClick={() => {
                        if (food.active) {
                           deactivateFood(food.id).then(() => {
                              navigate(`/chef/${food.chefId}`)
                           })
                        } else {
                           activateFood(food.id).then(() => {
                              navigate(`/chef/${food.chefId}`)
                           })
                        }
                     }}
                  >
                     {t(food.active ? 'food_add_page.deactivate' : 'food_add_page.activate')}
                  </button>
               </div>
            )}
            {surpriseBagMode && chefWithFoods.size > 0 && (
               <div className='flex flex-col'>
                  <h1 className='text-2xl font-semibold text-gray-800'>
                     {t('food_add_page.add_foods_for_surprise_bag')}
                  </h1>
                  {Array.from(chefWithFoods).map(([chef, foods]) => {
                     return (
                        <div
                           key={chef.id}
                           className='flex flex-col space-y-4 rounded-lg border border-gray-200 bg-gray-200 p-6 shadow-md'
                        >
                           <h1 className='text-2xl font-semibold text-gray-800'>{chef.name}</h1>
                           <div className='flex flex-col space-y-3'>
                              {foods.map(food => (
                                 <div
                                    key={food.id}
                                    className='flex flex-col rounded-lg border border-gray-100 bg-gray-50 p-4 shadow-xs'
                                 >
                                    <label className='flex items-center justify-between space-x-4'>
                                       <div className='flex items-center space-x-2'>
                                          <input
                                             type='checkbox'
                                             checked={Boolean(realFoodWithQuantity[food.id])}
                                             onChange={e => handleFoodSelection(food.id, e.target.checked)}
                                             className='h-4 w-4 rounded-sm border-gray-300 text-blue-600 focus:ring-blue-500'
                                          />
                                          <span className='text-lg font-medium text-gray-700'>
                                             {food.name} (€{formatPrice(food.price)})
                                          </span>
                                       </div>
                                       {realFoodWithQuantity[food.id] > 0 && (
                                          <input
                                             type='number'
                                             min='1'
                                             value={realFoodWithQuantity[food.id]}
                                             onChange={e => handleQuantityChange(food.id, parseInt(e.target.value, 10))}
                                             className='w-20 rounded-sm border border-gray-300 bg-white px-3 py-2 text-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500'
                                          />
                                       )}
                                    </label>
                                 </div>
                              ))}
                           </div>
                           <div className='mt-4 flex justify-between border-t border-gray-200 pt-4'>
                              <span className='text-lg font-semibold text-gray-800'>Total Price:</span>
                              <span className='text-lg font-semibold text-blue-600'>
                                 €
                                 {formatPrice(
                                    Object.keys(realFoodWithQuantity).reduce((total, foodId) => {
                                       const foodItem = foods.find(f => f.id === foodId)
                                       return total + (realFoodWithQuantity[foodId] || 0) * (foodItem?.price || 0)
                                    }, 0),
                                 )}
                              </span>
                           </div>
                        </div>
                     )
                  })}
               </div>
            )}
            <div className='mb-[60px] flex gap-x-2'>
               <button type='submit' className={`sign-confirm-button ${!isValid ? 'gray-out' : ''}`}>
                  {t('food_add_page.confirm')}
               </button>
            </div>
         </form>
      </div>
   )
}

export default FoodForm
