export interface CustomAddress {
   locationName?: string // only for GUI, header1 + header2

   longitude: number
   latitude: number

   buildingType?: BuildingType
   street?: string
   villageTownCity?: string
   stateOrRegion?: string
   country: string

   id: number

   aptOrUnitOrFloor?: string // apartment
   buildingName?: string // apartment
   entryCode?: string // apartment
   additionalAddressInfo?: string // house/apartment/office/ other (always required)
   additionalDetails?: string // house/
   businessName?: string // office (required)
   suiteOrFloor?: string // office
   hotelName?: string // hotel
   roomAndFloor?: string //hotel
   houseNumber?: string // house
   specialInstructions?: string
   chefCityType?: ChefCityType
}

export enum BuildingType {
   HOUSE = 'HOUSE',
   APARTMENT = 'APARTMENT',
   OFFICE = 'OFFICE',
   HOTEL = 'HOTEL',
   OTHER = 'OTHER',
}

export enum ChefCityType {
   PARIS = 'PARIS',
   LONDON = 'LONDON',
   MADRID = 'MADRID',
   BERLIN = 'BERLIN',
   BRUSSELS = 'BRUSSELS',
   VIENNA = 'VIENNA',
   DUBLIN = 'DUBLIN',
   LISBON = 'LISBON',
   ATHENS = 'ATHENS',
   WARSAW = 'WARSAW',
   BUDAPEST = 'BUDAPEST',
   PRAGUE = 'PRAGUE',
}

export const ChefCityCoordinates: { [key in ChefCityType]: { lat: number; lon: number } } = {
   [ChefCityType.PARIS]: { lat: 48.8566, lon: 2.3522 },
   [ChefCityType.LONDON]: { lat: 51.5074, lon: -0.1278 },
   [ChefCityType.MADRID]: { lat: 40.4168, lon: -3.7038 },
   [ChefCityType.BERLIN]: { lat: 52.52, lon: 13.405 },
   [ChefCityType.BRUSSELS]: { lat: 50.8503, lon: 4.3517 },
   [ChefCityType.VIENNA]: { lat: 48.2082, lon: 16.3738 },
   [ChefCityType.DUBLIN]: { lat: 53.3498, lon: -6.2603 },
   [ChefCityType.LISBON]: { lat: 38.7223, lon: -9.1393 },
   [ChefCityType.ATHENS]: { lat: 37.9838, lon: 23.7275 },
   [ChefCityType.WARSAW]: { lat: 52.2297, lon: 21.0122 },
   [ChefCityType.BUDAPEST]: { lat: 47.4979, lon: 19.0402 },
   [ChefCityType.PRAGUE]: { lat: 50.0755, lon: 14.4378 },
}
