import React, { useState } from 'react'
import { FieldError, FieldErrors, Path, RegisterOptions, UseFormRegister } from 'react-hook-form'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'

interface InputWithHeaderProps<T> {
   label: string
   name: Path<T>
   type?: string
   placeholder?: string
   register: UseFormRegister<T>
   errors: FieldErrors<T>
   isRequired?: boolean
   options?: { value: string | number; label: string }[]
   onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
   rules?: RegisterOptions
   externalError?: string
   additionalStyles?: string
}

const InputWithHeader = <T,>({
   label,
   name,
   type = 'text',
   placeholder,
   register,
   errors,
   options,
   onChange,
   isRequired = true,
   rules = {},
   externalError,
   additionalStyles = '',
}: InputWithHeaderProps<T>) => {
   const { t } = useTranslation()
   const errorObject = get(errors, name) as FieldError | undefined
   const errorMessage = typeof errorObject?.message === 'string' ? errorObject.message : externalError
   const [showPassword, setShowPassword] = useState(false)

   return (
      <div className={`space-y-2 ${additionalStyles}`}>
         <div className='flex items-center justify-between'>
            <label htmlFor={name} className='ml-1 text-sm font-medium text-black'>
               {label}
               {!isRequired && <span className='text-xs text-gray-500'> ({t('chef_add_page.optional')})</span>}
            </label>
         </div>

         {type === 'select' ? (
            <select
               id={name}
               // @ts-ignore
               {...register(name, rules)}
               onChange={e => {
                  register(name).onChange(e) // React Hook Form's onChange handler
                  if (onChange) {
                     onChange(e) // Custom onChange handler if provided
                  }
               }}
               className={`background-gray-dark-normal block w-full rounded-md border-none px-4 py-2 pr-4 ${
                  errorObject ? 'border-red-600' : ''
               }`}
            >
               {options?.map(option => (
                  <option className='bg-gray-150' key={option.value} value={option.value}>
                     {option.label}
                  </option>
               ))}
            </select>
         ) : (
            <div className={'relative'}>
               <input
                  id={name}
                  data-cy={String(name)}
                  type={type === 'password' && showPassword ? 'text' : type}
                  placeholder={placeholder}
                  // @ts-ignore
                  {...register(name, rules)}
                  onChange={e => {
                     register(name).onChange(e)
                     if (onChange) {
                        onChange(e)
                     }
                  }}
                  className={`background-gray-dark-normal block w-full rounded-md border-none px-4 py-2 ${
                     errorObject ? 'border-red-600' : ''
                  }`}
               />
               {type == 'password' && (
                  <span className='absolute inset-y-0 right-0 flex items-center justify-center pr-3'>
                     {!showPassword ? (
                        <AiOutlineEye className='h-5 w-5 hover:cursor-pointer' onClick={() => setShowPassword(true)} />
                     ) : (
                        <AiOutlineEyeInvisible
                           className='h-5 w-5 hover:cursor-pointer'
                           onClick={() => setShowPassword(false)}
                        />
                     )}
                  </span>
               )}
            </div>
         )}

         {errorMessage && <p className='error-validation'>{errorMessage}</p>}
      </div>
   )
}

export default InputWithHeader
