import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Link, useNavigate } from 'react-router-dom'
import { RegisterFormData } from '@/model/register.model'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { hideRegisterModal, registerNewUser, showLoginModal } from '@/service/authentication'
import { LoadingIndicator } from '../common/LoadingIndicator'
import GoogleLoginRegisterPage from '@/layout/login_register/GoogleLoginRegisterPage'
import { FloatingLabelInput } from '@/layout/common/FloatingLabelInput'
import { AbstractWebModal } from '@/layout/common/AbstractWebModal'
import { HorizontalDivider } from '@/layout/common/Dividers'
import { emailRules } from '@/layout/login_register/RegisterPageMobile'

export function passwordRules(t: any) {
   return {
      required: t('error.required'),
      minLength: {
         value: 8,
         message: t('error.password_min_length', { length: 8 }),
      },
      maxLength: {
         value: 64,
         message: t('error.password_max_length', { length: 64 }),
      },
      pattern: {
         value: /^(?=.*[^A-Za-z0-9]).{8,64}$/,
         message: t('error.password_special_char'),
      },
   }
}

export const RegisterModal = () => {
   const {
      register,
      handleSubmit,
      setValue,
      getValues,
      watch,
      reset,
      clearErrors,
      formState: { errors, isValid, touchedFields },
   } = useForm<RegisterFormData>({ mode: 'all' })
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const locale = useAppSelector(state => state.localeSlice.lng)

   const { showModalRegister } = useAppSelector(state => state.authentication)
   const [errorMessage, setErrorMessage] = useState<string>('')
   const [isLoading, setIsLoading] = useState(false)
   const navigate = useNavigate()
   const [showPassword, setShowPassword] = useState(false)
   const [showConfirmPassword, setShowConfirmPassword] = useState(false)

   useEffect(() => {
      if (touchedFields.password || touchedFields.confirmPassword) {
         setValue('rulesAccepted', getValues('rulesAccepted'), {
            shouldValidate: true,
         })
      }
   }, [watch('password'), watch('confirmPassword')])

   const onSubmit = useCallback(
      (formValues: any) => {
         if (isLoading) return
         setIsLoading(true)
         setErrorMessage('')
         formValues.locale = locale
         registerNewUser(formValues)
            .then(value => {
               dispatch(hideRegisterModal())
               reset()
               navigate('/welcome')
            })
            .finally(() => {
               setIsLoading(false)
            })
      },
      [dispatch, hideRegisterModal, reset, navigate, locale],
   )

   useEffect(() => {
      reset()
   }, [showModalRegister])

   function handleCancelClick(event) {
      setShowPassword(false)
      setShowConfirmPassword(false)
      reset()
      setErrorMessage('')
      dispatch(hideRegisterModal())
      clearErrors()
   }

   function triggerLoginModal() {
      setShowPassword(false)
      setShowConfirmPassword(false)
      reset()
      setErrorMessage('')
      dispatch(hideRegisterModal())
      dispatch(showLoginModal())
   }

   return showModalRegister ? (
      <AbstractWebModal title={t('sign.sing_up')} onClose={handleCancelClick}>
         <div className={'sm:px-8 min-h-[643px]'}>
            <form className='mt-6' onSubmit={handleSubmit(onSubmit)} autoComplete='on'>
               <div className='space-y-4'>
                  <FloatingLabelInput<RegisterFormData>
                     label={t('username')}
                     name='login'
                     type='text'
                     register={register}
                     rules={{
                        required: t('error.required'),
                     }}
                     watch={watch}
                     errors={errors}
                     autocomplete='username'
                     autoFocus={true}
                  />

                  {/* Email Input */}
                  <FloatingLabelInput<RegisterFormData>
                     label={t('email')}
                     name='email'
                     type='email'
                     register={register}
                     rules={emailRules(t)}
                     watch={watch}
                     errors={errors}
                     autocomplete='email'
                  />

                  {/* Password Input */}
                  <FloatingLabelInput<RegisterFormData>
                     label={t('password')}
                     name='password'
                     type='password'
                     register={register}
                     rules={passwordRules(t)}
                     watch={watch}
                     errors={errors}
                     autocomplete='new-password'
                  />

                  <FloatingLabelInput<RegisterFormData>
                     label={t('profile_settings.confirm_password')}
                     name='confirmPassword'
                     type='password'
                     register={register}
                     rules={{
                        validate: (value: string) => value === getValues('password') || t('error.password_mismatch'),
                     }}
                     watch={watch}
                     errors={errors}
                     autocomplete='new-password'
                  />

                  <div className='text-sm-normal mt-2 inline-block'>
                     <input
                        type='checkbox'
                        name='rulesAccepted'
                        {...register('rulesAccepted', {
                           required: t('error.required'),
                        })}
                        className='checkbox-lg mr-1 ml-1 align-middle'
                     />
                     <span
                        className='inline cursor-pointer align-middle'
                        onClick={() =>
                           setValue('rulesAccepted', !getValues('rulesAccepted'), {
                              shouldValidate: true,
                           })
                        }
                     >
                        {t('sign.accept_terms')}
                     </span>
                     &nbsp;
                     <Link
                        to='/terms-and-conditions'
                        className='inline align-middle text-blue-600 hover:cursor-pointer hover:underline'
                        target='_blank'
                     >
                        {t('sign.terms_link')}
                     </Link>
                  </div>

                  {errors.rulesAccepted && <p className='error-validation'>{t('sign.errors.rules_accepted')}</p>}

                  <div className='text-sm-normal mt-2 flex flex-row'>
                     <label className='' id='checkbox_id'>
                        <input
                           type='checkbox'
                           name='Name'
                           className='checkbox-lg mr-1 ml-1'
                           {...register('advAccepted')}
                           defaultChecked={true}
                           id='checkbox_id'
                        />
                        {t('sign.accept_adv')}
                     </label>
                  </div>

                  {errorMessage.length > 0 && <p className='error-validation'>{errorMessage}</p>}
                  <div className='mt-5 space-y-2'>
                     <div className='flex gap-x-2'>
                        <button
                           type='submit'
                           className={`sign-confirm-button ${!isValid ? 'gray-out' : ''}`}
                           disabled={!isValid || isLoading}
                        >
                           {t('sign.join_in')}
                        </button>
                     </div>
                  </div>
               </div>
            </form>

            <div className='-mt-2 mb-1'>
               <HorizontalDivider text={t('common.or')} dividerHeight={2} />
            </div>
            <div className='w-full text-center'>
               <div className='flex w-full items-center justify-center'>
                  <GoogleLoginRegisterPage mode='register' />
               </div>
            </div>

            <div className='mt-1.5 mb-2.5'>
               <HorizontalDivider text={t('common.or')} dividerHeight={2} />
            </div>

            <div className='mb-3 w-full text-center sm:mb-2'>
               <p className='text-coolGray-600 px-6 text-center text-sm'>
                  {t('sign.have_an_account')}&nbsp;
                  <a onClick={triggerLoginModal} className='cursor-pointer text-blue-600 hover:underline'>
                     {t('common.log_in')}
                  </a>
               </p>
            </div>

            {isLoading && <LoadingIndicator />}
         </div>
      </AbstractWebModal>
   ) : (
      <div />
   )
}
