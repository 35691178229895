import { FiX } from 'react-icons/fi'
import React from 'react'

interface CloseButtonProps {
   onButtonClicked: (event) => void
}
export const CloseButton = ({ onButtonClicked }: CloseButtonProps) => {
   return (
      <button
         onClick={onButtonClicked}
         className='rounded-full p-1 text-gray-500 hover:cursor-pointer hover:bg-gray-100 hover:text-red-500 flex items-center justify-center w-10 h-10'
      >
         <FiX size={24} />
      </button>
   )
}