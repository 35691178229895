import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AbstractMobileModal } from '@/layout/common/AbstractMobileModal'
import { useTranslation } from 'react-i18next'
import { CommentsSectionMobileProps, MainCommentMobile } from '@/layout/post/comment/mobile/MainCommentMobile'
import { getCommentById } from '@/service/comment/comment-reducer'
import { ISubcomment } from '@/model/subcomment.model'
import { loadingIndicator } from '@/layout/common/LoadingIndicator'
import { IUser } from '@/model/user.model'
import AddEditCommentComponentMobile from '@/layout/post/comment/mobile/AddEditCommentComponentMobile'
import { IComment } from '@/model/comment.model'
import { COMMENT_SORT_BY } from '@/service/search'
import { isIOS } from 'react-device-detect'

interface ReplyToCommentMobileProps {
   initialReplyData?: { commentsSection: CommentsSectionMobileProps; user: IUser }
   customNavFun?: any
   postId?: string
   increaseZIndex?: boolean
   updateAfterCommentAddF?: (comment: IComment | ISubcomment, add: boolean) => void
}

const ReplyToCommentMobile = ({
   initialReplyData,
   customNavFun = null,
   postId = null,
   increaseZIndex = false,
   updateAfterCommentAddF = (comment: IComment | ISubcomment) => {},
}: ReplyToCommentMobileProps) => {
   const { t } = useTranslation()
   const bottomRef = useRef(null)

   const navigate = useNavigate()
   const { parentCommentId } = useParams()

   const [comment, setComment] = useState(initialReplyData?.commentsSection?.com)
   const [editedComment, setEditedComment] = useState<IComment | ISubcomment>(null)

   const [loading, setLoading] = useState(false)
   const [updatedSubcomment, setUpdatedSubcomment] = useState<ISubcomment>(null)
   const [userToReply, setUserToReply] = useState<IUser>(initialReplyData?.user)

   useEffect(() => {
      if (initialReplyData?.commentsSection?.subcomments?.length > 0)
         bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
   }, [initialReplyData?.commentsSection?.subcomments])

   useEffect(() => {
      if (!initialReplyData?.commentsSection?.com && parentCommentId) {
         setLoading(true)
         getCommentById(parentCommentId)
            .then(data => {
               setComment(data.data)
            })
            .finally(() => {
               setLoading(false)
            })
      }
   }, [parentCommentId, comment?.id])

   function updateAfterCommentAdd(newComment: ISubcomment | IComment, add: boolean) {
      if (add) {
         setUpdatedSubcomment(newComment)
      } else {
         if (editedComment) setEditedComment(null)

         if (newComment.id === comment.id) setComment(newComment)
         else setUpdatedSubcomment(newComment)
      }
      if (updateAfterCommentAddF) updateAfterCommentAddF(newComment, add)
   }

   return (
      <AbstractMobileModal
         customNavigation={() => {
            if (customNavFun) customNavFun()
            else navigate('/blog/' + (postId == null ? comment?.postId : postId))
         }}
         title={<div className='mt-1.5 pl-1 text-xl font-medium'>{t('comments.reply')}</div>}
         increaseZIndex={increaseZIndex}
      >
         <div className='post-box'>
            {comment && (
               <div className='flex flex-col'>
                  <MainCommentMobile
                     comment={comment}
                     updatedSubcomment={updatedSubcomment}
                     key={comment.id}
                     actionsVisible={false}
                     preLoadedSubcomments={initialReplyData?.commentsSection?.subcomments || []}
                     triggerEditComment={(c: IComment | ISubcomment) => {
                        setEditedComment(c)
                     }}
                     getAllSubcomments={true}
                     scrollToBottom={true}
                     fromDate={null}
                     triggerReplyToCommentMobile={(commentsSection, user: IUser) => {
                        setUserToReply(user)
                     }}
                     getFirstPageOfSubcomments={true}
                     subcommentSortBy={initialReplyData?.commentsSection?.sort || COMMENT_SORT_BY.NEW}
                  />
                  <div className={`${isIOS ? 'h-32' : 'h-20'} `} />
                  <div ref={bottomRef} />
               </div>
            )}
            {/*<div className="flex flex-col h-[140px]" />*/}
            {loading && loadingIndicator(loading)}

            <AddEditCommentComponentMobile
               postId={postId == null ? comment?.postId : postId}
               parentCommentId={comment?.id}
               afterCommentAddEdit={updateAfterCommentAdd}
               editedComment={editedComment}
               userToReply={userToReply}
            />
         </div>
      </AbstractMobileModal>
   )
}

export default ReplyToCommentMobile
