import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FavouritesComments } from './FavouritesComments'
import { PanelOption, PanelPlaceholder } from '@/layout/common/TabPanel'

const Favourites = () => {
   const { t } = useTranslation()

   const [openTab, setOpenTab] = React.useState(0)

   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   // todo complete
   const items = [
      {
         label: t('user_panel.saved_posts'),
         key: 0,
         content: <div />,
      },
      {
         label: t('user_panel.comments'),
         key: 1,
         content: <FavouritesComments />,
      },
   ]

   return (
      <div className='py-2'>
         <PanelPlaceholder
            content={items.map(it => {
               return <PanelOption tabKey={it.key} openTabKey={openTab} content={it.label} setOpenTab={setOpenTab} />
            })}
         />
         {items.map((tab, index) => {
            return (
               <div className={tab.key === openTab ? 'block' : 'hidden'} key={'t' + index}>
                  {tab.content}
               </div>
            )
         })}
      </div>
   )
}
export default Favourites
