import { RestClient } from '@/config/rest-client'
import { FoodOrderItem, OrderForChef } from '@/model/order/FoodOrderItem'
import { Order } from '@/model/order/order'

const apiUrl = '/api/shopping-card'

export const handleFoodOrder = (foodOrder: FoodOrderItem) => {
   return RestClient.post<void>(apiUrl + '/handle-order', foodOrder)
}

export const getShoppingCard = () => {
   return RestClient.get<OrderForChef[]>(apiUrl + '/get-SC')
}

export const getShoppingCardOrderByChef = (chefId: string) => {
   return RestClient.get<OrderForChef>(apiUrl + `/get-SC/${chefId}`)
}

export const createPaymentIntent = (order: Order) => {
   return RestClient.post<Order>(apiUrl + '/create-order', order)
}

export const getPaymentById = (id: string) => {
   return RestClient.get<Order>(apiUrl + '/payment/' + id)
}

export const confirmPayment = ({ paymentIntentId, stripePaymentId }) => {
   return RestClient.post<Order>(`${apiUrl}/payment/${paymentIntentId}/${stripePaymentId}/confirm`)
}

export const logStripeError = (error: string) => {
   return RestClient.post<void>(apiUrl + '/log-stripe-error', error)
}

export const deleteFoodOrder = (foodOrderId: string) => {
   return RestClient.delete<void>(apiUrl + '/delete-order/' + foodOrderId)
}

export const cancelOrderByChef = (orderId: string, reason: string) => {
   return RestClient.post<void>(`${apiUrl}/cancel-order-by-chef/${orderId}`, { reason })
}

export interface DashboardLink {
   url: string
   linkType: string
}

export const getMyDashboard = () => {
   return RestClient.get<DashboardLink>(apiUrl + '/my-dashboard')
}
export const getMyClientSecret = () => {
   return RestClient.get<string>(apiUrl + '/get-client-secret')
}

export interface CapabilitiesDTO {
   capabilities: string[]
   onboardingURL: string
}

export const getMyCapabilities = () => {
   return RestClient.get<CapabilitiesDTO>(apiUrl + '/get-my-capabilities')
}

export const refreshConnectAccountAfterUpdate = () => {
   return RestClient.post<void>(apiUrl + '/refresh-connect-account')
}
