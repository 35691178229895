import { Food } from '@/model/order/food.model'
import { Chef } from '@/model/chef.model'
import { DeliveryType } from '@/model/enumerations/delivery-type.model'

export interface FoodOrderItem {
   id?: string
   food: Food
   quantity: number
   instructions?: string
   createDate?: string
   orderType: OrderType
}

export interface OrderForChef {
   orders: FoodOrderItem[]
   chef: Chef
}

export enum OrderType {
   CREATE = 'CREATE',
   ADD_QUANTITY = 'ADD_QUANTITY',
   REMOVE_QUANTITY = 'REMOVE_QUANTITY',
   // todo use it
   REMOVE_FROM_SHOPPING_CART = 'REMOVE_FROM_SHOPPING_CART',
}
