import { UserLoginComponent, UserLoginComponentType } from '@/layout/common/UserLoginComponent'
import { SingleItemInSC } from '@/layout/shopping_cart/SingleItemInSC'
import { Order } from '@/model/order/order'
import React from 'react'
import { formatPrice } from '@/util/text-utils'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface OrdersForChefProps {
   order: Order
   withBorderAndBG?: boolean
   compactVersion?: boolean
   forChefMode?: boolean
}

export const OrdersForChef = ({
   order,
   withBorderAndBG,
   compactVersion = false,
   forChefMode = false,
}: OrdersForChefProps) => {
   const navigate = useNavigate()
   const { t } = useTranslation()
   return (
      <>
         <div
            className={`rounded-normal mb-4 flex flex-col ${withBorderAndBG ? 'background-normal border-gray-custom' : ''}`}
         >
            {!forChefMode && (
               <div
                  className='mb-4 flex items-center'
                  onClick={() => {
                     navigate('/chef/' + order.chef?.id)
                  }}
               >
                  <div className='text-xl font-semibold'>
                     {t('shopping_cart.order_from')}&nbsp;{order.chef?.name}
                  </div>
               </div>
            )}
            <div id='singleItemInSCForChef' className='space-y-4'>
               {order.orderItems.map((foodOrder, index) => (
                  <SingleItemInSC
                     key={'food' + index}
                     foodOrder={foodOrder}
                     buttonsEnabled={false}
                     compactVersion={compactVersion}
                  />
               ))}
            </div>
            {order?.totalPrice && (
               <div className='flex justify-end text-lg font-medium'>Total: €{formatPrice(order.totalPrice)}</div>
            )}
         </div>
      </>
   )
}
