import { RestClient } from '@/config/rest-client'
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit'
import { defaultValue, INewsUser } from '@/model/news-user.model'
import { IUser } from '@/model/user.model'
import { BlockInfoProps } from '@/layout/common/AdminModUtils'
import { createEntitySlice, EntityState, IQueryParams } from '@/service/reducer.utils'

const initialState: EntityState<INewsUser> = {
   loading: false,
   errorMessage: null,
   entities: [],
   entity: defaultValue,
   updating: false,
   totalItems: 0,
   updateSuccess: false,
}

const apiUrl = '/api/news-users'

export const searchUsersAxios = filersMap => {
   return RestClient.post<IUser[]>(`/api/admin/users/get`, filersMap)
}
export const fetUserDetailsByIds = ids => {
   return RestClient.post<IUser[]>(`/api/admin/users/get-details`, ids)
}

export const updateAuthorities = (body: any) => {
   return RestClient.post<any>('/api/assign-authority', body)
}

export const blockUser = (body: any) => {
   return RestClient.post<any>('/api/block-user', body)
}
export const deleteUser = (body: any) => {
   return RestClient.post<any>('/api/delete-user', body)
}
export const unblockUser = (body: BlockInfoProps) => {
   return RestClient.post<any>('/api/unblock-user', body)
}
export const activateUserById = (userId: string) => {
   return RestClient.post<any>('/api/activate-user/' + userId)
}
export const changeLogin = (userId: string, newLogin: string) => {
   return RestClient.post<any>(`/api/change-login/${userId}/${newLogin}`)
}

export const getUsersForMention = login => {
   const requestUrl = `/api/mention-users?login=${login}`
   return RestClient.get<any>(requestUrl)
}

export const getEntities = createAsyncThunk(
   'newsUser/fetch_entity_list',
   async ({ page, size, sort }: IQueryParams) => {
      const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`
      return RestClient.get<INewsUser[]>(requestUrl)
   },
)

export const getEntity = createAsyncThunk('newsUser/fetch_entity', async (id: string | number) => {
   const requestUrl = `${apiUrl}/${id}`
   return RestClient.get<INewsUser>(requestUrl)
})

export const createEntity = createAsyncThunk('newsUser/create_entity', async (entity: INewsUser, thunkAPI) => {
   const result = await RestClient.post<INewsUser>(apiUrl, entity)
   thunkAPI.dispatch(getEntities({}))
   return result
})

export const updateEntity = createAsyncThunk('newsUser/update_entity', async (entity: INewsUser, thunkAPI) => {
   const result = await RestClient.put<INewsUser>(`${apiUrl}/${entity.id}`, entity)
   thunkAPI.dispatch(getEntities({}))
   return result
})

export const deleteEntity = createAsyncThunk('newsUser/delete_entity', async (id: string | number, thunkAPI) => {
   const requestUrl = `${apiUrl}/${id}`
   const result = await RestClient.delete<INewsUser>(requestUrl)
   thunkAPI.dispatch(getEntities({}))
   return result
})

// slice

export const NewsUserSlice = createEntitySlice({
   name: 'newsUser',
   initialState,
   extraReducers(builder) {
      builder
         .addCase(getEntity.fulfilled, (state, action) => {
            state.loading = false
            state.entity = action.payload.data
         })
         .addCase(deleteEntity.fulfilled, state => {
            state.updating = false
            state.updateSuccess = true
            state.entity = {}
         })
         .addMatcher(isFulfilled(getEntities), (state, action) => {
            const { data, headers } = action.payload

            return {
               ...state,
               loading: false,
               entities: data,
               totalItems: parseInt(headers['x-total-count'], 10),
            }
         })
         .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
            state.updating = false
            state.loading = false
            state.updateSuccess = true
            state.entity = action.payload.data
         })
         .addMatcher(isPending(getEntities, getEntity), state => {
            state.errorMessage = null
            state.updateSuccess = false
            state.loading = true
         })
         .addMatcher(isPending(createEntity, updateEntity, deleteEntity), state => {
            state.errorMessage = null
            state.updateSuccess = false
            state.updating = true
         })
   },
})

export const { reset } = NewsUserSlice.actions

// Reducer
export default NewsUserSlice.reducer
