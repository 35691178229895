export const DEFAULT_BOUNDS: [number[], number[]] = [
   [48.815573, 2.224199],
   [48.902145, 2.46992],
] // Bounds for Paris, France

export interface ChefFilters {
   cuisine?: string[]
   delivery?: string[]
   dietary?: string[]
   sort?: string
   bounds?: [number[], number[]]
   //mobile only
   radius?: number
   // frontend only
   zoom?: number
}

interface UpdateFiltersInUrlProps {
   updatedFilters: ChefFilters
}

export const updateFiltersInUrl = ({ updatedFilters }: UpdateFiltersInUrlProps) => {
   const params = new URLSearchParams(location.search)

   if (updatedFilters?.cuisine?.length > 0) {
      params.set('cuisine', updatedFilters.cuisine.join(','))
   } else {
      params.delete('cuisine')
   }

   if (updatedFilters?.delivery?.length > 0) {
      params.set('delivery', updatedFilters.delivery.join(','))
   } else {
      params.delete('delivery')
   }

   if (updatedFilters?.dietary?.length > 0) {
      params.set('dietary', updatedFilters.dietary.join(','))
   } else {
      params.delete('dietary')
   }

   if (updatedFilters?.sort) {
      params.set('sort', updatedFilters.sort)
   } else {
      params.delete('sort')
   }

   if (updatedFilters?.bounds && updatedFilters?.zoom !== undefined) {
      updateBoundsInParams(updatedFilters.bounds, updatedFilters.zoom, params)
   }

   const newUrl = `${location.pathname}?${params.toString()}`
   window.history.replaceState({}, '', newUrl)
}

export const parseFiltersFromUrl = (isMobile: boolean, coordinates: [number, number] | null): ChefFilters => {
   const params = new URLSearchParams(location.search)
   const cuisine = params.get('cuisine') ? params.get('cuisine')!.split(',') : []
   const delivery = params.get('delivery') ? params.get('delivery')!.split(',') : []
   const dietary = params.get('dietary') ? params.get('dietary')!.split(',') : []
   const sort = params.get('sort') || 'POPULAR'
   const zoom = parseInt(params.get('zoom') || '8', 10)

   // Parse the bounds string from the URL
   const boundsString = params.get('bounds')
   let bounds: [number[], number[]]

   if (boundsString) {
      bounds = boundsString.split('|').map(coord => coord.split(',').map(Number)) as [number[], number[]]
   } else if (coordinates) {
      // Calculate bounds based on the given coordinates (you can adjust the logic to fit your needs)
      const offset = 0.05 // Adjust this value to determine the size of the bounding box
      const [lat, lng] = coordinates
      bounds = [
         [lat - offset, lng - offset],
         [lat + offset, lng + offset],
      ]
   } else {
      bounds = DEFAULT_BOUNDS
   }

   const radius: number | null = isMobile ? parseInt(params.get('radius') || '10', 10) || 10 : null

   return { cuisine, delivery, dietary, sort, radius, zoom, bounds }
}

const updateBoundsInParams = (bounds: [number[], number[]], zoom: number, params: URLSearchParams) => {
   // Convert bounds to a string format "lat1,lng1|lat2,lng2"
   const boundsString = bounds.map(coord => coord.map(c => roundCoord(c).toFixed(9)).join(',')).join('|')
   params.set('bounds', boundsString)
   params.set('zoom', zoom.toString())
}

// todo maybe round
export const roundCoord = (coord: number, precision = 2) => {
   // return Math.round(coord * Math.pow(10, precision)) / Math.pow(10, precision)
   return coord
}
