import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'
import { confirmEmailChange, requestEmailChange, resendEmailChangeVerification } from '@/service/user-settings-reducer'
import { useAppDispatch } from '@/config/store'
import { getSession } from '@/service/authentication'
import { useNavigate } from 'react-router-dom'
import InputWithHeader from '../common/InputWithHeader'
import { ClipLoader } from 'react-spinners'
import { isMobile } from 'react-device-detect'

const EmailChangeAndVerification = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const [step, setStep] = useState(1)
   const [isLoading, setIsLoading] = useState(false)
   const [email, setEmail] = useState('')
   const [emailError, setEmailError] = useState<string | null>(null)
   const [code, setCode] = useState(['', '', '', '', '', ''])

   const [verificationError, setVerificationError] = useState<string | null>(null)

   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm<any>()

   const handleEmailSubmit = (values: any) => {
      const newEmail = values.new
      setIsLoading(true)
      setEmailError(null)
      requestEmailChange(newEmail)
         .then(() => {
            setIsLoading(false)
            setEmail(newEmail)
            setStep(2)
         })
         .catch(reason => {
            setIsLoading(false)
            const error = reason.response?.data?.errorKey
            console.log(reason.response?.data?.errorKey)
            if (error === 'email_already_exists_in_change') {
               setEmailError(t('change_email.error_email_exists'))
            }
         })
   }

   const focusInput = (index: number) => {
      const nextInput = document.getElementById(`code-input-${index}`)
      if (nextInput) {
         ;(nextInput as HTMLInputElement).focus()
      }
   }

   const handleCodeInput = (value: string, index: number) => {
      const newCode = [...code]
      newCode[index] = value
      setCode(newCode)
   }

   const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, startIndex: number) => {
      e.preventDefault()
      const pasteData = e.clipboardData.getData('Text').replace(/\D/g, '') // only digits
      if (!pasteData) return

      const newCode = [...code]
      let currentIndex = startIndex

      for (let i = 0; i < pasteData.length && currentIndex < newCode.length; i++) {
         newCode[currentIndex] = pasteData[i]
         currentIndex++
      }

      setCode(newCode)

      for (let i = startIndex; i < newCode.length; i++) {
         if (newCode[i] === '') {
            focusInput(i)
            return
         }
      }
      if (currentIndex >= newCode.length) {
         const lastInput = document.getElementById(`code-input-${newCode.length - 1}`)
         if (lastInput) (lastInput as HTMLInputElement).focus()
      }
      setVerificationError(null)
   }

   const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
      if (e.key === 'Backspace' && code[index] === '') {
         if (index > 0) {
            focusInput(index - 1)
         }
      }
   }

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const val = e.target.value
      if (!val) {
         handleCodeInput('', index)
         return
      }

      if (/^\d$/.test(val)) {
         handleCodeInput(val, index)
         setVerificationError(null)
         if (index < code.length - 1) {
            focusInput(index + 1)
         }
      } else {
         e.target.value = code[index]
      }
   }

   const handleEmailChange = () => {
      if (emailError) {
         setEmailError(null)
      }
   }

   const handleCodeSubmit = () => {
      const verificationCode = code.join('')
      setIsLoading(true)
      setVerificationError(null)
      confirmEmailChange(verificationCode)
         .then(() => {
            toast.success(t('change_email.success_verification'))
            dispatch(getSession)
            navigate('/user-panel')
         })
         .catch(err => {
            setIsLoading(false)
            const error = err?.response?.data?.errorKey
            if (error === 'invalid_verification_code') {
               setVerificationError(t('change_email.error_invalid_code'))
            }
            if (error === 'verification_code_expired') {
               setVerificationError(t('change_email.error_code_expired'))
            }
         })
   }

   const handleResendVerification = () => {
      setIsLoading(true)
      resendEmailChangeVerification()
         .then(() => {
            setIsLoading(false)
            toast.success(t('change_email.success_code_resent'))
            setCode(['', '', '', '', '', ''])
            focusInput(0)
            setVerificationError(null)
         })
         .catch(err => {
            setIsLoading(false)
            toast.error(t('change_email.error_resend'))
         })
   }

   return (
      <div className={`mx-auto ${isMobile ? 'mt-[60px]': 'mt-[70px]'} max-w-md rounded-lg bg-white p-6 shadow-md sm:max-w-[30rem]`}>
         {step === 1 && (
            <form onSubmit={handleSubmit(handleEmailSubmit)} className='space-y-7'>
               <h1 className='mb-4 text-center text-xl font-bold text-gray-800'>
                  {t('change_email.title_change_email')}
               </h1>
               <p className='text-center text-gray-600'>{t('change_email.instruction_change_email')}</p>
               <InputWithHeader
                  label={t('change_email.new_email_label')}
                  name='new'
                  type='email'
                  placeholder={t('change_email.new_email_placeholder')}
                  register={register}
                  errors={errors}
                  rules={{
                     required: t('error.required'),
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t('change_email.error_invalid_email'),
                     },
                  }}
                  onChange={handleEmailChange}
                  externalError={emailError}
               />
               <button
                  type='submit'
                  className='confirm-button w-full py-2 text-center font-semibold'
                  disabled={isLoading}
               >
                  {isLoading ? (
                     <div className='flex items-center justify-center'>
                        <ClipLoader size={24} color={'#edf2f7'} loading={true} />
                     </div>
                  ) : (
                     t('common.submit')
                  )}
               </button>
            </form>
         )}

         {step === 2 && (
            <div className='space-y-7'>
               <h1 className='mb-4 text-center text-xl font-bold text-gray-800'>
                  {t('change_email.title_verify_email')}
               </h1>
               <p className='text-center text-gray-600'>
                  {t('change_email.verify_instruction')} <span className='font-medium text-gray-800'>{email}</span>.
               </p>
               <div className='flex justify-center space-x-2'>
                  {code.map((digit, index) => (
                     <input
                        key={index}
                        id={`code-input-${index}`}
                        type='text'
                        maxLength={1}
                        value={digit}
                        onKeyDown={e => handleKeyDown(e, index)}
                        onChange={e => handleChange(e, index)}
                        onPaste={e => handlePaste(e, index)}
                        className='background-gray-dark-normal h-12 w-12 rounded-md border border-gray-300 text-center text-xl font-medium text-gray-700 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:outline-hidden'
                     />
                  ))}
               </div>
               {verificationError && <p className='error-validation'>{verificationError}</p>}

               <button
                  onClick={handleCodeSubmit}
                  className={`confirm-button w-full py-2 font-semibold ${
                     !code.every(digit => digit !== '') ? 'cursor-not-allowed opacity-50' : ''
                  }`}
                  disabled={!code.every(digit => digit !== '') || isLoading}
               >
                  {isLoading ? (
                     <div className='flex items-center justify-center'>
                        <ClipLoader size={24} color={'#edf2f7'} loading={true} />
                     </div>
                  ) : (
                     t('change_email.verify_button')
                  )}
               </button>
               <div className='flex justify-center space-x-4 text-sm font-medium'>
                  <button
                     onClick={handleResendVerification}
                     className='text-blue-600 transition-colors hover:text-blue-700 focus:outline-hidden'
                     disabled={isLoading}
                  >
                     {t('change_email.resend_code')}
                  </button>
                  <button
                     onClick={() => {
                        setStep(1)
                        setCode(['', '', '', '', '', ''])
                        setVerificationError(null)
                     }}
                     className='text-blue-600 transition-colors hover:text-blue-700 focus:outline-hidden'
                     disabled={isLoading}
                  >
                     {t('change_email.back_button')}
                  </button>
               </div>
            </div>
         )}
      </div>
   )
}

export default EmailChangeAndVerification
