import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { updateComment } from '@/service/comment/comment-reducer'
import { MIN_COMMENT_LENGTH } from '@/layout/post/comment/desktop/AddEditCommentComponentWeb'
import { PhotoType } from '@/model/enumerations/photo-type'
import { LexicalEditorWrapper, SubmitLexicalButton } from '@/layout/common/lexical-editor/LexicalEditorWrapper'

export const EditCommentForm = ({ comment, onEditFinished }) => {
   // todo to common constants

   const { t } = useTranslation()
   const [loading, setLoading] = useState(false)
   const [picture, setPicture] = useState('')

   const editorStateHTMLRef = useRef('')
   const clearEditorContentRef = useRef(null)
   const [isEditorStateValid, setIsEditorStateValid] = useState(false)

   const onSubmit = () => {
      if (!isEditorStateValid || loading) return
      setLoading(true)
      updateComment({
         id: comment.id,
         content: editorStateHTMLRef.current,
         picture: picture,
         postId: comment.postId,
      })
         .then(value => {
            toast.success(t('comments.editSuccess'))
            onEditFinished(true, value.data)
            if (clearEditorContentRef.current) {
               clearEditorContentRef.current()
            }
         })
         .catch(reason => {})
         .finally(() => {
            setLoading(false)
            // todo decide if we need to update the comment content
         })
   }

   function clearEditorContent(callback) {
      clearEditorContentRef.current = callback
   }

   return (
      <div className='relative mt-1 ml-1.5 flex w-full min-w-full flex-row'>
         <LexicalEditorWrapper
            placeholder={<p className='lexical-placeholder'>{t('add_your_post.add_new_discussion')}</p>}
            photoType={PhotoType.FOR_COMMENT}
            // todo size
            htmlStateUpdateFunction={(newState: string, size: number, hasImage: boolean) => {
               if (hasImage || size >= MIN_COMMENT_LENGTH) setIsEditorStateValid(true)
               else setIsEditorStateValid(false)
               editorStateHTMLRef.current = newState
            }}
            clearEditorContent={clearEditorContent}
            initialHtml={comment.content}
            addImageEnabled={true}
         />
         <SubmitLexicalButton loading={loading} isValid={isEditorStateValid} onSubmit={onSubmit} />
      </div>
   )
}
