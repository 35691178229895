import React from 'react'
import { AbstractMobileModal } from '@/layout/common/AbstractMobileModal'
import ChefDetail from '@/layout/chef/ChefDetail'
import { Chef } from '@/model/chef.model'

interface SingleChefInAppViewProps {
   customNavigationFunction: () => void
   chef: Chef
}

const SingleChefInAppView = ({ customNavigationFunction, chef }: SingleChefInAppViewProps) => {
   return (
      <AbstractMobileModal
         customNavigation={() => {
            customNavigationFunction()
         }}
         title={<p className='pt-1 text-lg'>{chef.name}</p>}
      >
         <div className=' '>{chef && <ChefDetail propChef={chef} />}</div>
      </AbstractMobileModal>
   )
}
export default SingleChefInAppView
