import { dotSpinner, newtonsCradle, superballs } from 'ldrs'

dotSpinner.register()
newtonsCradle.register()
superballs.register()

interface LoadingIndicatorProps {
   blueColor?: boolean
}

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({ blueColor = false }) => {
   return (
      <div
         style={{ zIndex: 1200 }}
         className={`fixed inset-0 flex items-center justify-center ${blueColor ? 'bg-blue-500/5-' : 'bg-black/50'}`}
      >
         {/*@ts-ignore*/}
         <l-dot-spinner size={90} speed={0.9} color={`${blueColor ? 'blue' : 'white'}`} />
      </div>
   )
}

interface LoadingBallsProps {
   cursorWithWaitEffect?: boolean
}

export const LoadingBalls = ({ cursorWithWaitEffect = false }: LoadingBallsProps) => {
   const containsDark = document.documentElement.classList.contains('dark')
   return (
      <div className={`flex flex-row items-center justify-center ${cursorWithWaitEffect ? 'cursor-wait' : ''}`}>
         <div>
            {/*@ts-ignore*/}
            <l-newtons-cradle size={70} speed={1.4} color={containsDark ? 'white' : 'black'} />
         </div>
      </div>
   )
}

export const SuperBallsLoadingIndicator = () => {
   const containsDark = document.documentElement.classList.contains('dark')
   /*@ts-ignore*/
   return <l-superballs color={containsDark ? 'white' : 'black'} />
}

export function loadingIndicator(loading: boolean) {
   const containsDark = document.documentElement.classList.contains('dark')
   return (
      <>
         {loading && (
            <div className='flex flex-row items-center justify-center'>
               <div className=''>
                  {/*@ts-ignore*/}
                  <l-newtons-cradle size={50} speed={1.4} color={containsDark ? 'white' : 'black'} />
               </div>
            </div>
         )}
      </>
   )
}
