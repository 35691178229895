import { Circle, Marker, Popup } from 'react-leaflet'
import React from 'react'
import L from 'leaflet'
import { useTranslation } from 'react-i18next'

const locationIcon = new L.Icon({
   iconUrl:
      'data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><circle cx="5" cy="5" r="5" fill="%23007BFF"/></svg>',
   iconSize: [10, 10], // size of the icon
   iconAnchor: [5, 5], // point of the icon which will correspond to marker's location
})

interface MyLocationMarkerProps {
   myLocation: [number, number] | null | undefined
   accuracy: number | null
}

export const MyLocationMarker = ({ myLocation, accuracy }: MyLocationMarkerProps) => {
   const { t } = useTranslation()

   // Check if myLocation is valid
   const isValidLocation =
      Array.isArray(myLocation) &&
      myLocation.length === 2 &&
      typeof myLocation[0] === 'number' &&
      typeof myLocation[1] === 'number' &&
      !isNaN(myLocation[0]) &&
      !isNaN(myLocation[1])

   return (
      <>
         {isValidLocation && (
            <Marker position={myLocation as [number, number]} icon={locationIcon}>
               <Popup>{t('chef_map.you_are_here')}</Popup>
            </Marker>
         )}
         {isValidLocation && accuracy && (
            <Circle
               center={myLocation as [number, number]}
               radius={accuracy} // Accuracy in meters
               fillColor='blue'
               fillOpacity={0.15}
               stroke={false}
            />
         )}
      </>
   )
}
