import React, { useEffect } from 'react'
import { MapContainer, Marker, Polyline, Popup, TileLayer, useMap } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import * as turf from '@turf/turf'
import { Units } from '@turf/turf'
import { useAppSelector } from '@/config/store'
import { renderToStaticMarkup } from 'react-dom/server'
import { FaMapMarkerAlt, FaMapPin } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

// Function to calculate the distance
const calculateDistance = (coords1: [number, number], coords2: [number, number]) => {
   if (
      typeof coords1[0] !== 'number' ||
      typeof coords1[1] !== 'number' ||
      typeof coords2[0] !== 'number' ||
      typeof coords2[1] !== 'number'
   ) {
      console.error('Invalid coordinates provided')
      return '0'
   }
   const from = turf.point(coords1)
   const to = turf.point(coords2)
   const options: { units: Units } = { units: 'kilometers' }
   const distance = turf.distance(from, to, options)
   return distance.toFixed(2) // returns distance in kilometers with two decimal points
}

const createIcon = (icon: React.ReactNode) => {
   return L.divIcon({
      html: renderToStaticMarkup(icon),
      className: '',
      iconSize: [25, 41],
      iconAnchor: [12, 41],
   })
}

// Custom icons
const userLocationIcon = createIcon(<FaMapMarkerAlt className='mt-4' style={{ color: 'blue', fontSize: '25px' }} />)
export const targetLocationIcon = createIcon(<FaMapPin className='mt-4' style={{ color: 'red', fontSize: '25px' }} />)

const FitBounds = ({ bounds }: { bounds: [[number, number], [number, number]] }) => {
   const map = useMap()
   useEffect(() => {
      map.fitBounds(bounds, { padding: [10, 20] }) // Add padding to the bounds
   }, [bounds, map])
   return null
}

export const LocationMarkerByCoordinates = ({
   coordinates,
   isPickupPoint,
}: {
   coordinates: [number, number]
   isPickupPoint?: boolean
}) => {
   const { t } = useTranslation()
   const { currentUserLocation } = useAppSelector(state => state.search)

   if (!coordinates || coordinates[0] === undefined || coordinates[1] === undefined) {
      console.error('Invalid coordinates provided', coordinates)
      return <div>{/* Handle case where coordinates are not available */}</div>
   }

   const userCords: [number, number] = [
      currentUserLocation?.latitude ?? 0, // Fallback to 0 if undefined
      currentUserLocation?.longitude ?? 0, // Fallback to 0 if undefined
   ]

   if (!currentUserLocation && userCords) {
      return (
         <MapContainer
            style={{ height: '180px', width: '100%', borderRadius: '4px', overflow: 'hidden' }}
            center={coordinates}
            zoom={13}
            scrollWheelZoom={false}
            zoomControl={false}
         >
            <TileLayer
               url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
               attribution='<span style="font-size: 10px;">&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors</span>'
            />
            <Marker position={coordinates} icon={targetLocationIcon}>
               <Popup>{t(isPickupPoint ? 'chef_map.pick_up_point_pin' : 'chef_map.chef_pin')}</Popup>
            </Marker>
         </MapContainer>
      )
   }

   const distance = calculateDistance(userCords, coordinates)
   const bounds: [[number, number], [number, number]] = [userCords, coordinates]
   const mapStyle = {
      height: '100%',
      width: '100%',
      borderRadius: '0.375rem',
   }

   return (
      <MapContainer
         style={mapStyle}
         center={coordinates}
         zoom={13}
         scrollWheelZoom={false}
         zoomControl={false}
         className='rounded-md'
      >
         <TileLayer
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
         />
         {/* Conditionally render user location marker if available */}
         {currentUserLocation && (
            <Marker position={userCords} icon={userLocationIcon}>
               <Popup>{t('your_location')}</Popup>
            </Marker>
         )}
         {/* Always render target location marker */}
         <Marker position={coordinates} icon={targetLocationIcon}>
            <Popup>{t('target_location')}</Popup>
         </Marker>

         {/* Draw a polyline between user location and target location */}
         {currentUserLocation && <Polyline positions={[userCords, coordinates]} color='blue' />}
         {/* Adjust map bounds to fit both locations */}
         {currentUserLocation && <FitBounds bounds={bounds} />}
         {/* Display distance information */}
         {currentUserLocation && <DistanceInfoControl distance={distance} smallView={true} />}
      </MapContainer>
   )
}

const DistanceInfoControl = ({ distance, smallView }: { distance: string; smallView?: boolean }) => {
   const map = useMap()
   const { t } = useTranslation()

   useEffect(() => {
      // @ts-ignore
      const distanceInfo = L.control({ position: 'topleft' })

      distanceInfo.onAdd = () => {
         const div = L.DomUtil.create('div', 'distance-info')
         div.innerHTML = `<p class="m-0 p-1 bg-white/85 rounded-xl text-black ${smallView ? 'text-base' : 'text-lg'}">
            ${t('chef_map.distance')} ${distance} km
    </p>`
         return div
      }

      distanceInfo.addTo(map)
      return () => {
         distanceInfo.remove()
      }
   }, [distance, map, smallView, t])

   return null
}
