import React, { useEffect } from 'react'

export enum ScreenSizeType {
   Small = 'small',
   Medium = 'medium',
   Large = 'large',
   ExtraLarge = 'extraLarge',
}

export const ScreenSize = ({ changeScreenSize }) => {
   useEffect(() => {
      function handleResize() {
         const width = window.innerWidth
         if (width < 600) changeScreenSize(ScreenSizeType.Small)
         else if (width >= 600 && width < 700) changeScreenSize(ScreenSizeType.Medium)
         else if (width >= 700 && width < 980) changeScreenSize(ScreenSizeType.Large)
         else changeScreenSize(ScreenSizeType.ExtraLarge)
      }

      window.addEventListener('resize', handleResize)
      handleResize() // initial check on component mount

      return () => {
         window.removeEventListener('resize', handleResize)
      }
   }, [])

   return <></>
}
