import { useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import FoodForm from '@/layout/food/add-edit/FoodForm'

export const CreateFood = () => {
   const { chefId } = useParams()

   useEffect(() => {
      console.log('chefId ', chefId)
   }, [])

   return chefId && <FoodForm chefId={chefId} />
}

export default CreateFood
