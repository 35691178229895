import React, { useEffect, useState } from 'react'
import { RcFile } from 'antd/es/upload/interface'
import { UploadChangeParam } from 'antd/es/upload'
import { Upload } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '@/config/store'
import { getSession } from '@/service/authentication'
import { LoadingBalls } from './LoadingIndicator'
import { deleteUserProfilePhoto } from '@/service/photo/photo-reducer'

export const DeletePhotoButton = ({ setImageUrl, loggedAccount, imageUrl }) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const [isLoading, setIsLoading] = useState(false)
   return (
      <div>
         <p
            role='button'
            className='text-red-600 hover:cursor-pointer'
            onClick={() => {
               setIsLoading(true)
               deleteUserProfilePhoto()
                  .then(value => {
                     setImageUrl(null)
                     dispatch(getSession)
                  })
                  .finally(() => {
                     setIsLoading(false)
                  })
            }}
         >
            {imageUrl && t('profile_settings.delete_own_avatar')}
         </p>
         {isLoading && <LoadingBalls />}
      </div>
   )
}

export const getBase64 = (img: RcFile, callback: (url: string) => void) => {
   const reader = new FileReader()
   reader.addEventListener('load', () => callback(reader.result as string))
   reader.readAsDataURL(img)
}

export interface UploadButtonProps {
   loading: boolean
   placeholder: string
}

const UploadButton = ({ loading, placeholder }: UploadButtonProps) => {
   const { t } = useTranslation()
   return (
      <div>
         {loading ? <LoadingOutlined /> : <PlusOutlined className='text-color-normal' />}
         <div style={{ marginTop: 8 }} className='text-color-normal'>
            {placeholder || t('common.upload_button')}
         </div>
      </div>
   )
}

interface UploadPhotoProps {
   imageUrl: string
   loading: boolean
   placeholderText?: string
   uploadImage: (options: any) => void
   beforeUpload: (file: RcFile) => boolean
   handleChange: (info: UploadChangeParam) => void
}

export const UploadPhoto = ({
   imageUrl,
   loading,
   placeholderText,
   uploadImage,
   beforeUpload,
   handleChange,
}: UploadPhotoProps) => {
   useEffect(() => {}, [imageUrl])

   return (
      <Upload
         name='avatar'
         listType='picture-card'
         showUploadList={false}
         customRequest={uploadImage}
         beforeUpload={beforeUpload}
         onChange={handleChange}
      >
         {imageUrl ? (
            <img src={imageUrl} alt='avatar' className='h-[100px] w-[100px] object-cover' />
         ) : (
            <UploadButton loading={loading} placeholder={placeholderText} />
         )}
      </Upload>
   )
}
