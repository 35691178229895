import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { useTranslation } from 'react-i18next'
import { registerNewUserWithGoogle, saveTokenAndGetUserInfo, showLoginModal } from '@/service/authentication'
import { LoadingIndicator } from '../common/LoadingIndicator'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { FloatingLabelInput } from '@/layout/common/FloatingLabelInput'
import { RegisterFormGoogleData } from '@/model/register.model'

const RegisterGoogle = () => {
   const location = useLocation()
   const locale = useAppSelector(state => state.localeSlice.lng)
   const {
      register,
      handleSubmit,
      getValues,
      setValue,
      watch,
      formState: { errors, isValid },
   } = useForm<RegisterFormGoogleData>({
      mode: 'all',
      defaultValues: {
         email: location.state?.email,
         tokenId: location.state?.tokenId,
      },
   })
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   const [errorMessage, setErrorMessage] = useState('')

   const onSubmit = useCallback((formData: RegisterFormGoogleData) => {
      formData.locale = locale
      registerNewUserWithGoogle(formData)
         .then(res => {
            saveTokenAndGetUserInfo(res.headers.authorization, true, dispatch)
               .then(r => {})
               .finally(() => {
                  setIsLoading(false)
                  navigate('/')
                  window.location.reload()
               })
         })
         .catch(err => {
            console.log(err)
         })
         .finally(() => {
            setIsLoading(false)
         })
   }, [])

   function triggerLoginModal() {
      setErrorMessage('')
      dispatch(showLoginModal())
   }

   return (
      <div className='flex items-center justify-center'>
         <div className='border-gray-custom rounded-normal mx-auto max-w-xl border bg-white pr-3 pb-2 pl-3'>
            <div className='mb-3 w-full items-center text-center sm:mb-8'>
               <p className='text-coolGray-600 mt-6 text-lg font-semibold'>{t('sign.google_complete_register')}</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
               <div className='space-y-4'>
                  <FloatingLabelInput<RegisterFormGoogleData>
                     label={t('sign.login')}
                     name='login'
                     type='text'
                     register={register}
                     rules={{ required: t('error.required') }}
                     errors={errors}
                     watch={watch}
                  />

                  <FloatingLabelInput<RegisterFormGoogleData>
                     label={t('email')}
                     name='email'
                     type='email'
                     register={register}
                     rules={{}}
                     watch={watch}
                     errors={errors}
                     value={{ editDisabled: true, value: location.state?.email }}
                  />
               </div>

               <div className='mt-2 space-y-4'>
                  <div className='text-sm-normal mt-2 inline-block'>
                     <input
                        type='checkbox'
                        name='rulesAccepted'
                        {...register('rulesAccepted', {
                           required: t('error.required'),
                        })}
                        className='checkbox-lg mr-1 ml-1 align-middle'
                     />
                     <span
                        className='inline cursor-pointer align-middle'
                        onClick={() =>
                           setValue('rulesAccepted', !getValues('rulesAccepted'), {
                              shouldValidate: true,
                           })
                        }
                     >
                        <span className='text-red-500'>*</span> {t('sign.accept_terms')}
                     </span>
                     &nbsp;
                     <Link
                        to='/terms-and-conditions'
                        className='inline align-middle text-blue-600 hover:cursor-pointer hover:underline'
                        target='_blank'
                     >
                        {t('sign.terms_link')}
                     </Link>
                  </div>

                  {errors.rulesAccepted && <p className='error-validation'>{t('sign.errors.rules_accepted')}</p>}

                  <div className='text-sm-normal mt-2 flex flex-row'>
                     <label className='' htmlFor='advAccepted'>
                        <input
                           type='checkbox'
                           name='advAccepted'
                           className='checkbox-lg mr-1 ml-1'
                           {...register('advAccepted')}
                           defaultChecked={true}
                           id='advAccepted'
                        />
                        {t('sign.accept_adv')}
                     </label>
                  </div>
                  {errorMessage.length > 0 && <p className='error-validation'>{errorMessage}</p>}
                  <div className='mt-5 space-y-2'>
                     <div className='flex gap-x-2'>
                        <button
                           type='submit'
                           className={`sign-confirm-button ${!isValid ? 'gray-out' : ''}`}
                           disabled={!isValid}
                        >
                           {t('sign.join_in')}
                        </button>
                     </div>
                     <p className='text-coolGray-600 px-6 text-center text-sm'>
                        {t('sign.have_an_account')}&nbsp;
                        <a onClick={triggerLoginModal} className='cursor-pointer text-blue-600 hover:underline'>
                           {t('common.log_in')}
                        </a>
                     </p>
                  </div>
               </div>
            </form>
            {isLoading && <LoadingIndicator />}
         </div>
      </div>
   )
}
export default RegisterGoogle
