import React, { useState } from 'react'
import { commentActions, UserCommentDivider, userNameAndContent } from './CommonComment'
import { isMobile } from 'react-device-detect'
import { IComment } from '@/model/comment.model'

export const SubComment = ({ subcomment, triggerAddReply, triggerEditComment }) => {
   const [deleted, setDeleted] = useState(subcomment.deleted)
   const [editing, setEditing] = useState(false)

   function commentDeleted() {
      setDeleted(true)
   }

   function commentUpdated(shouldUpdate: boolean, newComment: IComment) {
      // todo only content is updated
      if (shouldUpdate) subcomment.content = newComment.content
   }

   function onEnableEditMode() {
      if (isMobile) {
         triggerEditComment()
         return
      }
      setEditing(true)
   }

   function changeEditing(value) {
      setEditing(value)
   }

   return (
      <div className='flex'>
         <div className='flex flex-row items-start'>
            <UserCommentDivider user={subcomment.user} />
            <div className='flex flex-col'>
               {userNameAndContent(subcomment, deleted, commentDeleted, commentUpdated, editing, changeEditing)}
               <div className='comment-action-buttons-section'>
                  {commentActions(!subcomment.deleted, triggerAddReply, subcomment, commentDeleted, onEnableEditMode)}
               </div>
            </div>
         </div>
      </div>
   )
}
