import React, { useRef, useState } from 'react'
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import { isDesktop, isMobile } from 'react-device-detect'
import { Link } from 'react-router-dom'
import { AiOutlineDown } from 'react-icons/ai'
import { languages, setLanguage } from '@/i18n/LocaleSlice'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { isUserLoggedIn } from '@/util/auth-util'
import { HiLanguage } from 'react-icons/hi2'
import { useOuterClick } from 'react-outer-click'
import FloatingUIWrapper from '../common/FloatingUIComponent'

export const PageFooter = () => {
   const { t } = useTranslation()
   const dropdownRef = useRef(null)
   const dispatch = useAppDispatch()
   const [showDropdown, setShowDropdown] = useState(false)
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const language = useAppSelector(state => state.localeSlice.lng)

   const sections = [
      {
         title: t('landing_page.lets_do_it_together'),
         links: [
            { text: t('landing_page.become_a_chef'), to: '/become-a-chef' },
            { text: t('landing_page.become_a_pick_up_point'), to: '/become-a-pick-up-point' },
         ],
      },
      {
         title: t('landing_page.links_of_interest'),
         links: [
            { text: t('landing_page.contact_us'), to: '/contact' },
            { text: t('landing_page.about_us'), to: '/about-us' },
            { text: t('landing_page.blog'), to: '/blog' },
            { text: t('landing_page.terms_and_conditions'), to: '/terms-and-conditions' },
            { text: t('landing_page.privacy_policy'), to: '/privacy-policy' },
            { text: t('landing_page.cookie_policy'), to: '/cookies' },
         ],
      },
   ]

   useOuterClick(dropdownRef, () => {
      if (showDropdown) setShowDropdown(false)
   })

   const handleChangeLanguage = (lng: string) => {
      dispatch(setLanguage({ lng, isUserLoggedIn: isUserLoggedIn(loggedAccount) }))
      setShowDropdown(false)
   }

   return (
      <footer
         id={'footer'}
         className={`relative bg-gray-900 p-4 text-white ${isDesktop ? 'left-0' : 'pb-16'} flex w-full flex-col select-none`}
      >
         {isMobile && (
            <div className='absolute top-2 right-2'>
               <img src='/MASCOTTE.svg' alt='Oh Les Chefs Mascotte' className='h-32 w-auto' />
            </div>
         )}
         <div className={`flex ${isDesktop ? 'flex-row' : 'flex-col'}`}>
            {isDesktop && (
               <div className='mt-1 flex flex-1 flex-col items-center justify-start'>
                  <img src='/MASCOTTE.svg' alt='Oh Les Chefs Mascotte' className='mb-2 h-48 w-auto' />
               </div>
            )}
            {sections.map((section, index) => (
               <FooterSection key={index} title={section.title} links={section.links} />
            ))}
            <SocialMediaLinks />
         </div>
         <div className={`m-1 mb-2 ml-0 flex justify-start ${isMobile ? 'mt-6' : ''}`}>
            <FloatingUIWrapper
               disableDefaultStyles={true}
               zIndex={1001}
               menuButton={({ ref, onClick }) => (
                  <button
                     ref={ref}
                     onClick={onClick}
                     className='flex min-w-[180px] items-center gap-2 rounded-md bg-gray-700 p-2 shadow-md hover:cursor-pointer hover:bg-gray-800'
                  >
                     <HiLanguage className='h-6 w-6' />
                     <span>{t(languages[language])}</span>
                     <AiOutlineDown className='mt-0.5 ml-auto h-4 w-4' />
                  </button>
               )}
            >
               <div ref={dropdownRef} className='w-[180px] rounded-lg bg-gray-700 shadow-lg'>
                  {Object.keys(languages)
                     .filter(value => value !== language)
                     .map(lng => (
                        <button
                           key={lng}
                           onClick={() => handleChangeLanguage(lng)}
                           className='block w-full px-4 py-2 text-left text-sm hover:cursor-pointer hover:bg-gray-800'
                        >
                           {t(languages[lng])}
                        </button>
                     ))}
               </div>
            </FloatingUIWrapper>
         </div>
         <a href='https://lordicon.com/' className='mt-6 text-center text-xs text-blue-500'>
            Animated icons by Lordicon.com
         </a>
      </footer>
   )
}

const FooterSection = ({ title, links }) => {
   return (
      <div className={`${isDesktop ? 'mt-4': 'mt-0'} flex flex-1 flex-col justify-start`}>
         {/* Centered header */}
         <div className={`my-2 text-center text-lg font-semibold ${isMobile ? 'hidden' : ''}`}>{title}</div>
         {/* Conditionally aligned links */}
         <div className={`flex flex-col space-y-0.5 text-base ${isMobile ? 'items-start' : 'items-center'}`}>
            {links.map((link, index) => (
               <Link to={link.to} key={index} className='text-white hover:underline'>
                  {link.text}
               </Link>
            ))}
         </div>
      </div>
   )
}

const SocialMediaLinks = () => {
   const { t } = useTranslation()
   const socialMediaLinks = [
      {
         href: 'https://www.linkedin.com/company/oh-les-chefs',
         label: 'LinkedIn',
         icon: <FaLinkedinIn size={isMobile ? 22 : 17} />,
      },
      {
         href: 'https://www.instagram.com/oh_les_chefs/',
         label: 'Instagram',
         icon: <FaInstagram size={isMobile ? 22 : 17} />,
      },
      {
         href: 'https://www.facebook.com/ohleschefs/',
         label: 'Facebook',
         icon: <FaFacebookF size={isMobile ? 22 : 17} />,
      },
   ]
   if (isMobile) {
      return (
         <div className='just mt-4 flex flex-row items-center justify-center gap-4'>
            {socialMediaLinks.map((link, index) => (
               <a
                  href={link.href}
                  target='_blank'
                  rel='noopener noreferrer'
                  aria-label={link.label}
                  key={index}
                  className='text-white transition-colors hover:text-blue-200'
               >
                  {React.cloneElement(link.icon, { color: 'currentColor' })}
               </a>
            ))}
         </div>
      )
   }
   return (
      <div className={`mt-4 flex flex-1 flex-col ${isDesktop ? 'items-center' : 'w-full justify-start'}`}>
         <div className={`my-2 text-center text-lg font-semibold ${isMobile ? 'hidden' : ''}`}>
            {t('landing_page.follow_us')}
         </div>

         <div className={`flex ${!isDesktop ? 'flex-row justify-center gap-2' : ''}`}>
            <div className={`flex ${isMobile ? 'w-full' : ''} flex-col space-y-3 text-base`}>
               {socialMediaLinks.map((link, index) => (
                  <Link
                     to={link.href}
                     target='_blank'
                     rel='noopener noreferrer'
                     aria-label={link.label}
                     key={index}
                     className={`flex ${isMobile ? 'items-start' : 'items-center'} gap-2 text-white transition-colors hover:text-blue-200`}
                  >
                     {React.cloneElement(link.icon, { color: 'currentColor' })}
                     <span>{link.label}</span>
                  </Link>
               ))}
            </div>
         </div>
      </div>
   )
}

export default PageFooter
