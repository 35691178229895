import { Chef } from '@/model/chef.model'
import { MapContainer, TileLayer, useMap, ZoomControl } from 'react-leaflet'
import { MyLocationMarker } from '@/layout/home/maps/MyLocationMarker'
import { LocationMarkers } from '@/layout/home/maps/LocationMarkers'
import { GoToMyLocationButton } from '@/layout/home/maps/GoToMyLocation'
import React, { useEffect, useRef } from 'react'
import { useAppSelector } from '@/config/store'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

const FitBounds = ({ points }: { points: Chef[] }) => {
   const map = useMap()
   const { currentUserLocation } = useAppSelector(state => state.search)

   useEffect(() => {
      if (points.length > 0 || (currentUserLocation?.latitude && currentUserLocation?.longitude)) {
         const validPoints = points
            .map(point =>
               point.address?.latitude && point.address?.longitude
                  ? ([point.address.latitude, point.address.longitude] as [number, number])
                  : null,
            )
            .filter(Boolean) as [number, number][]

         if (currentUserLocation?.latitude && currentUserLocation?.longitude) {
            validPoints.push([currentUserLocation.latitude, currentUserLocation.longitude])
         }

         if (validPoints.length > 0) {
            const bounds = L.latLngBounds(validPoints)
            map.fitBounds(bounds, { padding: [50, 50] }) // Adjust padding if needed
         }
      }
   }, [map, points, currentUserLocation])

   return null
}

interface PickUPointsMapProps {
   points: Chef[]
   addedStyles?: string
   customOnIconClick?: (chefId: string) => void
}

export const PickUPointsMap = ({ points, addedStyles = '', customOnIconClick }: PickUPointsMapProps) => {
   const { currentUserLocation } = useAppSelector(state => state.search)
   const mapRef = useRef(null)

   return (
      <div className={`h-48 ${addedStyles}`}>
         <MapContainer
            minZoom={4}
            className='h-full w-full rounded-md shadow-md select-none'
            zoomControl={false}
            ref={mapRef}
         >
            <TileLayer
               url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
               attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />

            <MyLocationMarker
               myLocation={[currentUserLocation?.latitude, currentUserLocation?.longitude]}
               accuracy={50}
            />

            <FitBounds points={points} />

            <ZoomControl position='bottomright' />

            <LocationMarkers chefs={points} customOnIconClick={customOnIconClick} compactMode={true} />

            <GoToMyLocationButton shouldFetchAddress={false} />
         </MapContainer>
      </div>
   )
}
