import React, { useState } from 'react'

interface ProgressiveImageProps {
   lowQualitySrc: string
   highQualitySrc: string
   alt: string
   className?: string
   style?: React.CSSProperties
   onLoad?: () => void
}

export const ProgressiveImage: React.FC<ProgressiveImageProps> = ({
   lowQualitySrc,
   highQualitySrc,
   alt,
   className = '',
   style = {},
   onLoad,
}) => {
   const [imageLoaded, setImageLoaded] = useState(false)

   const handleHighResImageLoaded = () => {
      setImageLoaded(true)
      if (onLoad) onLoad()
   }

   return (
      <>
         <img
            onError={e => {
               console.log('Error loading low-quality image', e)
               setImageLoaded(true)
            }}
            src={lowQualitySrc}
            className={`${className} linear ${imageLoaded ? 'opacity-0' : 'opacity-100'} inset-0 w-full object-cover`}
            alt={`${alt} (Low Quality)`}
            style={{ ...style }}
         />

         {/* High-quality image that loads in the background */}
         <img
            src={highQualitySrc}
            onLoad={handleHighResImageLoaded}
            className={`${className} linear ${imageLoaded ? 'opacity-100' : 'opacity-0'} inset-0 w-full object-cover`}
            alt={alt}
            style={{ ...style }}
         />
      </>
   )
}
