import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '@/config/store'
import { showLoginModal } from '@/service/authentication'
import { voteForPost } from '@/service/post/post-reducer'
import { TbArrowBigDown, TbArrowBigDownLine, TbArrowBigUp, TbArrowBigUpLine } from 'react-icons/tb'
import { trio } from 'ldrs'

trio.register()

// Default values shown

export const VoteForPost = ({
   id,
   votesUp,
   votesDown,
   afterVoteUP,
   afterVoteDOWN,
   userLoggedIn,
   miniView = false,
   fullView = false,
   voted,
}) => {
   const dispatch = useAppDispatch()
   const [loading, setLoading] = useState(false)
   const containsDark = document.documentElement.classList.contains('dark')
   const [liked, setLiked] = useState(voted == 1)
   const [unliked, setUnliked] = useState(voted == -1)
   const fontSize = miniView ? '15px' : '21px'

   useEffect(() => {
      if (voted == 1) {
         setLiked(true)
         setUnliked(false)
      } else if (voted == -1) {
         setLiked(false)
         setUnliked(true)
      }
   }, [voted])

   const handleVote = async (type: string, afterVote) => {
      if (loading) return
      if (!userLoggedIn) {
         dispatch(showLoginModal())
         return
      }
      setLoading(true)

      const actionType = type === 'UP' ? (liked ? 'UNDO' : 'UP') : unliked ? 'UNDO' : 'DOWN'
      voteForPost({ entityId: id, type: type, fromVote: voted })
         .then(response => {
            afterVote(response)
            if (type === 'UP') {
               setLiked(actionType === 'UP')
               setUnliked(false)
            } else {
               setUnliked(actionType === 'DOWN')
               setLiked(false)
            }
         })
         .finally(() => {
            setLoading(false)
         })
   }

   const handleLike = () => handleVote('UP', afterVoteUP)
   const handleDislike = () => handleVote('DOWN', afterVoteDOWN)

   return (
      <div
         className={`relative mt-1 flex flex-row items-center justify-center rounded-full select-none ${miniView ? 'bg-gray-100' : 'bg-gray-200'} dark:bg-gray-800 ${loading ? 'shadow-effect' : ''}`}
      >
         {loading && (
            <div className='absolute'>
               {/*@ts-ignore*/}
               <l-trio size='40' speed='1.3' color={containsDark ? 'white' : 'black'}></l-trio>
            </div>
         )}
         <div
            style={{ fontSize: fontSize }}
            onClick={handleLike}
            className={`transition-common rounded-full p-1 text-blue-600 hover:cursor-pointer hover:bg-gray-300 dark:text-blue-400 dark:hover:bg-gray-700 ${fullView ? '' : 'group-hover:text-blue-700 dark:group-hover:text-blue-600'} ${loading ? 'opacity-50' : ''}`}
            role='button'
         >
            {liked ? <TbArrowBigUpLine /> : <TbArrowBigUp />}
         </div>

         <div
            className={`${miniView ? 'min-w-[20px]' : 'min-w-[30px]'} text-center text-black dark:text-gray-100`}
            style={{ fontSize: fontSize }}
         >
            {votesUp - votesDown}
         </div>

         <div
            style={{ fontSize: fontSize }}
            onClick={handleDislike}
            className={`transition-common rounded-full p-1 text-red-700 hover:cursor-pointer hover:bg-gray-300 dark:text-red-600 dark:hover:bg-gray-700 ${fullView ? '' : 'group-hover:text-red-700 dark:group-hover:text-red-600'} ${loading ? 'opacity-50' : ''}`}
            role='button'
         >
            {unliked ? <TbArrowBigDownLine /> : <TbArrowBigDown />}
         </div>
         {/*<UsersWhoVoted miniView={miniView} entityId={id} target={VotingTarget.POST} />*/}
      </div>
   )
}
