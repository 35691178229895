import { FoodOrderItem, OrderForChef, OrderType } from '@/model/order/FoodOrderItem'
import { SingleItemInSC } from '@/layout/shopping_cart/SingleItemInSC'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontalDivider } from '@/layout/common/Dividers'
import { handleFoodOrder } from '@/service/shopping-card.service'
import { UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { addItemsIdsToSC, removeItemIdFromSC, removeItemsIdsFromSC } from '@/service/notification-reducer'
import { useDispatch } from 'react-redux'
import { isDesktop } from 'react-device-detect'
import { Order } from '@/model/order/order'

interface OrdersForChefInSCProps {
   ordersForChef: OrderForChef
   selectedOrders: {}
   deleteFromShoppingCard: (foodOrderId: string) => void
   setValue: (name: string, value: any) => void
   getValues: UseFormGetValues<Order>
   watch: UseFormWatch<Order>
}

export const OrdersForChefInSC = ({
   ordersForChef,
   selectedOrders,
   deleteFromShoppingCard,
   setValue,
   getValues,
   watch,
}: OrdersForChefInSCProps) => {
   const { t } = useTranslation()

   return (
      <div className='mt-6'>
         <div className='mb-2 flex flex-row items-center'>
            <div className='text-xl font-semibold'>
               {t('shopping_cart.order_from')}&nbsp;{ordersForChef.chef?.name}
            </div>
         </div>
         <OrdersComponent
            orders={ordersForChef.orders}
            selectedOrders={selectedOrders}
            deleteFromShoppingCard={deleteFromShoppingCard}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
         />
      </div>
   )
}

interface OrdersComponentProps {
   orders: FoodOrderItem[]
   deleteFromShoppingCard: (foodOrderId: string) => void
   selectedOrders: Record<string, boolean>
   setValue: (name: string, value: any) => void
   getValues: UseFormGetValues<Order>
   watch: UseFormWatch<Order>
}

interface OrderTime {
   id: string
   day: string
   time: string
}

const OrdersComponent: React.FC<OrdersComponentProps> = ({
   orders,
   deleteFromShoppingCard,
   selectedOrders,
   setValue,
   getValues,
}) => {
   const { t } = useTranslation()
   const [orderTimes, setOrderTimes] = useState<OrderTime[]>([])
   const [anyOrderWithDifferentDatetime, setAnyOrderWithDifferentDatetime] = useState(false)
   // Check if any order has different day or time
   useEffect(() => {
      if (orderTimes.length === 0) {
         setAnyOrderWithDifferentDatetime(false)
         return
      }

      const firstOrder = orderTimes[0]
      const hasDifferentDatetime = orderTimes.some(
         order => order.day !== firstOrder.day || order.time !== firstOrder.time,
      )
      setAnyOrderWithDifferentDatetime(hasDifferentDatetime)
   }, [orderTimes])

   // Handler to update orderTimes when a single order's day or time changes
   const handleSetValue = (name: string, value: string, orderId: string) => {
      setValue(name, value)

      setOrderTimes(prevOrderTimes =>
         prevOrderTimes.map(order => {
            if (order.id !== orderId) return order

            if (name.includes('orderForDay')) {
               return { ...order, day: value }
            } else if (name.includes('orderForTime')) {
               return { ...order, time: value }
            }
            return order
         }),
      )
   }

   return (
      <div id='singleOrderItemInSC' className={`${isDesktop && 'px-2'}`}>
         {orders.map((foodOrder: FoodOrderItem, index: number) => (
            <div key={`order-${foodOrder.id}`} className='xs:ml-1 mr-1 ml-0.5 flex flex-col'>
               <SingleOrder
                  index={index}
                  initialFoodOrder={foodOrder}
                  deleteFromShoppingCard={deleteFromShoppingCard}
                  selectedOrders={selectedOrders}
                  setValue={(name, value) => handleSetValue(name, value, foodOrder.id!)}
                  getValues={getValues}
               />
               {index < orders.length - 1 && (
                  <HorizontalDivider additionalClass='mt-2.5 mb-2.5 bg-gray-300' dividerHeight={1} />
               )}
            </div>
         ))}
      </div>
   )
}

export interface SingleOrderProps {
   index: number
   initialFoodOrder: FoodOrderItem
   selectedOrders: {}
   deleteFromShoppingCard: (foodOrderId: string) => void
   setValue: UseFormSetValue<Order>
   getValues: UseFormGetValues<Order>
}

export const SingleOrder = ({
   index,
   initialFoodOrder,
   deleteFromShoppingCard,
   setValue,
   getValues,
}: SingleOrderProps) => {
   const dispatch = useDispatch()

   const [foodOrder, setFoodOrder] = useState<FoodOrderItem>(initialFoodOrder)

   function updateOrder(order: FoodOrderItem) {
      handleFoodOrder(order).then(() => {})
   }

   return (
      <div key={'f ' + index} className='flex w-full flex-col'>
         <div className='flex w-full items-center'>
            <SingleItemInSC
               key={'food' + index}
               foodOrder={foodOrder}
               deleteFromShoppingCard={() => {
                  const foodsIds = Array(foodOrder.quantity).fill(foodOrder.food.id)
                  dispatch(removeItemsIdsFromSC(foodsIds))
                  deleteFromShoppingCard(foodOrder.id!)
                  setValue(`totalPrice`, getValues('totalPrice') - foodOrder.food.price * foodOrder.quantity)
                  setValue(`orderItems.${index}`, null)
               }}
               updateIncreaseQuantity={() => {
                  const newOrder = {
                     ...foodOrder,
                     quantity: foodOrder.quantity + 1,
                     orderType: OrderType.ADD_QUANTITY,
                  }
                  setFoodOrder(newOrder)
                  updateOrder(newOrder)
                  dispatch(addItemsIdsToSC([foodOrder.food.id]))
                  const value = getValues('totalPrice') + newOrder.food.price
                  setValue(`totalPrice`, value)
                  setValue(`orderItems.${index}.quantity`, newOrder.quantity)
               }}
               updateDecreaseQuantity={() => {
                  const newOrder = {
                     ...foodOrder,
                     quantity: foodOrder.quantity - 1,
                     orderType: OrderType.REMOVE_QUANTITY,
                  }
                  dispatch(removeItemIdFromSC([foodOrder.food.id]))
                  const value = getValues('totalPrice') - newOrder.food.price
                  setValue(`totalPrice`, value)
                  setValue(`orderItems.${index}.quantity`, newOrder.quantity)
                  setFoodOrder(newOrder)
                  updateOrder(newOrder)
               }}
            />
         </div>
      </div>
   )
}
