import { RestClient } from '@/config/rest-client'
import { Chef } from '@/model/chef.model'

const apiUrl = '/api/pick-up-point'

export const getAllPickUpPoints = () => {
   const requestUrl = `${apiUrl}/all`
   return RestClient.get<Chef[]>(requestUrl)
}

export const getPickUpPointsByIds = (ids: string[]) => {
   const requestUrl = `${apiUrl}/by-ids/${ids}`
   return RestClient.get<Chef[]>(requestUrl)
}

export const getChefsForPickUpPoint = (pickUpPointId: string) => {
   const requestUrl = `${apiUrl}/by-chef/${pickUpPointId}`
   return RestClient.get<Chef[]>(requestUrl)
}
export const addPickUpPoint = (chef: Chef) => {
   return RestClient.post<Chef>(`${apiUrl}`, chef)
}

export const editPickUpPoint = (chef: Chef) => {
   return RestClient.put<Chef>(`${apiUrl}`, chef)
}
