import dayjs from 'dayjs'
import { Chef, OfferInfoChef } from '@/model/chef.model'
import { SlotProps, UnavailableInfo } from '@/layout/shopping_cart/DateTimePickerForOrder'
import { useTranslation } from 'react-i18next'
import { DeliveryType } from '@/model/enumerations/delivery-type.model'
import { PickUPointsMap } from '@/layout/chef/PickUPointsMap'
import React from 'react'
import { MdOpenInNew } from 'react-icons/md'
import { haversineDistance } from '@/util/other-utils'
import { useAppSelector } from '@/config/store'
import { TfiLocationPin } from 'react-icons/tfi'

export function generateTimeSlotsForPickUpPoints(
   date: dayjs.Dayjs,
   offerInfo: OfferInfoChef,
   chef: Chef,
   chosenPoint: Chef,
) {
   const slots: SlotProps = []
   const today = dayjs().tz()

   const dateStr = date?.format('YYYY-MM-DD')
   if (!dateStr) {
      console.warn('Date is invalid:', date)
      return slots
   }

   if (!offerInfo || !offerInfo.open || !offerInfo.close) {
      console.warn('Missing or invalid offerInfo pickUp point:', offerInfo)
      return slots
   }

   const timeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/
   if (!timeFormat.test(offerInfo.open) || !timeFormat.test(offerInfo.close)) {
      console.warn('Open or close time format is invalid:', offerInfo.open, offerInfo.close)
      return slots
   }

   let openTime: dayjs.Dayjs, closeTime: dayjs.Dayjs
   try {
      openTime = dayjs.tz(`${dateStr} ${offerInfo.open}`, 'YYYY-MM-DD HH:mm', 'Europe/Paris')
      closeTime = dayjs.tz(`${dateStr} ${offerInfo.close}`, 'YYYY-MM-DD HH:mm', 'Europe/Paris')
   } catch (error) {
      return slots
   }

   if (!openTime.isValid() || !closeTime.isValid()) {
      return slots
   }

   let currentTime = openTime.clone()

   function isAllAvailable(param: { orders: Chef; day: dayjs.Dayjs; chosenPoint: Chef }) {
      const currentTime = dayjs().tz()
      const minimumTime = currentTime.add(chosenPoint.deliverToPickUpPointInAdvanceMinutes || 14, 'minute')

      return param.day.isAfter(minimumTime)
   }

   while (currentTime.isBefore(closeTime) || currentTime.isSame(closeTime)) {
      if (date.isSame(today, 'day')) {
         // Optionally handle today's slots if needed
      } else {
         const available = isAllAvailable({ day: currentTime, orders: chef, chosenPoint: chosenPoint });
         const unavailableInfo: UnavailableInfo[] = [];
         if (!available) {
            const minAdvance = chosenPoint.deliverToPickUpPointInAdvanceMinutes || 60;
            unavailableInfo.push({
               foodId: '',
               reason: `Time slot must be at least ${minAdvance} minutes from now.`
            });
         }
         slots.push({
            name: currentTime.format('HH:mm'),
            available,
            unavailableInfo
         });
      }
      currentTime = currentTime.add(30, 'minute');
   }

   return slots
}

interface PickUpModeComponentProps {
   preSelectedDeliveryType: DeliveryType
   pickupPoints: Chef[]
   setChosenPoint: (point: Chef) => void
   chosenPoint: Chef
   pickUpPoint: boolean
}

export const PickUpModeComponent = ({
   preSelectedDeliveryType,
   pickupPoints,
   setChosenPoint,
   chosenPoint,
   pickUpPoint,
}: PickUpModeComponentProps) => {
   const { t } = useTranslation()
   const { currentUserLocation } = useAppSelector(state => state.search)

   return (
      <>
         {preSelectedDeliveryType == DeliveryType.DELIVER_TO_PICKUP_POINT && !pickUpPoint && (
            <div className='flex w-full flex-col'>
               <h1 className='mt-1 mb-2 text-center text-sm'>{t('shopping_cart.choose_pick_up_point_on_map')}</h1>
               <PickUPointsMap
                  points={pickupPoints}
                  addedStyles={'h-[200px] w-full'}
                  customOnIconClick={pointId => {
                     const point = pickupPoints.find(p => p.id === pointId)
                     if (point) {
                        setChosenPoint(point)
                     }
                  }}
               />
            </div>
         )}

         {chosenPoint && (
            <div className='flex w-full flex-row items-center py-3'>
               <div className='flex w-full flex-col'>
                  <h1 className='flex flex-row items-center text-base font-semibold'>
                     {chosenPoint.name}
                     &nbsp;(
                     <TfiLocationPin className='mr-0.5 -ml-[2px] h-4 w-4 text-blue-800' />
                     <p className=''>
                        {haversineDistance(
                           [currentUserLocation?.longitude, currentUserLocation?.latitude],
                           [chosenPoint.address?.longitude, chosenPoint.address?.latitude],
                        )}{' '}
                        km
                     </p>
                     )
                  </h1>
                  <p className='text-sm'>
                     {chosenPoint.address?.villageTownCity} {chosenPoint.address?.street}
                  </p>
               </div>
               <MdOpenInNew
                  className='text-customPurple h-10 w-10 rounded-full p-1 hover:cursor-pointer hover:bg-gray-100'
                  onClick={() => {
                     window.open(`/pick-up-point/${chosenPoint.id}`, '_blank')
                  }}
               />
            </div>
         )}
      </>
   )
}
