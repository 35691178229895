import React, { useState } from 'react'
import { SingleFoodView } from '@/layout/food/SingleFoodView'
import { Food } from '@/model/order/food.model'
import { useTranslation } from 'react-i18next'
import { sectionHeader } from '@/layout/chef/ChefDetail'

interface FoodsForChefProps {
   foods?: Food[]
   limit?: number
   setFoodToAddMobile: (food: Food) => void
   chefSuspended?: boolean
}

export const FoodsForChef = ({ foods, limit, setFoodToAddMobile, chefSuspended }: FoodsForChefProps) => {
   const { t } = useTranslation()
   const [showAll, setShowAll] = useState(false)

   const displayedFoods = showAll ? foods : foods.slice(0, limit)

   return (
      <>
         <div id='foodsForChefCol' className='flex flex-col'>
            {displayedFoods?.length > 0 && <div className='mt-5 -mb-1'>{sectionHeader(t('chef_profile.menu'))}</div>}
            <div id='foodsForChefRow' className='-mx-2 flex flex-row flex-wrap'>
               {' '}
               {/* Added negative margin to counteract child padding */}
               {displayedFoods.map(food => (
                  <div key={food.id} className='w-full px-2 py-3 sm:w-1/2'>
                     {/* Added horizontal padding */}
                     <SingleFoodView
                        key={food.id}
                        food={food}
                        chefSuspended={chefSuspended}
                        addFoodMobileOpen={() => {
                           console.log('Add food mobile open ', food)
                           setFoodToAddMobile(food)
                           // window.history.pushState({ page: `/food/${food.id}` }, 'Add Food', `/food/${food.id}`)
                        }}
                     />
                  </div>
               ))}
            </div>
            {!showAll && foods.length > limit && (
               <button
                  onClick={() => setShowAll(true)}
                  className='mt-4 rounded-md bg-blue-500 p-2 font-semibold text-white hover:bg-blue-600'
               >
                  {t('chef_profile.show_all')}
               </button>
            )}
         </div>
      </>
   )
}
