import React, { useEffect, useRef, useState } from 'react'
import { CDN_ENDPOINT } from '@/env'
import { CiImageOn } from 'react-icons/ci'
import { Chef } from '@/model/chef.model'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Mousewheel, Pagination } from 'swiper/modules'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io'
import 'swiper/css'
import 'swiper/css/navigation'
import { PictureModeProps } from '@/layout/chef/pictures/BackgroundPictureWithHeart'
import { Link } from 'react-router-dom'

interface BackgroundPictureProps {
   chef?: Chef
   additionalStyles?: string
   pictureMode?: PictureModeProps
}

export const SliderPictures = ({ chef, additionalStyles = '', pictureMode }: BackgroundPictureProps) => {
   let chefImages = chef?.galleryImages || chef?.pictures || []
   const [imageErrors, setImageErrors] = useState(new Array(chefImages?.length).fill(false))
   const swiperRef = useRef(null)

   const [isBeginning, setIsBeginning] = useState(true)
   const [isEnd, setIsEnd] = useState(false)

   const handleImageError = index => {
      const newImageErrors = [...imageErrors]
      newImageErrors[index] = true
      setImageErrors(newImageErrors)
   }

   useEffect(() => {
      if (swiperRef == null || swiperRef.current == null) return
      const swiperInstance = swiperRef.current.swiper
      if (swiperInstance) {
         const checkSwiperPosition = () => {
            setIsBeginning(swiperInstance.isBeginning)
            setIsEnd(swiperInstance.isEnd)
         }

         swiperInstance.on('slideChange', checkSwiperPosition)

         // Initial check in case the Swiper is not at the first slide by default
         checkSwiperPosition()

         return () => {
            swiperInstance.off('slideChange', checkSwiperPosition)
         }
      }
   }, [chef?.pictures])

   // todo use pictureMode.sliderNavigationOnHover to show navigation buttons only on hover. On hover while pictureMode.sliderNavigationOnHover is
   // true, show the navigation buttons. On hover out, hide the navigation buttons.

   return (
      <div
         id={'sliderPictures'}
         className={`group relative aspect-1/3 h-full w-full overflow-hidden select-none ${additionalStyles}`}
      >
         {chefImages.length > 0 ? (
            <Swiper
               style={{
                  // @ts-ignore
                  '--swiper-pagination-color': '#e8e8e8',
                  '--swiper-pagination-bullet-inactive-color': '#a887e3',
                  '--swiper-pagination-bullet-inactive-opacity': '0.9',
                  '--swiper-pagination-bullet-size': '10px',
                  '--swiper-pagination-bullet-horizontal-gap': '6px',
               }}
               ref={swiperRef}
               slidesPerView={1}
               modules={[Pagination, Keyboard, Mousewheel]}
               pagination={{ clickable: true }}
               keyboard={{ enabled: true, onlyInViewport: true }}
               className='h-full w-full'
            >
               {chefImages.map((pic, index) =>
                  !imageErrors[index] ? (
                     <SwiperSlide key={index}>
                        <div className='relative h-full w-full rounded-t-md'>
                           <img
                              className='h-full w-full rounded-t-md object-cover object-center'
                              src={`${CDN_ENDPOINT}${pic}`}
                              alt='chef picture'
                              onError={() => handleImageError(index)}
                           />
                           {pictureMode.dark && <div className='absolute inset-0 bg-black/50'></div>}
                        </div>
                     </SwiperSlide>
                  ) : null,
               )}
            </Swiper>
         ) : (
            <Link
               to={`/chef/${chef.shortName}`}
               style={{ textDecoration: 'none', color: 'inherit' }}
               id={'sliderPicturesCiImageOn'}
               className='flex h-full w-full items-center justify-center'
            >
               <CiImageOn className='aspect-video h-full w-full' />
            </Link>
         )}

         {/* Previous Button */}
         <>
            {chefImages.length > 0 && (
               <>
                  {pictureMode.sliderNavigationOnHover ? (
                     <>
                        <div
                           className={`${
                              pictureMode.sliderNavigationOnHover ? 'opacity-0 group-hover:opacity-100' : ''
                           } ${!isBeginning ? 'hover:cursor-pointer' : 'cursor-not-allowed opacity-50'} absolute inset-y-1/2 left-0 z-10 h-11 w-11 -translate-y-1/2 transform`}
                           onClick={event => {
                              event.stopPropagation()
                              event.preventDefault() // Prevent default action
                              if (!isBeginning) swiperRef.current.swiper.slidePrev()
                           }}
                        >
                           <div
                              className={`absolute inset-y-1/2 left-0 flex h-12 w-full -translate-y-1/2 items-center justify-center rounded-r-md bg-gray-100/70 opacity-0 transition duration-300 hover:bg-gray-100/90 ${isBeginning ? 'group-hover:opacity-50' : 'group-hover:opacity-100'} `}
                           >
                              <IoIosArrowBack className='z-20 h-6 w-6 text-gray-900/80 hover:text-gray-900/100' />
                           </div>
                        </div>
                        {/* Next Button */}
                        <div
                           className={`${
                              pictureMode.sliderNavigationOnHover ? 'opacity-0 group-hover:opacity-100' : ''
                           } ${!isEnd ? 'hover:cursor-pointer' : 'cursor-not-allowed opacity-50'} absolute inset-y-1/2 right-0 z-10 h-11 w-11 -translate-y-1/2 transform`}
                           onClick={event => {
                              event.stopPropagation()
                              event.preventDefault()
                              if (!isEnd) swiperRef.current.swiper.slideNext()
                           }}
                        >
                           <div
                              className={`absolute inset-y-1/2 right-0 flex h-12 w-full -translate-y-1/2 items-center justify-center rounded-l-md bg-gray-100/70 opacity-0 transition duration-300 hover:bg-gray-100/90 ${isEnd ? 'group-hover:opacity-50' : 'group-hover:opacity-100'} `}
                           >
                              <IoIosArrowForward className='z-20 h-6 w-6 text-gray-900/80 hover:text-gray-900' />
                           </div>
                        </div>
                     </>
                  ) : (
                     <>
                        <div
                           className={`${
                              !isBeginning ? 'opacity-100 hover:cursor-pointer' : 'cursor-not-allowed opacity-50'
                           } absolute inset-y-1/2 left-0 z-10 ${pictureMode.compactButtons ? 'h-6 w-8' : 'h-11 w-11'} -translate-y-1/2 transform`}
                           onClick={event => {
                              event.preventDefault() // Prevent default action
                              event.stopPropagation()
                              if (!isBeginning) swiperRef.current.swiper.slidePrev()
                           }}
                        >
                           <div className='absolute inset-y-1/2 left-0 flex h-12 w-full -translate-y-1/2 items-center justify-center rounded-r-md bg-gray-100/70 transition duration-300 hover:bg-gray-100/90'>
                              <IoIosArrowBack className='z-20 h-6 w-6 text-gray-900 opacity-80 hover:opacity-100' />
                           </div>
                        </div>

                        <div
                           className={`${
                              !isEnd ? 'opacity-100 hover:cursor-pointer' : 'cursor-not-allowed opacity-50'
                           } absolute inset-y-1/2 right-0 z-10 ${pictureMode.compactButtons ? 'h-6 w-8' : 'h-11 w-11'} -translate-y-1/2 transform`}
                           onClick={event => {
                              event.preventDefault() // Prevent default action
                              event.stopPropagation()
                              if (!isEnd) swiperRef.current.swiper.slideNext()
                           }}
                        >
                           <div className='absolute inset-y-1/2 right-0 flex h-12 w-full -translate-y-1/2 items-center justify-center rounded-l-md bg-gray-100/70 transition duration-300 hover:bg-gray-100/90'>
                              <IoIosArrowForward className='z-20 h-6 w-6 text-gray-900 opacity-80 hover:opacity-100' />
                           </div>
                        </div>
                     </>
                  )}
               </>
            )}
         </>
      </div>
   )
}
