import React, { useEffect, useRef, useState } from 'react'
import { BlockStatusType } from '@/model/enumerations/block-status.type'
import { Checkbox, DatePicker, Form, Modal, Select } from 'antd'
import Radio from 'antd/lib/radio'
import { CgMoreO } from 'react-icons/cg'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { AssignAuthoritiesModal } from '@/layout/administration/users/AssignAuthoritiesModal'
import { isAdmin } from '@/util/auth-util'
import { useAppSelector } from '@/config/store'
import { blockUser, deleteUser, unblockUser } from '@/service/user-service'

export function MoreActionsUser({ record }) {
   const [menuVisible, setMenuVisible] = useState(false)
   const { t } = useTranslation()
   const [showAssignAuthModal, setShowAssignAuthModal] = useState(false)
   const [selectedLogin, setSelectedLogin] = useState<string>()
   const [selectedUserAuthorities, setSelectedUserAuthorities] = useState<any[]>()
   const [isBlockModalVisible, setIsBlockModalVisible] = useState(false)
   const [isUnblockModalVisible, setIsUnblockModalVisible] = useState(false)
   // const [isModalVisible, setModalVisible] = useState(false);
   const menuRef = useRef(null)

   useEffect(() => {
      function handleClickOutside(event) {
         if (menuRef.current && !menuRef.current.contains(event.target)) setMenuVisible(false)
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
         document.removeEventListener('mousedown', handleClickOutside)
      }
   }, [menuVisible])
   const unblocked = record.blockStatus == null || record.blockStatus === BlockStatusType.UNBLOCKED
   return (
      <>
         <div>
            <button onClick={() => setMenuVisible(!menuVisible)} className='z-20 flex items-center'>
               <CgMoreO />
            </button>
         </div>

         {menuVisible && (
            <div
               ref={menuRef}
               className='ring-opacity-5 absolute right-0 mt-2 w-56 origin-top-right overflow-y-auto rounded-md bg-white ring-1 shadow-lg ring-black focus:outline-hidden'
               style={{ maxHeight: 'calc(100vh - 100px)' }}
            >
               <div className='py-1' role='menu' aria-orientation='vertical' aria-labelledby='options-menu'>
                  <div
                     onClick={() => {
                        setSelectedLogin(record.login)
                        setSelectedUserAuthorities(record.authorities)
                        setShowAssignAuthModal(true)
                        setMenuVisible(false)
                     }}
                     className='block px-4 py-2 text-sm text-gray-700'
                  >
                     Rola
                  </div>

                  <div
                     onClick={() => {
                        if (!unblocked) {
                           unblockUser({ userId: record.id.toString() })
                              .then(result => {
                                 toast.success(t('common.success'))
                                 // todo
                                 // handleTableChange(pagination, null, { field: '', order: '' });
                              })
                              .catch(reason => toast.error(t('error')))
                        } else setIsBlockModalVisible(true)
                     }}
                     className='block px-4 py-2 text-sm text-gray-700'
                  >
                     {unblocked ? 'Block' : 'Unblock'}
                  </div>
               </div>
            </div>
         )}
         <AssignAuthoritiesModal
            showModal={showAssignAuthModal}
            setShowModal={setShowAssignAuthModal}
            userLogin={selectedLogin}
            currentAuthorities={selectedUserAuthorities}
         />
         <BlockUserModal
            userId={record.id}
            visible={isBlockModalVisible}
            onClose={() => setIsBlockModalVisible(false)}
         />
         <UnblockUserModal
            userId={record.id}
            visible={isUnblockModalVisible}
            onCancel={() => setIsUnblockModalVisible(false)}
            onSubmit={() => setIsUnblockModalVisible(false)}
         />
      </>
   )
}

export function BlockUserModal({ visible, onClose, userId }) {
   const [blockStatus, setBlockStatus] = useState(BlockStatusType.TEMPORARILY_BLOCKED)
   const [dueDate, setDueDate] = useState<string>(calculateFutureDate(7))
   const dueDateOptions = [1, 3, 5, 7, 14, 31]
   const { t } = useTranslation()
   const [messageForUser, setMessageForUser] = useState<string>('')
   const [reasonForModerators, setReasonForModerators] = useState<string>('')
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const [form] = Form.useForm()
   const isAdminAccount = isAdmin(loggedAccount)

   const [chatBlocked, setChatBlocked] = useState(true)
   const [postsBlocked, setPostsBlocked] = useState(true)
   const [commentsBlocked, setCommentsBlocked] = useState(true)
   const [reactionsBlocked, setReactionsBlocked] = useState(true)
   const [currentDateOption, setCurrentDateOption] = useState<number>(7)

   function calculateFutureDate(days: number): string {
      const futureDate = new Date()
      futureDate.setDate(futureDate.getDate() + days)
      return futureDate.toISOString()
   }

   function handleDueDateChange(value: number | string | string[]) {
      if (typeof value === 'number') {
         setCurrentDateOption(value)
         setDueDate(calculateFutureDate(value))
      } else if (Array.isArray(value)) setDueDate(new Date(value[0]).toISOString())
      else setDueDate(new Date(value).toISOString())
   }

   return (
      <Modal
         open={visible}
         onCancel={() => {
            form.resetFields()
            setChatBlocked(true)
            setPostsBlocked(true)
            setCommentsBlocked(true)
            setReactionsBlocked(true)
            onClose(undefined)
         }}
         okButtonProps={{ className: 'text-blue-500 border-blue-500' }}
         onOk={() => {
            if (blockStatus === BlockStatusType.TEMPORARILY_BLOCKED && !dueDate) {
               toast.error(t('error.invalid_fields_check_form'))
               return
            }

            if (blockStatus === BlockStatusType.PERMANENTLY_BLOCKED && dueDate) setDueDate(null)

            form
               .validateFields()
               .then(values => {
                  blockUser({
                     userId,
                     blockStatus,
                     dueDate,
                     reasonForModerators,
                     messageForUser,
                     chatBlocked,
                     postsBlocked,
                     commentsBlocked,
                     reactionsBlocked,
                  })
                     .then(result => {
                        toast.success(t('common.success'))
                     })
                     .finally(() => {
                        form.resetFields()
                        onClose(blockStatus)
                     })
               })
               .catch(reason => {
                  toast.error(t('error.invalid_fields_check_form'))
               })
         }}
      >
         <Form form={form} layout='vertical'>
            <Select value={blockStatus} onChange={setBlockStatus}>
               <Select.Option value={BlockStatusType.TEMPORARILY_BLOCKED}>
                  {t('user_details.block_temporarily')}
               </Select.Option>
               {isAdminAccount && (
                  <Select.Option value={BlockStatusType.PERMANENTLY_BLOCKED}>
                     {t('user_details.block_permanently')}
                  </Select.Option>
               )}
            </Select>

            {blockStatus === BlockStatusType.TEMPORARILY_BLOCKED && (
               <Radio.Group
                  className='mt-2'
                  value={currentDateOption}
                  options={dueDateOptions.map(option => ({ label: option, value: option }))}
                  onChange={e => handleDueDateChange(e.target.value)}
               />
            )}

            {dueDate === calculateFutureDate(31) && (
               <DatePicker onChange={(date, dateString) => handleDueDateChange(dateString)} />
            )}
            {blockStatus == BlockStatusType.TEMPORARILY_BLOCKED && (
               <div className='mt-2 flex flex-row flex-wrap'>
                  <Checkbox checked={chatBlocked} onChange={e => setChatBlocked(e.target.checked)}>
                     {t('user_details.block_chat')}
                  </Checkbox>
                  <Checkbox checked={postsBlocked} onChange={e => setPostsBlocked(e.target.checked)}>
                     {t('user_details.block_posts')}
                  </Checkbox>
                  <Checkbox checked={commentsBlocked} onChange={e => setCommentsBlocked(e.target.checked)}>
                     {t('user_details.block_comments')}
                  </Checkbox>
                  <Checkbox checked={reactionsBlocked} onChange={e => setReactionsBlocked(e.target.checked)}>
                     {t('user_details.block_reactions')}
                  </Checkbox>
               </div>
            )}

            <textarea
               value={messageForUser}
               onChange={e => setMessageForUser(e.target.value)}
               placeholder={t('Wiadomość do użytkownika o powodzie blokady')}
               rows={5}
               className='mt-2 w-full rounded-sm border p-2'
            />

            <Form.Item
               name='reasonForModerators'
               rules={[{ required: true, min: 5, message: t('error.min_length', { min: 5 }) }]}
            >
               <textarea
                  value={reasonForModerators}
                  onChange={e => setReasonForModerators(e.target.value)}
                  placeholder={t('Wiadomość do administracji portalu')}
                  rows={5}
                  minLength={5}
                  className='mt-2 w-full rounded-sm border p-2'
               />
            </Form.Item>
         </Form>
      </Modal>
   )
}

export interface BlockInfoProps {
   userId: string
   reasonForModerators?: string
   messageForUser?: string
}

export function UnblockUserModal({ visible, onCancel, onSubmit, userId }) {
   const { t } = useTranslation()
   const [messageForUser, setMessageForUser] = useState<string>('')
   const [reasonForModerators, setReasonForModerators] = useState<string>('')
   const [form] = Form.useForm()

   return (
      <Modal
         open={visible}
         onCancel={() => {
            form.resetFields()
            onCancel()
         }}
         okButtonProps={{ className: 'text-blue-500 border-blue-500' }}
         onOk={() => {
            form
               .validateFields()
               .then(values => {
                  unblockUser({
                     userId,
                     reasonForModerators,
                     messageForUser,
                  })
                     .then(result => {
                        toast.success(t('common.success'))
                     })
                     .finally(() => {
                        form.resetFields()
                        onSubmit()
                     })
               })
               .catch(reason => {
                  toast.error(t('error.invalid_fields_check_form'))
               })
         }}
      >
         <Form form={form} layout='vertical'>
            <textarea
               value={messageForUser}
               onChange={e => setMessageForUser(e.target.value)}
               placeholder={t('Wiadomość do użytkownika o cofnieciu blokady')}
               rows={5}
               className='mt-2 w-full rounded-sm border p-2'
            />

            <Form.Item
               name='reasonForModerators'
               rules={[{ required: true, min: 5, message: 'Message must be at least 5 characters long.' }]}
            >
               <textarea
                  value={reasonForModerators}
                  onChange={e => setReasonForModerators(e.target.value)}
                  placeholder={t('Wiadomość do administracji portalu')}
                  rows={5}
                  minLength={5}
                  className='mt-2 w-full rounded-sm border p-2'
               />
            </Form.Item>
         </Form>
      </Modal>
   )
}

export function DeleteUserModal({ visible, onClose, userId }) {
   const { t } = useTranslation()
   const [reason, setReason] = useState<string>('')

   return (
      <Modal
         open={visible}
         onCancel={onClose}
         okButtonProps={{ className: 'text-blue-500 border-blue-500' }}
         onOk={() => {
            deleteUser({ userId: userId, reason: reason, blockStatus: null, dueDate: null })
               .then(result => {
                  toast.success(t('common.success'))
               })
               .catch(reason => {
                  toast.error(t('error'))
               })
               .finally(() => {
                  console.log('on close')
                  onClose()
               })
         }}
      >
         <textarea
            value={reason}
            onChange={e => setReason(e.target.value)}
            placeholder={t('Dlaczego to robisz?')}
            rows={5}
            className='mt-2 w-full rounded-sm border p-2'
         />
      </Modal>
   )
}
