import { IUser } from './user.model'
import { NewsRole } from './enumerations/news-role.model'

export interface INewsUser {
   id?: string
   role?: NewsRole | null
   user?: IUser | null
}

export const defaultValue: Readonly<INewsUser> = {}
