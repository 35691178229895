import React, { startTransition, useEffect, useRef, useState } from 'react'
import { UserLoginComponent, UserLoginComponentType } from '@/layout/common/UserLoginComponent'
import { useAppSelector } from '@/config/store'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { IPost } from '@/model/post.model'
import { HiOutlineChatBubbleLeftEllipsis } from 'react-icons/hi2'
import { isMobile } from 'react-device-detect'
import { CDN_ENDPOINT } from '@/env'
import { AiOutlinePicture } from 'react-icons/ai'
import { FcAlarmClock } from 'react-icons/fc'
import { DateTime } from 'luxon'
import { TimeAgo } from '@/util/TimeAgo'
import ReadOnlyLexical from '@/layout/common/lexical-editor/ReadOnlyLexical'

export function voteDownForPost(
   post: IPost,
   setRefresh: (value: ((prevState: number) => number) | number) => void,
   refresh: number,
) {
   if (post.voted == -1) {
      post.votesDown -= 1
      post.voted = 0
   } else if (post.voted == 1) {
      post.votesDown += 1
      post.votesUp -= 1
      post.voted = -1
   } else {
      post.votesDown += 1
      post.voted = -1
   }
   setRefresh(refresh + 1)
}

export function voteUpForPost(
   post: IPost,
   setRefresh: (value: ((prevState: number) => number) | number) => void,
   refresh: number,
) {
   if (post.voted == 1) {
      post.votesUp -= 1
      post.voted = 0
   } else if (post.voted == -1) {
      post.votesUp += 1
      post.votesDown -= 1
      post.voted = 1
   } else {
      post.votesUp += 1
      post.voted = 1
   }
   setRefresh(refresh + 1)
}

export function getYoutubeVideoView(contentId: string, contentUri: string, autoplay = false) {
   let srcUrl = `https://www.youtube.com/embed/${contentId}`

   try {
      const url = new URL(contentUri)
      const startTime = url.searchParams.get('t')
      if (startTime) srcUrl += `?start=${startTime}`
   } catch (e) {
      console.log('error parsing youtube url', e)
   }

   if (autoplay) srcUrl += `?autoplay=1`

   return (
      <div className='video-responsive mt-2'>
         <iframe
            width='853'
            height='480'
            src={srcUrl}
            allow='autoplay; fullscreen; picture-in-picture'
            allowFullScreen
         />
      </div>
   )
}

// export const PostLoading = () => {
//    return (
//       <div className='flex w-full items-center justify-center p-10 text-xl'>
//          <Jelly size={50} color='#FF4501' />
//       </div>
//    )
// }

export const PostExpandableDescription = ({
   description,
   expandedByDefault,
   postId = null,
   setFullPageViewTriggered,
}) => {
   const MAX_HEIGHT = 400
   const darkMode = useAppSelector(state => state.themeModeReducer.darkTheme)
   const [isLongText, setIsLongText] = useState(false)
   const contentRef = useRef(null)
   const [showFullText, setShowFullText] = useState(expandedByDefault)
   const navigate = useNavigate()
   const { t } = useTranslation()
   const hasImage = description.includes('<img')

   useEffect(() => {
      const checkHeight = () => {
         if (contentRef.current) {
            const contentHeight = contentRef.current.offsetHeight
            setIsLongText(contentHeight > MAX_HEIGHT)
         }
      }

      const observer = new MutationObserver(checkHeight)

      if (contentRef.current) observer.observe(contentRef.current, { childList: true, subtree: true })

      if (hasImage) {
         setTimeout(checkHeight, 50)
         setTimeout(checkHeight, 150)
         setTimeout(checkHeight, 300)
         setTimeout(checkHeight, 750)
      }

      return () => observer.disconnect() // Disconnect observer on cleanup.
   }, [hasImage])

   return (
      <div
         style={{
            position: 'relative',
            maxHeight: showFullText ? 'none' : MAX_HEIGHT,
            overflow: 'hidden',
         }}
         onClick={() => {
            if (isLongText && !showFullText) setShowFullText(true)
            else if (!expandedByDefault && postId) {
               if (isMobile)
                  startTransition(() => {
                     setFullPageViewTriggered()
                  })
               else navigate('/blog/' + postId)
            }
         }}
         className={`${(isLongText && !showFullText) || !expandedByDefault ? 'hover:cursor-pointer' : ''}`}
      >
         <div className='pr-3' ref={contentRef}>
            <ReadOnlyLexical htmlState={description} />
         </div>

         {!showFullText && isLongText && (
            <>
               <div
                  style={{
                     position: 'absolute',
                     bottom: 0,
                     width: '100%',
                     height: '5em',
                     background: darkMode
                        ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 95%)'
                        : 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 95%)',
                     zIndex: 1,
                  }}
               />
               <div
                  style={{
                     position: 'absolute',
                     bottom: '1px',
                     width: '100%',
                     textAlign: 'center',
                     color: darkMode ? 'white' : 'black',
                     zIndex: 2,
                     cursor: 'pointer',
                  }}
               >
                  <div>{t('post.expand')}</div>
               </div>
            </>
         )}
      </div>
   )
}

export function extractShortenedText(htmlString: string, MAX_DESCRIPTION_LENGTH: number) {
   const parser = new DOMParser()
   const doc = parser.parseFromString(htmlString, 'text/html')
   const fullTextContent = doc.body.textContent || ''
   const isTruncated = fullTextContent.length > MAX_DESCRIPTION_LENGTH

   // Show truncated content if exceeds max length, otherwise show full content
   const displayedContent = isTruncated ? fullTextContent.substring(0, MAX_DESCRIPTION_LENGTH) + '' : fullTextContent
   return { isTruncated, displayedContent }
}

function hasLongWord(content: string, threshold: number) {
   const words = content.split(/\s+/) // Split content by whitespace
   return words.some(word => word.length > threshold)
}

export const PostPlainTextShortDescription = ({
   htmlString,
   MAX_DESCRIPTION_LENGTH = 220,
   onClickText,
   onClickExpand,
}) => {
   const { isTruncated, displayedContent } = extractShortenedText(htmlString, MAX_DESCRIPTION_LENGTH)

   const threshold = 15

   const breakStyle = hasLongWord(displayedContent, threshold) ? 'break-all' : 'break-words'

   return (
      <div className='text-[13px]'>
         <span className={`hover:cursor-pointer ${breakStyle}`} onClick={onClickText}>
            {displayedContent}
         </span>
         {isTruncated && (
            <button className={''} onClick={onClickExpand}>
               ...
            </button>
         )}
      </div>
   )
}

export const CompactPicture = ({ onClickImg, picture, addedStyle = '', showBlurredContent = undefined }) => {
   const [hasError, setHasError] = useState(false)
   const [imgSrc, setImgSrc] = useState(CDN_ENDPOINT + picture)
   const targetElement = useRef(null)
   const [isInView, setIsInView] = useState(false)

   useEffect(() => {
      const onIntersection = entries => {
         setIsInView(true)
      }

      const observer = new IntersectionObserver(onIntersection, {
         rootMargin: '800px',
      })

      if (targetElement.current) observer.observe(targetElement.current)

      return () => {
         if (targetElement.current) observer.unobserve(targetElement.current)
      }
   }, [])

   const handleError = () => {
      setHasError(true)
      setImgSrc('')
   }

   if (!picture || picture.length === 0) return null

   return (
      <div onClick={onClickImg} ref={targetElement} className={`w-content xs:px-2 mt-1.5 cursor-pointer px-1 sm:px-3`}>
         <div className={`relative overflow-hidden rounded-md ${addedStyle}`}>
            {hasError ? (
               <AiOutlinePicture className={`text-gray-600 ${addedStyle}`} />
            ) : (
               isInView && (
                  <img
                     src={imgSrc}
                     onError={handleError}
                     alt=''
                     className={`absolute top-0 left-0 h-full w-full object-cover ${showBlurredContent ? 'blur-[10px]' : ''}`}
                  />
               )
            )}
         </div>
      </div>
   )
}

interface CommentsAmountProps {
   triggerShowComments: () => void
   showComments: boolean
   commentsCount: number
   addedComments: number
}

export const CommentsAmount = ({
   triggerShowComments,
   showComments,
   commentsCount,
   addedComments,
}: CommentsAmountProps) => {
   const { t } = useTranslation()
   const shouldDisableClick = location.pathname.includes('/blog/') && showComments

   return (
      <div
         className={`flex flex-row items-center font-medium ${!shouldDisableClick ? 'hover-color hover:cursor-pointer' : ''} `}
         onClick={() => {
            if (shouldDisableClick) return
            triggerShowComments()
         }}
      >
         <HiOutlineChatBubbleLeftEllipsis
            className={`mr-1 ${isMobile ? 'mt-[4px]' : 'mt-2 mr-1.5'} h-[20px] w-[20px]`}
         />
         <p className={`${isMobile ? 'mt-[1px] mr-0.5 text-[17px]' : 'mt-[6px] mr-1 text-[17px]'} `}>
            {(commentsCount || 0) + addedComments}
            &nbsp;{t('comments.all')}
         </p>
      </div>
   )
}

export interface PostInfoProps {
   post: IPost
   fullView: boolean
}

export const PostInfo = ({ post, fullView }: PostInfoProps) => {
   const navigate = useNavigate()
   const { t } = useTranslation()
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const { id, login, picture } = post.user
   return (
      <div className='flex flex-row flex-wrap items-center text-base leading-5'>
         <div className='flex flex-row flex-wrap items-center'>
            <UserLoginComponent
               userId={id}
               userName={login}
               avatar={picture}
               type={UserLoginComponentType.ICON_AND_TEXT}
            />

            <div>&nbsp;</div>
         </div>

         <div className='flex flex-row items-center text-gray-500 dark:text-gray-400'>
            <TimeAgo key={'tI' + post.id} date={post.createDate} />
            &nbsp;
            {post.editedBy && (
               <>
                  (
                  {post.editedBy?.id != loggedAccount?.id ? (
                     <>
                        {t('post.editedBy')}&nbsp;
                        <UserLoginComponent user={post.user} type={UserLoginComponentType.ONLY_TEXT} />
                     </>
                  ) : (
                     <>{t('post.edited')}&nbsp;</>
                  )}
               </>
            )}
            {post.editedBy && (
               <p>
                  <TimeAgo key={'ed' + post.id} date={post.updateDate} />
                  )&nbsp;
               </p>
            )}
         </div>
      </div>
   )
}

export interface PostNameProps {
   shouldShowClickableLink: boolean
   showFullView: boolean
   setFullSizeView: { (): void; (): void }
   post: IPost
   deleted: boolean
}

export const PostName = ({ shouldShowClickableLink, showFullView, setFullSizeView, post, deleted }: PostNameProps) => {
   const navigate = useNavigate()
   const { t } = useTranslation()

   function onPostNameClicked(e) {
      navigate('/blog/' + post.id, { state: { post } })
   }

   return (
      <div className='mb-0.5 w-full pt-1'>
         <div className='flex w-full flex-row justify-between'>
            <div className='flex flex-row leading-snug'>
               <div className={'mt-0.5 text-[18px] font-medium hover:cursor-pointer'}>
                  <a
                     className='text-color-normal'
                     href={showFullView ? post.contentUri : `blog/${post.id}`}
                     onClick={onPostNameClicked}
                     target='_blank'
                     rel='noreferrer'
                  >
                     {post.name}
                     {deleted && (
                        <>
                           &nbsp;
                           <span className='text-red-600 italic'>
                              {post.from ? (
                                 <div className='flex flex-row'>
                                    <FcAlarmClock className='mr-1 ml-1 h-5 w-5' />
                                    {DateTime.fromISO(post.from).toFormat('MMMM d, yyyy, hh:mm a')}
                                 </div>
                              ) : (
                                 t('post.deleted')
                              )}
                           </span>
                        </>
                     )}
                  </a>
               </div>
            </div>
         </div>
      </div>
   )
}

export const PostNotExist = () => {
   const { t } = useTranslation()
   return (
      <div className='background-normal rounded-normal flex w-full flex-col items-center justify-center px-4 py-4'>
         <h1 className='text-xl font-semibold text-red-600'>{t('post.post_not_found_header')}</h1>
         <p className='text-base text-red-500'>{t('post.post_not_found_info')}</p>
      </div>
   )
}
