import React, { useState } from 'react'
import { Chef } from '@/model/chef.model'
import { AbstractWebModal } from '@/layout/common/AbstractWebModal'
import {
   EmailIcon,
   EmailShareButton,
   FacebookIcon,
   FacebookShareButton,
   TwitterIcon,
   TwitterShareButton,
   WhatsappIcon,
   WhatsappShareButton,
} from 'react-share'
import { MdContentCopy } from 'react-icons/md'
import { HiOutlineUserCircle } from 'react-icons/hi'
import { isMobile } from 'react-device-detect'
import { AbstractMobileModal } from '@/layout/common/AbstractMobileModal'
import { useTranslation } from 'react-i18next'
import { CDN_ENDPOINT, SITE_BASE_URL } from '@/env'

interface ShareChefModalProps {
   chef: Chef
   setShowShareModal: (showShareModal: boolean) => void
}

const ShareChef = ({ chef, setShowShareModal }: ShareChefModalProps) => {
   const { t } = useTranslation()
   const currentUrl = window.location.pathname
   const [copied, setCopied] = useState(false)

   const handleCancelClick = (event: any) => {
      event.stopPropagation()
      setShowShareModal(false)
   }

   const handleCopyLink = () => {
      navigator.clipboard.writeText(SITE_BASE_URL + 'embedded/' + chef.id)
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
   }

   const shareOptions = [
      {
         key: 'facebook',
         ShareButton: FacebookShareButton,
         Icon: FacebookIcon,
         label: t('share.facebook'),
         shareProps: {
            url: currentUrl,
            quote: t('share.message', { name: chef.name }),
         },
      },
      {
         key: 'twitter',
         ShareButton: TwitterShareButton,
         Icon: TwitterIcon,
         label: t('share.twitter'),
         shareProps: {
            url: currentUrl,
            title: t('share.message', { name: chef.name }),
         },
      },
      {
         key: 'whatsapp',
         ShareButton: WhatsappShareButton,
         Icon: WhatsappIcon,
         label: t('share.whatsapp'),
         shareProps: {
            url: currentUrl,
            title: t('share.message', { name: chef.name }),
            separator: ' - ',
         },
      },
      {
         key: 'email',
         ShareButton: EmailShareButton,
         Icon: EmailIcon,
         label: t('share.email'),
         shareProps: {
            url: currentUrl,
            subject: t('share.message', { name: chef.name }),
            body: t('share.emailBody', { name: chef.name }),
         },
      },
      {
         key: 'copy',
         onClick: handleCopyLink,
         Icon: MdContentCopy,
         label: t('share.copyLink'),
         isCopy: true,
      },
   ]

   function shareComponent() {
      return (
         <div>
            <div className='flex flex-col items-center space-y-2 mt-4'>
               {chef.avatar ? (
                  <img
                     src={CDN_ENDPOINT + chef.avatar}
                     alt={`${chef.name}'s profile`}
                     className='mb-3 h-24 w-24 rounded-full border-2 border-gray-200 shadow-xs'
                  />
               ) : (
                  <HiOutlineUserCircle className='h-24 w-24 text-gray-400' />
               )}
               <h3 className='text-xl font-semibold text-gray-800'>{chef.name}</h3>
            </div>

            <div className='mt-4 grid grid-cols-3 gap-4'>
               {shareOptions.map(option => {
                  const { key, ShareButton, Icon, label, shareProps, onClick, isCopy } = option
                  if (isCopy) {
                     return (
                        <div key={key} onClick={onClick} className='w-full'>
                           <div className='flex cursor-pointer flex-col items-center space-y-1.5 rounded-lg p-2 transition-colors duration-300 hover:bg-blue-50'>
                              <div
                                 className='flex items-center justify-center rounded-full bg-gray-200'
                                 style={{ width: 44, height: 44 }}
                              >
                                 <Icon size={28} className='text-gray-600' />
                              </div>
                              <span className='text-sm text-gray-500'>{label}</span>
                              {copied && <span className='mt-1 text-xs text-green-500'>{t('share.copied')}</span>}
                           </div>
                        </div>
                     )
                  } else {
                     return (
                        <ShareButton key={key} {...shareProps} className='w-full'>
                           <div className='flex cursor-pointer flex-col items-center space-y-1.5 rounded-lg p-3 transition-colors duration-300 hover:bg-blue-50'>
                              <Icon size={40} round />
                              <span className='text-sm text-gray-500'>{label}</span>
                           </div>
                        </ShareButton>
                     )
                  }
               })}
            </div>
         </div>
      )
   }

   return isMobile ? (
      <AbstractMobileModal
         increaseZIndex={true}
         title={<p>t('share.title')</p>}
         customNavigation={() => setShowShareModal(false)}
      >
         {shareComponent()}
      </AbstractMobileModal>
   ) : (
      <AbstractWebModal title={`${t('share.title')}\u00A0${chef.name}`} onClose={handleCancelClick}>
         {shareComponent()}
      </AbstractWebModal>
   )
}

export default ShareChef
