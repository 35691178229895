import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { hideForgotPasswordModal, resetPassword } from '@/service/authentication'
import { LoadingIndicator } from '@/layout/common/LoadingIndicator'
import { FloatingLabelInput } from '@/layout/common/FloatingLabelInput'
import { AbstractWebModal } from '@/layout/common/AbstractWebModal'

export const ForgotPasswordModal = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const {
      register,
      watch,
      handleSubmit,
      formState: { errors, isValid },
   } = useForm<any>({ mode: 'all' })

   const { showForgotPassword } = useAppSelector(state => state.authentication)

   const [isLoading, setIsLoading] = useState(false)

   const onSubmit = useCallback((formData: any) => {
      setIsLoading(true)
      resetPassword(formData.email)
         .then(value => {
            dispatch(hideForgotPasswordModal())
         })
         .finally(() => {
            setIsLoading(false)
         })
   }, [])

   function onCancelClick() {
      dispatch(hideForgotPasswordModal())
   }

   return showForgotPassword ? (
      <AbstractWebModal title={t('reset_pass.header')} onClose={onCancelClick}>
         <form onSubmit={handleSubmit(onSubmit)}>
            <p className='text-coolGray-600 my-4 text-sm'>{t('reset_pass.descr')}</p>
            <div className='space-y-4'>
               <FloatingLabelInput
                  label={t('reset_pass.email')}
                  type='email'
                  name={'email'}
                  register={register}
                  rules={{
                     required: t('error.required'),
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t('error.invalid_email'),
                     },
                  }}
                  errors={errors}
                  watch={watch}
                  submitOnEnter={true}
               />
            </div>
            <div className='mt-5 space-y-2'>
               <div className='flex gap-x-2'>
                  <button
                     type='submit'
                     className={`sign-confirm-button ${!isValid ? 'gray-out' : ''}`}
                     disabled={!isValid}
                  >
                     {t('reset_pass.confirm')}
                  </button>
               </div>
            </div>
         </form>
         {isLoading && <LoadingIndicator />}
      </AbstractWebModal>
   ) : (
      <div></div>
   )
}
