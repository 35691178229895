import dayjs from 'dayjs'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Chef, OfferInfoChef } from '@/model/chef.model'
import { OfferInfoFood, OfferPerWeekDaysFood } from '@/model/order/food.model'
import { FoodOrderItem } from '@/model/order/FoodOrderItem'
import { getOfferInfoChefForDeliveryType, isAllAvailableForDay } from '@/util/food-availability-utils'
import { useTranslation } from 'react-i18next'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { DeliveryType } from '@/model/enumerations/delivery-type.model'
import { isDesktop } from 'react-device-detect'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { getPickUpPointsByIds } from '@/service/pick-up-point.service'
import { generateTimeSlotsForPickUpPoints, PickUpModeComponent } from '@/layout/shopping_cart/PickUpPointPicker'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Paris')

interface DateTimePickerProps {
   chef: Chef
   onDateTimeSelect: (date: string, time: string, pickUpPointId: string) => void
   orders?: FoodOrderItem[]
   deliveryType: DeliveryType
   preSelectedDeliveryType?: DeliveryType
   preSelectedDate?: string
   preSelectedTime?: string
}

export type UnavailableInfo = { foodId: string; reason: string }
export type SlotProps = { name: string; available: boolean; unavailableInfo: UnavailableInfo[] }[]

export const DateTimePickerForOrder: React.FC<DateTimePickerProps> = ({
   chef,
   onDateTimeSelect,
   orders,
   preSelectedDeliveryType,
   preSelectedDate,
   preSelectedTime,
}) => {
   const { t } = useTranslation()
   const daysToShow = 7

   const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(null)
   const [selectedTime, setSelectedTime] = useState<string | null>(null)
   const daysContainerRef = useRef<HTMLDivElement>(null)
   const [days, setDays] = useState<{ date: dayjs.Dayjs; label1: string; label2: string; slots: SlotProps }[]>([])
   const [pickupPoints, setPickupPoints] = useState<Chef[]>([])
   const [chosenPoint, setChosenPoint] = useState<Chef | null>(null)

   useEffect(() => {
      if (preSelectedDeliveryType == DeliveryType.DELIVER_TO_PICKUP_POINT && !chef.pickUpPoint) {
         getPickUpPointsByIds(chef.chefsOrdPointsIds).then(res => {
            setPickupPoints(res.data)
         })
      }

      if (preSelectedDeliveryType != DeliveryType.DELIVER_TO_PICKUP_POINT && chosenPoint != null) setChosenPoint(null)
   }, [preSelectedDeliveryType])

   useEffect(() => {
      if (!chosenPoint) return

      const newDays = Array.from({ length: daysToShow }, (_, i) => {
         const date = dayjs().add(i, 'day')
         const weekday = date.format('dddd').toLowerCase() as keyof OfferPerWeekDaysFood
         let deliverToPickUpPointHours = chosenPoint?.deliverToPickUpPointHours
         const offerInfo = deliverToPickUpPointHours?.[weekday]
         if (!offerInfo?.open || !offerInfo.close || (!chef.pickupHours?.[weekday] && !chef.dineInHours?.[weekday])) {
            console.log('No operating hours for this day:', weekday)
            return {
               date,
               label1: i === 0 ? 'TODAY' : i === 1 ? 'TOMORROW' : date.format('dddd').toLowerCase(),
               label2: date.format('D MMM'),
               slots: [],
            }
         }
         const slots = generateTimeSlotsForPickUpPoints(date, offerInfo, chef, chosenPoint)
         return {
            date,
            label1: i === 0 ? 'TODAY' : i === 1 ? 'TOMORROW' : date.format('dddd').toLowerCase(),
            label2: date.format('D MMM'),
            slots,
         }
      })
      setDays(newDays)
   }, [chosenPoint, orders])

   useEffect(() => {
      if (!preSelectedDeliveryType) return
      if (preSelectedDeliveryType == DeliveryType.DELIVER_TO_PICKUP_POINT && !chef.pickUpPoint) return

      let operatingHoursBasedOnChef = getOfferInfoChefForDeliveryType(chef, preSelectedDeliveryType)
      const newDays = Array.from({ length: daysToShow }, (_, i) => {
         const date = dayjs().add(i, 'day')
         const weekday = date.format('dddd').toLowerCase() as keyof OfferPerWeekDaysFood

         const offerInfoChef = operatingHoursBasedOnChef?.[weekday]

         if (!offerInfoChef) return { date, label1: '', label2: '', slots: [] }
         const slots = generateTimeSlots(date, offerInfoChef, orders)
         return {
            date,
            label1: i === 0 ? 'TODAY' : i === 1 ? 'TOMORROW' : date.format('dddd').toLowerCase(),
            label2: date.format('D MMM'),
            slots,
         }
      })
      setDays(newDays)
   }, [preSelectedDeliveryType, chef, orders])

   // NEW useEffect to handle pre-selected date and time
   useEffect(() => {
      if (days.length === 0) return

      if (preSelectedDate && preSelectedTime) {
         const date = dayjs(preSelectedDate)
         setSelectedDate(date)

         const selectedDay = days.find(d => d.date.isSame(date, 'day'))
         if (selectedDay && selectedDay.slots.some(d => d.name === preSelectedTime)) {
            setSelectedTime(preSelectedTime)
         } else {
            // Time not available, reset selectedTime or handle accordingly
            setSelectedTime(null)
         }
      } else if (preSelectedDeliveryType) {
         // Find first available date with time slots
         const availableDay = days.find(day => day.slots.length > 0)
         if (availableDay) {
            setSelectedDate(availableDay.date)
         }
      }
   }, [days, preSelectedDate, preSelectedTime, preSelectedDeliveryType])

   // Generate time slots based on chef's operating hours and current time
   const generateTimeSlots = (date: dayjs.Dayjs, offerInfoChef: OfferInfoChef, orders: FoodOrderItem[]): SlotProps => {
      const slots: SlotProps = []
      const today = dayjs().tz()

      const dateStr = date?.format('YYYY-MM-DD')
      if (!dateStr) {
         console.warn('Date is inv alid:', date)
         return slots
      }

      if (!offerInfoChef || !offerInfoChef.open || !offerInfoChef.close) {
         // console.warn('Missing or invalid offerInfo:', offerInfoChef, ' day ', dateStr)
         return slots
      }

      const timeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/
      if (!timeFormat.test(offerInfoChef.open) || !timeFormat.test(offerInfoChef.close)) {
         console.warn('Open or close time format is invalid:', offerInfoChef.open, offerInfoChef.close)
         return slots
      }

      let openTime: dayjs.Dayjs, closeTime: dayjs.Dayjs
      try {
         openTime = dayjs.tz(`${dateStr} ${offerInfoChef.open}`, 'YYYY-MM-DD HH:mm', 'Europe/Paris')
         closeTime = dayjs.tz(`${dateStr} ${offerInfoChef.close}`, 'YYYY-MM-DD HH:mm', 'Europe/Paris')
      } catch (error) {
         console.warn('Error parsing open or close time:', error)
         return slots
      }

      if (!openTime.isValid() || !closeTime.isValid()) {
         console.warn('Invalid open or close time after parsing:', offerInfoChef.open, offerInfoChef.close)
         return slots
      }

      let currentTime = openTime.clone()

      let timeIntervalMinutes = 60

      while (currentTime.isBefore(closeTime) || currentTime.isSame(closeTime)) {
         if (
            !date.isSame(today, 'day') ||
            (date.isSame(today, 'day') && currentTime.isAfter(today.clone().add(timeIntervalMinutes, 'minute')))
         ) {
            const availability = isAllAvailableForDay({ day: currentTime, orders })
            slots.push({
               name: currentTime.format('HH:mm'),
               available: availability.isAllAvailable,
               unavailableInfo: availability.unavailable,
            })
         }
         currentTime = currentTime.add(timeIntervalMinutes, 'minute')
      }

      return slots
   }

   // Handle date selection
   const handleDateSelect = (date: dayjs.Dayjs) => {
      setSelectedDate(date)
      setSelectedTime(null) // Reset selected time when date changes
   }

   const handleTimeSelect = useCallback(
      (time: string) => {
         setSelectedTime(time)
         if (selectedDate) {
            const [hours, minutes] = time.split(':').map(Number)
            const selectedDateTime = selectedDate.hour(hours).minute(minutes).second(0).millisecond(0)
            let pointId = chosenPoint?.id || null
            onDateTimeSelect(selectedDate.format('YYYY-MM-DD'), selectedDateTime.format('HH:mm'), pointId)
         }
      },
      [selectedDate, chosenPoint],
   )

   // Find the selected day's time slots
   const selectedDay = selectedDate ? days.find(d => d.date.isSame(selectedDate, 'day')) : null
   const timeSlots = selectedDay ? selectedDay.slots : []
   const scrollAmount = 96 // Adjust based on button width
   const [isScrolledLeft, setIsScrolledLeft] = useState(true)
   const [isScrolledRight, setIsScrolledRight] = useState(false)

   const checkScrollPosition = () => {
      if (daysContainerRef.current) {
         const { scrollLeft, scrollWidth, clientWidth } = daysContainerRef.current
         setIsScrolledLeft(scrollLeft === 0)
      }
   }

   const scrollDaysLeft = () => {
      if (daysContainerRef.current) {
         daysContainerRef.current.scrollBy({ left: -scrollAmount, behavior: 'smooth' })
         setTimeout(checkScrollPosition, 300)
      }
   }

   const scrollDaysRight = () => {
      if (daysContainerRef.current) {
         daysContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' })
         setTimeout(checkScrollPosition, 300)
      }
   }

   useEffect(() => {
      checkScrollPosition()
   }, [])

   return (
      <div className={`mt-2 flex flex-col items-center ${isDesktop ? 'min-h-[400px] min-w-[380px]' : ''}`}>
         {/* todo that should be like a first step, after setChosenPoint the second step should appear*/}

         <PickUpModeComponent
            pickUpPoint={chef.pickUpPoint}
            preSelectedDeliveryType={preSelectedDeliveryType}
            pickupPoints={pickupPoints}
            setChosenPoint={setChosenPoint}
            chosenPoint={chosenPoint}
         />
         {(chosenPoint || preSelectedDeliveryType !== DeliveryType.DELIVER_TO_PICKUP_POINT || chef.pickUpPoint) && (
            <div className='relative mb-4 flex w-full items-center'>
               <button
                  type='button'
                  onClick={scrollDaysLeft}
                  className={`absolute left-0 z-10 shrink-0 p-3 ${isScrolledLeft ? '' : 'bg-transparent'}`}
                  disabled={isScrolledLeft}
               >
                  <FaChevronLeft size={24} color={isScrolledLeft ? 'gray' : 'black'} />
               </button>
               <div
                  className='mx-0.5 flex min-w-0 flex-1 overflow-x-auto pr-4'
                  ref={daysContainerRef}
                  onScroll={checkScrollPosition}
               >
                  {days.map(({ date, label1, label2, slots }) => {
                     const disabled = slots.length === 0
                     return (
                        <button
                           type='button'
                           key={date.toString()}
                           className={`mt-1 mr-2 mb-3 flex w-24 flex-none flex-col items-center rounded-md border px-2 py-1.5 ${
                              selectedDate?.isSame(date, 'day')
                                 ? 'bg-customPurple text-white'
                                 : 'bg-gray-200 text-gray-800 hover:bg-gray-300'
                           } ${disabled ? 'cursor-not-allowed opacity-50' : 'hover:cursor-pointer'}`}
                           disabled={disabled}
                           onClick={() => handleDateSelect(date)}
                        >
                           <div className='text-base font-semibold'>{t(`days.${label1}`)}</div>
                           <div className='text-sm'>{label2}</div>
                        </button>
                     )
                  })}
               </div>
               <button
                  type='button'
                  onClick={scrollDaysRight}
                  className={`absolute right-0 z-10 shrink-0 p-2 ${isScrolledRight ? '' : 'bg-transparent'}`}
                  disabled={isScrolledRight}
               >
                  <FaChevronRight size={24} color={isScrolledRight ? 'gray' : 'black'} />
               </button>
            </div>
         )}
         {/* Time Slots */}
         {timeSlots.length > 0 ? (
            <div className='flex flex-wrap justify-center'>
               {timeSlots.map((time, index) => (
                  <button
                     type='button'
                     disabled={false}
                     key={time.name + index}
                     className={`m-1 rounded-full px-4 py-2 text-sm ${
                        selectedTime === time.name ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'
                     } ${!time.available ? 'cursor-not-allowed opacity-50' : 'hover:cursor-pointer hover:bg-gray-300'}`}
                     onClick={() => {
                        if (time.available) {
                           handleTimeSelect(time.name)
                        } else {
                           console.log('Time slot not available:', time.unavailableInfo)
                        }
                     }}
                  >
                     {preSelectedDeliveryType === DeliveryType.PICKUP || preSelectedDeliveryType === DeliveryType.DELIVER_TO_PICKUP_POINT
                        ? `${time.name} - ${dayjs(`2000-01-01 ${time.name}`).add(1, 'hour').format('HH:mm')}`
                        : time.name}
                  </button>
               ))}
            </div>
         ) : (
            selectedDate && <p className='mt-4 text-gray-600'>{t('food_add_page.no_available_today')}</p>
         )}
      </div>
   )
}
