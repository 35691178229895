import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import en_translation from './translations/en_translation.json'
import fr_translation from './translations/fr_translation.json'
import br_translation from './translations/br_translation.json'
import { updateLocale } from '@/service/user-settings-reducer'

// Define available languages in your app
const availableLanguages = ['en', 'fr', 'br']
export const languages = {
   en: 'language_selector.en',
   // pl: 'language_selector.pl',
   fr: 'language_selector.fr',
   // br: 'language_selector.br',
}

// Get the language from localStorage, system, or fallback to 'en'
const getInitialLanguage = () => {
   // Check localStorage first
   const storedLang = localStorage.getItem('lng')
   if (storedLang && availableLanguages.includes(storedLang)) {
      return storedLang
   }

   // Check system language
   const systemLang = navigator.language.split('-')[0] // Get language code only
   if (availableLanguages.includes(systemLang)) {
      return systemLang
   }

   // Fallback to English
   return 'en'
}

// Initialize i18next within the slice
i18next.use(initReactI18next).init({
   resources: {
      en: {
         translation: en_translation,
      },
      // pl: {
      //    translation: pl_translation,
      // },
      fr: {
         translation: fr_translation,
      },
      br: {
         translation: br_translation,
      },
   },
   lng: getInitialLanguage(),
})

const initialState = {
   lng: i18next.language, // Initialize with i18next's current language
}

export type LocaleState = typeof initialState

// Thunk to initialize language
export const initializeLanguage = (store: any) => {
   const state = store.getState() as { locale: LocaleState }
   const currentLanguage = state?.locale?.lng

   const initialLanguage = getInitialLanguage()

   if (currentLanguage !== initialLanguage) {
      store.dispatch(setLanguage({ lng: initialLanguage, isUserLoggedIn: false }))
   }
}

export const LocaleSlice = createSlice({
   name: 'locale',
   initialState,
   reducers: {
      setLanguage(state, action: PayloadAction<{ lng: string; isUserLoggedIn?: boolean }>) {
         const { lng, isUserLoggedIn } = action.payload
         state.lng = lng
         if (isUserLoggedIn) updateLocale(lng)
         i18next.changeLanguage(lng) // Change the language in i18next

         if (isUserLoggedIn) {
            // Optionally handle logic for logged-in users here
            // e.g., syncing language preference with the server
         }

         localStorage.setItem('lng', lng) // Save the language in localStorage
      },
   },
})

export const { setLanguage } = LocaleSlice.actions

export default LocaleSlice.reducer
