import React, { ReactElement, useEffect, useRef } from 'react'

import { MdKeyboardArrowLeft } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

interface AbstractMobileModalProps {
   title: ReactElement<any, any> | string
   children: React.ReactNode
   titleAddedStyles?: string
   customNavigation?: () => void
   externalRef?: any
   increaseZIndex?: boolean
}

export const AbstractMobileModal = ({
   title,
   children,
   titleAddedStyles = '',
   customNavigation = undefined,
   externalRef = undefined,
   increaseZIndex = false,
}: AbstractMobileModalProps) => {
   const navigate = useNavigate()
   const internalRef = useRef(null)
   const activeRef = externalRef || internalRef

   useEffect(() => {
      document.body.style.overflow = 'hidden'

      return () => {
         document.body.style.overflow = ''
      }
   }, [])

   useEffect(() => {
      window.history.pushState(null, '', window.location.pathname)

      const handlePopState = () => {
         if (customNavigation) customNavigation()
         else navigate(-1)
      }

      window.addEventListener('popstate', handlePopState)

      // Cleanup the event listener when the modal is unmounted
      return () => {
         window.removeEventListener('popstate', handlePopState)
      }
   }, [navigate, customNavigation])

   return (
      <div
         style={{
            zIndex: increaseZIndex ? 1150 : 30,
            height: '100dvh',
         }}
         className='fixed inset-0 flex w-full flex-col'
      >
         {/* Header */}
         <header
            onClick={() => {
               if (customNavigation === undefined) navigate(-1)
               else customNavigation()
            }}
            className={`sticky top-0 z-40 flex h-[52px] items-center justify-between bg-gray-100 text-center text-xl font-semibold dark:bg-black ${titleAddedStyles}`}
         >
            {/* Back Button */}
            <div
               className='absolute left-2 mr-2 cursor-pointer'
               role='button'
               aria-label='Go Back'
               tabIndex={0}
               onKeyUp={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                     if (customNavigation === undefined) navigate(-1)
                     else customNavigation()
                  }
               }}
            >
               <MdKeyboardArrowLeft className='ml-1 h-8 w-8' />
            </div>
            {/* Title */}
            <div className='ml-8 w-full'>{title}</div>
            <div className='h-6 w-8' />
         </header>

         {/* Content */}
         <main ref={activeRef} className='w-full flex-1 overflow-y-auto bg-white dark:bg-black'>
            {children}
         </main>
      </div>
   )
}
