import React, { useEffect, useState } from 'react'
import { SingleFoodView } from '@/layout/food/SingleFoodView'
import { Food } from '@/model/order/food.model'
import { useParams } from 'react-router-dom'
import { LoadingBalls } from '@/layout/common/LoadingIndicator'
import { getFoodById } from '@/service/food.service'

export const FoodByIdView = () => {
   const [food, setFood] = useState<Food>()
   const [loading, setLoading] = useState(false)
   const { id } = useParams()

   useEffect(() => {
      if (!id) return
      setLoading(true)
      getFoodById(id)
         .then(response => {
            setFood(response.data)
         })
         .finally(() => {
            setLoading(false)
         })
   }, [id])

   return <>{loading ? <LoadingBalls /> : <SingleFoodView food={food} singleFoodView={true} chefSuspended={false} />}</>
}

export default FoodByIdView
