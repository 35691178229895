import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { isUserLoggedIn } from '@/util/auth-util'
import { useAppSelector } from '@/config/store'
import { createComment } from '@/service/comment/comment-reducer'
import { CommentType } from '@/model/enumerations/comment-type.model'
import { SuperBallsLoadingIndicator } from '../../../common/LoadingIndicator'
import { UserIconComponent } from '../../../common/UserIconComponent'
import { IComment } from '@/model/comment.model'
import { PhotoType } from '@/model/enumerations/photo-type'
import { ISubcomment } from '@/model/subcomment.model'
import { LexicalEditorWrapper, SubmitLexicalButton } from '@/layout/common/lexical-editor/LexicalEditorWrapper'

export const MIN_COMMENT_LENGTH = 2

export const AddEditCommentComponentWeb = ({
   postId,
   afterCommentAdd = (comment: IComment | ISubcomment) => {},
   commentId,
   addedStyles = '',
   key = 'cc',
   userToReply,
}) => {
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const { t } = useTranslation()
   const [loading, setLoading] = useState(false)
   const editorStateHTMLRef = useRef('')
   const [isEditorStateValid, setIsEditorStateValid] = useState(false)
   const [usersToMention, setUsersToMention] = useState([])

   useEffect(() => {
      setUsersToMention(userToReply ? [userToReply] : [])
   }, [userToReply])

   const loggedIn = isUserLoggedIn(loggedAccount)
   const clearEditorContentRef = useRef(null)
   const [isDragging, setIsDragging] = useState(false)
   const onSubmit = () => {
      if (!isEditorStateValid) return
      if (loading) return
      if (!loggedIn) return

      setLoading(true)

      createComment({
         content: editorStateHTMLRef.current,
         parentCommentId: commentId,
         postId: postId,
         commentType: commentId != null ? CommentType.SUB_COMMENT : CommentType.NORMAL,
      })
         .then(value => {
            afterCommentAdd(value.data)
            editorStateHTMLRef.current = ''
            if (clearEditorContentRef.current) clearEditorContentRef.current()
            setLoading(false)
         })
         .finally(() => {
            setLoading(false)
         })
   }

   const handleDragOver = event => {
      event.preventDefault()
      setIsDragging(true)
   }

   const handleDrop = event => {
      event.preventDefault()
      setIsDragging(false)
      setDroppedFile(event.dataTransfer.files[0])
   }

   const handleDragLeave = () => {
      setIsDragging(false)
   }

   function clearEditorContent(callback) {
      clearEditorContentRef.current = callback
   }

   const [droppedFile, setDroppedFile] = useState(null)
   return (
      loggedIn && (
         <div key={key == undefined ? 'keyAC' : key} className='mb-2 flex w-full flex-col'>
            <div className={'relative mt-1 mb-1.5 flex w-full flex-row ' + addedStyles}>
               <UserIconComponent picture={loggedAccount.picture} />
               <div style={{ minWidth: 0 }} className='mr-2.5 ml-1.5 w-full'>
                  {loading && (
                     <div className='absolute top-0 right-0 mr-[50px]'>
                        <SuperBallsLoadingIndicator />
                     </div>
                  )}
                  <div
                     ref={droppedFile}
                     className={`rounded-normal background-gray-dark-normal break-word flex flex-1 flex-row py-1.5 pl-3 outline-hidden ${isDragging ? 'bg-opacity-50 border-2 border-dashed border-blue-500 bg-gray-400 shadow-lg dark:bg-gray-700' : ''}`}
                     onDragOver={handleDragOver}
                     onDrop={handleDrop}
                     onDragLeave={handleDragLeave}
                  >
                     {isDragging && (
                        <div className='absolute mt-3 ml-[-20px] w-full text-center'>
                           <p className='font-semibold text-blue-500 dark:text-gray-100'>
                              {t('lexical.drag_to_upload')}
                           </p>
                        </div>
                     )}
                     <LexicalEditorWrapper
                        droppedFile={droppedFile}
                        placeholder={
                           <p className='lexical-placeholder text-[15px]'>{t('add_your_post.add_comment')}</p>
                        }
                        photoType={PhotoType.FOR_COMMENT}
                        htmlStateUpdateFunction={(newState: string, size: number, hasImage: boolean) => {
                           if (hasImage || size >= MIN_COMMENT_LENGTH) setIsEditorStateValid(true)
                           else setIsEditorStateValid(false)
                           editorStateHTMLRef.current = newState
                        }}
                        addImageEnabled={true}
                        clearEditorContent={clearEditorContent}
                        addedMentionUsers={usersToMention}
                        clearAddedMentionUser={() => {
                           setUsersToMention([])
                        }}
                        loadingF={l => {
                           setLoading(l)
                        }}
                     />
                     <SubmitLexicalButton loading={loading} isValid={isEditorStateValid} onSubmit={onSubmit} />
                  </div>
               </div>
            </div>
         </div>
      )
   )
}
export default AddEditCommentComponentWeb
