import React, { useEffect, useRef, useState } from 'react'
import { HiX } from 'react-icons/hi'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import { ClipLoader } from 'react-spinners'
import { Suggestion } from '@/layout/add-chef/CreateChefPage'
import { SuggestionComponent } from '@/layout/common/SuggestionComponent'

interface AddressSearchInputProps {
   placeholder: string
   selectedAddress: any
   searchedPhrase: string
   loading: boolean
   setLoading: (loading: boolean) => void
   addresses: Suggestion[]
   onSearch: (value: string) => void
   onClear: () => void
   onSuggestionSelect: (suggestion: Suggestion) => void
}

const AddressSearchInput: React.FC<AddressSearchInputProps> = ({
   placeholder,
   selectedAddress,
   searchedPhrase,
   loading,
   setLoading,
   addresses,
   onSearch,
   onClear,
   onSuggestionSelect,
}) => {
   const [showSuggestions, setShowSuggestions] = useState(false)
   const inputRef = useRef<HTMLDivElement>(null)

   useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
         if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
            setShowSuggestions(false) // Always close suggestions on outside click
            setLoading(false)
         }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
         document.removeEventListener('mousedown', handleClickOutside)
      }
   }, [setLoading])

   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      onSearch(value)

      if (value) {
         setShowSuggestions(true)
         setLoading(true)
      } else {
         setShowSuggestions(false)
         setLoading(false)
      }
   }

   const handleInputFocus = () => {
      if (searchedPhrase) {
         setShowSuggestions(true)
      }
   }

   return (
      <div
         className={`background-gray-dark-normal mb-6 flex w-full flex-col space-y-1 rounded-lg ${showSuggestions ? 'rounded-b-none' : ''} pt-1`}
         ref={inputRef}
      >
         <div className='ml-2 flex items-center justify-center space-x-1'>
            {!selectedAddress && <HiMagnifyingGlass className='ml-0.5 h-6 w-6 shrink-0 cursor-pointer text-gray-500' />}
            <input
               data-cy='searchLocation'
               className={`mr-4 h-[36px] w-full bg-transparent text-base outline-hidden ${
                  selectedAddress ? 'cursor-default pl-2 text-gray-500' : 'cursor-text pl-[1px]'
               }`}
               type='text'
               placeholder={placeholder}
               onChange={handleInputChange}
               onFocus={handleInputFocus}
               value={searchedPhrase}
               readOnly={!!selectedAddress}
            />
            {loading && (
               <ClipLoader
                  size={22}
                  color={'#2D84CB'}
                  loading={true}
                  className='right-3.5 mt-4 -translate-y-1/2 transform'
               />
            )}
            {selectedAddress && (
               <HiX
                  className='ml-2 h-6 w-6 shrink-0 cursor-pointer text-red-500'
                  onClick={() => {
                     onClear()
                     setLoading(false)
                  }}
               />
            )}
         </div>

         {showSuggestions && (
            <SuggestionComponent
               suggestions={addresses}
               handleSuggestionClick={suggestion => {
                  onSuggestionSelect(suggestion)
                  setShowSuggestions(false)
                  setLoading(false)
               }}
            />
         )}
      </div>
   )
}

export default AddressSearchInput
