import React from 'react'
import { useTranslation } from 'react-i18next'
import StepsBarComponent from '@/layout/common/StepsBarComponent'
import { OrderForChef } from '@/model/order/FoodOrderItem'
import { CDN_ENDPOINT } from '@/env'
import { formatPrice } from '@/util/text-utils'
import { isDesktop } from 'react-device-detect'

interface ShoppingCardProgressBarProps {
   step: number
}

const ShoppingCardProgressBar = ({ step }: ShoppingCardProgressBarProps) => {
   const { t } = useTranslation()
   const steps = [
      { label: t('shopping_cart_progress_bar.your_cart') },
      { label: t('shopping_cart_progress_bar.payment') },
      { label: t('shopping_cart_progress_bar.done') },
   ]

   return <StepsBarComponent steps={steps} currentStepIdx={step} includeStepZero={true} />
}

export default ShoppingCardProgressBar

interface SelectChefComponentProps {
   ordersForChef: OrderForChef[]
   onOrdersSelected: (orders: OrderForChef) => void
}

export const SelectChefComponent = ({ ordersForChef, onOrdersSelected }: SelectChefComponentProps) => {
   const { t } = useTranslation()
   return (
      <div>
         <h2 className='mt-1.5 mb-5 text-center text-2xl font-semibold select-none'>
            {t('shopping_cart.select_chef_to_continue')}
         </h2>
         <div className='mb-2 flex flex-col space-y-6'>
            {ordersForChef.map(o => (
               <div
                  key={o.chef.id}
                  className={`${isDesktop ? 'mx-2' : 'mx-1'} flex flex-row items-center justify-between rounded-lg bg-gray-100 p-4 hover:cursor-pointer hover:bg-gray-200`}
                  onClick={() => onOrdersSelected(o)}
               >
                  <div className='flex flex-row items-center'>
                     <img src={CDN_ENDPOINT + o.chef.avatar} alt={o.chef.name} className='h-16 w-16 rounded-full' />
                     <div>
                        <div className='mt-2 ml-2'>
                           <p className='font-bold'>{o.chef.name}</p>
                           <p id={'totalPriceSC'}>
                              {t('total')}: €
                              {formatPrice(
                                 o.orders.reduce((total, order) => total + order.food.price * order.quantity, 0),
                              )}
                           </p>
                        </div>
                     </div>
                  </div>
                  <div className='flex h-6 w-6 items-center justify-center rounded-full bg-gray-900 text-sm text-white'>
                     {o.orders.reduce((total, order) => total + order.quantity, 0)}
                  </div>
               </div>
            ))}
         </div>
      </div>
   )
}
