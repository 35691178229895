export const PRODUCTION = import.meta.env.VITE_PRODUCTION === 'true'
export const MOBILE_MODE = import.meta.env.VITE_MOBILE_MODE === 'true'

export const CDN_ENDPOINT = import.meta.env.VITE_CDN_ENDPOINT || 'http://localhost:9000/chefs/DEV/'
export const CDN_ENDPOINT_STATIC_CONTENT = `${CDN_ENDPOINT}STATIC_CONTENT/`

export const SERVER_API_URL = import.meta.env.VITE_SERVER_API_URL || 'http://localhost:8081/'
export const SITE_BASE_URL = import.meta.env.VITE_SERVER_API_URL || 'http://localhost:5174/'
export const FCM_VAPID_KEY = import.meta.env.FCM_VAPID_KEY || 'BDFgE9yyBUEviQP9Xzolt0UhCKF3bnNGImXVv3SegyS5C4NnaWG8viiA0-g_utT0Om2XAiiYF6WKkubt9uHEsmM'

export const REACT_APP_STRIPE_PUBLISHABLE_KEY =
   import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY ||
   'pk_test_51Mx9ngB3vIRu5RiwTaw1oWq4SCGU2jTbAcfLjWSIWAoHDyCuH6UD6tfNvw9FaDOA0D5NPP1PJ5uI8krRo0KxWpI700ZuYjfkuo'
