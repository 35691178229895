import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { voteForComment } from '@/service/comment/comment-reducer'
import { EditCommentForm } from './EditCommentForm'
import { MoreActionsComment } from '../../more_actions/MoreActionsComment'
import { CDN_ENDPOINT } from '@/env'
import { SuperBallsLoadingIndicator } from '@/layout/common/LoadingIndicator'
import { isSettingEnabled } from '@/util/auth-util'
import { useAppSelector } from '@/config/store'
import { ContentSettingsType } from '@/model/enumerations/content-settings-type'
import { isDesktop } from 'react-device-detect'
import { IComment } from '@/model/comment.model'
import { VoteForPost } from '@/layout/post/VoteForPost'
import { CommentsAmount, voteDownForPost, voteUpForPost } from '@/layout/post/singlePost/SinglePostCommon'
import { MoreActionsPost } from '@/layout/more_actions/MoreActionsPost'
import { COMMENT_SORT_BY } from '@/service/search'
import { PiArrowsOutLineVertical } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { UserLoginComponent, UserLoginComponentType } from '@/layout/common/UserLoginComponent'
import { TbArrowBigDown, TbArrowBigDownLine, TbArrowBigUp, TbArrowBigUpLine } from 'react-icons/tb'
import { TimeAgo } from '@/util/TimeAgo'
import ReadOnlyLexical from '@/layout/common/lexical-editor/ReadOnlyLexical'

function GetPicture({ picture, runGifs, showPicsAsLinks, t }) {
   if (picture == null) return null

   const [showLink, setShowLink] = useState(showPicsAsLinks)
   const [fullSize, setFullSize] = useState(false)

   const isGif = typeof picture === 'string' && picture.toLowerCase().endsWith('.gif')

   const [pictureURL, setPictureURL] = useState('')

   useEffect(() => {
      if (!showLink) {
         setPictureURL(
            runGifs && isGif && !fullSize ? CDN_ENDPOINT + picture.replace('.gif', '.jpeg') : CDN_ENDPOINT + picture,
         )
      }
   }, [showLink, runGifs, isGif, fullSize, picture])

   if (showLink) {
      return (
         <div
            className='text-blue-500 hover:cursor-pointer'
            onClick={() => {
               setShowLink(false)
            }}
         >
            {t('content.show_picture')}
         </div>
      )
   }

   return (
      picture && (
         <div
            className={
               'mt-1 mb-1 ml-0.5 ' + (fullSize ? '' : ' h-[100%] w-[100%] max-w-[220px] min-w-[150px] cursor-pointer')
            }
            onClick={_ => {
               setFullSize(true)
               if (isGif) {
                  setPictureURL(CDN_ENDPOINT + picture)
               }
            }}
         >
            <img className='' src={pictureURL} alt="There's a problem with showing the image" />
         </div>
      )
   )
}

export function userNameAndContent(
   comment: IComment,
   deleted: boolean,
   commentDeletedFunction: () => void,
   onCommentEditFinished,
   editing: boolean,
   changeEditing,
   canDeleteFromFavourites = false,
   deleteFromFavouritesFunction = (commentId: string) => {},
) {
   const MAX_HEIGHT = 400
   const { t } = useTranslation()
   const loggedAccount = useAppSelector(state => state.authentication.account)
   let runGifs = isSettingEnabled(loggedAccount, ContentSettingsType.LOAD_GIFS_AFTER_CLICK)
   let showPicsAsLinks = isSettingEnabled(loggedAccount, ContentSettingsType.SHOW_PICS_MOVIES_AS_LINKS)
   const [showFullText, setShowFullText] = useState(false)
   const contentRef = useRef(null)
   const [isLongText, setIsLongText] = useState(false)
   const darkMode = useAppSelector(state => state.themeModeReducer.darkTheme)

   useEffect(() => {
      const measureOffsetHeight = delay => {
         setTimeout(() => {
            if (contentRef.current) {
               const offsetHeight = contentRef.current.offsetHeight
               if (offsetHeight > MAX_HEIGHT) setIsLongText(true)
            }
         }, delay)
      }

      measureOffsetHeight(20)
      measureOffsetHeight(150)
      measureOffsetHeight(300)
      measureOffsetHeight(700)
   }, [comment])

   function enableEditMode() {
      changeEditing(true)
   }

   useEffect(() => {
      runGifs = isSettingEnabled(loggedAccount, ContentSettingsType.LOAD_GIFS_AFTER_CLICK)
      showPicsAsLinks = isSettingEnabled(loggedAccount, ContentSettingsType.SHOW_PICS_MOVIES_AS_LINKS)
   }, [runGifs, showPicsAsLinks, loggedAccount])

   function onEditFinished(shouldUpdate: boolean, newComment: string, images: any) {
      changeEditing(false)
      onCommentEditFinished(shouldUpdate, newComment, images)
   }

   return (
      <div className='mt-2 flex w-full min-w-fit flex-row flex-nowrap'>
         <div className='lexical-box'>
            {usernameAndCommentDate(comment)}
            {editing && isDesktop ? (
               <EditCommentForm comment={comment} onEditFinished={onEditFinished} />
            ) : (
               <div
                  className={`mx-2 mt-1 flex flex-col text-sm sm:min-w-[260px] ${deleted ? 'italic' : ''}`}
                  style={{ fontWeight: 400 }}
               >
                  {deleted ? (
                     <div className='italic'>{t('comments.deleted')}</div>
                  ) : (
                     <div
                        style={{
                           position: 'relative',
                           maxHeight: showFullText ? 'none' : MAX_HEIGHT,
                           overflow: 'hidden',
                        }}
                        className={`${!showFullText && isLongText ? 'hover:cursor-pointer' : ''}`}
                        onClick={() => {
                           if (!showFullText) setShowFullText(true)
                        }}
                     >
                        <div className='flex flex-col' ref={contentRef}>
                           <ReadOnlyLexical htmlState={comment.content} />
                           <GetPicture
                              picture={comment.picture}
                              runGifs={runGifs}
                              showPicsAsLinks={showPicsAsLinks}
                              t={t}
                           />
                        </div>
                        {!showFullText && isLongText && (
                           <>
                              <div
                                 style={{
                                    position: 'absolute',
                                    bottom: 0,
                                    width: '100%',
                                    height: `${MAX_HEIGHT}px`,
                                    background: darkMode
                                       ? 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 95%)'
                                       : 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 95%)',
                                    zIndex: 10,
                                 }}
                              />
                              <div
                                 style={{
                                    position: 'absolute',
                                    bottom: '1px',
                                    width: '100%',
                                    textAlign: 'center',
                                    color: darkMode ? 'white' : 'black',
                                    zIndex: 10,
                                    cursor: 'pointer',
                                 }}
                              >
                                 <div>{t('comments.expand')}</div>
                              </div>
                           </>
                        )}
                     </div>
                  )}
               </div>
            )}
         </div>

         <div className='mt-2 mr-1.5 ml-0.5 hidden sm:mr-3 sm:ml-2 md:block'>
            <MoreActionsComment
               deleted={comment.deleted}
               userLogin={comment.user?.login}
               commentId={comment.id}
               commentDeletedFunction={commentDeletedFunction}
               setEditMode={enableEditMode}
               canDeleteFromFavourites={canDeleteFromFavourites}
               deleteFromFavouritesFunction={deleteFromFavouritesFunction}
            />
         </div>
      </div>
   )
}

export function commentActions(
   canReply: boolean,
   setActiveReply,
   comment: IComment,
   commentDeletedFunction,
   onEnableEditMode,
   goToPost = false,
   canDeleteFromFavourites = false,
   deleteFromFavouritesFunction = (commentId: string) => {},
) {
   const { t } = useTranslation()
   const [loading, setLoading] = useState(false)

   const [liked, setLiked] = useState(comment.voted == 1)
   const [unliked, setUnliked] = useState(comment.voted == -1)
   const [voted, setVoted] = useState(comment.voted)
   const [votesUp, setVotesUp] = useState(comment.votesUp)
   const [votesDown, setVotesDown] = useState(comment.votesDown)
   const navigate = useNavigate()

   useEffect(() => {
      setVotesUp(comment.votesUp)
      setVotesDown(comment.votesDown)
   }, [comment.votesUp, comment.votesDown])

   // todo rest
   function vote(type) {
      if (type === 'UP') {
         if (voted === 1) {
            setVotesUp(votesUp - 1)
            setVoted(0)
         } else if (voted === -1) {
            setVotesUp(votesUp + 1)
            setVotesDown(votesDown - 1)
            setVoted(1)
         } else {
            setVotesUp(votesUp + 1)
            setVoted(1)
         }
      } else {
         if (voted === -1) {
            setVotesDown(votesDown - 1)
            setVoted(0)
         } else if (voted === 1) {
            setVotesDown(votesDown + 1)
            setVotesUp(votesUp - 1)
            setVoted(-1)
         } else {
            setVotesDown(votesDown + 1)
            setVoted(-1)
         }
      }
      voteForComment({ entityId: comment.id, type: type })
         .then(() => {})
         .finally(() => {
            setLoading(false)
         })
   }

   return (
      <div className='mt-0.5 flex w-full flex-row items-center justify-between'>
         <div className='relative flex flex-row items-center space-x-2 select-none'>
            <div
               className='flex flex-row items-center space-x-2'
               style={{ visibility: comment.deleted ? 'hidden' : 'visible' }}
            >
               <div
                  role='button'
                  className='tab-hover flex cursor-pointer items-center space-x-2 rounded-md'
                  onClick={() => {
                     if (loading) return
                     setLiked(true)
                     if (liked) setLiked(false)
                     else {
                        setLiked(true)
                        setUnliked(false)
                     }
                     setLoading(true)
                     vote('UP')
                  }}
               >
                  {liked ? (
                     <TbArrowBigDownLine className='h-5 w-5 stroke-2 text-blue-700 dark:text-blue-600' />
                  ) : (
                     <TbArrowBigUp className='h-5 w-5 stroke-2 text-blue-700 dark:text-blue-600' />
                  )}
               </div>
               {loading && (
                  <div className='absolute top-0 left-0 z-10 h-6 w-6'>
                     <SuperBallsLoadingIndicator />
                  </div>
               )}

               <p className='text-center text-lg text-black dark:text-gray-400'>{votesUp - votesDown}</p>

               <div
                  role='button'
                  className='tab-hover flex cursor-pointer items-center space-x-2 rounded-md'
                  onClick={() => {
                     if (loading) return
                     if (unliked) {
                        setUnliked(false)
                     } else {
                        setUnliked(true)
                        setLiked(false)
                     }
                     setLoading(true)
                     vote('DOWN')
                  }}
               >
                  {unliked ? (
                     <TbArrowBigUpLine className='h-5 w-5 stroke-2 text-red-800 dark:text-red-700' />
                  ) : (
                     <TbArrowBigDown className='h-5 w-5 stroke-2 text-red-800 dark:text-red-700' />
                  )}
               </div>
            </div>
            {canReply && (
               <div
                  role='button'
                  className='tab-hover my-1 mt-[4px] rounded-full px-2 py-1 text-xs font-normal dark:text-gray-300'
                  onClick={setActiveReply}
               >
                  {t('common.reply')}
               </div>
            )}
            {goToPost && (
               <a
                  href={`/blog/${comment.postId}`}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='tab-hover my-1 mt-[4px] rounded-full bg-gray-100 px-2 py-1 text-xs font-normal select-none dark:bg-gray-800 dark:text-gray-300'
                  onClick={() => {
                     navigate('/blog/' + comment.postId)
                  }}
               >
                  {t('post_action.go_to_post')}
               </a>
            )}
            {/*{*/}
            {/*   new Date(comment?.createDate) > new Date('2024-05-07') &&*/}
            {/*   <UsersWhoVoted miniView={false} entityId={comment.id} target={VotingTarget.COMMENT} />*/}
            {/*}*/}
         </div>
         <div className='mr-2.5 ml-1 block md:hidden'>
            <MoreActionsComment
               deleted={comment.deleted}
               userLogin={comment.user?.login}
               commentId={comment.id}
               commentDeletedFunction={commentDeletedFunction}
               setEditMode={() => {
                  onEnableEditMode()
               }}
               canDeleteFromFavourites={canDeleteFromFavourites}
               deleteFromFavouritesFunction={deleteFromFavouritesFunction}
            />
         </div>
      </div>
   )
}

function usernameAndCommentDate(comment: IComment) {
   const { id, login } = comment.user
   return (
      <div className='mx-2 mb-0 flex flex-row text-xs font-normal text-gray-800 dark:text-gray-300'>
         <UserLoginComponent userId={id} userName={login} type={UserLoginComponentType.ONLY_TEXT} />
         <TimeAgo date={comment.createDate} />
      </div>
   )
}

interface VotesCommentsMoreActionsProps {
   post: any
   userInfo: any
   fullSizeView: boolean
   refresh: number
   setRefresh: any
   showComments: boolean
   triggerShowComments: () => void
   deleted: boolean
   setDeleted: any
   addedCommentsCount: number
   afterUndoDeleteFromFavorites: () => void
}

export const VotesCommentsMoreActions = ({
   post,
   userInfo,
   fullSizeView,
   refresh,
   setRefresh,
   showComments,
   triggerShowComments,
   deleted,
   setDeleted,
   addedCommentsCount,
   afterUndoDeleteFromFavorites = () => {},
}: VotesCommentsMoreActionsProps) => {
   return (
      <div
         className={`on-hover-more-visible mr-1.5 mb-2 flex w-full flex-row flex-wrap place-content-between items-center`}
      >
         <VoteForPost
            id={post.id}
            votesUp={post.votesUp}
            userLoggedIn={userInfo.loggedIn}
            votesDown={post.votesDown}
            afterVoteUP={() => voteUpForPost(post, setRefresh, refresh)}
            afterVoteDOWN={() => voteDownForPost(post, setRefresh, refresh)}
            fullView={fullSizeView}
            voted={post.voted}
         />

         <div className='rounded-normal mr-1 flex flex-row items-center justify-between px-0 text-base select-none'>
            <CommentsAmount
               triggerShowComments={triggerShowComments}
               showComments={showComments}
               commentsCount={post.commentsCount}
               addedComments={addedCommentsCount}
            />
            <MoreActionsPost
               afterDeleteAction={() => setDeleted(!deleted)}
               deleted={deleted}
               key={post.id}
               userLogin={post?.user?.login}
               postId={post.id}
               hasNotifications={post.hasNotification}
               isStarred={post.favorite}
               afterUndoDeleteFromFavorites={afterUndoDeleteFromFavorites}
            />
         </div>
      </div>
   )
}

interface ShowOlderCommentsComponentProps {
   commentSortBy: any
   hasBefore: boolean
   showOlder: () => void
}

export const ShowOlderCommentsComponent = ({
   commentSortBy,
   hasBefore,
   showOlder,
}: ShowOlderCommentsComponentProps) => {
   const { t } = useTranslation()

   return (
      commentSortBy === COMMENT_SORT_BY.UNREAD &&
      hasBefore && (
         <div
            onClick={() => {
               showOlder()
            }}
            className='mt-1.5 flex w-full justify-center pb-2 hover:cursor-pointer hover:text-blue-600'
         >
            <PiArrowsOutLineVertical className='mr-2 h-5 w-5' />
            <div className='mt-[-5px] text-lg'>{t('comments.show_older')}</div>
         </div>
      )
   )
}

export const ShowMoreCommentsButton = ({ hideButton, loadComments }) => {
   const { t } = useTranslation()
   return (
      <div className={`mb-1 flex flex-row justify-start`}>
         {!hideButton && (
            <div
               role='button'
               hidden={hideButton}
               id='showMoreCommentsButton'
               className={`hover-color rounded-normal ${isDesktop ? 'ml-2' : 'ml-1'} pr-1 pl-1`}
               onClick={loadComments}
            >
               <p className='commentMoreButton'>{t('comments.show_more')}</p>
            </div>
         )}
      </div>
   )
}

export function getInitialSort(fromCommentId: string, topComment, commentsCount: number) {
   return fromCommentId
      ? COMMENT_SORT_BY.UNREAD
      : topComment
        ? COMMENT_SORT_BY.BEST
        : commentsCount > 30
          ? COMMENT_SORT_BY.NEW
          : COMMENT_SORT_BY.OLD
}

export const CommentUserDivider = () => {
   return <div className='mb-[22px] h-full w-[1px] rounded-full bg-gray-100 dark:bg-gray-800' />
}

export const UserCommentDivider = ({ user }) => {
   const { id, picture } = user
   return (
      <div className='mt-3 mr-0 flex h-full flex-col items-center'>
         <UserLoginComponent userId={id} avatar={picture} type={UserLoginComponentType.ONLY_ICON} />
         <CommentUserDivider />
      </div>
   )
}
