import React, { useState } from 'react'

import { FiPlus } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import { CDN_ENDPOINT } from '@/env'
import { Food } from '@/model/order/food.model'
import { CiEdit } from 'react-icons/ci'
import { useNavigate } from 'react-router-dom'
import { FoodAvailabilitySection } from '@/layout/food/FoodAvailabilitySection'
import { formatPrice } from '@/util/text-utils'
import { isDesktop } from 'react-device-detect'
import { AddFoodModalDesktop } from '@/layout/food/AddFoodModalDesktop'
import { useAppSelector } from '@/config/store'
import { isAdmin } from '@/util/auth-util'
import { PiBowlFood } from 'react-icons/pi'

interface SingleFoodViewProps {
   food: Food
   addFoodMobileOpen?: () => void
   singleFoodView?: boolean
   chefSuspended?: boolean
}

export const SingleFoodView = ({
   food,
   addFoodMobileOpen,
   singleFoodView = false,
   chefSuspended,
}: SingleFoodViewProps) => {
   const { t } = useTranslation()
   const navigate = useNavigate()
   const [isDesktopAddFoodModalOpen, setIsDesktopAddFoodModalOpen] = useState(false)
   const loggedAccount = useAppSelector(state => state.authentication.account)

   const handleAddFoodOrder = () => {
      if (!loggedAccount) navigate('/login')

      if (loggedAccount?.id == food.chefId) return

      if (singleFoodView) {
         navigate('/chef/' + food.chefId)
         return
      }

      if (isDesktop) setIsDesktopAddFoodModalOpen(true)
      else addFoodMobileOpen()
   }

   const canManage = loggedAccount?.id == food?.chefId || isAdmin(loggedAccount)

   return (
      <>
         <div className='flex w-full flex-row justify-between rounded-lg bg-white shadow-lg'>
            <div id='chef-info' className={`flex h-44 w-full flex-col justify-between p-3`}>
               <div className='flex flex-col'>
                  <div className={`mb-1 flex flex-col justify-between`}>
                     <h2 className={`text-lg font-semibold text-gray-800`}>{food?.name}</h2>
                     <div className='text-sm font-semibold text-gray-800'>€{formatPrice(food?.price)}</div>
                  </div>
                  <p className='line-clamp-3 overflow-hidden text-sm text-gray-600'>{food?.description}</p>
               </div>
               <FoodAvailabilitySection offer={food?.offer} />
            </div>
            <div className='relative h-44 w-44 max-w-[50%] flex-none' onClick={handleAddFoodOrder}>
               {food?.image ? (
                  <img
                     src={CDN_ENDPOINT + food?.image}
                     alt={food?.name}
                     className='h-44 w-44 rounded-r-md object-cover'
                  />
               ) : (
                  <div className='flex h-44 w-44 items-center justify-center rounded-r-md bg-gray-200'>
                     <PiBowlFood className='mb-10 h-20 w-20 text-gray-500' />
                  </div>
               )}
               {/*<FoodLastItemsCounter offer={food?.offer} />*/}
               <div
                  className={`absolute right-2 bottom-2 flex flex-row items-center rounded-full bg-white p-1 ${loggedAccount?.id == food?.chefId ? 'hover:cursor-not-allowed' : 'hover:cursor-pointer hover:bg-gray-100'} `}
               >
                  <FiPlus className='text-customPurple hover:text-customPurpleDark h-8 w-8 stroke-[2.5px]' />
               </div>
               {canManage && (
                  <div
                     className='hover:text-customPurple absolute bottom-2 left-2 mb-0.5 flex flex-row items-center rounded-xl border-[0.5px] border-transparent bg-white p-0.5 hover:cursor-pointer'
                     onClick={e => {
                        e.stopPropagation()
                        navigate('/edit-food/' + food?.id)
                     }}
                  >
                     <CiEdit className='h-8 w-8 pr-0.5' />
                     <p className='pr-0.5 text-[18px] font-semibold'>{t('single_food_view.edit')}</p>
                  </div>
               )}
            </div>
         </div>

         {isDesktopAddFoodModalOpen && (
            <AddFoodModalDesktop
               food={food}
               chefSuspended={chefSuspended}
               onClose={() => {
                  setIsDesktopAddFoodModalOpen(false)
               }}
            />
         )}
      </>
   )
}
