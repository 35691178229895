import { Chef } from '@/model/chef.model'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CDN_ENDPOINT_STATIC_CONTENT } from '@/env'
import { PhotoType } from '@/model/enumerations/photo-type'
import { ChefsListMobile } from '@/layout/home/maps/ChefsListMobile'
import { fetchLandingPageChefs } from '@/service/chef.service'
import { isDesktop, isMobile } from 'react-device-detect'
import { SingleChefCard } from '@/layout/home/maps/SingleChefCard'
import { LandingPageSearchLocationMobile } from '@/layout/landing_page/LandingPageSearchLocationMobile'
import { LandingPageSearchLocationDesktop } from '@/layout/landing_page/LandingPageSearchLocationDesktop'
import { PageFooter } from '@/layout/landing_page/PageFooter'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { RiCheckFill } from 'react-icons/ri'
import { OurPartners } from '@/layout/landing_page/OurPartners'
import { HowItWorksSection, underscoreLine } from '@/layout/landing_page/HowItWorksLandingPage'
import { getCurrentPosition } from '@/util/UserLocalizations'
import { roundCoord } from '@/util/UrlUtils'
import { updateUserLocation } from '@/service/search'
import { fetchSingleLocationName } from '@/service/open-street-map.service'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ProgressiveImage } from '@/layout/common/ProgressiveImage'

const LandingPage = () => {
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   const navigate = useNavigate()
   const dispatch = useAppDispatch()
   const language = useAppSelector(state => state.localeSlice.lng)

   const handleClick = () => {
      setIsLoading(true)
      getCurrentPosition(false)
         .then(position => {
            const newCoordinates: [number, number] = [
               roundCoord(position?.coords?.latitude),
               roundCoord(position?.coords?.longitude),
            ]

            updateUserLocation({
               location: {
                  latitude: newCoordinates[0],
                  longitude: newCoordinates[1],
                  locationName: '',
               },
               shouldSaveLocation: false,
            })

            fetchSingleLocationName(newCoordinates[0], newCoordinates[1], language)
               .then(response => {
                  const address = response.data

                  dispatch(
                     updateUserLocation({
                        location: {
                           latitude: newCoordinates[0],
                           longitude: newCoordinates[1],
                           locationName: address.display_name || '',
                           villageTownCity: address.address?.village || address.address?.town || address.address?.city,
                        },
                        shouldSaveLocation: false,
                     }),
                  )
                  if (isMobile) {
                     const queryParams = new URLSearchParams({
                        lat: newCoordinates[0].toFixed(3),
                        lon: newCoordinates[1].toFixed(4),
                        radius: '25',
                     }).toString()
                     navigate(`/?${queryParams}`)
                  } else {
                     navigate('/')
                  }
               })
               .catch(error => {
                  console.error('Error fetching location name:', error)
               })
               .finally(() => {
                  setIsLoading(false)
               })
         })
         .catch(error => {
            if (error.code === error.PERMISSION_DENIED) {
               toast.error(t('location.permission_denied'))
            } else if (error.code === error.POSITION_UNAVAILABLE) {
               toast.error(t('location.position_unavailable'))
            } else if (error.code === error.TIMEOUT) {
               toast.error(t('location.timeout'))
            } else {
               toast.error(t('location.unknown_error'))
            }
            console.error('Error getting current position:', error)
         })
         .finally(() => {
            setIsLoading(false)
         })
   }

   function exploreMoreChefsButton() {
      return (
         <div className='mt-4 mb-2 flex justify-center'>
            <button
               onClick={handleClick}
               className={`rounded-md ${isLoading ? 'bg-customPurpleDark' : 'bg-customPurple'} hover:bg-customPurpleDark p-2.5 px-4 font-semibold text-white hover:cursor-pointer`}
               style={{ width: 'auto' }}
            >
               {t(isLoading ? 'landing_page.loading_your_location' : 'landing_page.explore_chefs_in_your_area')}
            </button>
         </div>
      )
   }

   return (
      <>
         {isDesktop ? (
            <>
               <div className='absolute top-16 left-0 flex w-full flex-col'>
                  <div id={'landingPageImageWithSearch'} className='h-[625px]'>
                     <LandingPageImageWithSearch />
                  </div>
                  <div className='bg-white'>
                     <div className='mx-auto max-w-6xl'>
                        <HowItWorksSection />
                     </div>
                  </div>
                  <div className='mx-auto w-full max-w-6xl'>
                     <div className='w-full'>
                        <ChefList />
                        {exploreMoreChefsButton()}
                     </div>
                  </div>
                  <OurPartners />
                  <PageFooter />
               </div>
            </>
         ) : (
            <div className='z-10 -mt-2 flex flex-col items-center'>
               <LandingPageImageWithSearch />
               <div className='mt-4 flex w-full flex-col'>
                  <HowItWorksSection />
                  <ChefList />
                  {exploreMoreChefsButton()}
               </div>
               <OurPartners />
               <PageFooter />
            </div>
         )}
      </>
   )
}

export default LandingPage

export const LandingPageImageWithSearch = () => {
   const { t } = useTranslation()

   function getBenefits() {
      return (
         <div key={'benefits'} className='mt-4 flex w-full flex-col text-left'>
            {['benefit1', 'benefit2', 'benefit3'].map((benefit, index) => (
               <div key={index} className='mb-0.5 flex flex-row items-center'>
                  <RiCheckFill className='mr-1.5 flex-shrink-0' />
                  {t(`landing_page.${benefit}`)}
               </div>
            ))}
         </div>
      )
   }

   function getHeader() {
      return (
         <h1
            id='landingPageH1'
            className={`${isMobile ? 'xss:text-[24px] xs:text-[28px] text-center text-[19px]' : 'text-[32px] lg:text-[34px]'} font-semibold ${isMobile ? 'text-center' : 'text-left'}`}
         >
            <span className='inline'>{t('landing_page.header1')}</span>{' '}
            <span className='text-customPurple inline'>{t('landing_page.header2')}</span>{' '}
            <span className='inline'>{t('landing_page.header3')}</span>
         </h1>
      )
   }

   return isDesktop ? (
      <div className='relative flex h-[625px] w-full items-center'>
         <ProgressiveImage
            lowQualitySrc={`${CDN_ENDPOINT_STATIC_CONTENT}${PhotoType.LANDING_PAGE_LQ}.webp`}
            highQualitySrc={`${CDN_ENDPOINT_STATIC_CONTENT}${PhotoType.LANDING_PAGE}.webp`}
            alt='Landing Page'
            className='absolute inset-0 h-[625px] w-full object-cover object-top lg:object-left-bottom'
         />
         <div className='absolute top-1/2 left-4 flex max-w-[40%] -translate-y-1/2 transform flex-col items-start rounded-lg bg-white/85 px-4 py-3 lg:left-12 lg:px-6'>
            <div>{getHeader()}</div>
            <div className={`mt-4 ${isDesktop ? 'text-base lg:text-[17px]' : ''}`}>{getBenefits()}</div>
            <div className='mt-4 w-full px-2'>
               <LandingPageSearchLocationDesktop additionalClassesStyles='rounded-lg w-full' />
            </div>
         </div>
      </div>
   ) : (
      <div className={`top-20 mt-[60px] flex w-full flex-col`}>
         <div className='h-[305px] w-full'>
            <ProgressiveImage
               lowQualitySrc={`${CDN_ENDPOINT_STATIC_CONTENT}${PhotoType.LANDING_PAGE_LQ}.webp`}
               highQualitySrc={`${CDN_ENDPOINT_STATIC_CONTENT}${PhotoType.LANDING_PAGE}.webp`}
               alt='Landing Page'
               className='absolute mt-12 h-[305px] w-full object-cover object-top'
            />
         </div>
         <div className='flex flex-col items-center px-2'>
            <div className='items-center'>
               {getHeader()}
               <div className='xss:text-base text-xs'>{getBenefits()}</div>
               <div className='w-full max-w-[770px] px-2'>
                  <LandingPageSearchLocationMobile additionalClassesStyles='rounded-xl mb-6 w-full border-gray-400' />
               </div>
            </div>
         </div>
      </div>
   )
}

export const ChefList = () => {
   const { t } = useTranslation()
   const [chefs, setChefs] = useState<Chef[]>([])

   useEffect(() => {
      // Fetch chefs data
      const fetchChefs = async () => {
         try {
            const response = await fetchLandingPageChefs()
            setChefs(response.data)
         } catch (error) {
            console.error('Error fetching chefs:', error.response?.data || error.message || error)
         }
      }

      fetchChefs()
   }, [])

   return (
      <div className='w-full'>
         <h1 className={`${isDesktop ? 'mt-8' : 'mb-2'} mb-7 text-center text-[34px] font-semibold`}>
            {t('landing_page.discover_our_chefs')}
            {underscoreLine(205)}
         </h1>

         {isDesktop ? (
            <div className='grid w-full grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-2 md:gap-4 lg:gap-6'>
               {chefs.map((chef, index) => (
                  <div key={chef.id} className='mb-2'>
                     <SingleChefCard
                        chef={chef}
                        starrClicked={starred => {}}
                        sliderNavigationOnHover={true}
                        fullWidth={true}
                     />
                  </div>
               ))}
            </div>
         ) : (
            <ChefsListMobile chefs={chefs} starrClicked={() => {}} />
         )}
      </div>
   )
}

export const SkeletonLandingPage = () => {
   return (
      <>
         {isMobile ? (
            <div className='z-10 -mt-2 flex flex-col items-center'>
               {/* Mobile: Image with Search Section */}
               <div className='mt-[60px] flex w-full flex-col'>
                  {/* Background Image Placeholder */}
                  <div className='h-[305px] w-full animate-pulse bg-gray-200' />
                  <div className='flex flex-col items-center px-2'>
                     <div className='mt-5 w-full max-w-[770px]'>
                        {/* Header Placeholder */}
                        <div className='mb-2 h-8 animate-pulse rounded bg-gray-200' />
                        {/* Benefits Placeholders */}
                        <div className='mb-1 h-4 animate-pulse rounded bg-gray-200' />
                        <div className='mb-1 h-4 animate-pulse rounded bg-gray-200' />
                        <div className='mb-4 h-4 animate-pulse rounded bg-gray-200' />
                        {/* Search Input Placeholder */}
                        <div className='h-10 animate-pulse rounded-xl bg-gray-200' />
                     </div>
                  </div>
               </div>
               {/* Mobile: How It Works Section */}
               <div className='mt-4 flex w-full flex-col'>
                  <div className='py-8'>
                     {/* Title Placeholder */}
                     <div className='mx-auto mb-4 h-8 w-1/3 animate-pulse rounded bg-gray-200' />
                     {/* Three Blocks Placeholder */}
                     <div className='grid grid-cols-1 gap-4'>
                        {[1, 2, 3].map((_, index) => (
                           <div key={index} className='space-y-2'>
                              <div className='h-40 animate-pulse rounded bg-gray-200' />
                              {/* Image */}
                              <div className='h-4 animate-pulse rounded bg-gray-200' />
                              {/* Text Line 1 */}
                              <div className='h-4 w-2/3 animate-pulse rounded bg-gray-200' />
                              {/* Text Line 2 */}
                           </div>
                        ))}
                     </div>
                  </div>
               </div>
            </div>
         ) : (
            <div className='absolute top-16 left-0 flex w-full flex-col'>
               {/* Desktop: Image with Search Section */}
               <div id='landingPageImageWithSearch' className='h-[625px]'>
                  <div className='relative flex h-[625px] w-full items-center'>
                     {/* Background Image Placeholder */}
                     <div className='absolute inset-0 h-[625px] w-full animate-pulse bg-gray-200' />
                     <div className='absolute top-1/2 left-4 max-w-[40%] -translate-y-1/2 transform rounded-lg bg-white/85 px-4 py-3 lg:left-12 lg:px-6'>
                        {/* Header Placeholder */}
                        <div className='mb-4 h-10 animate-pulse rounded bg-gray-200' />
                        {/* Benefits Placeholders */}
                        <div className='space-y-2'>
                           <div className='h-4 animate-pulse rounded bg-gray-200' />
                           <div className='h-4 animate-pulse rounded bg-gray-200' />
                           <div className='h-4 animate-pulse rounded bg-gray-200' />
                        </div>
                        {/* Search Input Placeholder */}
                        <div className='mt-4 h-10 animate-pulse rounded bg-gray-200' />
                     </div>
                  </div>
               </div>
               {/* Desktop: How It Works Section */}
               <div className='bg-white'>
                  <div className='mx-auto max-w-6xl'>
                     <div className='py-8'>
                        {/* Title Placeholder */}
                        <div className='mx-auto mb-4 h-8 w-1/3 animate-pulse rounded bg-gray-200' />
                        {/* Three Blocks Placeholder */}
                        <div className='grid grid-cols-3 gap-4'>
                           {[1, 2, 3].map((_, index) => (
                              <div key={index} className='space-y-2'>
                                 <div className='h-40 animate-pulse rounded bg-gray-200' />
                                 {/* Image */}
                                 <div className='h-4 animate-pulse rounded bg-gray-200' />
                                 {/* Text Line 1 */}
                                 <div className='h-4 w-2/3 animate-pulse rounded bg-gray-200' />
                                 {/* Text Line 2 */}
                              </div>
                           ))}
                        </div>
                     </div>
                  </div>
               </div>
               {/* Empty Div Placeholder */}
               <div className='mx-auto w-full max-w-6xl' />
            </div>
         )}
      </>
   )
}
