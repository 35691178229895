import React, { useEffect, useState } from 'react'
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { Suggestion } from '@/layout/add-chef/CreateChefPage'
import { FieldError, FieldErrors } from 'react-hook-form'
import get from 'lodash/get'

interface FloatingLabelInputProps<TFieldValues extends Record<string, any>> {
   label: string
   name: keyof TFieldValues | `${Extract<keyof TFieldValues, string>}.${string}`
   type: string
   register: (name: string, rules?: any) => any
   rules?: any
   watch: (name: string) => any
   errors: FieldErrors<any>
   value?: any
   infoComponent?: React.ReactNode
   onChange?: (value: any) => void
   suggestions?: Suggestion[]
   onSuggestionSelect?: (suggestion: Suggestion) => void
   editMode?: boolean
   autocomplete?: string
   step?: string
   allowEnter?: boolean
   submitOnEnter?: boolean
   autoFocus?: boolean
}

export const FloatingLabelInput = <TFieldValues,>({
   label,
   name,
   type,
   register,
   rules,
   watch,
   errors,
   value,
   infoComponent,
   onChange = () => {},
   editMode = false,
   autocomplete = 'off',
   step,
   allowEnter = false,
   submitOnEnter = false,
   autoFocus = false,
}: FloatingLabelInputProps<TFieldValues>) => {
   const [showPassword, setShowPassword] = useState(false)
   const isPassword = type === 'password'
   const actualType = isPassword ? (showPassword ? 'text' : 'password') : type
   const [initialEditModeRequested, setEditModeRequested] = useState(editMode)

   useEffect(() => {
      if (initialEditModeRequested) {
         setEditModeRequested(false)
         return
      }
      if (onChange) onChange(watch(String(name)))
   }, [watch(String(name))])

   const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (event.key !== 'Enter') return

      if (event.key === 'Enter' && !allowEnter && !submitOnEnter) {
         event.preventDefault() // Prevent form submission when Enter is pressed
      } else if (submitOnEnter && event.target instanceof HTMLInputElement) {
         // Trigger form submission if `submitOnEnter` is enabled
         event.target.form?.requestSubmit()
      }
   }

   const error = get(errors, String(name)) as FieldError | undefined

   return (
      <div className='flex flex-col'>
         <div className='relative'>
            {!allowEnter ? (
               <input
                  data-cy={String(name)}
                  id={String(name)}
                  name={String(name)}
                  autoComplete={autocomplete}
                  type={actualType}
                  placeholder={label}
                  defaultValue={value?.value}
                  disabled={value?.editDisabled}
                  autoFocus={autoFocus}
                  step={step}
                  {...register(String(name), rules)}
                  className={`background-gray-dark-normal peer h-14 w-full rounded-lg px-2 text-gray-900 placeholder-transparent focus:border-rose-600 focus:outline-hidden sm:px-4 dark:text-gray-200 ${
                     watch(String(name)) && 'pt-3'
                  } ${value?.editDisabled ? 'cursor-not-allowed' : ''}`}
                  onKeyDown={handleKeyDown}
               />
            ) : (
               <textarea
                  data-cy={String(name)}
                  id={String(name)}
                  name={String(name)}
                  autoComplete={autocomplete}
                  placeholder={label}
                  defaultValue={value?.value}
                  disabled={value?.editDisabled}
                  {...register(String(name), rules)}
                  className={`background-gray-dark-normal peer min-h-12 w-full rounded-lg px-2 py-3 pt-5 text-gray-900 placeholder-transparent focus:border-rose-600 focus:outline-hidden sm:px-4 dark:text-gray-200 ${
                     value?.editDisabled ? 'cursor-not-allowed' : ''
                  }`}
                  rows={3}
                  onInput={e => {
                     const target = e.target as HTMLTextAreaElement
                     target.style.height = 'auto'
                     target.style.height = `${target.scrollHeight}px`
                  }}
                  onKeyDown={handleKeyDown}
               />
            )}
            <label
               htmlFor={String(name)}
               className={`pointer-events-none absolute left-0 px-2 text-sm text-gray-600 transition-all sm:px-4 dark:text-gray-300 ${
                  watch(String(name))
                     ? 'top-0.5 text-xs text-gray-600 dark:text-gray-400'
                     : 'peer-placeholder-shown:top-3.5 peer-placeholder-shown:text-base'
               } peer-focus:top-0.5 peer-focus:text-xs`}
            >
               {label}
            </label>
            {isPassword && !allowEnter && (
               <span className='absolute inset-y-0 right-0 flex items-center justify-center pr-3'>
                  {!showPassword ? (
                     <AiOutlineEye className='h-5 w-5 hover:cursor-pointer' onClick={() => setShowPassword(true)} />
                  ) : (
                     <AiOutlineEyeInvisible
                        className='h-5 w-5 hover:cursor-pointer'
                        onClick={() => setShowPassword(false)}
                     />
                  )}
               </span>
            )}
            {infoComponent}
         </div>
         {error && <p className='error-validation'>{error.message}</p>}
      </div>
   )
}
