import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { LoginFormData } from '@/model/login.model'
import { useAppDispatch } from '@/config/store'
import { useTranslation } from 'react-i18next'
import { hideLoginModal, hideLoginOpenRegisterModal, login, resendActivationEmail } from '@/service/authentication'
import { LoadingIndicator } from '../common/LoadingIndicator'
import { useNavigate } from 'react-router-dom'
import { AbstractMobileModal } from '@/layout/common/AbstractMobileModal'
import GoogleLoginRegisterPage from '@/layout/login_register/GoogleLoginRegisterPage'
import { FloatingLabelInput } from '@/layout/common/FloatingLabelInput'
import { HorizontalDivider } from '@/layout/common/Dividers'
import { showToastMessageForChefsAndAdminsAndNavigate } from '@/layout/login_register/LoginModal'

const LoginPageMobile = () => {
   const {
      register,
      handleSubmit,
      getValues,
      watch,
      formState: { errors, isValid },
   } = useForm<LoginFormData>({ mode: 'all' })

   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const navigate = useNavigate()

   const [isLoading, setIsLoading] = useState(false)
   const [errorMessage, setErrorMessage] = useState('')
   const [resendEmail, setResendEmail] = useState(false)

   const onSubmit = useCallback(
      (formData: LoginFormData) => {
         if (isLoading) return
         setErrorMessage('')
         setIsLoading(true)
         const trimmedData = {
            ...formData,
            username: formData.usernameOrEmail.trim(),
         }
         login(trimmedData, dispatch)
            .then(value => {
               showToastMessageForChefsAndAdminsAndNavigate(value, t, navigate)
            })
            .catch(reason => {
               console.log('Login error reason', reason)
               if (reason?.response?.data?.errorKey == 'user_not_activated') {
                  setErrorMessage(t('sign.user_not_activated'))
                  setResendEmail(true)
               } else if (reason?.response?.data?.errorKey == 'invalid_credentials')
                  setErrorMessage(t('sign.username_or_password_invalid'))
               else setErrorMessage(t('sign.problem'))
            })
            .finally(() => {
               setIsLoading(false)
            })
      },
      [isLoading],
   )

   function resendTheEmail() {
      // todo
      resendActivationEmail({
         username: getValues().usernameOrEmail,
         // password: getValues().password, rememberMe: false
      })
      setIsLoading(false)
      setResendEmail(false)
      dispatch(hideLoginModal())
   }

   return (
      <AbstractMobileModal title={<div className='mt-1 text-xl font-medium'>{t('sign.login_in')}</div>}>
         <div className='mobile-page-box mt-3'>
            <form onSubmit={handleSubmit(onSubmit)}>
               <div className='space-y-4'>
                  <FloatingLabelInput<LoginFormData>
                     label={t('common.username_or_email')}
                     name='usernameOrEmail'
                     type='text'
                     register={register}
                     rules={{ required: t('error.required') }}
                     watch={watch}
                     errors={errors}
                     autocomplete={'username'}
                     submitOnEnter={true}
                  />

                  <FloatingLabelInput<LoginFormData>
                     label={t('password')}
                     name='password'
                     type='password'
                     register={register}
                     rules={{ required: t('error.required') }}
                     watch={watch}
                     errors={errors}
                     autocomplete={'current-password'}
                     submitOnEnter={true}
                  />
               </div>

               <div className='mt-2 flex flex-row'>
                  <div className='mt-0.5 flex flex-row'>
                     <input
                        type='checkbox'
                        name='rememberMe'
                        className='checkbox-lg mr-1 ml-1'
                        {...register('rememberMe')}
                        defaultChecked={true}
                     />
                     <div className=''>{t('sign.remember_me')}</div>
                  </div>
               </div>

               <div className='mt-3 flex justify-start'>
                  <label className='ml-1 text-sm'></label>
                  <a
                     role='button'
                     className='text-sm text-blue-600 hover:underline'
                     onClick={() => {
                        dispatch(hideLoginModal())
                        navigate('/forgot-password')
                     }}
                  >
                     {t('sign.forgot_password')}
                  </a>
               </div>

               {errorMessage.length > 0 && <p className='error-validation'>{errorMessage}</p>}

               {/*todo move to after register page*/}
               {resendEmail && (
                  <p onClick={resendTheEmail} className='mt-2 mb-0 text-blue-500 hover:cursor-pointer'>
                     {t('sign.resend_email')}
                     {/*   todo navigate to page with info about resend*/}
                  </p>
               )}

               <div className='mt-3 space-y-2'>
                  <div className='flex gap-x-2'>
                     <button
                        type='submit'
                        className={`sign-confirm-button ${!isValid ? 'gray-out' : ''}`}
                        disabled={!isValid || isLoading}
                     >
                        {t('common.log_in')}
                     </button>
                  </div>
               </div>
            </form>
            <div className='mb-1.5'>
               <HorizontalDivider text={t('common.or')} dividerHeight={2} />
            </div>
            <div className='mobile-page-google-box'>
               <GoogleLoginRegisterPage mode='login' />
            </div>
            <div className='mt-1.5 mb-2.5'>
               <HorizontalDivider text={t('common.or')} dividerHeight={2} />
            </div>
            <p className='text-coolGray-600 px-6 text-center text-sm'>
               {t('sign.don_not_have_an_account')}
               <a
                  role='button'
                  onClick={() => {
                     setErrorMessage('')
                     dispatch(hideLoginOpenRegisterModal())
                  }}
                  className='ml-1 text-blue-600 hover:underline'
               >
                  {t('sign.join_in')}
               </a>
            </p>
            {isLoading && <LoadingIndicator />}
         </div>
      </AbstractMobileModal>
   )
}
export default LoginPageMobile
