import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { confirmEmail } from '@/service/user-settings-reducer'
import { LoadingIndicator } from '../common/LoadingIndicator'
import { HiOutlineCheckCircle } from 'react-icons/hi2'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { isUserLoggedIn } from '@/util/auth-util'
import { logout } from '@/service/authentication'

const ConfirmEmail = () => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const navigate = useNavigate()
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const { token } = useParams()
   const [loading, setLoading] = useState(false)

   const [status, setStatus] = useState(null)
   const [message, setMessage] = useState(null)

   useEffect(() => {
      setLoading(true)
      if (isUserLoggedIn(loggedAccount)) {
         dispatch(logout())
      }
      confirm(token)
   }, [token])

   function confirm(token: string) {
      confirmEmail(token)
         .then(value => {
            if (value.data.status == 'OK' || value.data.status == 'ACTIVATED') {
               navigate('/success-confirm-email')
               return
            }

            setStatus(value.data.status)
            setMessage(value.data.message)
            setLoading(false)
         })
         .catch(reason => {
            setLoading(false)
         })
         .finally(() => {
            setLoading(false)
         })
   }

   return (
      <div className='main-content relative z-10 flex flex-col px-2'>
         <div className='rounded-normal background-normal flex flex-col items-center px-3 py-2'>
            {(status == 'OK' || status == 'ACTIVATED') && (
               <HiOutlineCheckCircle className='check-icon h-[100px] w-[100px]' />
            )}

            {(status == 'INVALID_KEY' || status == 'NO_KEY') && (
               <HiOutlineCheckCircle className='check-icon h-[100px] w-[100px] text-red-500' />
            )}

            {status != null && (
               <div className='w-full items-center'>
                  <h1 className='mt-4 text-center'>{message}</h1>
               </div>
            )}

            {loading && <LoadingIndicator />}
         </div>
      </div>
   )
}
export default ConfirmEmail
