export const AUTHORITIES = {
   ADMIN: 'ADMIN',
   CHEF: 'CHEF',
   USER: 'USER',
   MOD: 'MOD',
   SENIOR_MOD: 'SENIOR_MOD',
}

export const messages = {
   DATA_ERROR_ALERT: 'Internal Error',
}

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm'
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss'
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY'
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm'
export const APP_WHOLE_NUMBER_FORMAT = '0,0'
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]'
