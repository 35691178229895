import React from 'react'
import { SingleChefCard } from '@/layout/home/maps/SingleChefCard'
import { useTranslation } from 'react-i18next'
import { Chef } from '@/model/chef.model'

export interface ChefsListProps {
   chefs: Chef[]
   starrClicked: (starred: boolean, chefId: string) => void
}

export const ChefsListMobile = ({ chefs, starrClicked }: ChefsListProps) => {
   const { t } = useTranslation()

   return (
      <div className='flex w-full flex-col space-y-6'>
         {chefs.length === 0 ? (
            <div className='background-normal mt-1 flex w-full flex-col items-center justify-center space-y-2 rounded-md pb-2 shadow-md'>
               <div className='mt-2 text-center text-[22px]'>{t('chef_map.no_chefs_h1')}</div>
               <div className='mt-4 mb-3 text-center text-[17px] opacity-80'>{t('chef_map.no_chefs_h2')}</div>
            </div>
         ) : (
            chefs.map(chef => (
               <SingleChefCard
                  key={chef.id}
                  chef={chef}
                  starrClicked={starred => {
                     starrClicked(starred, chef.id)
                  }}
                  fullWidth={true}
               />
            ))
         )}
      </div>
   )
}
