import { EditOutlined, StarFilled, StarOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useAppSelector } from '@/config/store'
import {
   deletePost,
   savePost,
   turnNotifications,
   turnNotificationsOff,
   undoDelete,
   undoSavePost,
} from '@/service/post/post-reducer'
import { isAdmin, isModOrHigher } from '@/util/auth-util'
import FloatingUIWrapper from '../common/FloatingUIComponent'
import { ReportModal } from './ReportModal'
import { SuperBallsLoadingIndicator } from '@/layout/common/LoadingIndicator'
import { MdOutlineMoreVert } from 'react-icons/md'
import { HiOutlineBell, HiOutlineBellSlash, HiOutlineFire, HiOutlineTrash } from 'react-icons/hi2'
import { isMobile } from 'react-device-detect'
import { GiTargetArrows } from 'react-icons/gi'
import { addDistinguishedPost, addSuggestedPost } from '@/service/content-settings-service'
import { PiLinkSimpleBold } from 'react-icons/pi'
import { SITE_BASE_URL } from '@/env'

function FavoriteAction({ t, loading, starred, savePostForTheUser, undoSavePostForTheUser }) {
   return (
      <div>
         <button
            className='more-actions-item'
            onClick={() => {
               if (loading) return
               if (starred) undoSavePostForTheUser()
               else savePostForTheUser()
            }}
         >
            {starred ? (
               <StarFilled style={{ fontSize: '18px' }} className='mr-2 ml-0.5 text-amber-400' />
            ) : (
               <StarOutlined style={{ fontSize: '18px' }} className='mr-2 ml-0.5' />
            )}
            {starred ? t('post_action.remove_favourite') : t('post_action.add_favourite')}
         </button>
      </div>
   )
}

export const MoreActionsPost = ({
   userLogin,
   postId,
   afterDeleteAction,
   deleted,
   hasNotifications,
   isStarred,
   afterUndoDeleteFromFavorites = () => {},
}) => {
   const navigate = useNavigate()
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const { t } = useTranslation()
   const [showReportModal, setShowReportModal] = useState(false)
   const [loading, setLoading] = useState(false)
   // todo!
   const [starred, setStarred] = useState(isStarred)
   const [isOpen, setIsOpen] = useState(false)
   const [hasNotificationsState, setHasNotificationsState] = useState<boolean>()
   useEffect(() => {}, [starred, hasNotifications, hasNotificationsState])
   useEffect(() => {
      setHasNotificationsState(hasNotifications)
   }, [hasNotifications])

   function savePostForTheUser() {
      setLoading(true)
      setStarred(true)
      savePost(postId)
         .then(_ => {
            toast.success(t('post_saved'))
         })
         .finally(() => {
            setLoading(false)
            setIsOpen(false)
         })
   }

   function add_suggested_post() {
      setLoading(true)
      addSuggestedPost({ posts: [postId] })
         .then(_ => {
            toast.success(t('common.success'))
         })
         .finally(() => {
            setLoading(false)
            setIsOpen(false)
         })
   }

   function add_distinguished_post() {
      setLoading(true)
      addDistinguishedPost({ posts: [postId] })
         .then(_ => {
            toast.success(t('common.success'))
         })
         .finally(() => {
            setLoading(false)
            setIsOpen(false)
         })
   }

   function undoSavePostForTheUser() {
      afterUndoDeleteFromFavorites()
      setLoading(true)
      setStarred(false)

      undoSavePost(postId)
         .then(_ => {
            toast.success(t('post_saved_undo'))
         })
         .finally(() => {
            setLoading(false)
            setIsOpen(false)
         })
   }

   function generateActionMenuItems() {
      return (
         <div className='more-actions-items text-color-normal ring-opacity-5 w-56 ring-1 ring-black'>
            {loggedAccount.login === userLogin && (
               <div>
                  <button
                     className='more-actions-item'
                     onClick={() => {
                        navigate('/post-edit/' + postId)
                     }}
                  >
                     <EditOutlined style={{ fontSize: '18px' }} className='mr-2 ml-0.5' />
                     {t('post_action.edit_post')}
                  </button>
               </div>
            )}
            {isModOrHigher(loggedAccount) && (
               <div>
                  <button
                     className='more-actions-item text-red-500'
                     onClick={() => {
                        navigate('/post-edit/' + postId)
                     }}
                  >
                     <EditOutlined style={{ fontSize: '18px', color: 'red' }} className='mr-2 ml-0.5' />
                     {t('post_action.edit_post-mod')}
                  </button>
               </div>
            )}
            {isAdmin(loggedAccount) && (
               <div>
                  <button className='more-actions-item text-red-500' onClick={add_suggested_post}>
                     <GiTargetArrows style={{ fontSize: '18px', color: 'red' }} className='mr-2 ml-0.5' />
                     {t('post_action.add_suggested_post')}
                  </button>
               </div>
            )}
            {/*{isAdmin(loggedAccount) && (*/}
            {/*   <div>*/}
            {/*      <button className='more-actions-item text-red-500' onClick={add_distinguished_post}>*/}
            {/*         <AiOutlineFire style={{ fontSize: '18px', color: 'red' }} className='mr-2 ml-0.5 ' />*/}
            {/*         {t('post_action.add_distinguished_post')}*/}
            {/*      </button>*/}
            {/*   </div>*/}
            {/*)}*/}
            {/*<FavoriteAction*/}
            {/*   t={t}*/}
            {/*   loading={loading}*/}
            {/*   starred={starred}*/}
            {/*   savePostForTheUser={savePostForTheUser}*/}
            {/*   undoSavePostForTheUser={undoSavePostForTheUser}*/}
            {/*/>*/}
            {(loggedAccount.login === userLogin || isModOrHigher(loggedAccount)) && (
               <div>
                  <button
                     className='more-actions-item'
                     onClick={() => {
                        setLoading(true)
                        const promise = deleted ? undoDelete(postId) : deletePost(postId)
                        promise
                           .then(_ => {
                              toast.success(
                                 t(deleted ? 'post_action.success_undo_deletion' : 'post_action.success_deleted'),
                              )
                              afterDeleteAction()
                           })
                           .finally(() => {
                              setLoading(false)
                              setIsOpen(false)
                           })
                     }}
                  >
                     <HiOutlineTrash className='mr-2 h-5 w-5' />
                     {t(deleted ? 'post_action.undo_deletion' : 'post_action.delete_post')}
                  </button>
               </div>
            )}
            <div>
               <button
                  className='more-actions-item'
                  onClick={() => {
                     const postLink = SITE_BASE_URL + `news/${postId}`
                     navigator.clipboard
                        .writeText(postLink)
                        .then(() => {
                           toast.success(t('post_action.link_copied'))
                        })
                        .catch(err => {
                           toast.error(t('error.common.error_contact_us'))
                        })
                        .finally(() => {
                           setIsOpen(false)
                        })
                  }}
               >
                  <PiLinkSimpleBold className='mr-2 h-5 w-5' />
                  {t('post_action.copy_link')}
               </button>
            </div>
            <div>
               <div>
                  <button
                     className='more-actions-item'
                     onClick={() => {
                        setShowReportModal(true)
                     }}
                  >
                     <HiOutlineFire className='mr-2 h-5 w-5' />
                     {t('post_action.report_post')}
                  </button>
               </div>
            </div>
            <div>
               <div>
                  <button
                     className='more-actions-item'
                     onClick={() => {
                        const promise = hasNotificationsState ? turnNotificationsOff(postId) : turnNotifications(postId)
                        promise.then(() => {
                           setIsOpen(false)
                           toast.success(
                              t(
                                 hasNotificationsState
                                    ? 'post_action.notifications_turned_off'
                                    : 'post_action.notifications_turned_on',
                              ),
                           )
                           setHasNotificationsState(!hasNotificationsState)
                        })
                     }}
                  >
                     {hasNotificationsState ? (
                        <HiOutlineBellSlash className='mr-2 h-5 w-5' />
                     ) : (
                        <HiOutlineBell className='mr-2 h-5 w-5' />
                     )}

                     {t(
                        hasNotificationsState
                           ? 'post_action.' + 'turn_on_notifications_off'
                           : 'post_action.turn_on_notifications',
                     )}
                  </button>
               </div>
            </div>
         </div>
      )
   }

   return (
      <div className='mt-2 items-center'>
         {loading && (
            <div className='absolute'>
               <SuperBallsLoadingIndicator />
            </div>
         )}
         <FloatingUIWrapper
            menuButton={({ ref, onClick }) => (
               <div
                  ref={ref}
                  onClick={onClick}
                  className={`flex items-center justify-center ${isMobile ? '' : '-mt-[1px]'}`}
               >
                  <MdOutlineMoreVert className='tab-hover ml-1 h-[22px] w-[22px] rounded-full p-0 hover:cursor-pointer' />
               </div>
            )}
         >
            {generateActionMenuItems()}
         </FloatingUIWrapper>

         <ReportModal
            showModal={showReportModal}
            entityId={postId}
            type={'post'}
            onCancel={() => {
               setShowReportModal(false)
            }}
         />
      </div>
   )
}
