import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Radio, { RadioChangeEvent } from 'antd/lib/radio'
import { Input, Space } from 'antd'

import { toast } from 'react-toastify'
import { createNotice } from '@/service/notice/notice-reducer'
import { AbstractWebModal } from '@/layout/common/AbstractWebModal'

export const ReportModal = ({ showModal, onCancel, entityId, type }) => {
   const { t } = useTranslation()
   const [reason, setReason] = useState('other')
   const [description, setDescription] = useState()

   const onChange = (e: RadioChangeEvent) => {
      setReason(e.target.value)
   }

   const onChangeOtherReason = event => {
      setDescription(event.target.value)
   }

   return showModal ? (
      <AbstractWebModal
         title={type == 'post' ? t('report_post.header_1') : t('report_comment.header_1')}
         onClose={onCancel}
      >
         <div className='mb-8 text-center'>
            <p className='text-coolGray-600 text-color-normal text-sm'>{t('report_post.header_2')}</p>
         </div>
         <Radio.Group onChange={onChange} value={reason}>
            <Space direction='vertical'>
               <Radio className='text-color-normal' value={'porn'}>
                  {t('report_post.porn')}
               </Radio>
               <Radio className='text-color-normal' value={'duplicate'}>
                  {t('report_post.duplicate')}
               </Radio>
               <Radio className='text-color-normal' value={'fraud'}>
                  {t('report_post.fraud')}
               </Radio>
               <Radio className='text-color-normal' value={'lack_18'}>
                  {t('report_post.lack_18')}
               </Radio>
               <Radio className='text-color-normal' value={'advert'}>
                  {t('report_post.advert')}
               </Radio>
               <Radio className='text-color-normal' value={'other'}>
                  {t('report_post.other')}
                  {reason === 'other' ? (
                     <Input
                        className='text-black'
                        style={{ width: 160, marginLeft: 10 }}
                        onChange={onChangeOtherReason}
                     />
                  ) : null}
               </Radio>
            </Space>
         </Radio.Group>
         <div className='mt-5 space-y-2'>
            <div className='flex gap-x-2'>
               <button
                  role='button'
                  type='submit'
                  className='sign-confirm-button'
                  onClick={() => {
                     createNotice({
                        entityId: entityId,
                        type: type,
                        reason: reason,
                        description: description,
                     })
                        .then(() => {
                           toast.success(t('report_post.success'))
                           onCancel()
                        })
                        .catch(reason => {
                           toast.error(t('report_post.failure'))
                           onCancel()
                        })
                  }}
               >
                  {t('report_post.confirm')}
               </button>
            </div>
         </div>
      </AbstractWebModal>
   ) : (
      <div></div>
   )
}
