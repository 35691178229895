import React, { useEffect, useState } from 'react'
import { commentActions, UserCommentDivider, userNameAndContent } from '../CommonComment'
import { SubcommentListMobile } from '@/layout/post/comment/mobile/SubcommentListMobile'
import { ISubcomment } from '@/model/subcomment.model'
import { IComment } from '@/model/comment.model'
import { IUser } from '@/model/user.model'
import { COMMENT_SORT_BY } from '@/service/search'

export interface CommentsSectionMobileProps {
   com: IComment
   subcomments: ISubcomment[]
   olderPagination?: any
   afterPagination?: any
   sort: COMMENT_SORT_BY
}

interface MainCommentMobileProps {
   comment: IComment
   updatedSubcomment?: ISubcomment
   actionsVisible: boolean
   preLoadedSubcomments?: ISubcomment[]
   triggerEditComment: any
   getAllSubcomments?: boolean
   scrollToBottom?: boolean
   fromDate: any
   triggerReplyToCommentMobile: (commentSection: CommentsSectionMobileProps, user: IUser) => void
   canGoToPost?: boolean
   canDeleteFromFavourites?: boolean
   deleteFromFavouritesFunction?: any
   getFirstPageOfSubcomments?: boolean
   subcommentSortBy: COMMENT_SORT_BY
}

export const MainCommentMobile = ({
   comment,
   updatedSubcomment = null,
   actionsVisible,
   preLoadedSubcomments = [],
   triggerEditComment = c => {},
   getAllSubcomments = false,
   scrollToBottom = false,
   fromDate,
   triggerReplyToCommentMobile,
   canGoToPost = false,
   canDeleteFromFavourites = false,
   deleteFromFavouritesFunction = (commentId: string) => {},
   getFirstPageOfSubcomments = false,
   subcommentSortBy,
}: MainCommentMobileProps) => {
   const [copyComment, setCopyComment] = useState(comment)
   // todo refactor this
   const [deleted, setDeleted] = useState(comment.deleted)
   const [editing, setEditing] = useState(false)

   const [commentsSectionCoppy, setCommentSectionCoppy] = useState<CommentsSectionMobileProps>({
      com: comment,
      subcomments: preLoadedSubcomments,
      sort: subcommentSortBy,
   })

   useEffect(() => {
      setCopyComment(comment)
   }, [comment])

   function commentDeleted() {
      setDeleted(true)
   }

   function commentUpdated(shouldUpdate: boolean, newComment: IComment) {
      if (shouldUpdate) setCopyComment(newComment)
   }

   function onEnableEditMode() {
      triggerEditComment(comment)
   }

   function changeEditing(value) {
      setEditing(value)
   }

   function replyToCommentFromSubcomment(sub: ISubcomment) {
      triggerReplyToCommentMobile(commentsSectionCoppy, sub.user)
   }

   return (
      <div className='flex h-full flex-col'>
         <div className='ml-1 flex flex-row items-start'>
            <UserCommentDivider user={comment.user} />
            <div className='flex flex-col'>
               {userNameAndContent(copyComment, deleted, commentDeleted, commentUpdated, editing, changeEditing)}
               <div hidden={!actionsVisible} className='comment-action-buttons-section'>
                  {commentActions(
                     !copyComment.deleted,
                     () => triggerReplyToCommentMobile(commentsSectionCoppy, comment.user),
                     copyComment,
                     commentDeleted,
                     onEnableEditMode,
                     canGoToPost || canDeleteFromFavourites,
                     canDeleteFromFavourites,
                     deleteFromFavouritesFunction,
                  )}
               </div>
               <SubcommentListMobile
                  preloadedAfterSubcomments={
                     preLoadedSubcomments?.length > 0 ? preLoadedSubcomments : comment.subcomments
                  }
                  allSubcommentsCount={comment.subcommentsCount}
                  olderCommentsCount={comment.olderSubcommentsCount}
                  parentId={comment.id}
                  triggerEditComment={triggerEditComment}
                  triggerReplyToComment={replyToCommentFromSubcomment}
                  fromDate={fromDate}
                  updatedAddedSubcomment={updatedSubcomment}
                  updateCommentsSection={(subcomments: ISubcomment[], olderPagination: any, afterPagination: any) => {
                     setCommentSectionCoppy({
                        com: copyComment,
                        subcomments: subcomments,
                        olderPagination: olderPagination,
                        afterPagination: afterPagination,
                        sort: subcommentSortBy,
                     })
                  }}
                  subcommentSortBy={subcommentSortBy}
               />
            </div>
         </div>
      </div>
   )
}
