import { Chef, OfferPerWeekdaysChef } from '@/model/chef.model'
import { DeliveryType } from '@/model/enumerations/delivery-type.model'
import { AbstractMobileModal } from '@/layout/common/AbstractMobileModal'
import { AbstractWebModal } from '@/layout/common/AbstractWebModal'
import { isMobile } from 'react-device-detect'
import { daysOfWeek } from '@/util/food-availability-utils'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { PickUPointsMap } from '@/layout/chef/PickUPointsMap'
import { getPickUpPointsByIds } from '@/service/pick-up-point.service'

interface DeliveryDetailsProps {
   chef: Chef
   chosenDelivery: DeliveryType
   setChosenDelivery?: (value: DeliveryType) => void
}

const renderOperatingHours = (hours: OfferPerWeekdaysChef | undefined, orderedDays: string[], t: any) => {
   return (
      <ul>
         {orderedDays.map(day => {
            const dayInfo = hours ? hours[day] : null
            const hasHours = dayInfo && dayInfo.open && dayInfo.close && dayInfo.open !== 'Closed'
            return (
               <li key={day} className='mb-3 flex flex-row justify-between px-2 pl-3'>
                  <h3 className='w-1/2 text-sm font-semibold'>{day.charAt(0).toUpperCase() + day.slice(1)}</h3>
                  {hasHours ? (
                     <div className='text-rleft w-1/2 text-sm font-normal'>
                        {dayInfo.open} - {dayInfo.close}
                     </div>
                  ) : (
                     <div className='w-1/2 text-left text-sm font-normal'>{t('chef_profile.closed')}</div>
                  )}
               </li>
            )
         })}
      </ul>
   )
}

export const DeliveryDetails = ({ chef, chosenDelivery, setChosenDelivery }: DeliveryDetailsProps) => {
   const { t } = useTranslation()
   const [pickUpPoints, setPickUpPoints] = useState<Chef[]>([])

   useEffect(() => {
      if (chef?.deliveryTypes.includes(DeliveryType.DELIVER_TO_PICKUP_POINT)) {
         if (!chef.chefsOrdPointsIds || chef.chefsOrdPointsIds.length === 0) return
         getPickUpPointsByIds(chef.chefsOrdPointsIds).then(response => {
            setPickUpPoints(response.data)
         })
      }
   }, [chef])

   const getCurrentDayIndex = () => new Date().getDay()

   const getOrderedDays = () => {
      const currentDayIndex = getCurrentDayIndex()
      return [...daysOfWeek.slice(currentDayIndex), ...daysOfWeek.slice(0, currentDayIndex)]
   }

   const orderedDays = getOrderedDays()

   const handleTabClick = (deliveryType: DeliveryType) => {
      if (setChosenDelivery) setChosenDelivery(deliveryType)
   }

   if (!chef) return null

   const availableTypes = chef.deliveryTypes
   const tabs = [
      { type: DeliveryType.PICKUP, label: t('shopping_cart.pickup'), hours: chef.pickupHours },
      { type: DeliveryType.DINE_IN, label: t('shopping_cart.dine_in'), hours: chef.dineInHours },
      {
         type: DeliveryType.DELIVER_TO_PICKUP_POINT,
         label: t('shopping_cart.pick_up_point'),
         hours: chef.deliverToPickUpPointHours,
      },
   ].filter(tab => availableTypes.includes(tab.type))

   const currentTab = tabs.find(tab => tab.type === chosenDelivery)

   let content = null
   if (currentTab) {
      content = (
         <>
            {renderOperatingHours(currentTab.hours, orderedDays, t)}
            {currentTab.type === DeliveryType.DELIVER_TO_PICKUP_POINT && (
               <>
                  <PickUPointsMap points={pickUpPoints} />
                  {pickUpPoints.length === 0 && (
                     <p className='text-center text-xs text-gray-500'>{t('chef_profile.no_pickup_points')}</p>
                  )}
               </>
            )}
         </>
      )
   }
   const deliveryDetails = (
      <div className='mt-3 flex w-full flex-col'>
         {/* Tabs */}
         <div className='flex w-full border-b border-gray-300'>
            {tabs.map(tab => (
               <button
                  key={tab.type}
                  className={`flex-1 py-2 text-center text-sm ${
                     chosenDelivery === tab.type
                        ? 'border-customPurple border-b-4 font-semibold'
                        : 'border-b-4 border-gray-300 font-normal'
                  }`}
                  style={{ outline: 'none' }}
                  onClick={() => handleTabClick(tab.type)}
               >
                  {tab.label}
               </button>
            ))}
         </div>
         {/* Content */}
         <div className='mt-3'>{content}</div>
      </div>
   )

   return (
      chosenDelivery && (
         <>
            {isMobile ? (
               <AbstractMobileModal
                  title={<p className='text-xl font-semibold'>{t('chef_profile.delivery_details')}</p>}
                  customNavigation={() => {
                     if (setChosenDelivery) {
                        setChosenDelivery(null)
                     }
                  }}
                  increaseZIndex={true}
               >
                  {deliveryDetails}
               </AbstractMobileModal>
            ) : (
               <AbstractWebModal
                  title={t('chef_profile.delivery_details')}
                  onClose={() => {
                     if (setChosenDelivery) {
                        setChosenDelivery(null)
                     }
                  }}
               >
                  <div>{deliveryDetails}</div>
               </AbstractWebModal>
            )}
         </>
      )
   )
}
