import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '@/config/store'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { deleteObserveContent, observeContent } from '@/service/observedContent-service'
import { toast } from 'react-toastify'
import { UserIconComponent } from '@/layout/common/UserIconComponent'
import { IUser } from '@/model/user.model'

export const ObserveUserButton = ({ user }) => {
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const { t } = useTranslation()
   const [observed, setObserved] = useState(false)

   useEffect(() => {
      if (user?.observed) {
         setObserved(user.observed)
      }
   }, [user])

   const handleFollowToggle = () => {
      setObserved(!observed)
      const content = { contentType: 'USER', contentId: user?.id }
      const promise = observed ? deleteObserveContent(content) : observeContent(content)
      promise
         .then(_ => {
            toast.success(t(observed ? 'observed.user_deleted' : 'observed.user_added'))
            setObserved(!observed)
         })
         .catch(reason => {})
      // Here you would also make a network request to your backend to update the follow status
   }

   return (
      loggedAccount &&
      user && (
         <button
            className={`xs:px-3 right-10 mr-0 items-center justify-center rounded-2xl px-1 py-1 text-base text-gray-100 ${observed ? 'bg-red-600 hover:bg-red-700' : 'bg-blue-600 hover:bg-blue-700'}`}
            onClick={handleFollowToggle}
         >
            {t(observed ? 'user_details.following' : 'user_details.do_follow')}
         </button>
      )
   )
}

export enum UserLoginComponentType {
   ONLY_ICON,
   ONLY_TEXT,
   ICON_AND_TEXT,
}

export interface UserLoginComponentProps {
   userId?: string
   user?: IUser
   avatar?: string
   userName?: string
   type: UserLoginComponentType
   filledBackground?: boolean
   addedStylesForIcon?: string
   navigateEnabled?: boolean
}

export const UserLoginComponent: React.FC<UserLoginComponentProps> = ({
   userId,
   userName,
   avatar,
   type,
   filledBackground,
   addedStylesForIcon,
   navigateEnabled = false,
}) => {
   const navigate = useNavigate()

   let userLoginComponent: string | number | boolean | React.ReactNode | Iterable<React.ReactNode>

   const onNavigateToUser = () => {
      if (!navigateEnabled) return
      navigate('/user-details/' + userId)
   }

   switch (type) {
      case UserLoginComponentType.ONLY_ICON:
         userLoginComponent = (
            <div className='hover:cursor-pointer' onClick={onNavigateToUser}>
               <UserIconComponent
                  picture={avatar}
                  filledBackground={filledBackground}
                  addedStyles={addedStylesForIcon}
               />
            </div>
         )
         break
      case UserLoginComponentType.ONLY_TEXT:
         userLoginComponent = (
            <a
               className='hover-color mr-1 flex flex-row items-center font-semibold text-inherit hover:cursor-pointer'
               onClick={onNavigateToUser}
            >
               {userName}
            </a>
         )
         break
      case UserLoginComponentType.ICON_AND_TEXT:
         userLoginComponent = (
            <div className='flex flex-row items-center hover:cursor-pointer' onClick={onNavigateToUser}>
               <UserIconComponent picture={avatar} />
               <span className='hover-color ml-1 font-semibold text-inherit hover:underline'>{userName}</span>
            </div>
         )
         break
      default:
         userLoginComponent = null
   }

   return userLoginComponent
}

export const KarmaIndicator = ({ userInfo }) => {
   const totalVotes = userInfo?.karmaPlusPoints + userInfo?.karmaMinusPoints
   if (!totalVotes) return null

   const upVotePercentage = totalVotes > 0 ? userInfo.karmaPlusPoints / totalVotes : 0
   const downVotePercentage = totalVotes > 0 ? userInfo.karmaMinusPoints / totalVotes : 0

   return (
      <div className='flex w-full flex-row items-center py-2'>
         <div className='text-lg'>Karma:&nbsp;{userInfo.karmaPlusPoints - userInfo.karmaMinusPoints}</div>
         <div className='flex w-full items-center justify-between'>
            <div className='relative mx-2 h-2.5 w-full rounded-full bg-gray-200 dark:bg-gray-700'>
               <div className='absolute h-2.5 rounded-l bg-blue-600' style={{ width: `${upVotePercentage}%` }}></div>
               <div
                  className='absolute h-2.5 rounded-r bg-red-600'
                  style={{ width: `${downVotePercentage}%`, right: 0 }}
               ></div>
            </div>
            <span className='text-sm text-blue-600'>{upVotePercentage.toFixed(1)}%</span>
         </div>
      </div>
   )
}
