import { useTranslation } from 'react-i18next'
import React, { useState } from 'react'
import { Button, DatePicker } from 'antd'
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { FieldErrors, UseFormSetValue } from 'react-hook-form'
import { Food, OneTimeOffer } from '@/model/order/food.model'

dayjs.extend(customParseFormat)

interface OneTimeOfferInputProps {
   setValue: UseFormSetValue<Food>
   errors: FieldErrors<Food>
}

export const OneTimeOfferInput = ({ setValue, errors }: OneTimeOfferInputProps) => {
   const { t } = useTranslation()

   const [offerDays, setOfferDays] = useState<OneTimeOffer[]>([{ date: '', offerInfo: { amountServed: null } }])

   const addOfferDay = () => {
      const lastOfferDay = offerDays[offerDays.length - 1] || {
         date: '',
         offerInfo: { open: '', close: '', amountServed: null },
      }
      const newOfferDay = {
         date: lastOfferDay.date ? dayjs(lastOfferDay.date).add(1, 'day').format('YYYY-MM-DD') : '',
         offerInfo: {
            // open: lastOfferDay.offerInfo.open,
            // close: lastOfferDay.offerInfo.close,
            amountServed: lastOfferDay.offerInfo.amountServed,
         },
      }
      setOfferDays([...offerDays, newOfferDay])
   }

   const removeOfferDay = (index: number) => {
      const updatedOfferDays = offerDays.filter((_, i) => i !== index)
      setOfferDays(updatedOfferDays)
   }

   const handleDateChange = (dateString: any, index: number) => {
      const updatedOfferDays = [...offerDays]
      updatedOfferDays[index].date = dateString
      setOfferDays(updatedOfferDays)
      setValue(`offer.oneTimeOffers.${index}.date`, dateString)
   }

   const handleTimeChange = (timeString: any, index: number, type: 'open' | 'close') => {
      const updatedOfferDays = [...offerDays]
      updatedOfferDays[index] = {
         ...updatedOfferDays[index],
         offerInfo: {
            ...updatedOfferDays[index].offerInfo,
            [type]: timeString,
         },
      }
      setOfferDays(updatedOfferDays)
      // setValue(`offer.oneTimeOffers.${index}.offerInfo.${type}`, timeString)
   }

   const handleAmountChange = (value: number, index: number) => {
      const updatedOfferDays = [...offerDays]
      updatedOfferDays[index] = {
         ...updatedOfferDays[index],
         offerInfo: {
            ...updatedOfferDays[index].offerInfo,
            amountServed: value,
         },
      }
      setOfferDays(updatedOfferDays)
      // @ts-ignore
      setValue(`offer.oneTimeOffers.${index}.offerInfo.amountServed`, value)
   }

   return (
      <div className='flex flex-col pt-2 pl-2'>
         <div className='mb-4 flex items-center justify-between'>
            <label className='text-lg font-semibold'>{t('one_time_offer_page.offer_days')}</label>
         </div>
         {offerDays.map((day, index) => (
            <div key={index} className='mb-4 flex flex-col'>
               <div className='mb-2 flex items-center space-x-2'>
                  <DatePicker
                     className='w-[130px] min-w-[130px]'
                     onChange={(date, dateString) => handleDateChange(dateString, index)}
                     value={day.date ? dayjs(day.date) : null}
                     format='YYYY-MM-DD'
                  />
                  {/*<TimePicker*/}
                  {/*   className='w-full max-w-[130px]'*/}
                  {/*   format='HH:mm'*/}
                  {/*   minuteStep={15}*/}
                  {/*   onChange={(time, timeString) => handleTimeChange(timeString, index, 'open')}*/}
                  {/*   value={day.offerInfo.open ? dayjs(day.offerInfo.open, 'HH:mm') : null}*/}
                  {/*/>*/}
                  {/*<TimePicker*/}
                  {/*   className='w-full max-w-[130px]'*/}
                  {/*   format='HH:mm'*/}
                  {/*   minuteStep={15}*/}
                  {/*   onChange={(time, timeString) => handleTimeChange(timeString, index, 'close')}*/}
                  {/*   value={day.offerInfo.close ? dayjs(day.offerInfo.close, 'HH:mm') : null}*/}
                  {/*/>*/}
                  <input
                     type='number'
                     placeholder={t('one_time_offer_page.amount')}
                     value={day.offerInfo.amountServed}
                     onChange={e => handleAmountChange(Number(e.target.value), index)}
                     className='block w-full max-w-[150px] rounded-md border border-gray-300 p-1 shadow-xs focus:border-blue-500 focus:ring-blue-500 focus:outline-hidden sm:text-sm'
                  />
                  <Button
                     className='w-fit text-xs'
                     onClick={() => removeOfferDay(index)}
                     icon={<MinusCircleOutlined />}
                  >
                     {t('one_time_offer_page.remove_day')}
                  </Button>
                  {index == offerDays.length - 1 && (
                     <Button className='text-xs' type='primary' onClick={addOfferDay} icon={<PlusCircleOutlined />}>
                        {t('one_time_offer_page.add_day')}
                     </Button>
                  )}
               </div>
               {errors[`offer.oneTimeOffers[${index}].date`] && (
                  <p className='mt-1 text-xs text-red-500'>{errors[`offer.oneTimeOffers[${index}].date`].message}</p>
               )}
               {errors[`offer.oneTimeOffers[${index}].open`] && (
                  <p className='mt-1 text-xs text-red-500'>
                     {errors[`offer.oneTimeOffers[${index}].offerInfo.open`].message}
                  </p>
               )}
               {errors[`offer.oneTimeOffers[${index}].close`] && (
                  <p className='mt-1 text-xs text-red-500'>
                     {errors[`offer.oneTimeOffers[${index}].offerInfo.close`].message}
                  </p>
               )}
               {errors[`offer.oneTimeOffers[${index}].amountServed`] && (
                  <p className='mt-1 text-xs text-red-500'>
                     {errors[`offer.oneTimeOffers[${index}].offerInfo.amountServed`].message}
                  </p>
               )}
            </div>
         ))}
      </div>
   )
}
