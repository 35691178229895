import React, { useState } from 'react'
import { commentActions, UserCommentDivider, userNameAndContent } from '../CommonComment'
import { isMobile } from 'react-device-detect'
import { SubcommentsListWeb } from '@/layout/post/comment/desktop/SubcommentsListWeb'
import { IComment } from '@/model/comment.model'
import { COMMENT_SORT_BY } from '@/service/search'

interface MainCommentProps {
   comment: IComment
   actionsVisible: boolean
   triggerEditComment?: (comment: any) => void
   fromDate: string
   canGoToPost?: boolean
   canDeleteFromFavourites?: boolean
   deleteFromFavouritesFunction?: (commentId: string) => void
   increaseCommentCount?: () => void
   subcommentsSortBy: COMMENT_SORT_BY
}

export const MainCommentWeb = ({
   comment,
   actionsVisible,
   triggerEditComment = c => {},
   fromDate,
   canGoToPost = false,
   canDeleteFromFavourites = false,
   deleteFromFavouritesFunction = (commentId: string) => {},
   increaseCommentCount = () => {},
   subcommentsSortBy,
}: MainCommentProps) => {
   const [copyComment, setCopyComment] = useState(comment)
   const [replyActiveMainCommentUser, setReplyActiveMainCommentUser] = useState(null)
   const [deleted, setDeleted] = useState(comment.deleted)
   const [editing, setEditing] = useState(false)

   function commentDeleted() {
      setDeleted(true)
   }

   function commentUpdated(shouldUpdate: boolean, newComment: IComment) {
      if (shouldUpdate) setCopyComment(newComment)
   }

   function afterCommentAdd() {
      setReplyActiveMainCommentUser(null)
      increaseCommentCount()
   }

   function setActiveReply() {
      if (replyActiveMainCommentUser) setReplyActiveMainCommentUser(null)
      else setReplyActiveMainCommentUser(comment.user)
   }

   function onEnableEditMode() {
      if (isMobile) {
         triggerEditComment(comment)
         return
      }
      setEditing(true)
   }

   function changeEditing(value: boolean) {
      setEditing(value)
   }

   return (
      <div className='mb-2 flex'>
         <div className='text-xs'></div>
         <div className='ml-1 flex flex-row pl-1'>
            <UserCommentDivider user={comment.user} />
            <div className='flex flex-col'>
               {userNameAndContent(
                  copyComment,
                  deleted,
                  commentDeleted,
                  commentUpdated,
                  editing,
                  changeEditing,
                  canDeleteFromFavourites,
                  deleteFromFavouritesFunction,
               )}
               <div hidden={!actionsVisible} className='comment-action-buttons-section'>
                  {commentActions(
                     !copyComment.deleted,
                     setActiveReply,
                     copyComment,
                     commentDeleted,
                     onEnableEditMode,
                     canGoToPost || canDeleteFromFavourites,
                     canDeleteFromFavourites,
                     deleteFromFavouritesFunction,
                  )}
               </div>
               <SubcommentsListWeb
                  preloadedAfterSubcomments={comment.subcomments}
                  allSubcommentsCount={comment.subcommentsCount}
                  olderCommentsCount={comment.olderSubcommentsCount}
                  parentId={comment.id}
                  afterCommentAdd={afterCommentAdd}
                  triggerEditComment={triggerEditComment}
                  replyActiveMainCommentUser={replyActiveMainCommentUser}
                  postId={comment.postId}
                  mainCommentDate={fromDate}
                  subcommentSortBy={subcommentsSortBy}
               />
            </div>
         </div>
      </div>
   )
}
