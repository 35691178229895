import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SubComment } from '../SubComment'
import { PiArrowBendDownRightBold } from 'react-icons/pi'
import { ISubcomment } from '@/model/subcomment.model'
import { getSubcommentsByParentId } from '@/service/comment/comment-reducer'
import { COMMENT_SORT_BY } from '@/service/search'
import { LoadingBalls } from '@/layout/common/LoadingIndicator'

interface PreviousAfterSubcommentListMobileProps {
   allSubcommentsCount: number
   olderCommentsCount: number
   preloadedAfterSubcomments: ISubcomment[]

   updatedAddedSubcomment: ISubcomment

   parentId: string
   triggerEditComment: any
   triggerReplyToComment: any
   fromDate: any
   subcommentSortBy: COMMENT_SORT_BY
   updateCommentsSection: (subcomments: ISubcomment[], olderPagination, afterPagination) => void
}

export const SubcommentListMobile = ({
   preloadedAfterSubcomments,
   allSubcommentsCount,
   olderCommentsCount,

   updatedAddedSubcomment,

   parentId,
   triggerEditComment,
   triggerReplyToComment,
   fromDate,
   updateCommentsSection,
   subcommentSortBy,
}: PreviousAfterSubcommentListMobileProps) => {
   const { t } = useTranslation()
   const commentsEndRef = useRef(null)

   const [subcomments, setSubcomments] = useState<ISubcomment[]>(preloadedAfterSubcomments)

   const [loadingBefore, setLoadingBefore] = useState(false)
   const [loadingAfter, setLoadingAfter] = useState(false)

   const [hasOlderComments, setHasOlderComments] = useState(false)
   const [hasAfterComments, setHasAfterComments] = useState(false)
   const [scrollDownAfterNewComment, setScrollDownAfterNewComment] = useState(false)

   const [beforePagination, setBeforePagination] = useState({
      fromDate: fromDate,
      parentId: parentId,
      page: 0,
      sort: subcommentSortBy,
      total: olderCommentsCount,
      commentsCount: 0,
      forBefore: true,
   })

   const [afterPagination, setAfterPagination] = useState({
      fromDate: fromDate,
      parentId: parentId,
      page: preloadedAfterSubcomments.length > 0 ? 1 : 0,
      sort: subcommentSortBy,
      total: allSubcommentsCount - olderCommentsCount,
      commentsCount: preloadedAfterSubcomments.length,
      forBefore: false,
   })

   useEffect(() => {
      setHasOlderComments(beforePagination.commentsCount < beforePagination.total)
      updateCommentsSection(subcomments, beforePagination, afterPagination)
   }, [beforePagination])

   useEffect(() => {
      setHasAfterComments(afterPagination.commentsCount < afterPagination.total)
      updateCommentsSection(subcomments, beforePagination, afterPagination)
   }, [afterPagination])

   useEffect(() => {
      if (subcommentSortBy == beforePagination.sort) return

      setBeforePagination(prev => ({ ...prev, sort: subcommentSortBy }))

      const newAfterPagination = {
         ...afterPagination,
         sort: subcommentSortBy,
         page: 0,
         commentsCount: 0,
         total: allSubcommentsCount,
      }

      setAfterPagination(newAfterPagination)
   }, [subcommentSortBy])

   useEffect(() => {
      if (updatedAddedSubcomment) {
         setSubcomments(prevSubcomments => {
            const index = prevSubcomments.findIndex(subcomment => subcomment.id === updatedAddedSubcomment.id)
            let newSubcomments
            if (index !== -1) {
               newSubcomments = prevSubcomments.map((subcomment, i) =>
                  i === index ? updatedAddedSubcomment : subcomment,
               )
            } else {
               newSubcomments = [...prevSubcomments, updatedAddedSubcomment]
               setScrollDownAfterNewComment(true)
            }
            return newSubcomments
         })
      }
   }, [updatedAddedSubcomment])

   useEffect(() => {
      if (scrollDownAfterNewComment) {
         commentsEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
         setScrollDownAfterNewComment(false)
      }
   }, [scrollDownAfterNewComment])

   function loadOlderComments() {
      setLoadingBefore(true)
      return getSubcommentsByParentId(beforePagination)
         .then(result => {
            // const total = result.headers['x-total-count'];
            // console.log("It has subcomments count ", total)
            setBeforePagination(prev => ({
               ...prev,
               page: beforePagination.page + 1,
               commentsCount: prev.commentsCount + result.data.length,
            }))
            setSubcomments([...result.data, ...subcomments])
         })
         .finally(() => {
            setLoadingBefore(false)
         })
   }

   function loadMoreAfter() {
      setLoadingAfter(true)
      return getSubcommentsByParentId(afterPagination)
         .then(result => {
            setAfterPagination(prev => ({
               ...prev,
               page: afterPagination.page + 1,
               commentsCount: prev.commentsCount + result.data.length,
            }))

            const newSubcomments = result.data.filter(
               newComment => !subcomments.some(existingComment => existingComment.id === newComment.id),
            )

            setSubcomments([...subcomments, ...newSubcomments])
         })
         .finally(() => {
            setLoadingAfter(false)
         })
   }

   return (
      <div className='flex flex-col'>
         <div
            role='button'
            hidden={!hasOlderComments || loadingBefore}
            onClick={() => {
               loadOlderComments()
            }}
            className='mt-1 ml-2'
         >
            <p className='commentMoreButton'>{t('subcomments.show_before')}</p>
         </div>

         {loadingBefore && <LoadingBalls />}

         {/*after comments*/}
         {/* Display After Subcomments */}
         <div className='flex flex-col py-0.5'>
            {subcomments &&
               subcomments?.length > 0 &&
               subcomments.map(sub => (
                  <SubComment
                     key={sub.id}
                     subcomment={sub}
                     triggerAddReply={() => {
                        triggerReplyToComment(sub)
                     }}
                     triggerEditComment={() => {
                        triggerEditComment(sub)
                     }}
                  />
               ))}
         </div>

         {loadingAfter && <LoadingBalls />}

         <div className='rounded-normal h-[27px]' hidden={!hasAfterComments || loadingAfter} role='button'>
            <div className='hover-color flex shrink-0 flex-row px-2' onClick={loadMoreAfter}>
               <div className='mt-1.5 mb-0'>
                  <PiArrowBendDownRightBold />
               </div>
               <div className='commentMoreButton ml-4'>{t('subcomments.show_more')}</div>
            </div>
         </div>
         <div ref={commentsEndRef} />
      </div>
   )
}
