import React, { lazy, Suspense, useEffect, useState } from 'react'
import 'leaflet/dist/leaflet.css'
import ChefFiltersComponent from '@/layout/home/ChefFiltersComponent'
import { SwiperWithChefs } from '@/layout/home/maps/SwiperWithChefs'
import { Chef } from '@/model/chef.model'
import { useNavigate } from 'react-router-dom'
import SingleChefInAppView from '@/layout/home/SingleChefInAppView'
import { ChefFilters, parseFiltersFromUrl, updateFiltersInUrl } from '@/util/UrlUtils'
import { fetchChefs } from '@/service/chef.service'
import { isMobile } from 'react-device-detect'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { LoadingBalls } from '@/layout/common/LoadingIndicator'
import { initUserLocalizationAndAddress } from '@/util/UserLocalizations'
import { isUserLoggedIn } from '@/util/auth-util'

const ChefsMapMobile = lazy(() => import('@/layout/home/maps/ChefsMapMobile'))

export const HomeListChefsMobile = () => {
   const navigate = useNavigate()
   const dispatch = useAppDispatch()
   const loggedAccount = useAppSelector(state => state.authentication.account)

   const { currentUserLocation } = useAppSelector(state => state.search)

   const [selectedChefId, setSelectedChefId] = useState<string>()
   const [toShowChefId, setToShowChefId] = useState<string>()

   const [chefs, setChefs] = useState<Chef[]>([])
   const [filters, setFilters] = useState<ChefFilters>()

   useEffect(() => {
      if (filters) {
         updateFiltersInUrl({ updatedFilters: filters })
         fetchChefs(filters.bounds, filters.cuisine, filters.delivery, filters.dietary, filters.sort)
            .then(response => setChefs(response.data))
            .catch(error => console.error('Error fetching chefs:', error.response?.data || error.message || error))
      }
   }, [filters])

   useEffect(() => {
      if (currentUserLocation) {
         console.log('current location', currentUserLocation)
         setFilters(parseFiltersFromUrl(isMobile, [currentUserLocation.latitude, currentUserLocation.longitude]))
      } else {
         console.log('no current location')
         initUserLocalizationAndAddress(dispatch, true, isUserLoggedIn(loggedAccount))
      }
   }, [currentUserLocation])

   return (
      <>
         <div className='max-h-screen-safe mt-[65px] flex max-w-full flex-col overflow-x-hidden overflow-y-hidden'>
            <ChefFiltersComponent
               availableCuisines={Array.from(new Set(chefs.flatMap(chef => chef.cuisines)))}
               filters={filters}
               setFilters={setFilters}
            />
            <div className='flex h-full w-full flex-row overflow-y-hidden'>
               <Suspense
                  fallback={
                     <div className='flex justify-center'>
                        <LoadingBalls />
                     </div>
                  }
               >
                  {filters && (
                     <ChefsMapMobile
                        chefs={
                           filters?.cuisine?.length > 0
                              ? chefs.filter(chef => chef.cuisines.some(cuisine => filters.cuisine.includes(cuisine)))
                              : chefs
                        }
                        bounds={filters?.bounds}
                        //todo
                        starredChef={null}
                        selectedChefId={selectedChefId}
                        setSelectedChefId={setSelectedChefId}
                        zoom={filters?.zoom}
                        setFilters={setFilters}
                     />
                  )}

                  <SwiperWithChefs
                     chefs={
                        filters?.cuisine?.length > 0
                           ? chefs.filter(chef => chef.cuisines.some(cuisine => filters.cuisine.includes(cuisine)))
                           : chefs
                     }
                     selectedChefId={selectedChefId}
                     setSelectedChefId={chefId => {
                        setSelectedChefId(chefId)
                     }}
                     onShowChefDetails={chefId => {
                        setToShowChefId(chefId)
                        // navigate('/chef/' + chefId, { replace: true })
                        // window.history.replaceState({}, '', '/chef/' + chefId)
                        window.history.pushState({ page: `/chef/${chefId}` }, 'chef', `/chef/${chefId}`)
                     }}
                  />
               </Suspense>
            </div>
         </div>
         {toShowChefId && (
            <SingleChefInAppView
               chef={chefs.find(chef => chef.id === toShowChefId)}
               customNavigationFunction={() => {
                  setToShowChefId(null)
                  navigate(-1)
               }}
            />
         )}
      </>
   )
}

export default HomeListChefsMobile
