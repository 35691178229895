import React, { useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Keyboard, Mousewheel, Navigation, Virtual } from 'swiper/modules'
import { Chef } from '@/model/chef.model'
import { ChefSwiperComponentMobile } from '@/layout/home/maps/ChefSwiperComponentMobile'

interface SwiperWithChefsProps {
   chefs: Chef[]
   selectedChefId: string
   setSelectedChefId: (chefId: string) => void
   onShowChefDetails: (chefId: string) => void
}

export const SwiperWithChefs = ({
   chefs,
   selectedChefId,
   setSelectedChefId,
   onShowChefDetails,
}: SwiperWithChefsProps) => {
   const swiperRef = useRef(null)

   useEffect(() => {
      if (!selectedChefId || !swiperRef.current || !swiperRef.current.swiper) return

      const swiperInstance = swiperRef.current.swiper
      const chefIndex = chefs.findIndex(c => c.id === selectedChefId)

      if (chefIndex !== -1) {
         swiperInstance.slideTo(chefIndex)
      }
   }, [selectedChefId, chefs])

   useEffect(() => {
      if (swiperRef.current && swiperRef.current.swiper) {
         const swiperInstance = swiperRef.current.swiper

         swiperInstance.on('slideChange', () => {
            const currentIndex = swiperInstance.activeIndex
            const currentChef = chefs[currentIndex]
            if (currentChef) {
               setSelectedChefId(currentChef.id)
            }
         })
      }
   }, [chefs, setSelectedChefId])

   return (
      chefs.length > 0 && (
         <div style={{ position: 'absolute', bottom: '52px', width: '100%', zIndex: 20 }}>
            <Swiper
               ref={swiperRef}
               slidesPerView={'auto'}
               modules={[Virtual, Navigation, Keyboard, Mousewheel]}
               centeredSlides={true}
               spaceBetween={30}
               style={{ width: '100%' }}
               keyboard={{ enabled: true, onlyInViewport: true }}
            >
               {chefs.map((chef, index) => (
                  <SwiperSlide
                     key={chef.id}
                     virtualIndex={index}
                     style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '85%',
                     }}
                  >
                     <ChefSwiperComponentMobile
                        chef={chef}
                        onChefClicked={() => {
                           onShowChefDetails(chef.id)

                           // Navigate the Swiper to the chef's slide
                           if (swiperRef.current && swiperRef.current.swiper) {
                              const swiperInstance = swiperRef.current.swiper
                              const chefIndex = chefs.findIndex(c => c.id === chef.id)
                              if (chefIndex !== -1) swiperInstance.slideTo(chefIndex)
                           }
                        }}
                     />
                  </SwiperSlide>
               ))}
            </Swiper>
         </div>
      )
   )
}
export default SwiperWithChefs
