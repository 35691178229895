import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IComment } from '@/model/comment.model'
import { getCommentsSavedForUser } from '@/service/comment/comment-reducer'
import { MainCommentWeb } from '../post/comment/desktop/MainCommentWeb'
import { COMMENT_SORT_BY } from '@/service/search'
import { newtonsCradle } from 'ldrs'

newtonsCradle.register()

export const FavouritesComments = () => {
   const { t } = useTranslation()
   const [pagination] = useState({
      // query: postId,
      page: 0,
      size: 10,
      total: 0,
   })

   const [comments, setComments] = useState<Array<IComment>>([])
   const [loadedAll, setLoadedAll] = useState(true)
   const [loading, setLoading] = useState(false)

   function loadComments() {
      setLoading(true)
      getCommentsSavedForUser(pagination)
         .then(result => {
            setLoading(false)
            pagination.total = Number(result.headers['x-total-count'])
            setComments([...comments, ...result.data])
         })
         .catch(_ => {
            setLoading(false)
         })
   }

   useEffect(() => {
      loadComments()
   }, [])

   useEffect(() => {
      const total = pagination.total
      if (total == null) {
         return
      }

      const loadedEvery = total == comments.length
      setLoadedAll(loadedEvery)
   }, [comments])

   function loadingIndicator() {
      const containsDark = document.documentElement.classList.contains('dark')
      return (
         <>
            {loading && (
               <div className='flex flex-row items-center justify-center'>
                  <div className=''>
                     {/*@ts-ignore*/}
                     <l-newtons-cradle size='50' speed='1.4' color={containsDark ? 'white' : 'black'} />
                  </div>
               </div>
            )}
         </>
      )
   }

   return (
      <div className='flex flex-col'>
         {comments &&
            comments.length > 0 &&
            comments.map((comment, i) => (
               <MainCommentWeb
                  key={comment.id}
                  comment={comment}
                  actionsVisible={true}
                  fromDate={null}
                  canDeleteFromFavourites={true}
                  deleteFromFavouritesFunction={(commentId: string) => {
                     setComments(comments.filter(c => c.id !== commentId))
                     pagination.total = pagination.total - 1
                  }}
                  subcommentsSortBy={COMMENT_SORT_BY.NEW}
               />
            ))}
         <div className='mt-1 flex flex-row justify-start'>
            <div
               role='button'
               hidden={loadedAll}
               className='hover-color rounded-normal mb-1.5 ml-1 pr-1 pl-1'
               onClick={() => {
                  pagination.page = pagination.page + 1
                  loadComments()
               }}
            >
               <p className='commentMoreButton'>{t('comments.show_more')}</p>
            </div>
         </div>

         {/*todo after a comment add*/}
         {/*{pagination.page > 0 ? <AddCommentComponent postId={postId} afterCommentAdd={() => {*/}
         {/*}} commentId={null} isSubcomment={false} /> : <></>}*/}
         {loading && loadingIndicator()}
      </div>
   )
}
