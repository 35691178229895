import { useEffect } from 'react'

export function isElementInViewport(el) {
   const rect = el.getBoundingClientRect()
   return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
   )
}

export const isPWAOnIphone = () => {
   // @ts-ignore
   return window.navigator.standalone
}

export function handleMenuVisibleUseEffect(
   menuVisible: boolean,
   menuRef: any,
   setMenuVisible: (value: ((prevState: boolean) => boolean) | boolean) => void,
) {
   useEffect(() => {
      const handleEvent = event => {
         // @ts-ignore
         if (menuVisible && menuRef.current && !menuRef.current.contains(event.target)) {
            event.stopPropagation()
            event.preventDefault()
            setMenuVisible(false)
         }
      }

      if (menuVisible) {
         document.addEventListener('mousedown', handleEvent)
         document.addEventListener('touchstart', handleEvent)
      } else {
         document.removeEventListener('mousedown', handleEvent)
         document.removeEventListener('touchstart', handleEvent)
      }

      // Unbind the event listener on clean up
      return () => {
         document.removeEventListener('mousedown', handleEvent)
         document.removeEventListener('touchstart', handleEvent)
      }
   }, [menuVisible])
}

export const updateURLParameter = (navigate, parameter: string, newSort: string) => {
   const searchParams = new URLSearchParams(window.location.search)
   searchParams.set(parameter, newSort)
   navigate(`${window.location.pathname}?${searchParams.toString()}`)
}

export const haversineDistance = (coords1: number[], coords2: number[]) => {
   if (coords1?.length !== 2 || coords2?.length !== 2) {
      return ''
   }

   const toRad = (x: number) => (x * Math.PI) / 180

   const [lat1, lon1] = coords1
   const [lat2, lon2] = coords2

   const R = 6371 // Radius of the Earth in kilometers
   const dLat = toRad(lat2 - lat1)
   const dLon = toRad(lon2 - lon1)
   const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
   const distance = R * c

   return distance.toFixed(2) // Returns the distance in kilometers, rounded to 2 decimal places
}

export const isMongoDb = (login: string) => {
   // Check if the login matches the pattern of a MongoDB ObjectId
   const mongoDbObjectIdPattern = /^[a-f\d]{24}$/i
   return mongoDbObjectIdPattern.test(login)
}
