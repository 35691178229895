import React, { useRef } from 'react'
import { ChefStars } from '@/layout/common/ChefStars'
import { useForm } from 'react-hook-form'
import { PhotoType } from '@/model/enumerations/photo-type'
import { IReview } from '@/model/review.model'
import { useTranslation } from 'react-i18next'
import { addReview } from '@/service/chef.service'
import { AbstractWebModal } from '@/layout/common/AbstractWebModal'
import { LexicalEditorWrapper } from '@/layout/common/lexical-editor/LexicalEditorWrapper'

interface WriteReviewModalProps {
   chefId?: string
   modalOpened: boolean
   setModalOpened: (modalOpened: boolean, reviewCompleted: boolean, newReview) => void
}

export const WriteReviewModal = ({ chefId, modalOpened, setModalOpened }: WriteReviewModalProps) => {
   const { t } = useTranslation()

   const {
      register,
      reset,
      handleSubmit,
      getValues,
      watch,
      setValue,
      formState: { errors, isValid },
   } = useForm<IReview>({ mode: 'all' })

   const editorStateHTMLRef = useRef('')

   function handleCancelClick() {
      setModalOpened(false, true, null)
      reset()
   }

   const onSubmit = async (review: IReview) => {
      if (chefId) {
         review.chefId = chefId
         try {
            const saved = await addReview(review)
            setModalOpened(false, true, saved)
            reset()
         } catch (error) {
            console.error('Error submitting review:', error)
         }
      }
   }

   return (
      modalOpened && (
         <AbstractWebModal title={t('chef_profile.write_review')} onClose={handleCancelClick}>
            <ChefStars
               editMode={true}
               onRatingChange={rating => {
                  setValue('vote', rating)
               }}
            />
            <div className='background-gray-dark-normal break-word mt-2 mb-2 flex w-full flex-row rounded-lg px-1.5 py-1.5 outline-hidden'>
               <LexicalEditorWrapper
                  placeholder={<p className='lexical-placeholder'>{t('chef_add_page.description_placeholder')}</p>}
                  htmlStateUpdateFunction={(newState: string, size: number, hasImage: boolean) => {
                     editorStateHTMLRef.current = newState
                     setValue('review', newState)
                  }}
                  photoType={PhotoType.FOR_POST_LG}
                  clearEditorContent={() => {
                     editorStateHTMLRef.current = ''
                  }}
                  addImageEnabled={true}
               />
            </div>

            <div className='mt-6 mb-2 flex w-full items-center justify-center'>
               <button
                  className='cursor-default rounded-md bg-blue-600 px-4 py-2 font-medium text-white transition-colors duration-200 hover:bg-blue-700 disabled:opacity-50'
                  style={{ cursor: isValid ? 'pointer' : 'default' }}
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isValid}
               >
                  {t('submit')}
               </button>
            </div>
         </AbstractWebModal>
      )
   )
}

export default WriteReviewModal
