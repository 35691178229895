import { AxiosProgressEvent } from 'axios'
import { PhotoType } from '@/model/enumerations/photo-type'
import { RestClient } from '@/config/rest-client'

export interface PhotoProps {
   photo: File
   photoType: PhotoType
   entityId?: string
   tempId?: string
}

export const savePhotoToCDN = (
   photoProps: PhotoProps,
   onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
) => {
   const formData = new FormData()
   formData.append('photo', photoProps.photo)
   formData.append('photoType', photoProps.photoType)
   formData.append('entityId', photoProps.entityId)
   formData.append('tempId', photoProps.tempId)

   return RestClient.post<string>('/api/save-photo-to-cdn', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
   })
}

interface StaticContentPhoto {
   photo: File
   photoType: PhotoType
}

export const savePhotoForStaticContent = (fmData: StaticContentPhoto) => {
   return RestClient.post('/api/save-photo-for-static-content', fmData, {
      headers: { 'content-type': 'multipart/form-data' },
   })
}

export const deletePhotoForStaticContent = (photoName: string) => {
   return RestClient.post(`/api/delete-photo-for-static-content`, {
      photoName: photoName
   })
}

export const getStaticContentPhotos = () => {
   return RestClient.get<string[]>('/api/static-content-photos')
}

export const getOurPartnersPhotos = () => {
   return RestClient.get<string[]>('/api/our-partners-photos')
}

export const deleteUserProfilePhoto = () => {
   return RestClient.post('/api/delete-photo')
}
export const savePhotoFromUrl = (photoUrl: string, photoType: PhotoType) => {
   const fmData = new FormData()
   fmData.append('photoUrl', photoUrl)
   fmData.append('photoType', photoType)
   return RestClient.post<string>('/api/save-photo-from-url', fmData)
}
