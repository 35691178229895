import React, { useEffect, useState } from 'react'
import { getConfigByType } from '@/service/content-settings-service'
import { useAppSelector } from '@/config/store'
import { StaticContentType } from '@/layout/static-page/StaticContentType'
import { isDesktop, isMobile } from 'react-device-detect'
import { PageFooter } from '@/layout/landing_page/PageFooter'
import ReadOnlyLexical from '@/layout/common/lexical-editor/ReadOnlyLexical'

interface StaticContentViewProps {
   contentType: StaticContentType
   fullWidth?: boolean
   withoutStyles?: boolean
   skeletonLines?: number
   showFooter?: boolean
   additionalElement?: React.JSX.Element
   textStyles?: string
}

const StaticContentView = ({
   contentType,
   fullWidth = false,
   withoutStyles = false,
   skeletonLines = 12,
   showFooter = false,
   additionalElement,
   textStyles = '',
}: StaticContentViewProps) => {
   const [terms, setTerms] = useState<string>()
   const [isLoading, setIsLoading] = useState(false)
   const language = useAppSelector(state => state.localeSlice.lng)

   useEffect(() => {
      if (contentType == null) return
      window.scrollTo(0, 0)

      setIsLoading(true)
      getConfigByType(contentType, language)
         .then(value => {
            setTerms(value.data)
         })
         .catch(reason => {
            console.error(reason)
         })
         .finally(() => {
            setIsLoading(false)
         })
   }, [contentType, language])

   return (
      <>
         <main className={`flex ${withoutStyles ? '' : `${isMobile ? 'pt-[60px]' : 'pt-[70px]'} flex-col bg-white`}`}>
            {/* Main content container that grows */}
            <div className='flex-grow bg-white'>
               <div
                  className={`${
                     withoutStyles
                        ? ''
                        : `background-normal rounded-normal ${
                             isDesktop ? 'p-4 md:p-6' : 'p-3'
                          } ${fullWidth ? 'w-full' : 'w-auto'} flex flex-col`
                  }`}
               >
                  <div className={textStyles}>
                     {isLoading ? (
                        <div className='animate-pulse space-y-4'>
                           {Array.from({ length: skeletonLines }).map((_, index) => (
                              <div
                                 key={index}
                                 className={`h-4 rounded-sm ${index === skeletonLines - 1 ? 'w-3/4' : ''}`}
                              ></div>
                           ))}
                        </div>
                     ) : (
                        <>
                           <ReadOnlyLexical htmlState={terms} />
                           {additionalElement}
                        </>
                     )}
                  </div>
               </div>
            </div>
         </main>
         {showFooter && <PageFooter />}
      </>
   )
}

export default StaticContentView
