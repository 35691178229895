import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingBalls } from '@/layout/common/LoadingIndicator'
import {
   createPaymentIntent,
   deleteFoodOrder,
   getShoppingCard,
   getShoppingCardOrderByChef,
} from '@/service/shopping-card.service'
import { OrderForChef } from '@/model/order/FoodOrderItem'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import ShoppingCardProgressBar, { SelectChefComponent } from '@/layout/shopping_cart/ShoppingCardProgressBar'
import { formatPrice } from '@/util/text-utils'
import { OrdersForChefInSC } from './OrdersForChefInSC'
import { useDispatch } from 'react-redux'
import { DeliveryType } from '@/model/enumerations/delivery-type.model'
import { DeliveryTypeSelector } from '@/layout/shopping_cart/DeliveryTypeSelector'
import { useAppSelector } from '@/config/store'
import { isUserLoggedIn } from '@/util/auth-util'
import { isMobile } from 'react-device-detect'
import { Order } from '@/model/order/order'

const ShoppingCard = () => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const [loading, setLoading] = useState(true)
   const [apiError, setApiError] = useState<string | null>(null)
   const [ordersForChef, setOrdersForChef] = useState<OrderForChef[]>([])
   const [selectedChefOrders, setSelectedChefOrders] = useState<OrderForChef | undefined>()
   const [isSubmitting, setIsSubmitting] = useState(false)
   const [selectOrders, setSelectOrders] = useState(false)

   const {
      setValue,
      getValues,
      handleSubmit,
      watch,
      register,
      formState: { isValid, errors },
      trigger,
   } = useForm<Order>({ mode: 'all' })

   useEffect(() => {
      register('deliveryType', {
         required: t('shopping_cart.errors.delivery_type_required'),
      })
      register('orderForTime', {
         required: t('shopping_cart.errors.order_time_required'),
      })
      register('orderForDay', {
         required: t('shopping_cart.errors.order_day_required'),
      })
      register('orderItems', {
         required: t('shopping_cart.errors.orders_required'),
      })
      register('chef.id', {
         required: t('shopping_cart.errors.chef_required'),
      })
   }, [register])

   useEffect(() => {
      if (!isUserLoggedIn(loggedAccount)) {
         setLoading(false)
         return
      }

      const fetchData = async () => {
         setLoading(true)
         setApiError(null)

         try {
            const chefId = new URLSearchParams(location.search).get('chefId')

            if (chefId) {
               if (getValues('chef.id') === chefId) return
               const response = await getShoppingCardOrderByChef(chefId)
               setSelectedChefOrders(response.data)
               setOrdersForChef([response.data])
               setValue('orderItems', response.data.orders)
               setValue('chef.id', chefId)
               const total = response.data.orders.map(o => o.food.price * o.quantity).reduce((a, b) => a + b, 0)
               setValue('totalPrice', total)
            } else {
               const response = await getShoppingCard()
               setOrdersForChef(response.data)
               if (response.data.length === 1) {
                  setSelectedChefOrders(response.data[0])
                  setValue('orderItems', response.data[0].orders)
                  setValue('chef.id', response.data[0].chef.id)
                  const total = response.data[0].orders.map(o => o.food.price * o.quantity).reduce((a, b) => a + b, 0)
                  setValue('totalPrice', total)
                  setSelectOrders(false)
               } else {
                  setSelectOrders(true)
               }
            }
         } catch (error: any) {
            console.error('Error fetching shopping cart:', error)
            setApiError(t('shopping_cart.errors.fetching_cart'))
         } finally {
            setLoading(false)
         }
      }

      fetchData()
   }, [])

   useEffect(() => {
      if (ordersForChef.length === 0) {
         setSelectedChefOrders(undefined)
      } else if (ordersForChef.length === 1) {
         setSelectedChefOrders(ordersForChef[0])
      }
   }, [ordersForChef])

   useEffect(() => {
      const chefId = new URLSearchParams(location.search).get('chefId')
      if (!chefId && ordersForChef.length > 1) {
         setSelectedChefOrders(undefined)
         setSelectOrders(true)
      } else if (!chefId && ordersForChef.length > 1) {
         setSelectedChefOrders(ordersForChef.find(c => c.chef.id === chefId))
         setSelectOrders(false)
      }
   }, [window.location.href])

   const deleteFromShoppingCard = async (foodOrderId: string) => {
      try {
         await deleteFoodOrder(foodOrderId)
         setOrdersForChef(prevFoodsToBuy =>
            prevFoodsToBuy
               .map(chefOrders => ({
                  ...chefOrders,
                  orders: chefOrders.orders.filter(foodOrder => foodOrder.id !== foodOrderId),
               }))
               .filter(chefOrders => chefOrders.orders.length > 0),
         )

         // If the selected chef is impacted
         if (selectedChefOrders) {
            const updatedOrders = selectedChefOrders.orders.filter(o => o.id !== foodOrderId)
            if (updatedOrders.length === 0) {
               // No orders left for this chef -> remove selection
               setSelectedChefOrders(undefined)
               setValue('orderItems', [])
               setValue('chef.id', '')
               const newUrl = new URL(window.location.href)
               newUrl.searchParams.delete('chefId')
               window.history.pushState({}, '', newUrl.toString())
               setSelectOrders(true)

               // If there's still more than one chef left, we remain on /shopping-card
               // which will show either the list or empty
            } else {
               setSelectedChefOrders({ ...selectedChefOrders, orders: updatedOrders })
               const newTotalPrice = updatedOrders.map(o => o.food.price * o.quantity).reduce((a, b) => a + b, 0)
               setValue('totalPrice', newTotalPrice)
            }
         }
      } catch (error) {
         console.error('Error deleting food order:', error)
         setApiError(t('shopping_cart.errors.deleting_order'))
      }
   }

   const onSubmit = useCallback(
      async (order: Order) => {
         setIsSubmitting(true)
         setApiError(null)

         createPaymentIntent(order)
            .then(response => {
               // todo redirect with the paymentObject included
               navigate(`/payment/${response.data.id}`, {
                  state: { paymentObject: response.data },
               })
            })
            .catch(error => {
               console.error('Error creating payment intent:', error)
               setApiError(t('shopping_cart.errors.payment_failed'))
            })
            .finally(() => {
               setIsSubmitting(false)
            })
      },
      [navigate, dispatch, t],
   )

   // Handle delivery details selection
   const onDeliveryDetailsSelect = (deliveryType: DeliveryType, date: string, time: string, pickUpPointId: string) => {
      setValue('deliveryType', deliveryType)
      setValue('orderForDay', date)
      setValue('orderForTime', time)
      setValue('pickUpPointId', pickUpPointId)
      trigger(['deliveryType', 'orderForDay', 'orderForTime'])
   }

   if (loading) {
      return (
         <div className='mt-1 flex items-center justify-center'>
            <LoadingBalls />
         </div>
      )
   }

   // Show API errors
   if (apiError) {
      return (
         <div className={`rounded-normal background-normal flex flex-col py-2 ${isMobile ? 'mt-[60px]': 'mt-[70px]'}`}>
            <div className='mt-0.5 ml-2 text-center text-2xl font-semibold text-red-500'>
               {t('shopping_cart.errors.title')}
            </div>
            <div className='mt-2 text-center text-red-500'>{apiError}</div>
         </div>
      )
   }

   // Empty cart
   if (ordersForChef.length === 0) {
      return (
         <div className={`rounded-normal background-normal flex flex-col py-2 ${isMobile ? 'mt-[60px]': 'mt-[70px]'}`}>
            <div className='mt-0.5 ml-2 text-center text-2xl font-semibold'>{t('shopping_cart.title')}</div>
            <div className='mx-1.5 mt-2 text-center'>{t('shopping_cart.empty')}</div>
         </div>
      )
   }

   return selectOrders == true ? (
      <div className={`rounded-normal background-normal border-gray-custom mx-auto mb-4 flex w-full max-w-[1280px] flex-col p-2 pb-4 shadow-md xl:p-4 ${isMobile ? 'mt-[60px]': 'mt-[70px]'}`}>
         <SelectChefComponent
            ordersForChef={ordersForChef}
            onOrdersSelected={ordersForChef => {
               setSelectedChefOrders(ordersForChef)
               // todo add parameter to URL with the chefId. The logic should work in a way that if the user refreshes the page, the same chef is
               // selected. And if a user navigates back then all the chefs are shown again.

               setSelectOrders(false)
               const newUrl = new URL(window.location.href)
               newUrl.searchParams.set('chefId', ordersForChef.chef?.id)
               window.history.pushState({}, '', newUrl.toString())

               setValue('chef.id', ordersForChef.chef?.id)
               setValue('orderItems', ordersForChef.orders)
               const total = ordersForChef.orders.map(o => o.food.price * o.quantity).reduce((a, b) => a + b, 0)
               setValue('totalPrice', total)
            }}
         />
      </div>
   ) : (
      <div className={`rounded-normal background-normal border-gray-custom mx-auto mb-4 flex w-full max-w-[1280px] flex-col p-2 pb-4 shadow-md md:p-4 ${isMobile ? 'mt-[60px]' : 'mt-[70px]'}`}>
         <ShoppingCardProgressBar step={0} />
         <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {selectedChefOrders && (
               <OrdersForChefInSC
                  ordersForChef={selectedChefOrders}
                  selectedOrders={selectedChefOrders}
                  deleteFromShoppingCard={deleteFromShoppingCard}
                  setValue={setValue}
                  getValues={getValues}
                  watch={watch}
               />
            )}
            <div className='mt-4 mr-2.5 mb-2 text-right text-lg font-semibold'>
               {t('shopping_cart.total')}: €{formatPrice(watch('totalPrice'))}
            </div>
            <DeliveryTypeSelector
               chef={selectedChefOrders?.chef}
               orders={selectedChefOrders?.orders || []}
               availableDeliveryTypes={selectedChefOrders?.chef?.deliveryTypes || []}
               onDeliveryDetailsSelect={onDeliveryDetailsSelect}
               selectedDate={watch('orderForDay')}
               selectedTime={watch('orderForTime')}
            />

            {/* Display form validation errors */}
            <div className='mt-2'>
               {errors.deliveryType && <p className='text-sm text-red-500'>{errors.deliveryType.message}</p>}
               {errors.orderForDay && <p className='text-sm text-red-500'>{errors.orderForDay.message}</p>}
               {errors.orderForTime && <p className='text-sm text-red-500'>{errors.orderForTime.message}</p>}
               {errors.orderItems && <p className='text-sm text-red-500'>{errors.orderItems.message}</p>}
               {errors.totalPrice && <p className='text-sm text-red-500'>{errors.totalPrice.message}</p>}
            </div>

            {/* Display API errors */}
            {apiError && <div className='mt-2 text-center text-red-500'>{apiError}</div>}
            <div className='mt-6 flex justify-end'>
               <button
                  type='submit'
                  disabled={isSubmitting || !isValid}
                  className={`rounded-normal mr-1 mb-1 w-1/3 p-1.5 py-3 font-semibold text-white transition ${!isValid || isSubmitting ? 'cursor-not-allowed bg-gray-400' : 'hover:bg-customPurple-dark bg-customPurple hover:cursor-pointer'}`}
               >
                  {isSubmitting ? t('shopping_cart.processing') : t('shopping_cart.buy')}
               </button>
            </div>
         </form>
      </div>
   )
}

export default ShoppingCard
