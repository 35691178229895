import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ChooseOnMapYourLocalization } from '@/layout/add-chef/ChooseOnMapYourLocalization'
import { BackStepButton, NextStepButton } from '@/layout/common/StepsBarComponent'
import { useAppSelector } from '@/config/store'
import { BuildingType, CustomAddress } from '@/model/custom-address.model'
import { Suggestion } from './CreateChefPage'
import {
   fetchSuggestions,
   mapAddresses,
   mapCustomAddressToSuggestion,
   NominatimResponse,
} from '@/service/open-street-map.service'
import { debounce } from 'lodash'
import { Chef } from '@/model/chef.model'
import {
   FieldErrors,
   UseFormGetValues,
   UseFormRegister,
   UseFormSetValue,
   UseFormTrigger,
   UseFormWatch,
} from 'react-hook-form'
import InputWithHeader from '@/layout/common/InputWithHeader'
import AddressSearchInput from '@/layout/common/AddressSearchInput'

export const debouncedHandleAddressChange = debounce(
   (e: string, setCurrentSearch, fetchSuggestions, setAddresses, locale, setLoading) => {
      if (e?.length >= 3) {
         setLoading(true)
         setCurrentSearch(e)
         fetchSuggestions(e, locale)
            .then((res: { data: NominatimResponse[] }) => {
               const mapAddresses1 = mapAddresses(res.data.filter(a => a.addresstype !== 'province'))
               setAddresses(mapAddresses1)
            })
            .catch(err => {
               console.error('Error fetching suggestions', JSON.stringify(err))
            })
            .finally(() => {
               setLoading(false)
            })
      } else {
         setLoading(false)
      }
   },
   400,
)

interface CreateChefStepTwoProps {
   watch: UseFormWatch<Chef>
   errors: FieldErrors<Chef>
   register: UseFormRegister<Chef>
   getValues: UseFormGetValues<Chef>
   setValue: UseFormSetValue<Chef>
   trigger: UseFormTrigger<Chef> //
   setStep: any
   validSteps: number[]
   setValidSteps: any
   editMode?: boolean
}

export const CreateChefStepTwo = ({
   watch,
   errors,
   register,
   setValue,
   getValues,
   trigger,
   setStep,
   validSteps,
   setValidSteps,
   editMode,
}: CreateChefStepTwoProps) => {
   const { t } = useTranslation()
   const LOCATION_NAME = 'address.locationName'
   const [loading, setLoading] = useState(false)

   const locale = useAppSelector(state => state.localeSlice.lng)

   const [addresses, setAddresses] = useState<CustomAddress[]>([])
   const [selectedAddress, setSelectedAddress] = useState<CustomAddress>(null)
   const [isSectionValid, setIsSectionValid] = useState(false)

   const [currentSearch, setCurrentSearch] = useState<string>('')
   const [searchedPhrase, setSearchedPhrase] = useState<string>('')

   const [buildingType, setBuildingType] = useState<BuildingType>(BuildingType.APARTMENT)

   const fieldPaths = [
      'address.country',
      'address.villageTownCity',
      ...(buildingType === BuildingType.HOUSE ? ['address.houseNumber'] : []),
      ...(buildingType === BuildingType.HOTEL ? ['address.hotelName', 'address.roomAndFloor'] : []),
      ...(buildingType === BuildingType.OFFICE ? ['address.businessName', 'address.suiteOrFloor'] : []),
   ]

   const checkSectionValidity = () => {
      const hasErrors = fieldPaths.some(fieldPath => hasFieldError(fieldPath))

      const allFieldsFilled = fieldPaths.every(fieldPath => {
         // @ts-ignore
         return getValues(fieldPath)
      })

      return !hasErrors && allFieldsFilled
   }

   useEffect(() => {
      const validThere = checkSectionValidity()
      setIsSectionValid(validThere)
      if (validThere && !validSteps.includes(2)) {
         setValidSteps([...validSteps, 2])
      } else if (!validThere && validSteps.includes(2)) {
         setValidSteps(validSteps.filter(step => step !== 2))
      }
   }, [])

   // @ts-ignore
   const watchedFields = watch(fieldPaths)

   useEffect(() => {
      const isValid = checkSectionValidity()
      setIsSectionValid(isValid)

      if (isValid && !validSteps.includes(2)) {
         setValidSteps([...validSteps, 2])
      } else if (!isValid && validSteps.includes(2)) {
         setValidSteps(validSteps.filter(step => step !== 2))
      }
   }, [errors, validSteps, setValidSteps, fieldPaths, watchedFields])

   const hasFieldError = (fieldPath: string) => {
      return fieldPath.split('.').reduce((acc, key) => (acc && acc[key] ? acc[key] : null), errors) != null
   }

   useEffect(() => {
      if (editMode) setSelectedAddress(watch('address'))
   }, [editMode])

   const handleSearch = (e: string) => {
      setSearchedPhrase(e)
      if (e == currentSearch) {
         setLoading(false)
         return
      }

      setLoading(true)
      debouncedHandleAddressChange(e, setCurrentSearch, fetchSuggestions, setAddresses, locale, setLoading)
   }

   const handleSuggestionSelect = (suggestion: Suggestion) => {
      const customAddress = addresses.find(a => a.id === suggestion.id)

      const name = suggestion.header1 + ', ' + suggestion.header2
      setCurrentSearch(name)
      setSearchedPhrase(name)
      setValue(LOCATION_NAME, name)
      if (customAddress.villageTownCity) setValue('address.villageTownCity', customAddress.villageTownCity)
      if (customAddress.country) setValue('address.country', customAddress.country)
      if (customAddress.latitude) setValue('address.latitude', customAddress.latitude)
      if (customAddress.longitude) setValue('address.longitude', customAddress.longitude)
      customAddress.locationName = name
      setSelectedAddress(customAddress)
      setAddresses([])
   }

   const clearSelectedAddress = () => {
      setSelectedAddress(null)
      setSearchedPhrase('')
      setValue('address', null)
   }

   return (
      <div className='space-y-8'>
         <ChooseOnMapYourLocalization address={selectedAddress} setValue={setValue} />

         <AddressSearchInput
            placeholder={t('chef_add_page.address_search')}
            selectedAddress={selectedAddress}
            searchedPhrase={searchedPhrase}
            loading={loading}
            setLoading={setLoading}
            addresses={addresses.map(s => mapCustomAddressToSuggestion(s))}
            onSearch={handleSearch}
            onClear={clearSelectedAddress}
            onSuggestionSelect={handleSuggestionSelect}
         />

         {watch('address') && (
            <div className='space-y-3'>
               <InputWithHeader<Chef>
                  label={t('chef_add_page.building_type')}
                  name='address.buildingType'
                  type='select'
                  register={register}
                  errors={errors}
                  options={[
                     { value: BuildingType.APARTMENT, label: t('chef_add_page.apartment') },
                     { value: BuildingType.HOUSE, label: t('chef_add_page.house') },
                     { value: BuildingType.OFFICE, label: t('chef_add_page.office') },
                     { value: BuildingType.HOTEL, label: t('chef_add_page.hotel') },
                     { value: BuildingType.OTHER, label: t('chef_add_page.other') },
                  ]}
                  rules={{
                     required: t('error.required'),
                  }}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                     const selectedType = e.target.value as BuildingType
                     setBuildingType(selectedType)
                     setValue('address.buildingType', selectedType)
                  }}
               />
               <InputWithHeader<Chef>
                  label={t('chef_add_page.country')}
                  name='address.country'
                  type='text'
                  register={register}
                  errors={errors}
                  rules={{
                     required: t('error.required'),
                     pattern: {
                        value: /^[\p{L}\s'-]+$/u,
                        message: t('error.invalid_input'),
                     },
                  }}
               />
               <InputWithHeader<Chef>
                  label={t('chef_add_page.street')}
                  name='address.street'
                  type='text'
                  register={register}
                  errors={errors}
                  rules={{
                     maxLength: {
                        value: 100,
                        message: t('error.max_length', { max: 100 }),
                     },
                     minLength: {
                        value: 3,
                        message: t('error.min_length', { min: 3 }),
                     },
                  }}
               />
               <InputWithHeader<Chef>
                  label={t('chef_add_page.city_town_village')}
                  name='address.villageTownCity'
                  type='text'
                  register={register}
                  errors={errors}
                  rules={{
                     required: t('error.required'),
                     pattern: {
                        value: /^[\p{L}\s'-]+$/u,
                        message: t('error.invalid_input'),
                     },
                  }}
               />

               {buildingType === BuildingType.APARTMENT && (
                  <>
                     <InputWithHeader<Chef>
                        label={t('chef_add_page.apartment_unit_floor')}
                        name='address.aptOrUnitOrFloor'
                        type='text'
                        register={register}
                        errors={errors}
                        rules={{
                           required: t('error.required'),
                           maxLength: {
                              value: 50,
                              message: t('error.max_length', { max: 50 }),
                           },
                        }}
                     />
                     <InputWithHeader<Chef>
                        label={t('chef_add_page.building_name')}
                        name='address.buildingName'
                        type='text'
                        register={register}
                        errors={errors}
                     />
                     <InputWithHeader<Chef>
                        label={t('chef_add_page.entry_code')}
                        name='address.entryCode'
                        type='text'
                        register={register}
                        errors={errors}
                        rules={{
                           pattern: {
                              value: /^[0-9]+$/,
                              message: t('error.only_numbers_allowed'),
                           },
                        }}
                     />
                  </>
               )}
               {buildingType === BuildingType.HOUSE && (
                  <InputWithHeader<Chef>
                     label={t('chef_add_page.house_number')}
                     name='address.houseNumber'
                     type='text'
                     register={register}
                     errors={errors}
                     rules={{
                        required: t('error.required'),
                        pattern: {
                           value: /^[0-9A-Za-z-]+$/,
                           message: t('error.invalid_input'),
                        },
                     }}
                  />
               )}

               {buildingType === BuildingType.HOTEL && (
                  <>
                     <InputWithHeader<Chef>
                        label={t('chef_add_page.hotel_name')}
                        name='address.hotelName'
                        type='text'
                        register={register}
                        errors={errors}
                        rules={{
                           required: t('error.required'),
                           pattern: {
                              value: /^[\p{L}0-9\s'-@&]+$/u,
                              message: t('error.invalid_input'),
                           },
                        }}
                     />
                     <InputWithHeader<Chef>
                        label={t('chef_add_page.room_and_floor')}
                        name='address.roomAndFloor'
                        type='text'
                        register={register}
                        errors={errors}
                        rules={{
                           required: t('error.required'),
                           maxLength: {
                              value: 50,
                              message: t('error.max_length', { max: 50 }),
                           },
                        }}
                     />
                  </>
               )}
               {buildingType === BuildingType.OFFICE && (
                  <>
                     <InputWithHeader<Chef>
                        label={t('chef_add_page.business_name')}
                        name='address.businessName'
                        type='text'
                        register={register}
                        errors={errors}
                        rules={{
                           required: t('error.required'),
                           pattern: {
                              value: /^[\p{L}0-9\s'&.,-]+$/u,
                              message: t('error.invalid_input'),
                           },
                        }}
                     />
                     <InputWithHeader<Chef>
                        label={t('chef_add_page.suite_floor')}
                        name='address.suiteOrFloor'
                        type='text'
                        register={register}
                        errors={errors}
                        rules={{
                           required: t('error.required'),
                           maxLength: {
                              value: 50,
                              message: t('error.max_length', { max: 50 }),
                           },
                        }}
                     />
                  </>
               )}

               <InputWithHeader<Chef>
                  label={t('chef_add_page.special_instructions')}
                  name='address.specialInstructions'
                  type='text'
                  register={register}
                  errors={errors}
                  rules={{
                     maxLength: {
                        value: 300,
                        message: t('error.max_length', { max: 300 }),
                     },
                  }}
               />
            </div>
         )}

         <div className='flex w-full flex-row items-center justify-between'>
            <BackStepButton step={1} setStep={setStep} />
            <NextStepButton step={3} setStep={setStep} isValid={isSectionValid} triggerValidation={trigger} />
         </div>
      </div>
   )
}
