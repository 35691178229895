import { fetchShortAddressName, getIPGeolocation } from '@/service/address.service'
import { getLocalStorageItem, LocalStorageKey } from '@/util/local-storage-utils'
import { getLastUserLocation } from '@/service/user-locations.service'
import { updateUserLocation } from '@/service/search'

interface DetailedAddress {
   street: string
   city: string
   state: string
   country: string
}

export interface UserLocation {
   locationName?: string
   coordinatesVerified?: boolean
   longitude: number
   latitude: number
   radius?: number
   detailedAddress?: DetailedAddress
   villageTownCity?: string
}

export async function initUserLocalizationAndAddress(
   dispatch: any,
   initializeAny,
   isAuthenticated: boolean,
): Promise<void> {
   console.log('Initializing user localization', { initializeAny, isAuthenticated })

   let storedLocation: UserLocation | null = getLocalStorageItem(LocalStorageKey.LAST_USER_LOCALIZATION, null)
   console.log('Retrieved location from local storage:', storedLocation)

   if (!storedLocation && isAuthenticated) {
      console.log('No stored location found and user is authenticated, fetching from backend')
      try {
         const res = await getLastUserLocation()
         console.log('Backend location response:', res)

         if (res?.data && res.data.longitude != null && res.data.latitude != null) {
            storedLocation = {
               locationName: res.data.locationName,
               longitude: res.data.longitude,
               latitude: res.data.latitude,
               radius: res.data.radius,
            }
            console.log('Using location from backend:', storedLocation)
         } else {
            console.log('Backend response did not contain valid coordinates')
         }
      } catch (error) {
         console.error('Error fetching last user location from backend', error)
      }
   }

   if ((initializeAny || isAuthenticated) && !storedLocation) {
      console.log('Attempting to get location from IP or browser geolocation')
      try {
         const ipLocation = await getIPGeolocation()
         console.log('IP geolocation result:', ipLocation)

         if (ipLocation) {
            console.log('Using IP geolocation data')
            storedLocation = ipLocation
         } else {
            console.log('No IP geolocation, attempting browser geolocation')
            const position = await getCurrentPosition()
            const { latitude, longitude } = position.coords
            console.log('Browser position:', { latitude, longitude })

            storedLocation = await updateAddress(latitude, longitude)
            console.log('Updated location with address details:', storedLocation)
         }
      } catch (error) {
         console.error('Error fetching location', error)
      }
   }

   if (storedLocation) {
      console.log('Dispatching location update:', {
         location: storedLocation,
         shouldSaveLocation: isAuthenticated
      })
      dispatch(
         updateUserLocation({
            location: storedLocation,
            shouldSaveLocation: isAuthenticated,
         }),
      )
   } else {
      console.warn('No location data available after all attempts')
   }
}

export function getCurrentPosition(enableHighAccuracy: boolean = true): Promise<GeolocationPosition> {
   return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
         enableHighAccuracy: enableHighAccuracy,
         timeout: 100000,
         maximumAge: 0,
      })
   })
}

export async function updateAddress(latitude: number, longitude: number): Promise<UserLocation> {
   // Fetch address details using the provided latitude and longitude
   const addressDetails = await fetchShortAddressName(latitude, longitude)
   return {
      locationName: addressDetails, // Name this appropriately based on your logic
      longitude: longitude,
      latitude: latitude,
      // detailedAddress: addressDetails,      // Include detailed address if available
   }
}
