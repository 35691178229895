import React, { useEffect, useState } from 'react'
import { getOurPartnersPhotos } from '@/service/photo/photo-reducer'
import { CDN_ENDPOINT } from '@/env'
import { isDesktop, isMobile } from 'react-device-detect'
import { underscoreLine } from '@/layout/landing_page/HowItWorksLandingPage'
import { useTranslation } from 'react-i18next'

export const OurPartners = () => {
   const { t } = useTranslation()
   const [ourPartnersPhotos, setOurPartnersPhotos] = useState<string[]>([])

   useEffect(() => {
      getOurPartnersPhotos().then(response => {
         setOurPartnersPhotos(response.data)
      })
   }, [])

   return (
      <div className='mt-4 flex flex-1 flex-col items-center bg-white'>
         <h1 className={`${isDesktop ? 'mt-8' : 'mb-2 mt-2'} mb-7 text-center text-[34px] font-semibold`}>
            {t('landing_page.our_partners')}
            {underscoreLine(205)}
         </h1>
         <div className={`flex w-full flex-wrap items-center justify-evenly ${isMobile ? 'gap-1 pb-1': 'gap-4 pb-8'} `}>
            {ourPartnersPhotos.map((photo, index) => (
               <img
                  key={index}
                  src={CDN_ENDPOINT + photo}
                  alt={`Partner ${index}`}
                  className={`w-1/5 ${isMobile ? 'max-w-[200px] min-w-[130px] max-h-[105px]': 'max-w-[250px] min-w-[150px] max-h-[150px]'}  object-contain`}
               />
            ))}
         </div>
      </div>
   )
}
