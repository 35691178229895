import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const ConfigureStripeAccountForChef = () => {
   const { t } = useTranslation()
   const [onboardingLink, setOnboardingLink] = useState(null)

   useEffect(() => {
      const fetchOnboardingLink = async () => {
         const response = await fetch(`/api/stripe/create-account-link`, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
         })

         const data = await response.json()
         setOnboardingLink(data.url)
      }

      fetchOnboardingLink()
   }, [])

   return (
      <div>
         {onboardingLink ? (
            <a href={onboardingLink} target='_blank' rel='noopener noreferrer'>
               {t('stripe_account.complete_onboarding')}
            </a>
         ) : (
            <p>{t('stripe_account.loading')}</p>
         )}
      </div>
   )
}

export default ConfigureStripeAccountForChef
