import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '@/config/store'
import { hideForgotPasswordModal, resetPassword } from '@/service/authentication'
import { LoadingIndicator } from '@/layout/common/LoadingIndicator'
import { useNavigate } from 'react-router-dom'
import { FloatingLabelInput } from '@/layout/common/FloatingLabelInput'

const ForgotPasswordMobile = () => {
   const {
      register,
      handleSubmit,
      watch,
      formState: { errors, isValid },
   } = useForm<any>({ mode: 'all' })

   const dispatch = useAppDispatch()

   const { t } = useTranslation()

   const [errorMessage, setErrorMessage] = useState('')

   const [isLoading, setIsLoading] = useState(false)
   const navigate = useNavigate()

   const onSubmit = useCallback((formData: any) => {
      setIsLoading(true)
      const email = formData.email
      resetPassword(email)
         .then(value => {
            navigate('/forgot-pass-next', { state: { email } })
            dispatch(hideForgotPasswordModal())
         })
         .finally(() => {
            setIsLoading(false)
         })
   }, [])

   return (
      <div className='mt-3 pr-2 pl-2'>
         <div className='mb-3 w-full items-center text-center sm:mb-8'>
            <p className='text-coolGray-600'>{t('reset_pass.descr')}</p>
         </div>
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className='space-y-4'>
               <FloatingLabelInput
                  label={t('reset_pass.email')}
                  type='email'
                  name={'email'}
                  register={register}
                  rules={{
                     required: t('error.required'),
                     pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t('error.invalid_email'),
                     },
                  }}
                  errors={errors}
                  watch={watch}
                  submitOnEnter={true}
               />
            </div>
            {errorMessage.length > 0 && <p className='error-validation'>{errorMessage}</p>}
            <div className='mt-5 space-y-2'>
               <div className='flex gap-x-2'>
                  <button
                     type='submit'
                     className={`sign-confirm-button ${!isValid ? 'gray-out' : ''}`}
                     disabled={!isValid}
                  >
                     {t('reset_pass.confirm')}
                  </button>
               </div>
            </div>
         </form>
         {isLoading && <LoadingIndicator />}
      </div>
   )
}
export default ForgotPasswordMobile
