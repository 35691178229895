import React, { lazy, useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { useAppSelector } from '@/config/store'
import { isAdmin, isModOrHigher, isUserLoggedIn } from '@/util/auth-util'
import { LoadingBalls } from '@/layout/common/LoadingIndicator'
import { ErrorPage, NotFoundPage } from '@/config/ErrorBoundary'
import { StaticContentType } from '@/layout/static-page/StaticContentType'
import { isMobile } from 'react-device-detect'
import BecomeChefStaticPage from '@/layout/static-page/BecomeChefStaticPage'
import HomeMapChefsMobile from '@/layout/home/HomeMapChefsMobile'
import StaticContentView from '@/layout/static-page/StaticContentView'
import UserDetailsView from '@/layout/user/UserDetailsView'
import UserPanel from '@/layout/user/UserPanel'
import ReplyToCommentMobile from '@/layout/post/comment/mobile/ReplyToCommentMobile'
import Favourites from '@/layout/favourites/Favourites'
import NotificationsPage from '@/layout/notification/NotificationsPage'
import CreateFood from '@/layout/food/add-edit/CreateFood'
import EditFood from '@/layout/food/add-edit/EditFood'
import FoodByIdView from '@/layout/chef/FoodByIdView'
import ShoppingCard from '@/layout/shopping_cart/ShoppingCard'
import PaymentConfirmation from '@/layout/payment/PaymentConfirmation'
import PaymentAndDeliveryView from '@/layout/shopping_cart/PaymentAndDeliveryView'
import ConfigureStripeAccountForChef from '@/layout/add-chef/ConfigureStripeAccountForChef'
import RegisterGoogle from '@/layout/login_register/RegisterGoogle'
import LoginPageMobile from '@/layout/login_register/LoginPageMobile'
import ResetPass from '@/layout/user/ResetPass'
import PersonalizeFeed from '@/layout/user/PersonalizeFeed'
import EmailChangeAndVerification from '@/layout/user/EmailChangeAndVerification'
import ConfirmEmail from '@/layout/user/ConfirmEmail'
import ForgotPasswordMobile from '@/layout/login_register/ForgotPasswordMobile'
import RegisterPageMobile from '@/layout/login_register/RegisterPageMobile'
import { SkeletonLandingPage } from '@/layout/landing_page/LandingPage'
import { ChefDetailSkeleton } from '@/layout/chef/ChefDetail'

// Lazy-loaded components
const FullScreenChat = lazy(() => import('@/layout/chat/FullScreenChat'))
const ChefDetail = lazy(() => import('@/layout/chef/ChefDetail'))
const AdminPanel = lazy(() => import('@/layout/administration/admin-panel/AdminPanel'))
const CreateChefPage = lazy(() => import('@/layout/add-chef/CreateChefPage'))
const MyOrders = lazy(() => import('@/layout/my-orders/MyOrders'))
const HomeListChefsMobile = lazy(() => import('@/layout/home/HomeListChefsMobile'))
const HomeDesktop = lazy(() => import('@/layout/home/HomeDesktop'))
const LandingPage = lazy(() => import('@/layout/landing_page/LandingPage'))
const AddPostRouteView = lazy(() => import('@/layout/post/AddPostRouteView'))
const PostEditView = lazy(() => import('@/layout/post/edit/PostEdit'))
const BlogSection = lazy(() => import('@/layout/post/BlogSection'))
const SinglePostViewDesktop = lazy(() => import('@/layout/post/singlePost/desktop/SinglePostViewDesktop'))
const SinglePostViewMobile = lazy(() => import('@/layout/post/singlePost/mobile/SinglePostViewMobile'))
const ChefDashboard = lazy(() => import('@/layout/chef/dashboard/ChefDashboard'))
const ChefOrders = lazy(() => import('@/layout/chef/dashboard/ChefOrders'))
const ChefPayments = lazy(() => import('@/layout/chef/dashboard/ChefPayments'))

const PrivateRoute = ({ element, check }) => {
   const [isChecking, setIsChecking] = useState(true)
   const [hasPermission, setHasPermission] = useState(false)
   const loggedAccount = useAppSelector(state => state.authentication.account)

   useEffect(() => {
      const performCheck = async () => {
         const permission = check(loggedAccount)
         setHasPermission(permission)
         setIsChecking(false)
      }

      performCheck()
   }, [loggedAccount, check])

   if (isChecking) return <LoadingBalls />
   return hasPermission ? element : <Navigate to='/' />
}

const MainRouteHandleBasedOnUserTypeAndLocation = () => {
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const { currentUserLocation } = useAppSelector(state => state.search)

   if (loggedAccount?.activeChef) {
      return <Navigate to='/dashboard' />
   }

   const isLoggedInOrHasLocation = isUserLoggedIn(loggedAccount) || currentUserLocation
   return isLoggedInOrHasLocation
      ? getLazyLoaded(isMobile ? <HomeListChefsMobile /> : <HomeDesktop />)
      : getLazyLoaded(<LandingPage />, <SkeletonLandingPage />)
}

// Function to wrap elements in Suspense for lazy loading
function getLazyLoaded(element: React.ReactNode, skeletonElement?: React.ReactNode) {
   return <React.Suspense fallback={skeletonElement || <LoadingBalls />}>{element}</React.Suspense>
}

export const AppRoutes = ({ layout }) => {
   return (
      <BrowserRouter>
         <Routes>
            <Route element={layout} errorElement={<ErrorPage />}>
               {/* Home & Landing */}
               <Route path='/' element={<MainRouteHandleBasedOnUserTypeAndLocation />} />
               <Route path='/home/*' element={getLazyLoaded(isMobile ? <HomeListChefsMobile /> : <HomeDesktop />)} />
               <Route path='/map/*' element={<HomeMapChefsMobile />} />
               <Route path='/landing' element={getLazyLoaded(<LandingPage />, <SkeletonLandingPage />)} />

               {/* Chefs by cities */}
               <Route
                  path='/city/:city'
                  element={getLazyLoaded(
                     isMobile ? <HomeListChefsMobile cityMode={true} /> : <HomeDesktop cityMode={true} />,
                  )}
               />

               {/* Chat */}
               <Route path='/chat' element={getLazyLoaded(<FullScreenChat />)} />
               <Route path='/chat-with-user/:userId' element={getLazyLoaded(<FullScreenChat />)} />

               {/* Static Content */}
               <Route
                  path='/terms-and-conditions'
                  element={<StaticContentView contentType={StaticContentType.TERMS_AND_CONDITIONS} showFooter={true} />}
               />
               <Route
                  path='/privacy-policy'
                  element={<StaticContentView contentType={StaticContentType.PRIVACY_POLICY} showFooter={true} />}
               />
               <Route
                  path='/cookies'
                  element={<StaticContentView contentType={StaticContentType.COOKIES_POLICY} showFooter={true} />}
               />
               <Route
                  path='/deleted'
                  element={<StaticContentView contentType={StaticContentType.DELETE_MY_ACCOUNT} showFooter={true} />}
               />
               <Route
                  path='/about-us'
                  element={<StaticContentView contentType={StaticContentType.ABOUT_US} showFooter={true} />}
               />
               <Route
                  path='/contact'
                  element={<StaticContentView contentType={StaticContentType.CONTACT_US} showFooter={true} />}
               />
               <Route path='/become-a-chef' element={<BecomeChefStaticPage />} />
               <Route
                  path='/become-a-pick-up-point'
                  element={
                     <StaticContentView contentType={StaticContentType.BECOME_A_PICK_UP_POINT} showFooter={true} />
                  }
               />
               <Route
                  path='/welcome'
                  element={
                     <StaticContentView contentType={StaticContentType.SUCCESS_CONFIRM_EMAIL} showFooter={true} />
                  }
               />
               <Route
                  path='/forgot-pass-next'
                  element={<StaticContentView contentType={StaticContentType.AFTER_FORGOT_PASS_INFO} />}
               />
               <Route
                  path='/success-confirm-email'
                  element={
                     <StaticContentView contentType={StaticContentType.SUCCESS_CONFIRM_EMAIL} showFooter={true} />
                  }
               />

               {/* User Management */}
               <Route path='/user-details/:login' element={<UserDetailsView />} />

               {/* Admin */}
               <Route
                  path='/admin/*'
                  element={<PrivateRoute element={getLazyLoaded(<AdminPanel />)} check={isAdmin} />}
               />

               {/* User Panel & Feed */}
               <Route path='/user-panel' element={<UserPanel />} />
               <Route
                  path='/change-email'
                  element={<PrivateRoute element={<EmailChangeAndVerification />} check={isUserLoggedIn} />}
               />
               <Route
                  path='/personalize-feed'
                  element={<PrivateRoute element={<PersonalizeFeed />} check={isUserLoggedIn} />}
               />

               {/* Authentication */}
               <Route path='/account/activate/:token' element={<ConfirmEmail />} />
               <Route path='/account/reset-pass/:token' element={<ResetPass />} />
               <Route path='/forgot-password' element={<ForgotPasswordMobile />} />
               <Route path='/register-google' element={<RegisterGoogle />} />
               <Route path='/register' element={<RegisterPageMobile />} />
               <Route path='/login' element={<LoginPageMobile />} />

               {/* User Actions */}
               <Route path='/favourites' element={<Favourites />} />
               <Route path='/notifications' element={<NotificationsPage />} />
               <Route path='/reply-to-comment/:parentCommentId' element={<ReplyToCommentMobile />} />

               {/* Chef Management */}
               <Route path='/become-chef' element={getLazyLoaded(<CreateChefPage />)} />
               <Route path='/register-chef-account' element={getLazyLoaded(<CreateChefPage />)} />
               <Route path='/edit-chef/:id' element={getLazyLoaded(<CreateChefPage />)} />
               <Route path='/chef/:login' element={getLazyLoaded(<ChefDetail />, <ChefDetailSkeleton />)} />
               <Route
                  path='/add-review/:login'
                  element={getLazyLoaded(<ChefDetail addReview={true} />, <ChefDetailSkeleton />)}
               />
               <Route path='/pick-up-point/:login' element={getLazyLoaded(<ChefDetail />, <ChefDetailSkeleton />)} />
               <Route path='/embedded/:login' element={getLazyLoaded(<ChefDetail />, <ChefDetailSkeleton />)} />

               {/* Pick Up Points Management */}
               <Route path='/add-pick-up-point' element={getLazyLoaded(<CreateChefPage pickUpPointMode={true} />)} />
               <Route
                  path='/edit-pick-up-point/:id'
                  element={getLazyLoaded(<CreateChefPage pickUpPointMode={true} />)}
               />

               {/* Food Management */}
               <Route path='/create-food/:chefId' element={<CreateFood />} />
               <Route path='/food/:id' element={<FoodByIdView />} />
               <Route path='/edit-food/:foodId' element={<EditFood />} />

               {/* Shopping & Payment */}
               <Route path='/shopping-card' element={<ShoppingCard />} />
               <Route path='/shopping-card/chefId' element={<ShoppingCard />} />
               <Route path='/payment/:id' element={<PaymentAndDeliveryView />} />
               <Route path='/confirmation/:id' element={<PaymentConfirmation />} />
               <Route path='/configure-stripe' element={<ConfigureStripeAccountForChef />} />
               <Route path='/my-orders' element={getLazyLoaded(<MyOrders />)} />

               {/* Dashboard */}
               <Route path='/dashboard' element={<ChefDashboard confirmMode={false} />} />
               <Route path='/confirm-chef' element={<ChefDashboard confirmMode={true} />} />
               <Route path='/confirm-chef-complete' element={<ChefDashboard confirmMode={true} />} />
               <Route path='/chef-orders' element={<ChefOrders />} />
               <Route path='/chef-payments' element={<ChefPayments />} />

               {/* Blog */}
               <Route
                  path='/add-post'
                  element={<PrivateRoute element={getLazyLoaded(<AddPostRouteView />)} check={isAdmin} />}
               />
               <Route
                  path='/post-edit/:postId'
                  element={<PrivateRoute element={getLazyLoaded(<PostEditView />)} check={isAdmin} />}
               />
               <Route path='/blog' element={getLazyLoaded(<BlogSection />)} />
               <Route
                  path='/blog/:id'
                  element={getLazyLoaded(isMobile ? <SinglePostViewMobile /> : <SinglePostViewDesktop />)}
               />

               {/* Fallback */}
               <Route path='*' element={<NotFoundPage />} />
            </Route>
         </Routes>
      </BrowserRouter>
   )
}
