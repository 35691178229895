import React, { Suspense } from 'react'
import { isBrowser, isMobile } from 'react-device-detect'
import appLogo from '../../../content/images/logo.svg'
import { Link } from 'react-router-dom'

const Header = () => {
   const HeaderComponent = React.lazy(() =>
      isMobile ? import('@/layout/header/HeaderMobile') : import('@/layout/header/HeaderDesktop'),
   )

   const skeletonHeader = (
      <header
         className={`background-normal border-bottom-custom fixed top-0 z-20 mx-auto flex w-full min-w-[120px] flex-1 flex-row items-center justify-between py-1 shadow-xs ${isMobile ? 'h-[50px]' : 'h-[64px]'}`}
      >
         {isBrowser && (
            <Link to={'/'} className='ml-2 flex flex-row items-center hover:cursor-pointer'>
               <img src={appLogo} alt='App Logo' className='max-h-[40px]' />
            </Link>
         )}
      </header>
   )

   return (
      <Suspense fallback={skeletonHeader}>
         <HeaderComponent />
      </Suspense>
   )
}

export default Header
