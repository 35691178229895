import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { fetchDiscoverMoreChefs } from '@/service/chef.service'
import { Chef } from '@/model/chef.model'
import { isDesktop } from 'react-device-detect'
import { SingleChefCard } from '@/layout/home/maps/SingleChefCard'
import { ChefsListMobile } from '@/layout/home/maps/ChefsListMobile'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa6'
import { Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import { getChefsForPickUpPoint } from '@/service/pick-up-point.service'
import { sectionHeader } from '@/layout/chef/ChefDetail'

export const DiscoverMoreChefs = ({
   longitude,
   latitude,
   excludedChefId,
   isPickUpPoint,
}: {
   longitude: number
   latitude: number
   excludedChefId: string
   isPickUpPoint?: boolean | undefined
}) => {
   const { t } = useTranslation()
   const navigate = useNavigate()
   const [chefs, setChefs] = useState<Chef[]>([])
   const [expanded, setExpanded] = useState(true)
   const [loading, setLoading] = useState(false)
   const [error, setError] = useState<string | null>(null)
   const hasFetched = useRef(false);

   const fetchChefs = useCallback(async () => {
      if (hasFetched.current) return;
      try {
         setLoading(true)
         const response = isPickUpPoint
            ? await getChefsForPickUpPoint(excludedChefId)
            : await fetchDiscoverMoreChefs(longitude, latitude, excludedChefId)
         setChefs(response.data)
         setError(null)
         hasFetched.current = true;
      } catch (error) {
         console.error('Error fetching discover more chefs', error)
         setError('Failed to load chefs.')
      } finally {
         setLoading(false)
      }
   }, [excludedChefId, isPickUpPoint, latitude, longitude])

   useEffect(() => {
      if (expanded && excludedChefId) {
         fetchChefs()
      }
   }, [expanded, excludedChefId, fetchChefs])

   const handleToggle = () => setExpanded(prev => !prev)

   return (
      <div className='flex flex-col'>
         {sectionHeader(
            <div className={`flex flex-row items-center hover:cursor-pointer`} onClick={handleToggle}>
               {t(isPickUpPoint ? 'chef_profile.show_chefs_who_deliver_to_the_point' : 'chef_profile.discover_more_chefs')}
               {expanded ? (
                  <FaChevronUp className='text-customPurple ml-2' />
               ) : (
                  <FaChevronDown className='text-customPurple ml-2' />
               )}
            </div>
         )}
         {loading && (
            <div className='my-4 flex items-center justify-center'>
               <Spin />
            </div>
         )}
         {error && <p className='text-red-500 ml-2'>{error}</p>}
         <div
            className='relative mt-2 min-h-[400px] w-full transition-all duration-300'
            style={{ maxHeight: expanded ? '100%' : '0px', overflow: 'hidden' }}
         >
            {expanded &&
               !loading &&
               chefs.length > 0 &&
               (isDesktop ? (
                  <div className='grid w-full grid-cols-1 gap-6 sm:grid-cols-2'>
                     {chefs.map(chef => (
                        <SingleChefCard key={chef.id} fullWidth={true} chef={chef} starrClicked={starred => {}} />
                     ))}
                  </div>
               ) : (
                  <ChefsListMobile chefs={chefs} starrClicked={() => {}} />
               ))}
            {expanded && chefs.length > 0 && !isPickUpPoint && (
               <button
                  className='text-customPurple mt-4 mb-4 w-full text-center text-xl font-semibold hover:cursor-pointer hover:underline'
                  onClick={() => navigate('/home')}
               >
                  {t('chef_profile.see_all_chefs')}
               </button>
            )}
            {expanded && chefs.length === 0 && !loading && (
               <div className='mt-4 mb-4 w-full text-center text-gray-500'>
                  {t(isPickUpPoint ? 'chef_profile.no_chefs_for_pick_up_point' : 'chef_profile.no_chefs')}
               </div>
            )}
         </div>
      </div>
   )
}
