import { StaticContentType } from '@/layout/static-page/StaticContentType'
import React from 'react'
import StaticContentView from '@/layout/static-page/StaticContentView'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

export const BecomeChefStaticPage = () => {
   const { t } = useTranslation()
   const navigate = useNavigate()

   return (
      <StaticContentView
         additionalElement={
            <div className='mt-4 mb-2 text-center'>
               <button
                  data-cy='register-as-chef'
                  onClick={() => navigate('/register-chef-account')}
                  className={
                     'bg-customPurple hover:bg-customPurpleDark w-auto rounded-md p-2.5 px-4 text-white hover:cursor-pointer'
                  }
               >
                  {t('landing_page.register_your_chef_profile')}
               </button>
            </div>
         }
         textStyles={`p-4 lg:px-6 mx-auto max-w-6xl items-center ${isMobile ? 'mt-[60px]' : 'mt-[70px]'}`}
         showFooter={true}
         withoutStyles={true}
         contentType={StaticContentType.BECOME_A_CHEF}
      />
   )
}

export default BecomeChefStaticPage
