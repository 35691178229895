import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useAppDispatch } from '@/config/store'
import { logout, resetPasswordFinish } from '@/service/authentication'
import { LoadingIndicator } from '../common/LoadingIndicator'
import { isDesktop } from 'react-device-detect'

const ResetPass = () => {
   const { token } = useParams()
   const [loading, setLoading] = useState(false)
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const navigate = useNavigate()

   const {
      register,
      handleSubmit,
      reset,
      formState: { errors, isValid },
      getValues,
   } = useForm<any>({ mode: 'all' })

   // { newPassword: pass, key: key }
   const onSubmit = (values: any) => {
      const newPassword = values.new
      const newPasswordConfirm = values.new_confirm

      if (newPassword !== newPasswordConfirm) {
         toast.error(t('change_pass.do_not_match'))
         return
      }
      logout()
      setLoading(true)
      resetPasswordFinish({ key: token, newPassword: newPassword })
         .then(result => {
            navigate('/')
            toast.success(t('change_pass.success'))
         })
         .finally(() => {
            setLoading(false)
         })
   }

   return (
      <div className={`flex w-full justify-center ${isDesktop ? 'mt-[70px]' : 'mt-[60px]'}`}>
         <form
            className='background-normal rounded-normal flex w-full max-w-2xl flex-col p-1.5 shadow-lg'
            onSubmit={handleSubmit(onSubmit)}
         >
            <h1 className='mt-2 text-center text-xl font-semibold'>{t('activation.reset_pass')}</h1>
            <div className='flex flex-1 items-center justify-start px-2'>
               <p className='paragraph-common'>{t('change_pass.new')}</p>
               <div className='flex flex-1 flex-col'>
                  <input
                     className='background-gray-dark-normal rounded-normal m-2 flex-1 p-2 outline-hidden'
                     name='new'
                     {...register('new', {
                        required: true,
                        minLength: {
                           value: 8,
                           message: t('error.min_length', { min: 8 }),
                        },
                     })}
                     type='password'
                     placeholder={t('change_pass.new_placeholder')}
                  />
                  {errors.new && errors.new.message ? (
                     <p className='red-deep-text mt-1'>
                        {typeof errors.new.message === 'string' ? errors.new.message : null}
                     </p>
                  ) : null}
               </div>
            </div>
            <div className='flex flex-1 items-center justify-start px-2'>
               <p className='paragraph-common'>{t('change_pass.new')}*</p>
               <div className='flex flex-1 flex-col'>
                  <input
                     className='background-gray-dark-normal rounded-normal m-2 flex-1 p-2 outline-hidden'
                     name='new_confirm'
                     {...register('new_confirm', {
                        validate: value => value === getValues('new') || t('error.password_mismatch'),
                     })}
                     type='password'
                     placeholder={t('change_pass.new_confirm_placeholder')}
                  />
                  {errors.new_confirm && errors.new_confirm.message && (
                     <p className='red-deep-text mt-1'>{String(errors.new_confirm.message)}</p>
                  )}
               </div>
            </div>
            <div className='px-4'>
               <button
                  type='submit'
                  className={`small-confirm-button mt-4 mb-4 ${!isValid ? 'gray-out' : ''}`}
                  disabled={!isValid}
               >
                  {t('common.submit')}
               </button>
            </div>

            {loading && <LoadingIndicator />}
         </form>
      </div>
   )
}
export default ResetPass
