import { RestClient } from '@/config/rest-client'
import { Food } from '@/model/order/food.model'

export const createFood = (food: Food) => {
   return RestClient.post<Food>('/api/food', food)
}

export const updateFood = (food: Food) => {
   return RestClient.put<Food>('/api/food', food)
}

export const getFoodById = (id: string) => {
   return RestClient.get<Food>('/api/food/' + id)
}

export const getFoodsForChef = (chefIc: string) => {
   return RestClient.get<Food[]>('/api/food/by-chef-id/' + chefIc)
}

export const starrFood = (id: string) => {
   return RestClient.post('api/food/starr/' + id)
}

export const unstarrFood = (id: string) => {
   return RestClient.post('api/food/unstarr/' + id)
}

export const deleteFood = (id: string) => {
   return RestClient.delete('api/food/' + id)
}

export const deactivateFood = (id: string) => {
   return RestClient.post('api/food/deactivate/' + id)
}

export const activateFood = (id: string) => {
   return RestClient.post('api/food/activate/' + id)
}
