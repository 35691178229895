import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SubComment } from '../SubComment'
import { PiArrowBendDownRightBold } from 'react-icons/pi'
import AddEditCommentComponentWeb from '@/layout/post/comment/desktop/AddEditCommentComponentWeb'
import { ISubcomment } from '@/model/subcomment.model'
import { isElementInViewport } from '@/util/other-utils'
import { getSubcommentsByParentId } from '@/service/comment/comment-reducer'
import { COMMENT_SORT_BY } from '@/service/search'
import { LoadingBalls } from '@/layout/common/LoadingIndicator'

interface PreviousAfterSubcommentListProps {
   allSubcommentsCount: number
   olderCommentsCount: number
   preloadedAfterSubcomments: ISubcomment[]

   parentId: string
   afterCommentAdd: () => void
   triggerEditComment: any
   replyActiveMainCommentUser: any
   postId: string
   mainCommentDate: any
   subcommentSortBy: COMMENT_SORT_BY
}

export const SubcommentsListWeb = ({
   preloadedAfterSubcomments,
   allSubcommentsCount,
   olderCommentsCount,

   parentId,
   afterCommentAdd,
   triggerEditComment,
   replyActiveMainCommentUser,
   postId,
   mainCommentDate,
   subcommentSortBy,
}: PreviousAfterSubcommentListProps) => {
   const { t } = useTranslation()
   const [replyToSubcomment, setReplyToSubcomment] = useState<ISubcomment>(null)
   const addCommentRef = useRef(null)

   const [subcomments, setSubcomments] = useState<ISubcomment[]>(preloadedAfterSubcomments)

   const [loadingBefore, setLoadingBefore] = useState(false)
   const [loadingAfter, setLoadingAfter] = useState(false)

   const [hasOlderComments, setHasOlderComments] = useState(false)
   const [hasAfterComments, setHasAfterComments] = useState(false)

   const [beforePagination, setBeforePagination] = useState({
      fromDate: mainCommentDate,
      parentId: parentId,
      page: 0,
      sort: subcommentSortBy,
      total: olderCommentsCount,
      commentsCount: 0,
      forBefore: true,
   })

   const [afterPagination, setAfterPagination] = useState({
      fromDate: mainCommentDate,
      parentId: parentId,
      page: preloadedAfterSubcomments.length > 0 ? 1 : 0,
      sort: subcommentSortBy,
      total: allSubcommentsCount - olderCommentsCount,
      commentsCount: preloadedAfterSubcomments.length,
      forBefore: false,
   })

   useEffect(() => {
      setHasOlderComments(beforePagination.commentsCount < beforePagination.total)
   }, [beforePagination])

   useEffect(() => {
      setHasAfterComments(afterPagination.commentsCount < afterPagination.total)
   }, [afterPagination])

   useEffect(() => {
      if (subcommentSortBy == beforePagination.sort) return

      setBeforePagination(prev => ({ ...prev, sort: subcommentSortBy }))

      const newAfterPagination = {
         ...afterPagination,
         sort: subcommentSortBy,
         page: 0,
         commentsCount: 0,
         total: allSubcommentsCount,
      }

      setAfterPagination(newAfterPagination)
   }, [subcommentSortBy])

   useEffect(() => {
      if (replyToSubcomment || replyActiveMainCommentUser) {
         if (addCommentRef.current && !isElementInViewport(addCommentRef.current))
            addCommentRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
   }, [replyToSubcomment, replyActiveMainCommentUser])

   function loadOlderComments() {
      setLoadingBefore(true)
      return getSubcommentsByParentId(beforePagination)
         .then(result => {
            // const total = result.headers['x-total-count'];
            // console.log("It has subcomments count ", total)
            setBeforePagination(prev => ({
               ...prev,
               page: beforePagination.page + 1,
               commentsCount: prev.commentsCount + result.data.length,
            }))
            setSubcomments([...result.data, ...subcomments])
         })
         .finally(() => {
            setLoadingBefore(false)
         })
   }

   function loadMoreAfter(pagination, withReset = false) {
      setLoadingAfter(true)
      return getSubcommentsByParentId(pagination)
         .then(result => {
            setAfterPagination(prev => ({
               ...prev,
               page: afterPagination.page + 1,
               commentsCount: prev.commentsCount + result.data.length,
            }))

            if (withReset) setSubcomments(result.data)
            else setSubcomments([...subcomments, ...result.data])
         })
         .finally(() => {
            setLoadingAfter(false)
         })
   }

   return (
      <div className='flex flex-col'>
         {loadingBefore && <LoadingBalls />}
         <div
            role='button'
            hidden={!hasOlderComments}
            onClick={() => {
               loadOlderComments()
            }}
            className='mt-1 ml-2'
         >
            <p className='commentMoreButton'>{t('subcomments.show_before')}</p>
         </div>

         {/* Display After Subcomments */}
         <div className='flex flex-col py-0.5'>
            {subcomments &&
               subcomments?.length > 0 &&
               subcomments.map((sub: ISubcomment) => (
                  <SubComment
                     key={sub.id}
                     subcomment={sub}
                     triggerAddReply={() => {
                        setReplyToSubcomment(sub)
                     }}
                     triggerEditComment={() => {
                        triggerEditComment(sub)
                     }}
                  />
               ))}
         </div>

         <div className='rounded-normal mb-0 ml-[10px] h-[20px]' hidden={!hasAfterComments} role='button'>
            <div className='hover-color flex shrink-0 flex-row px-2' onClick={() => loadMoreAfter(afterPagination)}>
               <div className='mt-1.5 mb-0'>
                  <PiArrowBendDownRightBold />
               </div>
               <div className='commentMoreButton ml-2'>{t('subcomments.show_more')}</div>
            </div>
         </div>

         {loadingAfter && <LoadingBalls />}

         {(replyToSubcomment || replyActiveMainCommentUser) && (
            <div className='' ref={addCommentRef}>
               <AddEditCommentComponentWeb
                  postId={postId}
                  commentId={parentId}
                  afterCommentAdd={com => {
                     afterCommentAdd()
                     setSubcomments([...subcomments, com])
                     setAfterPagination(prev => ({
                        ...prev,
                        total: prev.total + 1,
                        commentsCount: prev.commentsCount + 1,
                     }))
                  }}
                  userToReply={replyToSubcomment?.user != null ? replyToSubcomment?.user : replyActiveMainCommentUser}
               />
            </div>
         )}
      </div>
   )
}
