import { RestClient } from '@/config/rest-client'

const apiUrl = '/api/'

export const observeContent = async observeContent => {
   return RestClient.post<any>(apiUrl + 'observe-content', observeContent)
}

export const deleteObserveContent = async observeContent => {
   return RestClient.post<any>(apiUrl + 'observe-content/delete', observeContent)
}

export const observedContent = async () => {
   return RestClient.get<any>(apiUrl + 'observed-content')
}
