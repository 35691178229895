import { RestClient } from '@/config/rest-client'
import { ICommunity } from '@/model/community.model'
import { CommunitySort } from '@/service/search'

const apiUrl = '/api/communities'

export const getCommunityById = (communityId: string) => {
   const requestUrl = `${apiUrl}/${communityId}`
   return RestClient.get<any[]>(requestUrl)
}
export const getCommunityByShortName = (shorName: string) => {
   const requestUrl = `${apiUrl}/by-short-name/${shorName}`
   return RestClient.get<ICommunity>(requestUrl)
}
export const searchCommunities = ({ page, size, sort, phrase }) => {
   const requestUrl = `${apiUrl}/search?page=${page}&size=${size}&sort=${sort}&phrase=${phrase}`
   return RestClient.get<any[]>(requestUrl)
}

export const createCommunity = (entity: ICommunity) => {
   console.log('Creating community:', entity)

   // Send the POST request using axios
   return RestClient.post<ICommunity>(`${apiUrl}/create`, entity)
      .then(response => {
         // Handle success
         console.log('Community created successfully:', response.data)

         // Assuming the API returns a message or community data in the response
         return response.data // This will be passed to the caller
      })
      .catch(error => {
         // Handle errors
         if (error.response) {
            // Server responded with a status other than 200 range
            console.error('Error response:', error.response.data)

            // Optionally, display or return an error message
            throw new Error(error.response.data.message || 'An error occurred while creating the community')
         } else if (error.request) {
            // Request was made, but no response received
            console.error('No response from the server:', error.request)
            throw new Error('No response from the server. Please try again later.')
         } else {
            // Something happened in setting up the request
            console.error('Request error:', error.message)
            throw new Error(error.message)
         }
      })
}

export const updateCommunity = (entity: ICommunity) => {
   return RestClient.put<ICommunity>(`${apiUrl}/update/${entity.id}`, entity)
}

export type CommunitiesConfig = { sort: CommunitySort; grouped: boolean; communities: ICommunity[] }
export const getJoinedCommunities = () => {
   const requestUrl = `${apiUrl}/joined`
   return RestClient.get<CommunitiesConfig>(requestUrl)
}

export const updateCommunitiesConfig = (sort: CommunitySort, grouped: boolean) => {
   const fmData = new FormData()
   fmData.append('sort', sort)
   fmData.append('grouped', grouped.toString())
   return RestClient.post(`${apiUrl}/personalize`, fmData)
}

export const getCommunitiesToConfigure = (name: string, sort: string) => {
   const params = new URLSearchParams({ sort })
   if (name) params.append('name', name)
   const requestUrl = `${apiUrl}/to-configure?${params.toString()}`
   return RestClient.get<CommunitiesConfig>(requestUrl)
}

export const joinCommunity = (communityId: string) => {
   const fmData = new FormData()
   fmData.append('communityId', communityId)
   return RestClient.post(`${apiUrl}/join`, fmData)
}
export const leaveCommunity = (communityId: string) => {
   const fmData = new FormData()
   fmData.append('communityId', communityId)
   return RestClient.post(`${apiUrl}/leave`, fmData)
}

export const alignPostsCountForAllCommunities = () => {
   return RestClient.post(`${apiUrl}/align-posts-count`)
}

export const addToFavourites = (communityId: string) => {
   const fmData = new FormData()
   fmData.append('communityId', communityId)
   return RestClient.post(`${apiUrl}/add-to-favourites`, fmData)
}

export const removeFromFavourites = (communityId: string) => {
   const fmData = new FormData()
   fmData.append('communityId', communityId)
   return RestClient.post(`${apiUrl}/remove-from-favourites`, fmData)
}
