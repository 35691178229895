import React from 'react'

interface ProfileSettingRowProps {
   label: string
   value: string
   buttonText?: string
   onClick?: () => void
   showButton?: boolean
}

export const ProfileSettingRow: React.FC<ProfileSettingRowProps> = ({
   label,
   value,
   buttonText,
   onClick,
   showButton = true,
}) => (
   <div className='profile-column'>
      <div className='flex-column'>
         <p className='xs:text-xl mt-2 mb-1 text-lg sm:mt-0 sm:mb-0'>{label}</p>
         <p className='xs:text-base text-sm text-gray-500 dark:text-gray-400'>{value}</p>
      </div>
      {showButton && buttonText && (
         <button className='profile-button' onClick={onClick}>
            {buttonText}
         </button>
      )}
   </div>
)
