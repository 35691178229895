import React, { useEffect, useState } from 'react'
import { getChefById, getChefByLogin, suspendChef, unsuspendChef } from '@/service/chef.service'
import { isMongoDb } from '@/util/other-utils'
import { ReviewsForChef } from '@/layout/chef/ReviewsForChef'
import { useTranslation } from 'react-i18next'
import { Chef } from '@/model/chef.model'
import { ChefBannerInfoInChefDetails } from '@/layout/chef/ChefBannerInfoInChefDetails'
import { getFoodsForChef } from '@/service/food.service'
import { Food } from '@/model/order/food.model'
import { isMobile } from 'react-device-detect'
import { useAppSelector } from '@/config/store'
import { AddFoodModalMobile } from '@/layout/food/AddFoodModalMobile'
import { FoodsForChef } from '@/layout/chef/FoodsForChef'
import { isAdmin, isUserLoggedIn } from '@/util/auth-util'
import { DiscoverMoreChefs } from '@/layout/chef/DiscoverMoreChefs'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import PageFooter from '@/layout/landing_page/PageFooter'
import { toast } from 'react-toastify'
import ReadOnlyLexical from '@/layout/common/lexical-editor/ReadOnlyLexical'
import { ChefAddress } from '@/layout/chef/ChefAddress'

interface ChefDetailProps {
   addReview?: boolean
   propChef?: Chef
}

export const ChefDetail = ({ addReview = false, propChef = null }: ChefDetailProps) => {
   const { t } = useTranslation()
   const navigate = useNavigate()
   const { login } = useParams()
   const location = useLocation()
   const loggedAccount = useAppSelector(state => state.authentication.account)

   const [userLookingAtHisProfile, setUserLookingAtHisProfile] = useState(true)
   const locationState = location.state as { propChef?: Chef }
   const initialChef = locationState?.propChef || propChef

   const [chef, setChef] = useState<Chef | null>(initialChef || null)
   const [foods, setFoods] = useState<Food[] | null>(null)
   const [foodToAddMobile, setFoodToAddMobile] = useState<Food | null>(null)
   const [hasNavigated, setHasNavigated] = useState(false)
   const [showAddressDetails, setShowAddressDetails] = useState(false)

   // Fetch chef when login changes
   useEffect(() => {
      if (!login) {
         console.error('No login parameter provided in the URL.')
         return
      }
      const mongoDb = isMongoDb(login)
      const fetchChef = mongoDb ? getChefById(login) : getChefByLogin(login)
      fetchChef
         .then(response => {
            setChef(response.data)
            if (!isUserLoggedIn(loggedAccount) || response.data?.id !== loggedAccount?.id) {
               setUserLookingAtHisProfile(false)
            }
         })
         .catch(error => {
            console.error('Error fetching chef data:', error)
         })
   }, [login, loggedAccount])

   // Fetch foods when chef changes
   useEffect(() => {
      if (chef) {
         getFoodsForChef(chef.id)
            .then(foodResponse => {
               setFoods(foodResponse.data)
            })
            .catch(error => {
               console.error('Error fetching foods for chef:', error)
            })
      }
   }, [chef])

   // Other useEffect hooks remain unchanged
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [login])

   useEffect(() => {
      if (!chef || !isMongoDb(login) || hasNavigated) return
      const targetPath = addReview ? `/add-review/${chef.shortName}` : `/chef/${chef.shortName}`
      if (location.pathname !== targetPath) {
         navigate(targetPath, { replace: true, state: { propChef: chef } })
         setHasNavigated(true)
      }
   }, [chef, login, hasNavigated, addReview, location.pathname, navigate])

   useEffect(() => {
      if (chef?.name) {
         document.title = `Chef - ${chef.name}`
      }
   }, [chef])

   function manageProfileSection() {
      return loggedAccount?.id == chef?.id || isAdmin(loggedAccount) ? (
         <div className={`mt-4 flex flex-col rounded-lg bg-white p-4 pb-6 shadow-md`}>
            <h1 className={'text-xl font-bold'}>{t('chef_profile.manage_your_account')}</h1>
            <div className='flex w-full justify-start py-2'>
               <button
                  data-cy={'add-food'}
                  onClick={() => {
                     navigate(`/create-food/${chef?.id}`)
                  }}
                  className='bg-customPurple hover:bg-customPurpleDark mt-4 min-w-[120px] rounded-md p-2 font-semibold text-white hover:cursor-pointer'
               >
                  {t((foods?.length ?? 0) > 0 ? 'chef_profile.add_more_food' : 'chef_profile.add_first_food')}
               </button>
            </div>

            {loggedAccount?.id == chef?.id && chef?.active == false && (
               <div className='mt-2 flex flex-col justify-start'>
                  <h1 className='pb-1.5 text-2xl font-semibold'>{t('chef_profile.inactive_chef')}</h1>
                  <button
                     className='max-w-max rounded-md bg-blue-500 px-4 py-2 text-white hover:cursor-pointer hover:bg-blue-700'
                     onClick={() => {
                        navigate('/confirm-chef')
                     }}
                  >
                     {t('chef_profile.activate_stripe')}
                  </button>
               </div>
            )}

            {loggedAccount?.id == chef?.id && chef?.active == true && (
               <div className='mt-2 flex flex-col justify-start'>
                  <button
                     className='max-w-max rounded-md bg-blue-500 px-4 py-2 text-white hover:cursor-pointer hover:bg-blue-700'
                     onClick={() => {
                        navigate('/confirm-chef')
                     }}
                  >
                     {t('chef_profile.go_to_your_orders')}
                  </button>
               </div>
            )}

            <div className={'mt-4 flex flex-row justify-start'}>
               <button
                  className='max-w-max rounded-md bg-yellow-500 px-4 py-2 text-white hover:cursor-pointer hover:bg-yellow-600'
                  onClick={() => {
                     {
                        if (chef?.suspended) {
                           unsuspendChef(chef.id)
                              .then(() => {
                                 setChef({ ...chef, suspended: false })
                              })
                              .then(() => {
                                 toast.success(t('chef_profile.unsuspend_chef_success'))
                              })
                        } else {
                           suspendChef(chef.id)
                              .then(() => {
                                 setChef({ ...chef, suspended: true })
                              })
                              .then(() => {
                                 toast.success(t('chef_profile.suspend_chef_success'))
                              })
                        }
                     }
                  }}
               >
                  {chef?.suspended ? t('chef_profile.unsuspend_chef') : t('chef_profile.suspend_chef')}
               </button>
               <p className='mt-2 ml-2 text-base text-gray-600'>
                  {chef?.suspended
                     ? t('chef_profile.unsuspend_chef_description')
                     : t('chef_profile.suspend_chef_description')}
               </p>
            </div>
         </div>
      ) : (
         <></>
      )
   }

   return (
      <>
         <div
            id={'cd_' + chef?.id}
            className={`rounded-normal flex flex-col ${isMobile ? 'mt-[60px]' : 'mt-[70px] mb-8 p-4'}`}
         >
            <ChefBannerInfoInChefDetails
               chef={chef}
               showAddressClicked={() => {
                  setShowAddressDetails(true)
               }}
            />
            <div className='mx-auto w-full max-w-5xl items-center'>
               {manageProfileSection()}
               <div className={`flex flex-col space-y-6 ${isMobile ? 'p-1.5' : ''}`}>
                  <div className='mb-6'>
                     {foods === null ? (
                        <FoodsSkeleton />
                     ) : (
                        <FoodsForChef
                           foods={foods}
                           setFoodToAddMobile={setFoodToAddMobile}
                           chefSuspended={chef?.suspended}
                        />
                     )}
                  </div>

                  <div className='-mt-2 flex flex-col'>
                     <div className=''>{sectionHeader(t('chef_profile.about_me'))}</div>
                     <div className='background-normal rounded-normal mt-2 flex flex-col p-4 py-4 shadow-md'>
                        <ReadOnlyLexical htmlState={chef?.aboutMe} plainTextMode={false} />
                     </div>
                  </div>

                  <div className='flex flex-col pt-1 pb-1'>
                     <div className='mb-2 w-full'>{sectionHeader(t('chef_review.reviews'))}</div>
                     <ReviewsForChef
                        chef={chef}
                        onChefUpdate={c => {
                           setChef(c)
                        }}
                     />
                  </div>

                  {!userLookingAtHisProfile && (
                     <DiscoverMoreChefs
                        isPickUpPoint={chef?.pickUpPoint}
                        longitude={chef?.address?.longitude}
                        latitude={chef?.address?.latitude}
                        excludedChefId={chef?.id}
                     />
                  )}
               </div>
            </div>
         </div>
         <PageFooter />
         {foodToAddMobile && (
            <AddFoodModalMobile
               food={foodToAddMobile}
               onClose={() => {
                  setFoodToAddMobile(null)
                  // todo remove last from history
                  // history.back()
               }}
            />
         )}

         {showAddressDetails && (
            <ChefAddress
               address={chef?.address}
               onClose={() => {
                  setShowAddressDetails(false)
               }}
            />
         )}
      </>
   )
}

export default ChefDetail

export function sectionHeader(header: string | React.ReactNode) {
   return <div className={`pl-2 text-lg font-bold`}>{header}</div>
}

export const ChefDetailSkeleton = () => {
   return (
      <div className={`rounded-normal flex flex-col ${isMobile ? 'mt-[60px]' : 'mt-[70px] mb-0 p-4'}`}>
         <div className='rounded-normal relative mx-auto mt-1 h-80 w-full max-w-[1350px] bg-gray-300'></div>
         {/* Banner skeleton */}
         <div className='mt-4 space-y-6'>
            <FoodsSkeleton />
            <div className='h-60 w-full rounded-sm bg-gray-300'></div>
            {/* Foods skeleton */}
            <div className='h-8 w-1/3 rounded-sm bg-gray-300'></div>
            {/* About me heading skeleton */}
            <div className='h-24 w-full rounded-sm bg-gray-300'></div>
            {/* About me text skeleton */}
            <div className='h-48 w-full rounded-sm bg-gray-300'></div>
         </div>
      </div>
   )
}

export const FoodsSkeleton = () => (
   <div className='flex flex-col gap-4 md:flex-row'>
      <div className='h-[180px] w-full rounded-xl bg-gray-200 px-2 py-3 md:w-1/2'></div>
      <div className='h-[180px] w-full rounded-xl bg-gray-200 px-2 py-3 md:w-1/2'></div>
   </div>
)
