import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LoadingBalls } from '@/layout/common/LoadingIndicator'
import { getPaymentById } from '@/service/shopping-card.service'
import StripeContainer from '@/layout/payment/StripeContainer'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Order } from '@/model/order/order'
import ShoppingCardProgressBar from '@/layout/shopping_cart/ShoppingCardProgressBar'
import { OrdersForChef } from '@/layout/shopping_cart/OrdersForChef'

export const PaymentAndDeliveryView = () => {
   const { t } = useTranslation()
   const navigate = useNavigate()
   const { id } = useParams()
   const location = useLocation()
   const [loading, setLoading] = useState(true)
   const [foodPayment, setFoodPayment] = useState<Order>()

   useEffect(() => {
      const paymentFromState = location.state?.paymentObject

      if (paymentFromState) {
         // Use the payment object from state
         setFoodPayment(paymentFromState)
         sessionStorage.setItem(`payment-${id}`, JSON.stringify(paymentFromState))
         setLoading(false)
      } else {
         // Fallback to check session storage
         const storedPayment = sessionStorage.getItem(`payment-${id}`)
         if (storedPayment) {
            setFoodPayment(JSON.parse(storedPayment))
            setLoading(false)
         } else {
            // Fetch payment from backend
            getPaymentById(id)
               .then(response => {
                  setFoodPayment(response.data)
                  sessionStorage.setItem(`payment-${id}`, JSON.stringify(response.data))
               })
               .catch(() => {
                  setFoodPayment(undefined) // Payment is not valid
               })
               .finally(() => {
                  setLoading(false)
               })
         }
      }
   }, [id, location.state])

   if (!foodPayment?.clientSecret && !loading) {
      return (
         <div className='flex w-full flex-col px-2 pb-2'>
            <ShoppingCardProgressBar step={1} />
            <div className='mt-6 text-center text-2xl text-red-500'>{t('payment_view.error')}</div>
            <div
               onClick={() => {
                  navigate('/')
               }}
               className='mt-3 text-center text-xl text-blue-500 hover:cursor-pointer'
            >
               {t('payment_view.explore_chefs')}
            </div>
         </div>
      )
   }

   return (
      <div className='flex w-full flex-col px-2 pb-2'>
         <ShoppingCardProgressBar step={1} />
         <div className='rounded-normal background-normal mt-6 p-2 pr-2 shadow-md sm:px-4'>
            <div className='mt-0.5 ml-2 text-center text-2xl font-semibold'>{t('payment_view.title')}</div>

            {loading ? (
               <div className='mt-4 flex items-center justify-center'>
                  <LoadingBalls />
               </div>
            ) : (
               <div className='mt-3'>
                  <OrdersForChef order={foodPayment} />
               </div>
            )}
         </div>

         {!loading && <StripeContainer secret={foodPayment?.clientSecret} paymentId={foodPayment?.id} />}
      </div>
   )
}

export default PaymentAndDeliveryView
