import { RestClient } from '@/config/rest-client'
import { CustomAddress } from '@/model/custom-address.model'
import { Suggestion } from '@/layout/add-chef/CreateChefPage'

export function mapAddresses(addresses: NominatimResponse[]): CustomAddress[] {
   const uniquePlacesMap = new Map<string, NominatimResponse>()

   addresses.forEach(item => {
      const existingItem = uniquePlacesMap.get(item.display_name)

      const isHigherHierarchy =
         item.address.city ||
         (item.address.town && !existingItem?.address.city) ||
         (item.address.village && !existingItem?.address.city && !existingItem?.address.town)

      if (!existingItem || isHigherHierarchy) {
         uniquePlacesMap.set(item.display_name, item)
      }
   })

   return Array.from(uniquePlacesMap.values()).map((item: NominatimResponse) => ({
      longitude: parseFloat(item.lon),
      latitude: parseFloat(item.lat),
      id: item.place_id,
      street: item.address.road,
      villageTownCity:
         item.address.village ||
         item.address.town ||
         item.address.city ||
         item.address.municipality ||
         item.address.province,
      stateOrRegion: item.address.state || item.address.region || item.address.province,
      country: item.address.country,
      display_name: item.display_name,
   }))
}

export function mapCustomAddressToSuggestion(address: CustomAddress): Suggestion {
   let header1 = ''
   let header2 = ''

   if (address.street) {
      // If street is present, show it in header1
      header1 = address.street
      header2 = address.villageTownCity
   }
   // Ensure header2 contains the country, adding a delimiter if needed
   if (address.stateOrRegion) {
      header2 = header2
         ? `${header2}, ${address.stateOrRegion}, ${address.country}`
         : `${address.stateOrRegion}, ${address.country}`
   } else {
      header2 = header2 ? `${header2}, ${address.country}` : address.country
   }

   // Make sure header1 is never empty, fallback to city if needed
   if (!header1) {
      header1 = address.villageTownCity
   }

   return {
      id: address.id,
      header1,
      header2,
   }
}

export const fetchSuggestions = (address: string, locale: string) => {
   return RestClient.get<NominatimResponse[]>('https://nominatim.openstreetmap.org/search', {
      params: {
         q: address,
         format: 'json',
         addressdetails: 1,
         limit: 5,
         linkedplaces: 1,
         countrycodes: 'fr,be,lu,de,it,es,pt,ch,nl',
      },
      headers: {
         'Accept-Language': locale,
         'User-Agent': 'OhLesChefs/1.0 (contact@ohleschefs.com)', // Custom User-Agent for your app
      },
   })
}

export interface NominatimResponse {
   place_id: number
   licence: string
   osm_type: string
   osm_id: number
   lat: string
   lon: string
   class: string
   type: string
   place_rank: number
   importance: number
   addresstype: string
   name: string
   display_name: string
   address: {
      village?: string
      municipality?: string
      county?: string
      state?: string
      region?: string
      ISO3166_2_lvl4?: string
      postcode?: string
      country: string
      country_code: string
      tourism?: string
      road?: string
      town?: string
      city?: string
      ISO3166_2_lvl6?: string
      peak?: string
      province?: string
   }
   boundingbox: [string, string, string, string]
}

export const fetchSingleLocationName = (latitude: number, longitude: number, locale: string) => {
   return RestClient.get<NominatimResponse>('https://nominatim.openstreetmap.org/reverse', {
      params: {
         lat: latitude,
         lon: longitude,
         format: 'json',
         addressdetails: 1, // Include detailed address components
         limit: 1, // Request only one result
         countrycodes: 'fr,be,lu,de,it,es,pt,ch,nl',
      },
      headers: {
         'Accept-Language': locale, // Localized response
         'User-Agent': 'OhLesChefs/1.0 (contact@ohleschefs.com)',
      },
   })
}
