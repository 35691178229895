import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../../config/store'
import { changeAboutMe } from '../../../service/user-settings-reducer'
import { getSession } from '../../../service/authentication'
import { LoadingIndicator } from '../../common/LoadingIndicator'
import { getNumberOfSignsWithValidation } from '../../../util/text-utils'

export const ChangeAboutMe = ({ onConfirm }) => {
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   const {
      register,
      handleSubmit,
      getValues,
      watch,
      formState: { errors },
   } = useForm<any>()
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const [aboutMe] = useState(loggedAccount.aboutMe !== null ? loggedAccount.aboutMe : '')
   const [descriptionRows, setDescriptionRows] = useState(1)
   const dispatch = useAppDispatch()
   const MAX_ABOUT_ME_SIGNS = 150
   const MIN_ABOUT_ME_ROWS = 3

   const onFinish = (values: any) => {
      setIsLoading(true)
      changeAboutMe({ aboutMe: values.aboutMe })
         .then(result => {
            dispatch(getSession).then(_ => {
               onConfirm()
            })
         })
         .finally(() => {
            setIsLoading(false)
         })
   }

   useEffect(() => {
      let aboutMe = getValues('aboutMe')
      if (aboutMe === undefined) {
         if (loggedAccount.aboutMe != null && loggedAccount.aboutMe.length > 0) {
            aboutMe = loggedAccount.aboutMe
         } else {
            setDescriptionRows(1)
            return
         }
      }
      const rowlen = aboutMe.split('\n').length
      const MAX_DESCRIPTION_ROWS = 11
      if (rowlen > MAX_DESCRIPTION_ROWS) {
         setDescriptionRows(MAX_DESCRIPTION_ROWS)
      } else if (rowlen <= MIN_ABOUT_ME_ROWS) {
         setDescriptionRows(MIN_ABOUT_ME_ROWS)
      } else {
         setDescriptionRows(rowlen)
      }
   }, [watch('aboutMe'), loggedAccount.aboutMe])

   //todo refactor common with eddit post description
   return (
      <form className='border-gray-custom rounded-normal mb-2 max-w-xl' onSubmit={handleSubmit(onFinish)}>
         <div className='flex flex-1 justify-start px-2'>
            <p className='paragraph-common mt-4'>{t('profile_settings.about_you')}</p>
            <div className='flex flex-1 flex-col'>
               <textarea
                  style={{ resize: 'none' }}
                  defaultValue={aboutMe}
                  rows={descriptionRows}
                  className='background-gray-dark-normal m-2 flex-1 rounded-2xl p-2 outline-hidden'
                  name='aboutMe'
                  {...register('aboutMe', { maxLength: MAX_ABOUT_ME_SIGNS })}
               />
               {getNumberOfSignsWithValidation(getValues('aboutMe'), MAX_ABOUT_ME_SIGNS, '')}
               {errors.aboutMe && (
                  <p className='red-deep-text mt-2 mb-0 ml-2.5'>
                     {t('sign.errors.description.' + errors.aboutMe.type)}
                  </p>
               )}
            </div>
         </div>
         <button type='submit' className='small-confirm-button'>
            {t('common.submit')}
         </button>
         {isLoading && <LoadingIndicator />}
      </form>
   )
}
