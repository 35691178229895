import React, { useEffect, useState } from 'react'
import { RcFile, UploadProps } from 'antd/es/upload/interface'
import { UploadChangeParam } from 'antd/es/upload'
import { getBase64, UploadPhoto } from '../common/PhotoUpload'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { savePhotoToCDN } from '@/service/photo/photo-reducer'
import { PhotoType } from '@/model/enumerations/photo-type'

// todo refactor
interface PicturesWallProps {
   onImageSaved?: (imageId: string) => void
   entityId?: string
   beforeUpload?: (file: RcFile) => boolean
   resetView?: number
   predefinedImageUrl?: string
   photoType: PhotoType
   placeholderText?: string
   tempId?: string
}

export const PicturesWall = ({
   onImageSaved = imageId => {},
   entityId = null,
   beforeUpload = file => {
      console.log(file)
      return true
   },
   resetView = 0,
   predefinedImageUrl = null,
   photoType,
   placeholderText,
   tempId,
}: PicturesWallProps) => {
   const { t } = useTranslation()
   const [loading, setLoading] = useState(false)
   const [imageUrl, setImageUrl] = useState<string>(predefinedImageUrl)

   useEffect(() => {
      setImageUrl(predefinedImageUrl)
   }, [predefinedImageUrl])

   useEffect(() => {}, [imageUrl])

   const uploadImage = async options => {
      const { onSuccess, file } = options
      savePhotoToCDN({ photo: file, entityId, photoType, tempId })
         .then(data => {
            onImageSaved(data.data)
            onSuccess('Ok')
         })
         .catch(reason => {
            if (reason.response.data.detail == 'invalidFormat') {
               toast.error('')
               toast.error(t('error.invalid_type'))
            } else {
               toast.error(t('common.error_contact_us'))
            }
         })
   }

   useEffect(() => {
      if (resetView > 0) setImageUrl(null)
   }, [resetView])

   const handleChange: UploadProps['onChange'] = (info: UploadChangeParam) => {
      if (info.file.status === 'uploading') {
         setLoading(true)
         return
      }
      if (info.file.status === 'done') {
         // Get this url from response in real world.
         getBase64(info.file.originFileObj, url => {
            setLoading(false)
            setImageUrl(url)
         })
      }
   }

   // @ts-ignore
   return (
      <div>
         <UploadPhoto
            imageUrl={imageUrl}
            loading={loading}
            uploadImage={uploadImage}
            placeholderText={placeholderText}
            beforeUpload={beforeUpload}
            handleChange={handleChange}
         />
         {/*todo use in the future*/}
         {/*{progress > 0 ? <Progress percent={progress}/> : null}*/}
         {/*<DeletePhotoButton setImageUrl={setImageUrl} loggedAccount={loggedAccount} imageUrl={imageUrl}/>*/}
      </div>
   )
}
