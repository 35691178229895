import React, { useCallback, useState } from 'react'
import { DeliveryType } from '@/model/enumerations/delivery-type.model'
import { AbstractWebModal } from '@/layout/common/AbstractWebModal'
import { Chef } from '@/model/chef.model'
import { FoodOrderItem } from '@/model/order/FoodOrderItem'
import { isMobile } from 'react-device-detect'
import { AbstractMobileModal } from '@/layout/common/AbstractMobileModal'
import { useTranslation } from 'react-i18next'
import { DateTimePickerForOrder } from '@/layout/shopping_cart/DateTimePickerForOrder'

interface DeliveryTypeSelectorProps {
   availableDeliveryTypes: DeliveryType[]
   chef: Chef
   onDeliveryDetailsSelect: (deliveryType: DeliveryType, date: string, time: string, pickUpPointId: string) => void
   orders: FoodOrderItem[]
   selectedDate: string
   selectedTime: string
}

export const DeliveryTypeSelector: React.FC<DeliveryTypeSelectorProps> = ({
   availableDeliveryTypes,
   chef,
   onDeliveryDetailsSelect,
   orders,
}) => {
   const { t } = useTranslation()
   const [selectedDeliveryType, setSelectedDeliveryType] = useState<DeliveryType | null>(null)
   const [selectedDate, setSelectedDate] = useState<string | null>(null)
   const [selectedTime, setSelectedTime] = useState<string | null>(null)
   const [selectedPickUpPointId, setSelectedPickUpPointId] = useState<string | null>(null)
   const [chooseDateTimeOpen, setChooseDateTimeComponentOpen] = useState(false)

   const handleDeliveryTypeClick = useCallback(
      (deliveryType: DeliveryType) => {
         if (selectedDeliveryType != deliveryType) {
            setSelectedDate(null)
            setSelectedTime(null)
            setSelectedDeliveryType(deliveryType)
         }
         setChooseDateTimeComponentOpen(true)
      },
      [selectedDeliveryType],
   )

   const handleDateTimeSelect = useCallback(
      (date: string, time: string, pickUpPointId: string) => {
         setSelectedDate(date)
         setSelectedTime(time)
         setChooseDateTimeComponentOpen(false) // Close the modal
         setSelectedPickUpPointId(pickUpPointId)
         onDeliveryDetailsSelect(selectedDeliveryType, date, time, pickUpPointId)
      },
      [selectedDeliveryType, onDeliveryDetailsSelect, selectedPickUpPointId],
   )

   const isSingleOption = availableDeliveryTypes.length === 1

   return (
      <div className='mt-2'>
         <h4 className='text-xl font-semibold'>{t('shopping_cart.pick_delivery_type')}</h4>
         <div
            className={`mt-2 flex`}
            // Remove centering styles when there's only one option
            style={isSingleOption ? { maxWidth: '50%' } : {}}
         >
            {availableDeliveryTypes.map(deliveryType => (
               <div
                  key={deliveryType}
                  // Conditionally include 'flex-1' based on isSingleOption
                  className={`${!isSingleOption ? 'flex-1' : ''} m-0.5 sm:m-1 lg:m-2 flex h-[66px] min-w-[100px] flex-col justify-center rounded-lg ${isMobile ? 'border-[2px] px-1.5': 'border-[3.5px] px-3.5'} py-2 hover:cursor-pointer hover:bg-gray-200 ${selectedDeliveryType === deliveryType ? 'border-customPurpleDark bg-gray-200' : 'border-customPurple bg-gray-50'}`}
                  onClick={() => handleDeliveryTypeClick(deliveryType)}
               >
                  <p
                     className={` ${isMobile ? 'text-xs xs:text-sm': 'text-sm lg:text-base'}  ${selectedDeliveryType === deliveryType ? 'font-semibold' : ''} ${selectedDate && selectedTime ? '' : 'text-center'} `}
                  >
                     {deliveryType === DeliveryType.DINE_IN && t('shopping_cart.dine_in')}
                     {deliveryType === DeliveryType.PICKUP && t('shopping_cart.pickup')}
                     {deliveryType === DeliveryType.DELIVER_TO_PICKUP_POINT && t('shopping_cart.pick_up_point')}
                  </p>
                  {selectedDate && selectedTime && deliveryType == selectedDeliveryType && (
                     <>
                        <p className={`text-xs sm:text-sm`}>
                           {t('shopping_cart.on')} {selectedDate} {t('shopping_cart.at')} {selectedTime}
                        </p>
                     </>
                  )}
               </div>
            ))}
         </div>

         {chooseDateTimeOpen && selectedDeliveryType && (
            <>
               {isMobile ? (
                  <AbstractMobileModal
                     increaseZIndex={true}
                     customNavigation={() => {
                        setChooseDateTimeComponentOpen(false)
                        if (selectedDeliveryType && !selectedDate && !selectedTime) setSelectedDeliveryType(null)
                     }}
                     title={<div className='mt-1 text-xl font-medium'>{t('shopping_cart.select_date_time')}</div>}
                  >
                     <div className='mt-4'>
                        <DateTimePickerForOrder
                           onDateTimeSelect={handleDateTimeSelect}
                           chef={chef}
                           orders={orders}
                           deliveryType={selectedDeliveryType}
                           preSelectedDeliveryType={selectedDeliveryType}
                           preSelectedDate={selectedDate}
                           preSelectedTime={selectedTime}
                        />
                     </div>
                  </AbstractMobileModal>
               ) : (
                  <AbstractWebModal
                     title={t('shopping_cart.select_date_time')}
                     onClose={() => {
                        setChooseDateTimeComponentOpen(false)
                        if (selectedDeliveryType && !selectedDate && !selectedTime) setSelectedDeliveryType(null)
                     }}
                  >
                     <DateTimePickerForOrder
                        onDateTimeSelect={handleDateTimeSelect}
                        chef={chef}
                        orders={orders}
                        deliveryType={selectedDeliveryType}
                        preSelectedDeliveryType={selectedDeliveryType}
                        preSelectedDate={selectedDate}
                        preSelectedTime={selectedTime}
                     />
                  </AbstractWebModal>
               )}
            </>
         )}
      </div>
   )
}
