import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging, getToken, Messaging, onMessage } from 'firebase/messaging'
import { PushNotifications } from '@capacitor/push-notifications'
import { Capacitor } from '@capacitor/core'
import { registerFCMToken } from '@/service/notification/notification-service'
import { toast } from 'react-toastify'
import { FCM_VAPID_KEY } from '@/env'

const firebaseConfig = {
   apiKey: 'AIzaSyBuuLcjEoA9ggRLGnynCXXKwrSYVvx7lQo',
   authDomain: 'oh-les-chefs-73f3c.firebaseapp.com',
   projectId: 'oh-les-chefs-73f3c',
   storageBucket: 'oh-les-chefs-73f3c.appspot.com',
   messagingSenderId: '906799778101',
   appId: '1:906799778101:web:f1d0dba05607b10f1d647c',
   measurementId: 'G-KD7EKL82TH',
}

const nativeMode = Capacitor.isNativePlatform()

export const setupFCM = async () => {
   if (nativeMode) {
      await setupMobileFCM()
   } else {
      return await setupWebFCM()
   }
}

async function setupMobileFCM() {
   try {
      let permStatus = await PushNotifications.checkPermissions()
      if (permStatus.receive === 'prompt') {
         permStatus = await PushNotifications.requestPermissions()
      }

      if (permStatus.receive !== 'granted') {
         console.error('User denied permissions!')
         return
      }

      PushNotifications.register()

      PushNotifications.addListener('registration', async token => {
         if (token && token.value) {
            console.log('FCM Token (Mobile):', token.value)
            await registerFCMToken(token.value)
         } else {
            console.error('No registration token received.')
         }
      })

      PushNotifications.addListener('registrationError', error => {
         console.error('Registration Error:', JSON.stringify(error))
      })

      PushNotifications.addListener('pushNotificationReceived', notification => {
         console.log('Notification received:', JSON.stringify(notification))
      })

      PushNotifications.addListener('pushNotificationActionPerformed', action => {
         console.log('Notification action performed:', JSON.stringify(action))
      })
   } catch (error) {
      console.error('Error initializing push notifications:', error)
   }
}

async function setupWebFCM() {
   const app = initializeApp(firebaseConfig)
   if (!nativeMode) getAnalytics(app)

   let messaging: Messaging | undefined

   try {
      messaging = getMessaging(app)
   } catch (err) {
      console.error('An error occurred while initializing Firebase messaging: ', err)
   }
   if (!messaging) {
      console.error('FCM messaging not initialized.')
      return
   }

   const existingToken = localStorage.getItem('fcm_token')
   if (existingToken) return existingToken

   const permission = await Notification.requestPermission()
   if (permission === 'granted') {
      try {
         const currentToken = await getToken(messaging, {
            vapidKey: FCM_VAPID_KEY,
         })
         if (currentToken) {
            console.log('FCM Token (Web):', currentToken)
            localStorage.setItem('fcm_token', currentToken)
            await registerFCMToken(currentToken)
            console.log('FCM token registered for web.')

            onMessage(messaging, payload => {
               console.log('Message received (Web):', payload)

               if (payload.notification) {
                  // todo if it is an order etc, then increase order count etc.
                  const { title, body } = payload.notification
                  // If the page is currently focused
                  if (document.hasFocus()) {
                     toast.success(title + ': ' + body)
                  } else {
                     // Show a system notification
                     new Notification(title, {
                        body,
                        icon: '/chef-hat.svg',
                     })
                  }
               }
            })
         } else {
            console.log('No registration token available.')
         }
      } catch (err) {
         console.log('An error occurred while retrieving token: ', err)
      }
   } else {
      console.log('Unable to get permission to notify on web.')
   }
}
