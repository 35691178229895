import React, { useState } from 'react'
import { useMapEvents } from 'react-leaflet'
import { MdMyLocation } from 'react-icons/md'
import { updateUserLocation } from '@/service/search'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { roundCoord } from '@/util/UrlUtils'
import { getCurrentPosition } from '@/util/UserLocalizations'
import { fetchShortAddressName } from '@/service/address.service'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { fetchSingleLocationName } from '@/service/open-street-map.service'

interface GoToMyLocationButtonProps {
   onNearestAddressChange?: (address: string) => void
   shouldFetchAddress?: boolean
   addedStyles?: string
   setMarkerPosition?: (position: [number, number]) => void
}

export const GoToMyLocationButton = ({
   onNearestAddressChange,
   shouldFetchAddress = true,
   addedStyles,
   setMarkerPosition,
}: GoToMyLocationButtonProps) => {
   const { t } = useTranslation()
   const language = useAppSelector(state => state.localeSlice.lng)
   const { currentUserLocation } = useAppSelector(state => state.search)
   const [isLoading, setIsLoading] = useState(false)
   const dispatch = useAppDispatch()
   const map = useMapEvents({})
   const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated)

   const handleClick = () => {
      setIsLoading(true)
      getCurrentPosition()
         .then(position => {
            const newCoordinates: [number, number] = [
               roundCoord(position?.coords?.latitude),
               roundCoord(position?.coords?.longitude),
            ]

            updateUserLocation({
               location: {
                  latitude: newCoordinates[0],
                  longitude: newCoordinates[1],
                  locationName: currentUserLocation.locationName || '',
               },
               shouldSaveLocation: isAuthenticated,
            })

            fetchSingleLocationName(newCoordinates[0], newCoordinates[1], language)
               .then(response => {
                  const address = response.data

                  dispatch(
                     updateUserLocation({
                        location: {
                           latitude: newCoordinates[0],
                           longitude: newCoordinates[1],
                           locationName: address.display_name || '',
                           villageTownCity: address.address?.village || address.address?.town || address.address?.city,
                        },
                        shouldSaveLocation: isAuthenticated,
                     }),
                  )
               })
               .catch(error => {
                  console.error('Error fetching location name:', error)
               })
               .finally(() => {
                  setIsLoading(false)
               })

            if (map) {
               const currentBounds = map.getBounds()
               if (currentBounds && currentBounds.getSouthWest && currentBounds.getNorthEast) {
                  map.flyTo(newCoordinates, 13, {
                     animate: true,
                     duration: 1.5,
                  })
               } else {
                  console.error('Map bounds are not properly initialized.')
               }
            }

            if (shouldFetchAddress) {
               fetchShortAddressName(newCoordinates[0], newCoordinates[1]).then(address => {
                  if (onNearestAddressChange) {
                     onNearestAddressChange(address)
                  }
               })
            }

            if (setMarkerPosition) setMarkerPosition(newCoordinates)
         })
         .catch(error => {
            if (error.code === error.PERMISSION_DENIED) {
               toast.error(t('location.permission_denied'))
            } else if (error.code === error.POSITION_UNAVAILABLE) {
               toast.error(t('location.position_unavailable'))
            } else if (error.code === error.TIMEOUT) {
               toast.error(t('location.timeout'))
            } else {
               toast.error(t('location.unknown_error'))
            }
            console.error('Error getting current position:', error)
         })
         .finally(() => {
            setIsLoading(false)
         })
   }

   return (
      <div className={`leaflet-bottom leaflet-right mb-20 pb-2 ${addedStyles}`}>
         <div className={`leaflet-control leaflet-bar ${isLoading ? 'cursor-not-allowed bg-gray-300' : 'bg-white'}`}>
            <MdMyLocation
               onClick={isLoading ? undefined : handleClick}
               className={`p-1 text-blue-500 ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
               size={30}
            />
         </div>
      </div>
   )
}
