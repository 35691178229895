import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import {
   getCommunitiesToConfigure,
   joinCommunity,
   leaveCommunity,
   updateCommunitiesConfig,
} from '@/service/community-service'
import { HiOutlineChevronDown } from 'react-icons/hi2'
import { CommunitySort } from '@/service/search'
import { LoadingBalls } from '@/layout/common/LoadingIndicator'
import { ICommunity } from '@/model/community.model'
import { Switch } from '@/layout/common/CustomSwitch'
import { toast } from 'react-toastify'
import { REFRESH_TARGET, refreshPage } from '@/service/refresher-reducer'
import { useDispatch } from 'react-redux'
import { PiPlus } from 'react-icons/pi'
import { useNavigate } from 'react-router-dom'
import { ITopicCommunity } from '@/model/category-community.model'
import { getCommunityTopics } from '@/service/community-topics-service'
import { getLocalStorageItem, LocalStorageKey, setLocalStorageItem } from '@/util/local-storage-utils'
import { CgArrowBottomRightR } from 'react-icons/cg'

const PersonalizeFeed = ({ browseMode = false }) => {
   const { t } = useTranslation()
   const dispatch = useDispatch()

   const containerRef = useRef(null)

   const [isInitialLoad, setIsInitialLoad] = useState(true)
   const [loading, setLoading] = useState(false)

   const [communities, setCommunities] = useState<ICommunity[]>([])
   const [categories, setCategories] = useState<ITopicCommunity[]>([])
   const [byTopicCategorizedMode, setByTopicCategorizedMode] = useState(
      getLocalStorageItem(LocalStorageKey.CATEGORIZED_MODE, false),
   )
   const [editableShortName, setEditableShortName] = useState('')
   const [sortOpen, setSortOpen] = useState(false)
   const [communitySort, setCommunitySort] = useState(
      getLocalStorageItem(LocalStorageKey.COMMUNITY_SORT, CommunitySort.NAME),
   )

   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   useEffect(() => {
      setLocalStorageItem(LocalStorageKey.COMMUNITY_SORT, communitySort)

      const delayDebounce = setTimeout(() => {
         if (categories.length === 0) return

         setLoading(true)

         searchForCommunities()
      }, 300)
      return () => clearTimeout(delayDebounce)
   }, [editableShortName, communitySort])

   useEffect(() => {
      if (!byTopicCategorizedMode) {
         setLoading(true)
         searchForCommunities()
      } else {
         setLoading(true)
         getCommunityTopics()
            .then(value => {
               searchForCommunities()
               setCategories(value.data)
            })
            .finally(() => {
               setLoading(false)
            })
      }
   }, [byTopicCategorizedMode])

   useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
         document.removeEventListener('mousedown', handleClickOutside)
      }
   }, [])

   const handleClickOutside = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) setSortOpen(false)
   }

   function searchForCommunities() {
      setCommunities([])
      getCommunitiesToConfigure(editableShortName, communitySort)
         .then(res => {
            setCommunities(res.data.communities)
            setByTopicCategorizedMode(res.data.grouped)
            setLocalStorageItem(LocalStorageKey.CATEGORIZED_MODE, res.data.grouped)
            setCommunitySort(res.data.sort)
         })
         .then(() => {
            setLoading(false)
         })
   }

   function getCommunitiesSort() {
      return (
         <div className='flex flex-row items-center'>
            <label className='rounded-normal mr-2 flex flex-row items-center bg-gray-100 px-3 py-2 text-sm dark:bg-gray-800'>
               <Switch
                  onChange={(checked: boolean) => {
                     setByTopicCategorizedMode(checked)
                     setLocalStorageItem(LocalStorageKey.CATEGORIZED_MODE, checked)

                     if (browseMode) return

                     setIsInitialLoad(false)

                     updateCommunitiesConfig(communitySort, checked).then(r => {
                        dispatch(refreshPage(REFRESH_TARGET.COMMUNITIES_REFRESH))
                     })
                  }}
                  checked={byTopicCategorizedMode}
                  disableAnimation={isInitialLoad}
               />
               <span className='ml-2'>{t('community_sort.group')}</span>
            </label>
            <div
               ref={containerRef}
               className='selected-bg xs:px-1.5 mr-0 flex h-8 w-[94px] flex-row flex-nowrap items-center justify-center rounded-2xl px-1 py-1 text-sm hover:cursor-pointer hover:bg-gray-300 sm:px-2.5 md:h-9 dark:bg-gray-800 dark:hover:bg-gray-800'
               style={{ position: 'relative' }}
            >
               <div className='mr-1 w-full' onClick={() => setSortOpen(!sortOpen)}>
                  <div className='flex w-full flex-row items-center justify-between py-0.5'>
                     <div className='whitespace-nowrap'>{t('community_sort.' + communitySort)}</div>
                     <HiOutlineChevronDown className='mr-[-4px] ml-0.5 h-3 w-3 shrink-0' />
                  </div>
                  {sortOpen && (
                     <div
                        className='border-gray-custom background-normal ml-[-7px] w-[80px] space-y-1.5 rounded-md px-1 py-1 shadow-xs transition-all duration-200 ease-in-out'
                        style={{ position: 'absolute', zIndex: 1, marginTop: '5px' }}
                     >
                        {Object.values(CommunitySort)
                           .filter(value => value !== communitySort)
                           .map(value => (
                              <div
                                 key={value}
                                 className={`dropdown-item hover-color ${value == communitySort ? 'selected' : ''}`}
                                 onClick={() => {
                                    setCommunitySort(value)
                                    setSortOpen(false)

                                    if (browseMode) return

                                    updateCommunitiesConfig(value, byTopicCategorizedMode).then(r => {
                                       dispatch(refreshPage(REFRESH_TARGET.COMMUNITIES_REFRESH))
                                    })
                                 }}
                              >
                                 {t('community_sort.' + value)}
                              </div>
                           ))}
                     </div>
                  )}
               </div>
            </div>
         </div>
      )
   }

   function addCommunityButton() {
      return (
         <div className='mr-4 mb-4 flex justify-end'>
            <button
               className='flex flex-row items-center rounded-2xl bg-blue-500 px-2 py-1 text-xs text-gray-100 hover:bg-blue-700 dark:bg-blue-700 dark:hover:bg-blue-900'
               onClick={() => {
                  window.location.href = '/add-community'
               }}
            >
               <PiPlus className='mr-1 h-6 w-6' />
               {t('communities.create_community')}
            </button>
         </div>
      )
   }

   return (
      <div className='rounded-normal background-normal mb-3 pt-4 pb-4'>
         {addCommunityButton()}
         <div className='mb-3 flex flex-row items-center px-4'>
            <input
               className='rounded-normal mr-1 w-full bg-gray-100 px-4 py-2 text-sm focus:outline-hidden sm:mr-4 dark:bg-gray-800'
               value={editableShortName}
               onChange={e => {
                  setEditableShortName(e.target.value)
               }}
               placeholder={t('add_your_post.choose_community')}
            />
            {getCommunitiesSort()}
         </div>

         <RenderCommunities
            communities={communities}
            communitySort={communitySort}
            byTopicMode={byTopicCategorizedMode}
         />

         {loading && <LoadingBalls />}
      </div>
   )
}
export default PersonalizeFeed

const RenderCommunities = ({ byTopicMode, communities, communitySort }) => {
   if (byTopicMode) {
      const groupedCommunities = groupCommunitiesByTopic(communities, communitySort)
      return groupedCommunities.map(entry => {
         return (
            <div key={entry.topic?.id}>
               <h3 className='pt-3 pb-2 text-center text-lg font-semibold'>{entry.topic?.name}</h3>
               <div className='flex flex-col gap-2'>
                  {entry.communities.map((community: ICommunity) => (
                     <div key={community.id} className='tab-hover flex items-center justify-between pr-3'>
                        {/*<CommunityEntry community={community} setToBeVisible={null} />*/}
                        <CommunityLeaveJoinButton community={community} />
                     </div>
                  ))}
               </div>
            </div>
         )
      })
   } else if (!byTopicMode) {
      return (
         <div className='flex flex-col gap-3'>
            {communities.map((community: ICommunity) => (
               <div key={community.id} className='tab-hover flex items-center justify-between pr-3'>
                  {/*<CommunityEntry community={community} setToBeVisible={null} />*/}
                  <CommunityLeaveJoinButton community={community} />
               </div>
            ))}
         </div>
      )
   }
}

export const groupCommunitiesByTopic = (communities: ICommunity[], sort: CommunitySort) => {
   // Initial empty array for grouped topics
   const grouped: { topic: ITopicCommunity; communities: ICommunity[] }[] = []

   // Temporary object to help with grouping
   const topicsMap: Record<string, { topic: ITopicCommunity; communities: ICommunity[] }> = {}

   communities.forEach(community => {
      if (community.topicId && community.topic) {
         // If the topic hasn't been added to the topicsMap, add it
         if (!topicsMap[community.topicId]) {
            topicsMap[community.topicId] = { topic: community.topic, communities: [] }
         }
         // Push the community into the correct topic
         topicsMap[community.topicId].communities.push(community)
      }
   })

   // Convert the topicsMap to the desired array structure
   for (const topicId in topicsMap) {
      grouped.push({
         topic: topicsMap[topicId].topic,
         communities: sortCommunities(topicsMap[topicId].communities, sort),
      })
   }
   return grouped
}

export function sortCommunities(communities: ICommunity[], sort: CommunitySort): ICommunity[] {
   switch (sort) {
      case CommunitySort.NAME:
         return communities.sort((a, b) => a.name.localeCompare(b.name))
      case CommunitySort.POPULAR:
         return communities.sort((a, b) => b.membersCount - a.membersCount)
      // case CommunitySort.JOINED:
      // 	return communities.sort((a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime());

      default:
         return communities
   }
}

export const CommunityLeaveJoinButton = ({ community }) => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const [isMember, setIsMember] = useState(community?.member)

   function joinOrLeaveComm() {
      if (isMember) {
         leaveCommunity(community.id)
            .then(() => {
               toast.success(t('communities.leaveSuccess'))
               setIsMember(false)
            })
            .finally(() => {
               dispatch(refreshPage(REFRESH_TARGET.COMMUNITIES_REFRESH))
            })
      } else {
         joinCommunity(community.id)
            .then(() => {
               toast.success(t('communities.joinSuccess'))
               setIsMember(true)
            })
            .finally(() => {
               dispatch(refreshPage(REFRESH_TARGET.COMMUNITIES_REFRESH))
            })
      }
   }

   return (
      <button
         className={
            'xs:px-3 right-10 mr-2 items-center justify-center rounded-2xl px-1 py-2 text-xs font-bold text-gray-100 ' +
            (isMember ? 'bg-red-600 hover:bg-red-700' : 'bg-green-600 hover:bg-green-700')
         }
         onClick={() => joinOrLeaveComm()}
      >
         {isMember ? t('communities.leave') : t('communities.join')}
      </button>
   )
}

export const CommunityEditButton = ({ communityShortName }) => {
   const { t } = useTranslation()
   const navigate = useNavigate()

   return (
      <button
         className={
            'xs:px-3 right-10 mr-2 flex flex-row items-center justify-center rounded-2xl px-1 py-2 text-xs font-bold text-gray-100 ' +
            'bg-blue-600 hover:bg-blue-700'
         }
         onClick={() => {
            navigate(`/community-edit/${communityShortName}`)
         }}
      >
         <CgArrowBottomRightR className='mr-1 h-4 w-4' />
         {t('communities.edit')}
      </button>
   )
}
