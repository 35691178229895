import { RestClient } from '@/config/rest-client'
import { Chef, ExtendedChef } from '@/model/chef.model'
import { IReview } from '@/model/review.model'
import { UserAndChefRegisterModel } from '@/model/UserAndChefRegister.model'
import { ChefCityType } from '@/model/custom-address.model'

const apiUrl = '/api/chef'

export const addChef = (chef: Chef) => {
   return RestClient.post<Chef>(`${apiUrl}`, chef)
}

export const registerUserAndAddChef = (userAndChef: UserAndChefRegisterModel) => {
   return RestClient.post<Chef>(`${apiUrl}/register-user-and-chef`, userAndChef)
}

export const editChef = (chef: Chef) => {
   return RestClient.put<Chef>(`${apiUrl}`, chef)
}

export interface MiniChef {
   id: string
   name: string
   avatar: string
   description: string
}

export const fullTextSearchChefs = (phrase: string, lon: number, lat: number) => {
   return RestClient.post<MiniChef[]>(`${apiUrl}/text-search`, { phrase, lon, lat })
}

export const fetchChefs = (
   bounds: [number[], number[]],
   cuisine: string[] | null,
   delivery: string[] | null,
   dietary: string[] | null,
   sort: string | null,
) => {
   const northEast = bounds[1]
   const southWest = bounds[0]
   return RestClient.get<Chef[]>(`${apiUrl}/by-coordinates`, {
      params: {
         ne_lat: northEast[0],
         ne_lng: northEast[1],
         sw_lat: southWest[0],
         sw_lng: southWest[1],
         // cuisine: cuisine || [],
         delivery: delivery || [],
         dietary: dietary || [],
         sort: sort?.toUpperCase(),
      },
   })
}
export const fetchDiscoverMoreChefs = (longitude: number, latitude: number, excludedChefId) => {
   return RestClient.get<Chef[]>(`${apiUrl}/discover-more-chefs/${longitude}/${latitude}/${excludedChefId}`)
}

// In chef.service.ts or wherever fetChefsByCity is defined
export const fetChefsByCity = (
   city: ChefCityType,
   cuisine?: string[],
   delivery?: string[],
   dietary?: string[],
   sort?: string,
) => {
   return RestClient.get<Chef[]>(`${apiUrl}/by-city/${city.toUpperCase()}`, {
      params: {
         cuisine: cuisine || [],
         delivery: delivery || [],
         dietary: dietary || [],
         sort,
      },
   })
}

export const fetchChefsByLocation = (
   lat: number,
   lon: number,
   kilometers: number,
   cuisine: string[] | null,
   delivery: string[] | null,
   dietary: string[] | null,
   sort: string | null,
) => {
   return RestClient.get<Chef[]>(`${apiUrl}/by-my-location`, {
      params: {
         lon,
         lat,
         km: kilometers,
         cuisine: cuisine || [],
         // cuisine: cuisine || [],
         delivery: delivery || [],
         dietary: dietary || [],
         sort,
      },
   })
}

export const fetchLandingPageChefs = () => {
   return RestClient.get<Chef[]>(`${apiUrl}/landing-page-chefs`)
}

export const getChefsForAdmin = (page: number, sort: string, size: number, filters) => {
   return RestClient.get<ExtendedChef[]>(`${apiUrl}/chefs-for-admin`, {
      params: {
         page,
         sort,
         size,
         filters,
      },
   })
}

export const refreshStripeAccount = (id: string) => {
   return RestClient.post<boolean>(`${apiUrl}/refresh-stripe-account/${id}`)
}

export const getChefByLogin = (login: string) => {
   return RestClient.get<Chef>(`${apiUrl}/by-shortName/${login}`)
}

export const starrChef = (id: string) => {
   return RestClient.post(`${apiUrl}/starr/${id}`)
}

export const unstarrChef = (id: string) => {
   return RestClient.post(`${apiUrl}/unstarr/${id}`)
}

export const unsuspendChef = (id: string) => {
   return RestClient.post(`${apiUrl}/unsuspend/${id}`)
}
// todo
export const suspendChef = (id: string) => {
   return RestClient.post(`${apiUrl}/suspend/${id}`)
}

export const getChefById = (id: string) => {
   return RestClient.get<Chef>(`${apiUrl}/by-id/${id}`)
}

export const addReview = (review: IReview) => {
   return RestClient.post<IReview>(`${apiUrl}/add-review`, review)
}

export const verifyChef = (id: string) => {
   return RestClient.post(`${apiUrl}/verify-chef/${id}`)
}

export const unverifyChef = (id: string) => {
   return RestClient.post(`${apiUrl}/unverify-chef/${id}`)
}

export const updateChefCity = (chefId: string, city: ChefCityType): Promise<any> => {
   return RestClient.post(`/api/chefs/update-city/${chefId}`, { city })
}
