import { RestClient } from '@/config/rest-client'
import { IComment } from '@/model/comment.model'
import { ISubcomment } from '@/model/subcomment.model'

const apiUrl = '/api/comments'

export const getCommentsByPostId = ({ query, page, sort, fromCommentId = '', forBefore = false, fromDate = '' }) => {
   const requestUrl =
      `${apiUrl}?page=${page}&sort=${sort}&postId=${query}` +
      (fromCommentId ? `&fromCommentId=${fromCommentId}` : '') +
      (forBefore !== false ? `&forBefore=${forBefore}` : '') +
      (fromDate !== null ? `&fromDate=${fromDate}` : '')
   return RestClient.get<IComment[]>(requestUrl)
}

export const getSubcommentsByParentId = ({ parentId, page, sort, fromDate = null, forBefore = false }) => {
   const requestUrl =
      `/api/subcomments?page=${page}&sort=${sort}&parentId=${parentId}` +
      (fromDate !== null ? `&fromDate=${fromDate}` : '') +
      (forBefore !== false ? `&forBefore=${forBefore}` : '')

   return RestClient.get<ISubcomment[]>(requestUrl)
}

export const getCommentsForUserId = (userId: string, page: number, sort: string) => {
   const requestUrl = `/api/comments-for-user?page=${page}&sort=${sort}&userId=${userId}`
   return RestClient.get<ISubcomment[]>(requestUrl)
}

export const createComment = (entity: IComment) => {
   return RestClient.post<IComment>(apiUrl, entity)
}

export const updateComment = (entity: IComment) => {
   return RestClient.put<IComment>(`${apiUrl}/${entity.id}`, entity)
}

export const voteForComment = async (body: any) => {
   return RestClient.post<any>(apiUrl + '/vote', body)
}

export const deleteComment = async (id: any) => {
   return RestClient.delete<any>(apiUrl + '/' + id)
}
export const undoDeleteComment = async (id: any) => {
   return RestClient.delete<any>(apiUrl + '/undo-delete/' + id)
}

export const saveCommentForTheUser = (commentId: string) => {
   return RestClient.post<any>(apiUrl + '/save/' + commentId)
}
export const undoSaveComment = (commentId: string) => {
   return RestClient.post<any>(apiUrl + '/undo-save/' + commentId)
}

export const getCommentsSavedForUser = ({ page, size }) => {
   const requestUrl = `${apiUrl}/saved?page=${page}&size=${size}`
   return RestClient.get<any[]>(requestUrl)
}
export const getCommentById = (commentId: string) => {
   const requestUrl = `${apiUrl}/by-id/${commentId}`
   return RestClient.get<IComment>(requestUrl)
}

export const getUsersVotedForComment = (commentId: string) => {
   return RestClient.get<any>(apiUrl + '/voted-users/' + commentId)
}
