import React from 'react'
import { AbstractWebModal } from '@/layout/common/AbstractWebModal'

/** @deprecated */
export const ConfirmModal = ({
   showModal,
   onConfirm,
   onCancel,
   confirmTranslated,
   headerTranslated,
   secondHeaderTranslated = null,
   cancelButtonTranslated = null,
   innerComponent = null,
}) => {
   return showModal ? (
      <AbstractWebModal title={headerTranslated} onClose={onCancel}>
         <div className='mb-4 text-center'>
            <p className='text-coolGray-600 text-sm'>{secondHeaderTranslated}</p>
         </div>
         {innerComponent != null && innerComponent}
         <div className='mt-5 space-y-2'>
            <div className='flex justify-center gap-x-2'>
               {/*todo button*/}
               <button
                  role='button'
                  type='submit'
                  className='rounded-normal mx-auto mt-0.5 w-full bg-red-700 px-2.5 py-2 text-lg font-bold text-gray-200'
                  onClick={() => {
                     onConfirm()
                  }}
               >
                  {confirmTranslated}
               </button>
               {cancelButtonTranslated != null && (
                  <button
                     role='button'
                     type='submit'
                     className='rounded-normal mt-0.5 w-full bg-blue-600 px-2 py-2 text-lg font-bold text-gray-200'
                     onClick={() => {
                        onCancel()
                     }}
                  >
                     {cancelButtonTranslated}
                  </button>
               )}
            </div>
         </div>
      </AbstractWebModal>
   ) : (
      <></>
   )
}
