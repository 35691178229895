import { Chef } from '@/model/chef.model'
import React, { useState } from 'react'
import { WriteReviewModal } from '@/layout/chef/WriteReviewModal'
import ReadOnlyLexical from '@/layout/common/lexical-editor/ReadOnlyLexical'
import { ChefStars } from '@/layout/common/ChefStars'
import { UserLoginComponent, UserLoginComponentType } from '@/layout/common/UserLoginComponent'
import { useTranslation } from 'react-i18next'
import { IReview } from '@/model/review.model'
import { TimeAgo } from '@/util/TimeAgo'

interface ReviewsForChefProps {
   chef?: Chef
   onChefUpdate: (updatedChef: Chef) => void
}

export const ReviewsForChef = ({ chef, onChefUpdate }: ReviewsForChefProps) => {
   const { t } = useTranslation()
   const [writeReviewModalOpened, setWriteReviewModalOpened] = useState(false)

   return (
      <>
         <div id={chef?.id} className='rounded-normal background-normal shadow-md'>
            <div className='flex flex-col flex-wrap mt-2'>
               {chef?.reviews?.length > 0 ? (
                  <>
                     {chef?.reviews?.map(review => {
                        return <React.Fragment key={review.id}>{SingleReview(review)}</React.Fragment>
                     })}
                  </>
               ) : (
                  <p className='px-3.5 pt-4 pb-2'>
                     {chef?.firstName && chef?.lastName ? `${chef.firstName} ${chef.lastName}` : chef?.name}
                     &nbsp;{t('chef_review.no_reviews')}
                  </p>
               )}
            </div>

            {chef?.canBeReviewed && (
               <button
                  className='rounded-normal bg-customPurple m-3 p-2 font-semibold text-white hover:cursor-pointer'
                  onClick={() => setWriteReviewModalOpened(true)}
               >
                  {t('chef_review.write_review')}
               </button>
            )}
         </div>

         <WriteReviewModal
            chefId={chef?.id}
            modalOpened={writeReviewModalOpened}
            setModalOpened={(modalOpened, reviewCompleted, newReview) => {
               if (reviewCompleted) {
                  const updatedChef = {
                     ...chef,
                     canBeReviewed: false,
                     reviews: [...(chef?.reviews || []), newReview],
                  }
                  onChefUpdate(updatedChef)
               }
               setWriteReviewModalOpened(modalOpened)
            }}
         />
      </>
   )
}

function SingleReview(review: IReview) {
   return (
      <div id={review?.id} className='flex flex-row mb-2 items-center'>
         <div className='ml-1 flex flex-col items-center'>
            <UserLoginComponent
               type={UserLoginComponentType.ONLY_ICON}
               userId={review.user?.id}
               avatar={review.user?.picture}
               addedStylesForIcon='w-[55px] h-[55px] stroke-gray-400'
            />
            <ChefStars
               averageVote={review.vote}
               votesCount={1}
               withCount={false}
               emptyColor={'text-gray-400'}
               iconSize={16}
            />
         </div>
         <div key={review.id} className='flex w-full flex-col p-2'>
            <div className='mx-2 mb-0 flex flex-row text-xs font-normal text-gray-800 dark:text-gray-300'>
               <UserLoginComponent user={review?.user} type={UserLoginComponentType.ONLY_TEXT} />
               <TimeAgo key={'cTa' + review.id} date={review.date} />
            </div>

            <div className='flex flex-row items-center'></div>
            <div className={'mt-0.5 ml-0.5 flex flex-row items-center'}>
               <ReadOnlyLexical htmlState={review?.review} />
            </div>
         </div>
         {/*</div>{review.date}*/}
      </div>
   )
}
