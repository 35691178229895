import React from 'react'
import { useTranslation } from 'react-i18next'
import loopSpin from '../../static/wired-gradient-19-magnifier-zoom-search-loop-spin.json'
import basket from '../../static/wired-gradient-139-basket-morph-fill.json'
import apple from '../../static/wired-gradient-543-apple-hover-pinch.json'
import { ConfigurableLottie } from '@/layout/common/ConfigurableLottie'
import { isDesktop } from 'react-device-detect'
import { HowItWorksStepsMobile } from '@/layout/landing_page/HowItWorksStepsMobile'

export const underscoreLine = (width: number) => (
   <div className='mx-auto mt-1.5 h-[2px] bg-black' style={{ width: `${width}px` }}></div>
)

export function HowItWorksStep(step: { id: number; animationData: any; title: string; description: string }) {
   return (
      <div key={step.id} className={`flex flex-col items-center ${isDesktop ? 'w-1/3 pt-4' : 'mx-2 pt-3'}`}>
         <ConfigurableLottie animationData={step.animationData} loop={true} size={{ width: '100%', height: '97px' }} />
         <div className={`mx-3.5 ${isDesktop ? 'mb-6' : 'mb-3'} flex flex-col items-center`}>
            <h2 className={`text-center ${isDesktop ? 'text-xl' : 'text-2xl'} font-semibold opacity-95`}>
               {step.title}
            </h2>
            <p className='mt-1 text-center text-lg'>{step.description}</p>
         </div>
      </div>
   )
}

export const HowItWorksSection = () => {
   const { t } = useTranslation()
   const steps = [
      {
         id: 1,
         animationData: loopSpin,
         title: t('landing_page.how_it_works_h_1'),
         description: t('landing_page.how_it_works_1'),
      },
      {
         id: 2,
         animationData: basket,
         title: t('landing_page.how_it_works_h_2'),
         description: t('landing_page.how_it_works_2'),
      },
      {
         id: 3,
         animationData: apple,
         title: t('landing_page.how_it_works_h_3'),
         description: t('landing_page.how_it_works_3'),
      },
   ]

   return (
      <div className={`flex w-full flex-col pb-4 `}>
         <div className={`mb-3 ${isDesktop ? 'pt-8 mt-8' : ''} text-center text-[34px] font-semibold`}>
            {t('landing_page.how_it_works_title')}
            {underscoreLine(140)}
         </div>
         {isDesktop ? (
            <div className={`flex flex-row space-x-4`}>{steps.map(step => HowItWorksStep(step))}</div>
         ) : (
            <HowItWorksStepsMobile steps={steps} />
         )}
      </div>
   )
}
