import { Marker } from 'react-leaflet'
import React, { useEffect, useState } from 'react'
import { Icon } from 'leaflet'
import { FaHeart } from 'react-icons/fa'
import { LuChefHat } from 'react-icons/lu'
import ReactDOMServer from 'react-dom/server'
import { Chef } from '@/model/chef.model'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { GoPackage } from 'react-icons/go'

interface LocationMarkersMobileProps {
   chefs: Chef[]
   starredChef: { chefId: string; starred: boolean }
   selectedChefId: string | null
   onMarkerSelect: (chefId: string) => void // Pass a function to handle marker selection
}

export const LocationMarkersMobile = ({
   chefs,
   starredChef,
   selectedChefId,
   onMarkerSelect,
}: LocationMarkersMobileProps) => {
   const [selectedMarker, setSelectedMarker] = useState<string | null>(null) // State to track selected marker

   useEffect(() => {
      const style = document.createElement('style')
      style.innerHTML = `
         .leaflet-popup-content-wrapper {
            padding: 0 !important;
         }
         .leaflet-popup-close-button {
            display: none !important;
         }
         .leaflet-popup-content {
            padding: 0 !important;
            margin: 0 !important;
         }
      `
      // Append the style to the document head
      document.head.appendChild(style)

      return () => {
         document.head.removeChild(style)
      }
   }, [])

   useEffect(() => {
      if (selectedChefId) setSelectedMarker(selectedChefId)
   }, [selectedChefId])

   const handleMarkerClick = (chef: Chef) => {
      setSelectedMarker(chef.id)
      onMarkerSelect(chef.id)
   }

   return (
      <MarkerClusterGroup chunkedLoading>
         {chefs.map((chef: Chef, index: React.Key) => (
            <Marker
               key={index}
               position={[chef.address.latitude, chef.address.longitude]}
               // @ts-ignore
               icon={CustomPointOnMap(
                  chef?.id === starredChef?.chefId ? starredChef?.starred : chef?.starred,
                  chef.id === selectedMarker,
                  chef.pickUpPoint,
               )}
               eventHandlers={{
                  click: () => handleMarkerClick(chef),
               }}
            ></Marker>
         ))}
      </MarkerClusterGroup>
   )
}

export const CustomPointOnMap = (starred: boolean, isSelected: boolean, isPickUpPoint: boolean) => {
   const iconSvg = isPickUpPoint
      ? ReactDOMServer.renderToString(<GoPackage color='black' size={18} style={{ padding: '0px' }} />)
      : starred
        ? ReactDOMServer.renderToString(<FaHeart color='white' size={18} style={{ padding: '0px' }} />)
        : ReactDOMServer.renderToString(<LuChefHat color='white' size={18} style={{ padding: '0px' }} />)

   return new Icon({
      iconUrl:
         'data:image/svg+xml;base64,' +
         btoa(`
         <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
            <circle cx="15" cy="15" r="13" fill="${isPickUpPoint ? 'yellow' : starred ? 'red' : '#5A1A97'}" stroke="${isSelected ? 'white' : 'none'}" stroke-width="3"/>
            <foreignObject x="5" y="5" width="20" height="20">
               <div xmlns="http://www.w3.org/1999/xhtml" style="display: flex; align-items: center; justify-content: center; height: 100%; width: 100%;">
                  ${iconSvg}
               </div>
            </foreignObject>
         </svg>`),
      iconSize: [30, 30],
      iconAnchor: [15, 30],
      popupAnchor: [0, -30],
   })
}
