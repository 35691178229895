import React, { useEffect } from 'react'
import Lottie, { LottieRefCurrentProps } from 'lottie-react'

interface LottieProps {
   animationData: any
   loop?: boolean
   size?: { width: string; height: string } // Configurable size prop
}

export const ConfigurableLottie: React.FC<LottieProps> = ({ animationData, loop = true, size }) => {
   const ref = React.useRef<LottieRefCurrentProps>(null)

   useEffect(() => {
      if (ref.current) {
         ref.current.setSpeed(0.7)
      }
   }, [ref])

   return (
      <Lottie
         animationData={animationData}
         loop={loop}
         lottieRef={ref}
         style={{
            width: size?.width || '100%', // Default to 100% width if no size is provided
            height: size?.height || 'auto', // Default to auto height
         }}
      />
   )
}
