import React from 'react'
import { CDN_ENDPOINT } from '@/env'
import { Chef } from '@/model/chef.model'
import 'swiper/css'
import 'swiper/css/navigation'
import { ImageGallery } from '@/layout/chef/ImageGallery'
import { FiImage } from 'react-icons/fi'

interface SingleBackgroundPictureProps {
   chef: Chef
   darken?: boolean
   additionalStyles?: string
   sliderMode?: boolean
   rounded?: string
}

export const SingleBackgroundPictureWithGallery = ({
   chef,
   darken,
   additionalStyles,
   rounded
}: SingleBackgroundPictureProps) => {
   const picturesToRender =
      chef?.galleryImages ||
      (chef?.defaultPicture ? [chef?.defaultPicture] : chef?.pictures?.length > 0 ? chef?.pictures : [])

   if (picturesToRender == null || picturesToRender.length == 0) {
      return (
         <div
            className={`flex h-full w-full items-center justify-center bg-linear-to-r from-purple-900 to-purple-400 ${additionalStyles}`}
         />
      )
   } else if (picturesToRender.length == 1) {
      return (
         <div className={`relative h-full w-full overflow-hidden ${additionalStyles}`}>
            <img
               id='SingleChefPicture'
               className={`h-full w-full object-cover object-center ${additionalStyles} `}
               src={CDN_ENDPOINT + picturesToRender[0]}
               alt='chef picture'
            />
            {darken && (
               <div
                  id='singlebgPictureDarken'
                  className={`absolute inset-0 bg-linear-to-r from-black/60 via-black/50 to-black/20 ${additionalStyles}`}
               ></div>
            )}
         </div>
      )
   } else {
      return (
         <div className={`relative overflow-hidden ${rounded}`}>
            <ImageGallery images={chef?.galleryImages} />
            {darken && (
               <div className={`pointer-events-none absolute inset-0 bg-black opacity-50 ${additionalStyles}`}></div>
            )}
            <div className='bg-opacity-40 pointer-events-none absolute right-2 bottom-2 flex items-center space-x-1 rounded-xl bg-gray-900 px-1 py-0.5 text-xl text-white hover:cursor-pointer'>
               <FiImage className='h-5 w-5' /> {/* Icon from react-icons */}
               <span>{chef?.galleryImages?.length}</span> {/* Number of images */}
            </div>
         </div>
      )
   }
}
