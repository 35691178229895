import React, { useEffect, useState } from 'react'
import type { UploadChangeParam } from 'antd/es/upload'
import type { UploadProps } from 'antd/es/upload/interface'
import { RcFile } from 'antd/es/upload/interface'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { DeletePhotoButton, getBase64, UploadPhoto } from '../../common/PhotoUpload'
import { getSession } from '@/service/authentication'
import { isSizeInLimit } from '@/util/files-utils'
import { ChangePassForm } from './ChangePassForm'
import { DeleteAccountForm } from './DeleteAccountForm'
import { ChangeAboutMe } from './ChangeAboutMe'
import { SuperBallsLoadingIndicator } from '../../common/LoadingIndicator'
import { CDN_ENDPOINT } from '@/env'
import { toast } from 'react-toastify'
import { ProfileSettingRow } from './ProfileSettingRow'
import { useNavigate } from 'react-router-dom'

export const ProfileSettings = () => {
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const navigate = useNavigate()

   const loggedAccount = useAppSelector(state => state.authentication.account)
   const [showChangePass, setShowChangePass] = useState(false)
   const [showChangeAboutMe, setShowChangeAboutMe] = useState(false)
   const [showDeleteAccountForm, setShowDeleteAccountForm] = useState(false)
   const [loading, setLoading] = useState(false)
   const [imageUrl, setImageUrl] = useState<string>()
   const [fileUploadError, setFileUploadError] = useState<string>()
   console.log(loggedAccount)

   useEffect(() => {
      if (loggedAccount.picture !== undefined && loggedAccount.picture !== null) {
         setImageUrl(CDN_ENDPOINT + loggedAccount.picture)
      }
   }, [loggedAccount])

   const handleChange: UploadProps['onChange'] = (info: UploadChangeParam) => {
      if (info.file.status === 'uploading') {
         setLoading(true)
         return
      }
      if (info.file.status === 'done') {
         // Get this url from response in real world.
         getBase64(info.file.originFileObj, url => {
            setLoading(false)
            setImageUrl(url)
         })
      }
   }

   const uploadImage = async options => {
      const { onSuccess, onError, file, onProgress } = options

      const fmData = new FormData()
      const config = {
         headers: { 'content-type': 'multipart/form-data' },
      }
      fmData.append('photo', file)
      // todo refactor it, somehow move it into service
      setLoading(true)
      axios
         .post('/api/update-photo', fmData, config)
         .then(result => {
            setLoading(false)
            onSuccess('Ok')
            toast.success(t('profile_settings.photo_updated'))
            dispatch(getSession)
         })
         .finally(() => {
            setLoading(false)
         })
   }

   // todo duplicate code
   const beforeUpload = (file: RcFile) => {
      setFileUploadError(null)
      const isValidType =
         file.type === 'image/jpeg' ||
         file.type === 'image/png' ||
         file.type === 'image/webp' ||
         file.type === 'image/bmp' ||
         file.type === 'image/gif' ||
         file.type === 'image/svg+xml' ||
         file.type === 'image/avif'
      if (!isValidType) {
         setFileUploadError(t('error.invalid_type'))
      }
      const isLt2M = isSizeInLimit(file)
      if (!isLt2M) {
         setFileUploadError(t('error.upload_file_too_big'))
      }
      if (!isValidType) {
         toast.error(t('error.invalid_type'))
      } else if (!isLt2M) {
         toast.error(t('error.upload_file_too_big'))
      }
      return isValidType && isLt2M
   }

   return (
      <div className='rounded-normal background-normal top-16 flex flex-col divide-y divide-gray-100 p-2 md:p-4 dark:divide-gray-500'>
         <h1 className='text-color-normal xs:text-2xl mb-2 text-xl'>
            {t('profile_settings.account_settings')}
            {loggedAccount.login && loggedAccount.login.trim() !== '' && ` (${loggedAccount.login})`}
         </h1>
         <ProfileSettingRow
            label={t('profile_settings.email')}
            value={loggedAccount.email}
            buttonText={t('profile_settings.email_change')}
            onClick={() => navigate('/change-email')}
         />

         <ProfileSettingRow
            label={t('profile_settings.password')}
            value={t('profile_settings.password_change_note')}
            buttonText={t('profile_settings.password_change')}
            onClick={() => setShowChangePass(!showChangePass)}
         />
         {showChangePass && <ChangePassForm onConfirm={() => setShowChangePass(false)} />}

         <ProfileSettingRow
            label={t('profile_settings.delete_account')}
            value={t('profile_settings.delete_account_descr')}
            buttonText={t('profile_settings.delete_account')}
            onClick={() => setShowDeleteAccountForm(!showDeleteAccountForm)}
         />
         {showDeleteAccountForm && <DeleteAccountForm onConfirm={() => setShowDeleteAccountForm(false)} />}

         <ProfileSettingRow
            label={t('profile_settings.about_me')}
            value={t('profile_settings.about_me_descr')}
            buttonText={
               loggedAccount.aboutMe ? t('profile_settings.edit_about_me') : t('profile_settings.add_about_me')
            }
            onClick={() => setShowChangeAboutMe(!showChangeAboutMe)}
         />
         {showChangeAboutMe && <ChangeAboutMe onConfirm={() => setShowChangeAboutMe(false)} />}

         <div className='max-w-xl space-y-1'>
            <ProfileSettingRow
               label={t('profile_settings.avatar')}
               value={t('profile_settings.avatar_note')}
               showButton={false}
            />
            <UploadPhoto
               imageUrl={imageUrl}
               loading={loading}
               uploadImage={uploadImage}
               beforeUpload={beforeUpload}
               handleChange={handleChange}
            />
            {loading && (
               <div className='absolute -mt-12'>
                  <SuperBallsLoadingIndicator />
               </div>
            )}
            <DeletePhotoButton setImageUrl={setImageUrl} loggedAccount={loggedAccount} imageUrl={imageUrl} />
         </div>
      </div>
   )
}
