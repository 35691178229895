import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Link, useNavigate } from 'react-router-dom'
import { RegisterFormData } from '@/model/register.model'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { hideRegisterModal, registerNewUser } from '@/service/authentication'
import { LoadingIndicator } from '../common/LoadingIndicator'
import { AbstractMobileModal } from '@/layout/common/AbstractMobileModal'
import GoogleLoginRegisterPage from '@/layout/login_register/GoogleLoginRegisterPage'
import { FloatingLabelInput } from '@/layout/common/FloatingLabelInput'
import { HorizontalDivider } from '@/layout/common/Dividers'

export function emailRules(t: any) {
   return {
      required: t('error.required'),
      pattern: {
         value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
         message: t('error.invalid_email'),
      },
   }
}

const RegisterPageMobile = () => {
   const {
      register,
      handleSubmit,
      setValue,
      getValues,
      watch,
      reset,
      formState: { errors, isValid, touchedFields },
   } = useForm<RegisterFormData>({ mode: 'all' })
   const locale = useAppSelector(state => state.localeSlice.lng)

   const { t } = useTranslation()
   const dispatch = useAppDispatch()

   const [errorMessage, setErrorMessage] = useState<string>('')
   const [isLoading, setIsLoading] = useState(false)
   const navigate = useNavigate()
   const [showPassword, setShowPassword] = useState(false)
   const [showConfirmPassword, setShowConfirmPassword] = useState(false)

   useEffect(() => {
      if (touchedFields.password || touchedFields.confirmPassword) {
         setValue('rulesAccepted', getValues('rulesAccepted'), {
            shouldValidate: true,
         })
      }
   }, [watch('password'), watch('confirmPassword')])

   const onSubmit = useCallback(
      (formValues: any) => {
         setErrorMessage('')
         setIsLoading(true)
         formValues.locale = locale
         registerNewUser(formValues)
            .then(value => {
               reset()
               dispatch(hideRegisterModal())
               navigate('/welcome')
            })
            .finally(() => {
               setIsLoading(false)
               setShowPassword(false)
               setShowConfirmPassword(false)
            })
      },
      [navigate, locale],
   )

   function triggerLoginModal() {
      reset()
      setErrorMessage('')
      dispatch(hideRegisterModal())
      navigate('/login')
   }

   return (
      <AbstractMobileModal title={<div className='mt-1 text-xl font-medium'>{t('sign.sing_up')}</div>}>
         <div className='mobile-page-box mt-3'>
            <form onSubmit={handleSubmit(onSubmit)}>
               <div className='space-y-4'>
                  <FloatingLabelInput<RegisterFormData>
                     label={t('username')}
                     name='login'
                     type='text'
                     register={register}
                     rules={{
                        required: t('error.required'),
                     }}
                     watch={watch}
                     errors={errors}
                     autocomplete='username'
                  />

                  {/* Email Input */}
                  <FloatingLabelInput<RegisterFormData>
                     label={t('email')}
                     name='email'
                     type='email'
                     register={register}
                     rules={emailRules(t)}
                     watch={watch}
                     errors={errors}
                     autocomplete='email'
                  />

                  {/* Password Input */}
                  <FloatingLabelInput<RegisterFormData>
                     label={t('password')}
                     name='password'
                     type='password'
                     register={register}
                     rules={{
                        required: t('error.required'),
                     }}
                     watch={watch}
                     errors={errors}
                     autocomplete='new-password'
                  />

                  <FloatingLabelInput<RegisterFormData>
                     label={t('profile_settings.confirm_password')}
                     name='confirmPassword'
                     type='password'
                     register={register}
                     rules={{
                        validate: value => value === getValues('password') || t('error.password_mismatch'),
                     }}
                     watch={watch}
                     errors={errors}
                     autocomplete='new-password'
                  />

                  <div className='text-sm-normal mt-2 inline-block'>
                     <input
                        type='checkbox'
                        name='rulesAccepted'
                        {...register('rulesAccepted', { required: t('error.required') })}
                        className='checkbox-lg mr-1 ml-1 align-middle'
                     />
                     <span
                        className='inline cursor-pointer align-middle'
                        onClick={() => setValue('rulesAccepted', !getValues('rulesAccepted'), { shouldValidate: true })}
                     >
                        {t('sign.accept_terms')}
                     </span>
                     &nbsp;
                     <Link
                        to='/terms-and-conditions'
                        className='inline align-middle text-blue-600 hover:cursor-pointer hover:underline'
                        target='_blank'
                     >
                        {t('sign.terms_link')}
                     </Link>
                  </div>

                  {errors.rulesAccepted && <p className='error-validation'>{t('sign.errors.rules_accepted')}</p>}

                  <div className='text-sm-normal mt-2 flex flex-row items-center'>
                     <label className='items-center' id='checkbox_id'>
                        <input
                           type='checkbox'
                           name='Name'
                           className='checkbox-lg mr-1 ml-1'
                           {...register('advAccepted')}
                           defaultChecked={true}
                           id='checkbox_id'
                        />
                        {t('sign.accept_adv')}
                     </label>
                  </div>
                  {errorMessage.length > 0 && <p className='error-validation'>{errorMessage}</p>}
                  <div className='mt-5 space-y-2'>
                     <div className='flex gap-x-2'>
                        <button
                           type='submit'
                           className={`sign-confirm-button ${!isValid ? 'gray-out' : ''}`}
                           disabled={!isValid}
                        >
                           {t('sign.join_in')}
                        </button>
                     </div>
                  </div>
               </div>
            </form>
            <div className='mb-1.5'>
               <HorizontalDivider text={t('common.or')} dividerHeight={2} />
            </div>
            <div className='mobile-page-google-box'>
               <GoogleLoginRegisterPage mode='register' />
            </div>
            <div className='mt-1.5 mb-2.5'>
               <HorizontalDivider text={t('common.or')} dividerHeight={2} />
            </div>
            <p className='text-coolGray-600 px-6 text-center text-sm'>
               {t('sign.have_an_account')}&nbsp;
               <a onClick={triggerLoginModal} className='cursor-pointer text-blue-600 hover:underline'>
                  {t('common.log_in')}
               </a>
            </p>
            {isLoading && <LoadingIndicator />}
         </div>
      </AbstractMobileModal>
   )
}
export default RegisterPageMobile
