import React, { useEffect, useRef, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

// Import react-modal styles (react-modal doesn't come with styles by default)
// You can use your own styles or import a CSS file
import { Navigation, Pagination } from 'swiper/modules'
import { CDN_ENDPOINT } from '@/env'

// Initialize Swiper modules
SwiperCore.use([Navigation, Pagination])

const GalleryImages = ({ images, enterFullScreen }: { images: string[]; enterFullScreen: (index: number) => void }) => {
   return (
      <div className='h-80 w-full cursor-pointer' onClick={() => enterFullScreen(0)}>
         {images?.length === 1 && (
            <div
               className='relative h-full w-full overflow-hidden'
               onClick={e => {
                  e.stopPropagation()
                  enterFullScreen(0)
               }}
            >
               <img alt={'Image'} src={CDN_ENDPOINT + images[0]} className='h-full w-full object-cover' />
            </div>
         )}

         {images?.length === 2 && (
            <div className='flex h-full w-full gap-3'>
               {images.map((image, index) => (
                  <div
                     key={index}
                     className='relative h-full min-h-0 flex-1 overflow-hidden'
                     onClick={e => {
                        e.stopPropagation()
                        enterFullScreen(index)
                     }}
                  >
                     <img src={CDN_ENDPOINT + image} alt={'Gallery images'} className='h-full w-full object-cover' />
                  </div>
               ))}
            </div>
         )}

         {images?.length === 3 && (
            <div className='flex h-full w-full gap-3'>
               <div
                  className='relative h-full min-h-0 flex-1 overflow-hidden'
                  onClick={e => {
                     e.stopPropagation()
                     enterFullScreen(0)
                  }}
               >
                  <img alt={'Image'} src={CDN_ENDPOINT + images[0]} className='h-full w-full object-cover' />
               </div>
               <div className='flex min-h-0 flex-1 flex-col gap-3'>
                  {images.slice(1, 3).map((image, index) => (
                     <div
                        key={index + 1}
                        className='relative min-h-0 flex-1 overflow-hidden'
                        onClick={e => {
                           e.stopPropagation()
                           enterFullScreen(index + 1)
                        }}
                     >
                        <img alt={'Image'} src={CDN_ENDPOINT + image} className='h-full w-full object-cover' />
                     </div>
                  ))}
               </div>
            </div>
         )}

         {images?.length >= 4 && (
            <div className='flex h-full w-full gap-3'>
               <div className='flex min-h-0 flex-1 flex-col gap-3'>
                  {images.slice(0, 2).map((image, index) => (
                     <div
                        key={index}
                        className='relative min-h-0 flex-1 overflow-hidden'
                        onClick={e => {
                           e.stopPropagation()
                           enterFullScreen(index)
                        }}
                     >
                        <img alt={'Image'} src={CDN_ENDPOINT + image} className='h-full w-full object-cover' />
                     </div>
                  ))}
               </div>
               <div className='flex min-h-0 flex-1 flex-col gap-3'>
                  {images.slice(2, 4).map((image, index) => (
                     <div
                        key={index + 2}
                        className='relative min-h-0 flex-1 overflow-hidden'
                        onClick={e => {
                           e.stopPropagation()
                           enterFullScreen(index + 2)
                        }}
                     >
                        <img src={CDN_ENDPOINT + image} className='h-full w-full object-cover' alt={'Gallery'} />
                     </div>
                  ))}
               </div>
               {images?.length > 4 && (
                  <div className='flex min-h-0 flex-1 flex-col gap-3'>
                     {images.slice(4, 6).map((image, index) => (
                        <div
                           key={index + 4}
                           className='relative min-h-0 flex-1 overflow-hidden'
                           onClick={e => {
                              e.stopPropagation()
                              enterFullScreen(index + 4)
                           }}
                        >
                           <img alt={'Image'} src={CDN_ENDPOINT + image} className='h-full w-full object-cover' />
                        </div>
                     ))}
                  </div>
               )}
            </div>
         )}
      </div>
   )
}

interface ImageGalleryProps {
   images?: string[]
}

export const ImageGallery = ({ images }: ImageGalleryProps) => {
   const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
   const [activeIndex, setActiveIndex] = useState<number>(0)
   const swiperRef = useRef<SwiperCore | null>(null)

   const enterFullScreen = (index: number) => {
      setActiveIndex(index)
      setIsFullScreen(true)
      // Push a new state to the history stack
      window.history.pushState({ isFullScreen: true }, '')
   }

   const exitFullScreen = () => {
      setIsFullScreen(false)
      // Go back in history when exiting full-screen mode
      if (window.history.state && window.history.state.isFullScreen) {
         window.history.back()
      }
   }

   // Handle keyboard events when in full-screen mode
   useEffect(() => {
      if (isFullScreen) {
         const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
               exitFullScreen()
            } else if (event.key === 'ArrowLeft') {
               if (activeIndex === 0) {
                  setActiveIndex(images.length - 1) // loop to last image
                  swiperRef.current?.slideTo(images.length - 1)
               } else {
                  swiperRef.current?.slidePrev()
               }
            } else if (event.key === 'ArrowRight') {
               if (activeIndex === images.length - 1) {
                  setActiveIndex(0) // loop to first image
                  swiperRef.current?.slideTo(0)
               } else {
                  swiperRef.current?.slideNext()
               }
            }
         }

         window.addEventListener('keydown', handleKeyDown)

         return () => {
            window.removeEventListener('keydown', handleKeyDown)
         }
      }
   }, [isFullScreen, activeIndex, images?.length])

   // Handle back button navigation on smartphones
   useEffect(() => {
      const handlePopState = (event: PopStateEvent) => {
         if (isFullScreen) {
            setIsFullScreen(false)
         }
      }

      window.addEventListener('popstate', handlePopState)

      return () => {
         window.removeEventListener('popstate', handlePopState)
      }
   }, [isFullScreen])

   return (
      <>
         {/* Thumbnail Gallery */}
         {!isFullScreen && <GalleryImages images={images} enterFullScreen={enterFullScreen} />}

         {/* Full-Screen Swiper */}
         {isFullScreen && (
            <div
               className='select-none'
               style={{
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100vw',
                  height: '100vh',
                  backgroundColor: 'black',
                  zIndex: 1100,
               }}
            >
               <button
                  onClick={exitFullScreen}
                  style={{
                     position: 'absolute',
                     top: '20px',
                     right: '20px',
                     zIndex: 1001,
                     background: 'transparent',
                     color: 'white',
                     border: 'none',
                     fontSize: '2rem',
                     cursor: 'pointer',
                  }}
               >
                  &times;
               </button>
               <Swiper
                  style={{
                     height: '100%',
                     // @ts-ignore
                     '--swiper-pagination-color': '#e8e8e8',
                     '--swiper-pagination-bullet-inactive-color': '#a887e3',
                     '--swiper-pagination-bullet-inactive-opacity': '0.9',
                     '--swiper-pagination-bullet-size': '12px',
                     '--swiper-pagination-bullet-horizontal-gap': '6px',
                  }}
                  initialSlide={activeIndex}
                  loop={true} // Enable loop
                  navigation={{
                     nextEl: '.swiper-button-next-custom',
                     prevEl: '.swiper-button-prev-custom',
                  }}
                  pagination={{ clickable: true }}
                  spaceBetween={30}
                  loopPreventsSliding={false}
                  slidesPerView={1}
                  onSwiper={swiper => {
                     swiperRef.current = swiper
                  }}
                  onSlideChange={swiper => {
                     setActiveIndex(swiper.activeIndex)
                  }}
               >
                  {images?.map((image, index) => (
                     <SwiperSlide key={index}>
                        <img
                           src={CDN_ENDPOINT + image}
                           style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'contain',
                           }}
                           alt={'Image'}
                        />
                     </SwiperSlide>
                  ))}
                  {/* Custom Navigation Buttons */}
                  <div
                     onClick={() => swiperRef.current?.slidePrev()}
                     className='swiper-button-prev-custom'
                     style={{
                        position: 'absolute',
                        top: '50%',
                        left: '10px',
                        transform: 'translateY(-50%)',
                        zIndex: 10,
                        background: 'rgba(0, 0, 0, 0.7)', // Darker background for better visibility
                        color: '#fff',
                        padding: '15px', // Increased padding for larger button
                        borderRadius: '50%',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '60px', // Increased width
                        height: '60px', // Increased height
                        fontSize: '24px', // Larger font for arrow
                     }}
                  >
                     ❮
                  </div>
                  <div
                     onClick={() => swiperRef.current?.slideNext()}
                     className='swiper-button-next-custom'
                     style={{
                        position: 'absolute',
                        top: '50%',
                        right: '10px',
                        transform: 'translateY(-50%)',
                        zIndex: 10,
                        background: 'rgba(0, 0, 0, 0.7)', // Darker background for better visibility
                        color: '#fff',
                        padding: '15px', // Increased padding for larger button
                        borderRadius: '50%',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '60px', // Increased width
                        height: '60px', // Increased height
                        fontSize: '24px', // Larger font for arrow
                     }}
                  >
                     ❯
                  </div>
               </Swiper>
            </div>
         )}
      </>
   )
}
