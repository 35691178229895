import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '@/config/store'
import { getUserDetailsById, getUserDetailsByLogin } from '@/service/user-management'
import { CDN_ENDPOINT } from '@/env'
import { HiOutlineUserCircle } from 'react-icons/hi2'
import CommentsForUserComponent from '@/layout/post/comment/CommentsForUserComponent'
import { isAdmin, isModOrHigher } from '@/util/auth-util'
import { TbBan } from 'react-icons/tb'
import { BlockUserModal, DeleteUserModal, UnblockUserModal } from '@/layout/common/AdminModUtils'
import { BlockStatusType } from '@/model/enumerations/block-status.type'
import {
   KarmaIndicator,
   ObserveUserButton,
   UserLoginComponent,
   UserLoginComponentType,
} from '@/layout/common/UserLoginComponent'
import { FaRegCircleQuestion } from 'react-icons/fa6'
import { FloatingUIComponent } from '@/layout/common/FloatingUIComponent'
import { TimeAgo } from '@/util/TimeAgo'
import { IUser } from '@/model/user.model'

const UserDetailsView = () => {
   const { t } = useTranslation()

   const { login } = useParams()
   const [openTab, setOpenTab] = React.useState(0)
   const [userDetails, setUserDetails] = useState<IUser>()
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const [isModalUserSuspendVisible, setIsModalUserSuspendVisible] = useState(false)
   const [isModalUserUnblockVisible, setIsModalUserUnblockVisible] = useState(false)
   const [isModalUserDeleteVisible, setIsModalUserDeleteVisible] = useState(false)
   const [userBlockStatus, setUserBlockStatus] = useState(BlockStatusType.UNBLOCKED)
   const [isUserBlockedInfoVisible, setIsUserBlockedInfoVisible] = useState(false)

   function isValidMongoId(id: string) {
      const regExp = /^[0-9a-fA-F]{24}$/
      return regExp.test(id)
   }

   const [items, setItems] = useState([
      // {
      //    label: 'posts',
      //    key: 0,
      // },
      // {
      //    label: 'liked',
      //    key: 1,
      // },
      // {
      //    label: 'unliked',
      //    key: 3,
      // },
      // {
      //    label: 'comments',
      //    key: 4,
      // },
   ])

   useEffect(() => {
      window.scrollTo(0, 0)

      // Check if login is a valid MongoDB ObjectID
      const isMongoId = isValidMongoId(login)

      const detailsPromise = isMongoId ? getUserDetailsById(login) : getUserDetailsByLogin(login)

      detailsPromise
         .then(value => {
            setUserDetails(value.data)
            setUserBlockStatus(value.data.blockInfo?.blockStatus)

            // if ((isMongoId && loggedAccount?.login == value.data.login) || loggedAccount?.id == value.data.id) {
            //    setItems([
            //       ...items,
            //       {
            //          label: 'last_seen_posts',
            //          key: 5,
            //       },
            //    ])
            // }
         })
         .catch(() => {
            // Handle the error
         })
   }, [login])

   function suspendOrUnsuspendUser() {
      if (userBlockStatus == BlockStatusType.UNBLOCKED) setIsModalUserSuspendVisible(true)
      else setIsModalUserUnblockVisible(true)
   }

   function getUserDetailsHeader() {
      return (
         <div className='mb-1 flex flex-col px-2 py-3'>
            <div className='flex flex-row flex-wrap items-center justify-between text-lg'>
               <div className='flex flex-row flex-wrap items-center'>
                  {userDetails?.picture ? (
                     <img
                        alt={'user avatar'}
                        className='mt-1 ml-0.5 h-8 w-8 rounded-full'
                        src={CDN_ENDPOINT + userDetails.picture}
                     />
                  ) : (
                     <HiOutlineUserCircle className='icon mt-[-1px]' />
                  )}
                  <p className='mb-0 text-blue-500'>&nbsp;{userDetails?.login}&nbsp;</p>
                  {isAdmin(loggedAccount) && <div className='mt-0.5 text-xs'>({userDetails?.id})&nbsp;</div>}
                  <p className='mb-0'>{t('user_details.joined')}&nbsp;</p>
                  <TimeAgo date={userDetails?.createdDate} />
                  {isModOrHigher(loggedAccount) &&
                     loggedAccount.id !== userDetails?.id &&
                     !userDetails?.blockInfo?.scheduledToDelete && (
                        <div className='flex flex-row text-sm'>
                           <button className='more-actions-item text-red-500' onClick={suspendOrUnsuspendUser}>
                              <TbBan style={{ fontSize: '18px', color: 'red' }} className='mr-2 ml-0.5' />
                              {t(
                                 userBlockStatus != BlockStatusType.UNBLOCKED
                                    ? 'post_action.unsuspend_user'
                                    : 'post_action.suspend_user',
                              )}
                           </button>
                           {/*todo on hover show userDetails.userStatus*/}
                        </div>
                     )}

                  {isAdmin(loggedAccount) && userBlockStatus != BlockStatusType.UNBLOCKED && (
                     <FloatingUIComponent
                        menuButton={<FaRegCircleQuestion className='ml-1 h-5 w-5' />}
                        insideElement={
                           <div className='absolute top-3 left-0 min-w-[500px] rounded-lg bg-white p-2 text-left text-xs shadow-lg dark:bg-gray-800'>
                              <p>Block Status: {userDetails?.blockInfo?.blockStatus}</p>
                              <p>
                                 Reason for User: {userDetails?.blockInfo?.messageForUser} <br />
                                 Reason for Admin: {userDetails?.blockInfo?.reasonForModerators}
                              </p>
                              <p className='flex flex-row items-center'>
                                 Blocked by: &nbsp;
                                 <UserLoginComponent
                                    userId={userDetails?.blockInfo?.blockedBy?.id}
                                    type={UserLoginComponentType.ICON_AND_TEXT}
                                 />
                              </p>
                              {userDetails?.blockInfo?.dueDate &&
                                 userDetails?.blockInfo.blockStatus != BlockStatusType.PERMANENTLY_BLOCKED && (
                                    <div>
                                       Until: <TimeAgo date={userDetails?.blockInfo?.dueDate} />
                                    </div>
                                 )}
                              {userDetails?.blockInfo?.dateOfBlocking && (
                                 <div>
                                    Blocked on: <TimeAgo date={userDetails?.blockInfo?.dateOfBlocking} />
                                 </div>
                              )}
                              {userDetails?.blockInfo?.blockStatus == BlockStatusType.TEMPORARILY_BLOCKED && (
                                 <div>
                                    <p>Comments blocked: {userDetails.blockInfo.commentsBlocked?.toString()}</p>
                                    <p>Posts blocked: {userDetails.blockInfo.postsBlocked?.toString()}</p>
                                    <p>Chat blocked: {userDetails.blockInfo.chatDisabled?.toString()}</p>
                                    <p>Reactions blocked: {userDetails.blockInfo.reactionsBlocked?.toString()}</p>
                                 </div>
                              )}
                           </div>
                        }
                        isOpen={isUserBlockedInfoVisible}
                        setIsOpen={setIsUserBlockedInfoVisible}
                        triggerOnHover={true}
                     />
                  )}

                  {isAdmin(loggedAccount) && loggedAccount?.id !== userDetails?.id && (
                     <div>
                        {userDetails?.blockInfo?.scheduledToDelete ? (
                           <div className='more-actions-item text-red-500'>
                              {t('user_details.scheduled_to_delete_user')}
                           </div>
                        ) : (
                           <button
                              className='more-actions-item text-red-500'
                              onClick={() => setIsModalUserDeleteVisible(!isModalUserDeleteVisible)}
                           >
                              <TbBan style={{ fontSize: '18px', color: 'red' }} className='mr-2 ml-0.5' />
                              {t('user_details.delete_user')}
                           </button>
                        )}
                     </div>
                  )}
               </div>

               <div className='mt-2'>
                  <ObserveUserButton user={userDetails} />
               </div>

               {userDetails?.aboutMe && (
                  <div className='mb-0.5 ml-2'>
                     <p className='mt-2.5 text-xl'>{t('user_details.about_me')}</p>
                     <p className=''>{userDetails?.aboutMe}</p>
                  </div>
               )}

               {/*<div className="ml-2 mt-3 flex flex-row flex-wrap">*/}
               {/*   <p className="mb-0">*/}
               {/*      {t('user_details.liked_count')}&nbsp;{userDetails?.liked}&nbsp;*/}
               {/*   </p>*/}
               {/*   <p className="mb-0">*/}
               {/*      {t('user_details.unliked_count')}&nbsp;{userDetails?.unliked}&nbsp;*/}
               {/*   </p>*/}
               {/*</div>*/}
            </div>
            <div className='px-2 pt-2'>
               <KarmaIndicator userInfo={userDetails} />
            </div>
         </div>
      )
   }

   return (
      <div className='mt-1 flex w-full flex-col'>
         <div className='rounded-normal background-normal border-gray-custom flex flex-wrap px-1'>
            {getUserDetailsHeader()}
         </div>
         <div className='rounded-normal flex flex-wrap px-1'>
            <div className='w-full'>
               <ul className='mb-0 flex list-none flex-row flex-wrap pt-2 pb-2' role='tablist'>
                  {items.map((it, idx) => {
                     return (
                        <li
                           className='mr-1 -mb-px flex-1 text-center select-none last:mr-0 hover:cursor-pointer'
                           key={'kit' + idx}
                        >
                           <a
                              className={
                                 'rounded-normal block px-1 py-2 text-xs leading-normal uppercase dark:text-gray-200 ' +
                                 (it.key === openTab
                                    ? 'red-deep-text border border-gray-500 bg-gray-200 dark:bg-zinc-600'
                                    : 'background-normal border-gray-custom text-black')
                              }
                              onClick={e => {
                                 e.preventDefault()
                                 setOpenTab(it.key)
                              }}
                              role='tablist'
                           >
                              {t('user_details.' + it.label)}
                           </a>
                        </li>
                     )
                  })}
               </ul>
               <div className='mt-2.5 space-y-2.5'>
                  {/*todo*/}
                  {openTab === 4 && userDetails && <CommentsForUserComponent userId={userDetails.id} />}
               </div>
            </div>
         </div>
         <BlockUserModal
            userId={userDetails?.id}
            visible={isModalUserSuspendVisible}
            onClose={(blockStatus: BlockStatusType) => {
               setIsModalUserSuspendVisible(false)
               if (blockStatus != undefined) setUserBlockStatus(blockStatus)
               if (blockStatus != BlockStatusType.UNBLOCKED) {
                  getUserDetailsById(userDetails?.id).then(value => {
                     setUserDetails(value.data)
                  })
               }
            }}
         />
         <UnblockUserModal
            userId={userDetails?.id}
            visible={isModalUserUnblockVisible}
            onCancel={() => {
               setIsModalUserUnblockVisible(false)
            }}
            onSubmit={() => {
               setIsModalUserUnblockVisible(false)
               setUserBlockStatus(BlockStatusType.UNBLOCKED)
            }}
         />
         <DeleteUserModal
            userId={userDetails?.id}
            visible={isModalUserDeleteVisible}
            onClose={() => setIsModalUserDeleteVisible(false)}
         />
      </div>
   )
}
export default UserDetailsView
