import React, { ReactNode, useEffect, useState } from 'react'
import { Cookies } from 'react-cookie-consent'
import { useTranslation } from 'react-i18next'
import { StaticContentType } from '@/layout/static-page/StaticContentType'
import { updateCookiesState } from '@/service/authentication'
import { useAppDispatch, useAppSelector } from '@/config/store'
import DOMPurify from 'dompurify'
import { getConfigByType } from '@/service/content-settings-service'
import { isMobile } from 'react-device-detect'

export const CookiesComponent = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const [terms, setTerms] = useState<any>()
   const [isLoading, setIsLoading] = useState(false)
   const [isNotAcceptedYet, setIsNotAcceptedYet] = useState(true)
   const language = useAppSelector(state => state.localeSlice.lng)

   useEffect(() => {
      // load user's preferences from cookies
      const cookiesAccepted = Cookies.get('cookies-accepted')
      if (cookiesAccepted != 'true') {
         setIsLoading(true)
         getConfigByType(StaticContentType.COOKIES_BANNER, language)
            .then(value => {
               setTerms(value.data)
            })
            .catch(reason => {})
            .finally(() => {
               setIsLoading(false)
            })
      } else {
         dispatch(updateCookiesState(true))
      }
   }, [])

   const handleAccept = () => {
      const inOneYear = new Date()
      inOneYear.setFullYear(inOneYear.getFullYear() + 1)
      Cookies.set('cookies-accepted', 'true', { expires: inOneYear })
      setIsNotAcceptedYet(false)
      dispatch(updateCookiesState(true))
   }

   const handleReject = () => {
      const inThreeDays = new Date()
      inThreeDays.setDate(inThreeDays.getDate() + 3)
      Cookies.set('cookies-accepted', 'false', { expires: inThreeDays })
      setIsNotAcceptedYet(false)
      dispatch(updateCookiesState(false))
   }

   const Container = ({ children }: { children: ReactNode }) => (
      <div className='mx-auto w-full max-w-6xl px-1 md:px-4'>{children}</div>
   )

   return (
      <>
         {Cookies.get('cookies-accepted') != 'true' &&
            Cookies.get('cookies-accepted') != 'false' &&
            isNotAcceptedYet && (
               <section className='fixed bottom-0 left-0 z-50 w-full py-1 md:py-4'>
                  <Container>
                     <div className='rounded-normal flex flex-col items-start space-y-2 border-2 border-gray-300 bg-gray-200 px-5 py-3'>
                        <div className='flex w-full grow items-center text-gray-900'>
                           <div className='w-full text-sm font-medium'>
                              {isLoading ? (
                                 <div role='status' className='w-full animate-pulse'>
                                    {[...Array(isMobile ? 4 : 2)].map((_, index) => (
                                       <div key={index} className='mb-4 h-2 w-full rounded-full bg-slate-700'></div>
                                    ))}
                                 </div>
                              ) : (
                                 <div
                                    className='w-full px-2 py-2'
                                    dangerouslySetInnerHTML={{
                                       __html: DOMPurify.sanitize(terms),
                                    }}
                                 />
                              )}
                           </div>
                        </div>
                        <div className='ml-1 flex h-9 flex-row items-center space-x-1.5 text-xs font-light sm:space-x-2.5 sm:text-base sm:font-bold'>
                           <button
                              data-cy='cookies'
                              className='rounded-normal bg-customPurple hover:bg-customPurpleDark px-2 py-2 hover:cursor-pointer'
                              onClick={handleAccept}
                           >
                              <div className='text-xs text-white'>{t('cookies.accept_all')}</div>
                           </button>
                           <button
                              className='rounded-normal border border-gray-500 bg-gray-100 px-2 py-2 hover:cursor-pointer hover:bg-gray-200'
                              onClick={handleReject}
                           >
                              <div className='text-xs text-black'>{t('cookies.reject_non_essential')}</div>
                           </button>
                        </div>
                     </div>
                  </Container>
               </section>
            )}
      </>
   )
}

export default CookiesComponent
