import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import ShoppingCardProgressBar from '@/layout/shopping_cart/ShoppingCardProgressBar'
import { getPaymentById } from '@/service/shopping-card.service'
import { Order } from '@/model/order/order'
import { useTranslation } from 'react-i18next'
import { formatPrice } from '@/util/text-utils'
import ChefAddress from '@/layout/payment/ChefAddress'
import { removeItemsIdsFromSC } from '@/service/notification-reducer'
import { CDN_ENDPOINT } from '@/env'
import { useAppDispatch } from '@/config/store'
import { isDesktop } from 'react-device-detect'

const PaymentConfirmation = () => {
   const { t } = useTranslation()
   const { id } = useParams()
   const dispatch = useAppDispatch()
   const [order, setOrder] = useState<Order | null>(null)

   useEffect(() => {
      if (!id) return

      getPaymentById(id)
         .then(response => {
            setOrder(response.data)
            const foodsIds = response.data.orderItems.map(order => order?.food?.id)
            dispatch(removeItemsIdsFromSC(foodsIds))
         })
         .catch(error => {
            console.error('Error fetching payment:', error)
         })
   }, [id])

   const openInGoogleMaps = (latitude: number, longitude: number) => {
      const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
      window.open(url, '_blank', 'noopener,noreferrer')
   }

   return (
      <div className={`mx-auto my-8 flex w-full max-w-2xl flex-col rounded-lg border bg-white p-4 shadow-md ${isDesktop ? 'mt-[70px]': 'mt-[60px]'}`}>
         <ShoppingCardProgressBar step={2} />
         <h1 className='my-4 text-center text-2xl font-bold'>{t('payment_confirmation.title')}</h1>
         {order ? (
            <div>
               <p className='mb-2'>
                  <strong>{t('payment_confirmation.status')}:</strong> {t(`orders.status.${order.status}`)}
               </p>
               <p className=''>
                  <strong>{t('payment_confirmation.order_from')}:</strong>{' '}
                  <a
                     href={`/chef/${order.id}`} // Replace with the correct URL
                     target='_blank'
                     rel='noopener noreferrer'
                     className='text-blue-500 hover:underline'
                  >
                     {order.chef?.name}
                  </a>
               </p>
               {order.orderItems?.map((order, index) => (
                  <div key={index} className='mb-4'>
                     <div key={order.id} className='flex flex-row items-center'>
                        {order.food?.image && (
                           <img
                              src={CDN_ENDPOINT + order.food?.image}
                              alt={order.food?.name}
                              className='mr-2 h-14 w-14 rounded-md object-cover'
                           />
                        )}
                        <div>
                           {order.food?.name} ({order.quantity} x €{formatPrice(order.food?.price)})
                        </div>
                     </div>
                  </div>
               ))}

               <p className='mt-1.5 mb-2'>
                  <strong>{t('payment_confirmation.total_amount')}:</strong> €{formatPrice(order.totalPrice)}
               </p>

               <div className='mt-4'>
                  <ChefAddress
                     address={order.chef?.address}
                     phoneNumber={order.chef?.phoneNumber}
                     pickUpPoint={!!order.chef?.pickUpPoint}
                     name={order?.chef?.name}
                     pickUpPointId={order?.chef?.id}
                  />
                  <button
                     onClick={() => openInGoogleMaps(order.chef?.address?.latitude, order.chef?.address?.longitude)}
                     className='ml-0 text-indigo-500 hover:cursor-pointer hover:underline'
                  >
                     {t('payment_confirmation.open_in_maps')}
                  </button>
               </div>

               {/*<p className="mb-2"><strong>{t('payment_confirmation.payment_method')}:</strong> {t(`payment_methods.${payment.paymentMethod}`)}</p>*/}
            </div>
         ) : (
            <p>{t('payment_confirmation.loading')}</p>
         )}
      </div>
   )
}

export default PaymentConfirmation
