import type {
   DOMConversionMap,
   DOMConversionOutput,
   DOMExportOutput,
   EditorConfig,
   ElementFormatType,
   LexicalEditor,
   NodeKey,
   Spread,
} from 'lexical'

import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents'
import { DecoratorBlockNode, SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import * as React from 'react'
import { ReactElement } from 'react'
import { TikTokEmbed } from 'react-social-media-embed'

type TikTokComponentProps = Readonly<{
   className: Readonly<{
      base: string
      focus: string
   }>
   format: ElementFormatType | null
   nodeKey: NodeKey
   contentId: string
}>

function TikTokComponent({ className, format, nodeKey, contentId }: TikTokComponentProps) {
   return (
      <BlockWithAlignableContents className={className} format={format} nodeKey={nodeKey}>
         <TikTokEmbed width='100%' url={`https://www.tiktok.com/${contentId}`} />
      </BlockWithAlignableContents>
   )
}

export type SerializedStreamableNode = Spread<
   {
      contentId: string
   },
   SerializedDecoratorBlockNode
>

export class TikTokNode extends DecoratorBlockNode {
   __id: string

   constructor(id: string, format?: ElementFormatType, key?: NodeKey) {
      super(format, key)
      this.__id = id
   }

   static getType(): string {
      return 'tiktok'
   }

   static clone(node: TikTokNode): TikTokNode {
      return new TikTokNode(node.__id, node.__format, node.__key)
   }

   static importJSON(serializedNode: SerializedStreamableNode): TikTokNode {
      const node = $createTikTokNode(serializedNode.contentId)
      node.setFormat(serializedNode.format)
      return node
   }

   static importDOM(): DOMConversionMap | null {
      return {
         iframe: (domNode: HTMLElement) => {
            if (!domNode.hasAttribute('data-lexical-tiktok')) {
               return null
            }
            return {
               conversion: convertTikTokElement,
               priority: 1,
            }
         },
      }
   }

   exportJSON(): SerializedStreamableNode {
      return {
         ...super.exportJSON(),
         type: 'tiktok',
         version: 1,
         contentId: this.__id,
      }
   }

   exportDOM(): DOMExportOutput {
      const element = document.createElement('iframe')
      element.setAttribute('data-lexical-tiktok', this.__id)
      // element.setAttribute('width', '560');
      // element.setAttribute('height', '315');
      element.setAttribute('src', `https://www.tiktok.com/${this.__id}`)
      element.setAttribute('allowfullscreen', 'true')
      element.setAttribute('title', 'TikTok Embed')
      return { element }
   }

   updateDOM(): false {
      return false
   }

   getId(): string {
      return this.__id
   }

   getTextContent(_includeInert?: boolean | undefined, _includeDirectionless?: false | undefined): string {
      return `https://www.tiktok.com/${this.__id}`
   }

   decorate(_editor: LexicalEditor, config: EditorConfig): ReactElement {
      const embedBlockTheme = config.theme.embedBlock || {}
      const className = {
         base: embedBlockTheme.base || '',
         focus: embedBlockTheme.focus || '',
      }
      return (
         <div className='w-full'>
            <TikTokComponent
               className={className}
               format={this.__format}
               nodeKey={this.getKey()}
               contentId={this.__id}
            />
         </div>
      )
   }
}

function convertTikTokElement(domNode: HTMLElement): null | DOMConversionOutput {
   const contentId = domNode.getAttribute('data-lexical-tiktok')
   if (contentId) {
      const node = $createTikTokNode(contentId)
      return { node }
   }
   return null
}

export function $createTikTokNode(contentId: string): TikTokNode {
   return new TikTokNode(contentId)
}
