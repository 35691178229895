import type {
   DOMConversionMap,
   DOMConversionOutput,
   DOMExportOutput,
   EditorConfig,
   ElementFormatType,
   LexicalEditor,
   LexicalNode,
   NodeKey,
   Spread,
} from 'lexical'

import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents'
import { DecoratorBlockNode, SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import * as React from 'react'
import { ReactElement } from 'react'

type RumbleComponentProps = Readonly<{
   className: Readonly<{
      base: string
      focus: string
   }>
   format: ElementFormatType | null
   nodeKey: NodeKey
   videoID: string
}>

function RumbleComponent({ className, format, nodeKey, videoID }: RumbleComponentProps) {
   return (
      <BlockWithAlignableContents className={className} format={format} nodeKey={nodeKey}>
         <div className='video-responsive'>
            <iframe
               src={`https://rumble.com/embed/${videoID}`}
               width='100%'
               height='auto'
               allowFullScreen
               allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            ></iframe>
         </div>
      </BlockWithAlignableContents>
   )
}

export type SerializedRumbleNode = Spread<
   {
      videoID: string
   },
   SerializedDecoratorBlockNode
>

export class RumbleNode extends DecoratorBlockNode {
   __id: string

   constructor(id: string, format?: ElementFormatType, key?: NodeKey) {
      super(format, key)
      this.__id = id
   }

   static getType(): string {
      return 'rumble'
   }

   static clone(node: RumbleNode): RumbleNode {
      return new RumbleNode(node.__id, node.__format, node.__key)
   }

   static importJSON(serializedNode: SerializedRumbleNode): RumbleNode {
      const node = $createRumbleNode(serializedNode.videoID)
      node.setFormat(serializedNode.format)
      return node
   }

   static importDOM(): DOMConversionMap | null {
      return {
         iframe: (domNode: HTMLElement) => {
            if (!domNode.hasAttribute('data-lexical-rumble')) {
               return null
            }
            return {
               conversion: convertRumbleElement,
               priority: 1,
            }
         },
      }
   }

   exportJSON(): SerializedRumbleNode {
      return {
         ...super.exportJSON(),
         type: 'rumble',
         version: 1,
         videoID: this.__id,
      }
   }

   exportDOM(): DOMExportOutput {
      const element = document.createElement('iframe')
      element.setAttribute('data-lexical-rumble', this.__id)
      element.setAttribute('width', '560')
      element.setAttribute('height', '315')
      element.setAttribute('src', `https://rumble.com/e/${this.__id}`)
      element.setAttribute('allowfullscreen', 'true')
      element.setAttribute('title', 'Rumble video')
      return { element }
   }

   updateDOM(): false {
      return false
   }

   getId(): string {
      return this.__id
   }

   getTextContent(_includeInert?: boolean | undefined, _includeDirectionless?: false | undefined): string {
      return `https://rumble.com/${this.__id}`
   }

   decorate(_editor: LexicalEditor, config: EditorConfig): ReactElement {
      const embedBlockTheme = config.theme.embedBlock || {}
      const className = {
         base: embedBlockTheme.base || '',
         focus: embedBlockTheme.focus || '',
      }
      return (
         <div className='w-full'>
            <RumbleComponent className={className} format={this.__format} nodeKey={this.getKey()} videoID={this.__id} />
         </div>
      )
   }
}

function convertRumbleElement(domNode: HTMLElement): null | DOMConversionOutput {
   const videoID = domNode.getAttribute('data-lexical-rumble')
   if (videoID) {
      const node = $createRumbleNode(videoID)
      return { node }
   }
   return null
}

export function $createRumbleNode(videoID: string): RumbleNode {
   return new RumbleNode(videoID)
}

export function $isRumbleNode(node: RumbleNode | LexicalNode | null | undefined): node is RumbleNode {
   return node instanceof RumbleNode
}
