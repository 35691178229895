import React from 'react'
import { AbstractWebModal } from '@/layout/common/AbstractWebModal'
import { IoShareSocialOutline } from 'react-icons/io5'
import { AddFood, AddFoodModalProps } from './AddFood'
import { useTranslation } from 'react-i18next'

export const AddFoodModalDesktop: React.FC<AddFoodModalProps> = ({ food, onClose, chefSuspended }) => {
   const { t } = useTranslation()
   return (
      <AbstractWebModal
         rightElement={
            <button className='rounded-full p-1.5 text-gray-500 hover:cursor-pointer hover:bg-gray-100'>
               <IoShareSocialOutline size={23} />
            </button>
         }
         title={t('add_food_page.title')}
         onClose={onClose}
      >
         <div className='flex flex-col'>
            <AddFood food={food} onClose={onClose} chefSuspended={chefSuspended} />
         </div>
      </AbstractWebModal>
   )
}
