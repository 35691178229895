import { Marker, Popup } from 'react-leaflet'
import React, { useEffect } from 'react'
import { Chef } from '@/model/chef.model'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { SingleChefCard } from '@/layout/home/maps/SingleChefCard'
import { CustomPointOnMap } from '@/layout/home/maps/LocationMarkersMobile'

interface LocationMarkersProps {
   chefs: Chef[]
   starredChef?: { chefId: string; starred: boolean }
   customOnIconClick?: (chefId: string) => void
   compactMode?: boolean
}

export const LocationMarkers = ({
   chefs,
   starredChef,
   customOnIconClick,
   compactMode = false,
}: LocationMarkersProps) => {
   useEffect(() => {
      const style = document.createElement('style')
      style.innerHTML = `
         .leaflet-popup-content-wrapper {
            padding: 0 !important;
         }
         .leaflet-popup-close-button {
            display: none !important;
         }
         .leaflet-popup-content {
            padding: 0 !important;
            margin: 0 !important;
         }
      `
      document.head.appendChild(style)

      return () => {
         document.head.removeChild(style)
      }
   }, [])

   return (
      <MarkerClusterGroup maxClusterRadius={25}>
         {chefs.map((chef: Chef, index: React.Key) => (
            <Marker
               key={index}
               position={[chef.address?.latitude, chef.address?.longitude]}
               // @ts-ignore
               icon={CustomPointOnMap(
                  chef?.id === starredChef?.chefId ? starredChef?.starred : chef?.starred,
                  false,
                  chef?.pickUpPoint,
               )}
               eventHandlers={{
                  click: () => {
                     if (customOnIconClick) {
                        customOnIconClick(chef.id)
                     }
                  },
               }}
            >
               {!customOnIconClick && (
                  <Popup>
                     <SingleChefCard
                        compactMode={compactMode}
                        chef={chef}
                        starrClicked={starred => {
                           // starrClicked(starred, chef.id)
                        }}
                     />
                  </Popup>
               )}
            </Marker>
         ))}
      </MarkerClusterGroup>
   )
}
