import React, { useEffect, useRef, useState } from 'react'
import {
   googleLoginRegister,
   hideLoginModal,
   hideRegisterModal,
   saveTokenAndGetUserInfo,
} from '@/service/authentication'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { LoadingIndicator } from '@/layout/common/LoadingIndicator'
import { Capacitor } from '@capacitor/core'
import { SocialLogin } from '@capgo/capacitor-social-login'
import { useTranslation } from 'react-i18next'

declare const google: any

interface GoogleAuthComponentProps {
   mode: 'login' | 'register'
}

const GoogleAuthComponent = ({ mode }: GoogleAuthComponentProps) => {
   const clientId = '191460933936-phi7a7vpov1a49b8ri9fct8p7c5cumh5.apps.googleusercontent.com'

   return Capacitor.isNativePlatform() ? (
      <NativeGoogleAuth mode={mode} clientId={clientId} />
   ) : (
      <WebGoogleAuth mode={mode} clientId={clientId} />
   )
}

export default GoogleAuthComponent

const WebGoogleAuth = ({ mode, clientId }: GoogleAuthComponentProps & { clientId: string }) => {
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const [isLoading, setIsLoading] = useState(false)
   const locale = useAppSelector(state => state.localeSlice.lng)
   const googleButtonRef = useRef<HTMLDivElement>(null)

   useEffect(() => {
      const handleCredentialResponse = (response: any) => {
         setIsLoading(true)
         googleLoginRegister(response.credential)
            .then(res => {
               if (res.headers.authorization) {
                  // Already exists, just save token and get user info
                  saveTokenAndGetUserInfo(res.headers.authorization, true, dispatch).then(() => {
                     if (isMobile) navigate('/')
                     else {
                        dispatch(hideLoginModal())
                        dispatch(hideRegisterModal())
                     }
                  })
               } else if (!res.data.googleUserExists) {
                  // First time Google user, redirect to register page
                  dispatch(hideLoginModal())
                  dispatch(hideRegisterModal())
                  navigate('register-google', {
                     state: { email: res.data.email, tokenId: response.credential },
                  })
               }
            })
            .finally(() => {
               setIsLoading(false)
            })
      }

      // Load the Google Identity Services SDK
      const script = document.createElement('script')
      script.src = 'https://accounts.google.com/gsi/client'
      script.async = true
      script.onload = () => {
         google.accounts.id.initialize({
            client_id: clientId,
            callback: handleCredentialResponse,
            context: mode, // 'signin' or 'signup'
            ux_mode: 'popup',
            allowed_parent_origin: window.location.origin,
            language: locale,
            error_callback: () => {
               console.log('Google Login Failed')
            },
         })

         google.accounts.id.renderButton(googleButtonRef.current, {
            theme: 'filled_blue',
            size: 'large',
            text: mode === 'login' ? 'signin_with' : 'signup_with',
            shape: 'rectangular',
            width: isMobile ? 200 : 490,
         })
      }
      script.onerror = () => {
         console.log('Google script failed to load')
      }
      document.body.appendChild(script)

      // Cleanup
      return () => {
         document.body.removeChild(script)
      }
   }, [locale, dispatch, navigate])

   const handleNativeSignIn = async () => {
      setIsLoading(true)
      try {
         const { provider, result } = await SocialLogin.login({
            provider: 'google',
            options: {},
         })

         // @ts-ignore
         const tokenToSend = result?.idToken
         console.log('Post token', tokenToSend)
         if (!tokenToSend) {
            throw new Error('No token found from Google Sign-In.')
         }

         const response = await googleLoginRegister(tokenToSend)

         if (response?.headers?.authorization) {
            await saveTokenAndGetUserInfo(response.headers.authorization, true, dispatch)
            navigate('/')
         } else if (!response.data.googleUserExists) {
            // New Google user => navigate to "register-google"
            dispatch(hideLoginModal())
            dispatch(hideRegisterModal())
            navigate('register-google', {
               state: { email: response.data.email, tokenId: tokenToSend },
            })
         }
      } catch (error: any) {
         console.error('Google Native Sign-In Error:', error)
      } finally {
         setIsLoading(false)
      }
   }
   return (
      <>
         <div
            style={{
               width: '100%',
               display: 'flex',
               justifyContent: 'center',
            }}
         >
            <div
               ref={googleButtonRef}
               style={{
                  borderRadius: '0.375rem',
                  overflow: 'hidden',
               }}
            />
         </div>
         {isLoading && <LoadingIndicator />}
      </>
   )
}

const NativeGoogleAuth = ({ mode, clientId }: GoogleAuthComponentProps & { clientId: string }) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const [isLoading, setIsLoading] = useState(false)
   useEffect(() => {
      SocialLogin.initialize({
         google: {
            webClientId: clientId,
         },
      }).catch(error => console.log('SocialLogin init error:', error))
   }, [])

   const handleNativeSignIn = async () => {
      setIsLoading(true)
      try {
         const { provider, result } = await SocialLogin.login({
            provider: 'google',
            options: {},
         })
         // @ts-ignore
         const tokenToSend = result?.idToken
         console.log('Post token', tokenToSend)
         if (!tokenToSend) {
            throw new Error('No token found from Google Sign-In.')
         }

         const response = await googleLoginRegister(tokenToSend)

         if (response?.headers?.authorization) {
            await saveTokenAndGetUserInfo(response.headers.authorization, true, dispatch)
            navigate('/')
         } else if (!response.data.googleUserExists) {
            // New Google user => navigate to "register-google"
            dispatch(hideLoginModal())
            dispatch(hideRegisterModal())
            navigate('register-google', {
               state: { email: response.data.email, tokenId: tokenToSend },
            })
         }
      } catch (error: any) {
         console.error('Google Native Sign-In Error:', error)
      } finally {
         setIsLoading(false)
      }
   }
   return (
      <>
         <div
            style={{
               width: '100%',
               display: 'flex',
               justifyContent: 'center',
            }}
         >
            <button
               onClick={handleNativeSignIn}
               style={{
                  borderRadius: '0.375rem',
                  backgroundColor: '#4285F4',
                  color: '#fff',
                  padding: '10px 20px',
                  fontSize: '16px',
                  cursor: 'pointer',
               }}
            >
               {mode === 'login' ? t('sign.signInWithGoogle') : t('sign.registerWithGoogle')}
            </button>
         </div>
         {isLoading && <LoadingIndicator />}
      </>
   )
}
