import { RestClient } from '@/config/rest-client'

const apiUrl = '/api/topics'

export const getCommunityTopics = async () => {
   return RestClient.get<any>(apiUrl + '/all')
}

export const createCommunityTopic = async (entity: any) => {
   return RestClient.post<any>(apiUrl + '/create', entity)
}

export const updateCommunityTopic = async (entity: any) => {
   return RestClient.put<any>(apiUrl + '/update', entity)
}
