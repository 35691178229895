import React, { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { LoginFormData } from '@/model/login.model'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { useTranslation } from 'react-i18next'
import {
   hideLoginModal,
   hideLoginOpenRegisterModal,
   login,
   resendActivationEmail,
   showForgotPasswordModal,
} from '@/service/authentication'
import { LoadingBalls } from '../common/LoadingIndicator'
import { useNavigate } from 'react-router-dom'
import GoogleLoginRegisterPage from '@/layout/login_register/GoogleLoginRegisterPage'
import { FloatingLabelInput } from '@/layout/common/FloatingLabelInput'
import { AbstractWebModal } from '@/layout/common/AbstractWebModal'
import { HorizontalDivider } from '@/layout/common/Dividers'
import { toast } from 'react-toastify'
import { isMobile } from 'react-device-detect'

export function showToastMessageForChefsAndAdminsAndNavigate<T>(value: any, t: any, navigate: any) {
   if (value.authorities.includes('ADMIN')) {
      toast.success(t('common.welcome_admin'), { autoClose: isMobile ? 3000 : 4000 })
   } else if (value.authorities.includes('CHEF')) {
      toast.success(t('common.welcome_chef'), { autoClose: isMobile ? 3000 : 4000 })
      navigate('/chef-orders')
      window.location.reload()
      return
   } else {
      if (location.pathname.includes('/activate/') || isMobile) navigate('/home')
   }
}

export const LoginModal = () => {
   const {
      register,
      reset,
      handleSubmit,
      getValues,
      watch,
      formState: { errors, isValid },
   } = useForm<LoginFormData>({ mode: 'all' })
   const dispatch = useAppDispatch()
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   const { showModalLogin } = useAppSelector(state => state.authentication)
   const [errorMessage, setErrorMessage] = useState('')
   const [resendEmail, setResendEmail] = useState(false)
   const navigate = useNavigate()
   const [showPassword, setShowPassword] = useState(false)

   const onSubmit = useCallback((formData: LoginFormData) => {
      if (isLoading) return
      setIsLoading(true)
      const trimmedData = {
         ...formData,
         username: formData.usernameOrEmail.trim(),
      }
      login(trimmedData, dispatch)
         .then(value => {
            console.log('Login successful', value)
            if (location.pathname.includes('/activate/') || location.pathname.includes('/success-confirm-email'))
               navigate('/')
            else showToastMessageForChefsAndAdminsAndNavigate(value, t, navigate)
         })
         .catch(reason => {
            if (reason?.response?.data?.errorKey == 'user_not_activated') {
               setErrorMessage(t('sign.user_not_activated'))
               setResendEmail(true)
            } else if (reason?.response?.data?.errorKey == 'invalid_credentials') {
               setErrorMessage(t('sign.username_or_password_invalid'))
            } else {
               setErrorMessage(t('sign.problem'))
            }
         })
         .finally(() => {
            setShowPassword(false)
            setIsLoading(false)
         })
   }, [])

   function handleCancelClick() {
      setShowPassword(false)
      if (location.pathname.includes('/dashboard')) navigate('/')
      reset()
      setErrorMessage('')
      dispatch(hideLoginModal())
   }

   function resendTheEmail() {
      // todo
      resendActivationEmail({
         username: getValues().usernameOrEmail, // password: getValues().password, rememberMe: false
      })
      setIsLoading(false)
      setResendEmail(false)
      dispatch(hideLoginModal())
   }

   return showModalLogin ? (
      <AbstractWebModal title={t('sign.login_in')} onClose={handleCancelClick}>
         <div className={'sm:px-8 min-h-[450px]'}>
            <form onSubmit={handleSubmit(onSubmit)}>
               <div className='mt-6 space-y-4'>
                  <FloatingLabelInput<LoginFormData>
                     label={t('common.username_or_email')}
                     name='usernameOrEmail'
                     type='text'
                     register={register}
                     rules={{ required: t('error.required') }}
                     watch={watch}
                     errors={errors}
                     autocomplete={'username'}
                     submitOnEnter={false}
                     autoFocus={true}
                  />
                  <FloatingLabelInput<LoginFormData>
                     label={t('password')}
                     name='password'
                     type='password'
                     register={register}
                     rules={{ required: t('error.required') }}
                     watch={watch}
                     errors={errors}
                     autocomplete={'current-password'}
                     submitOnEnter={true}
                  />
               </div>

               <div className='mt-2 flex flex-row'>
                  <div className='mt-0.5 flex flex-row'>
                     <input
                        type='checkbox'
                        name='rememberMe'
                        className='checkbox-lg mr-1 ml-1'
                        {...register('rememberMe')}
                        defaultChecked={true}
                     />
                     <div className=''>{t('sign.remember_me')}</div>
                  </div>
               </div>
               <div className='mt-3 mb-1 flex justify-start'>
                  <label className='ml-1 text-sm'></label>
                  <a
                     role='button'
                     className='text-sm text-blue-600 hover:cursor-pointer'
                     onClick={() => {
                        setErrorMessage('')
                        dispatch(hideLoginModal())
                        dispatch(showForgotPasswordModal())
                     }}
                  >
                     {t('sign.forgot_password')}
                  </a>
               </div>
               {errorMessage && <p className='error-validation mt-1'>{errorMessage}</p>}
               {/*todo move to after register page*/}
               {resendEmail && (
                  <p onClick={resendTheEmail} className='mt-2 mb-0 text-blue-500 hover:cursor-pointer'>
                     {t('sign.resend_email')}
                     {/*   todo navigate to page with info about resend*/}
                  </p>
               )}

               <div className='mt-2 space-y-4'>
                  <div className='flex gap-x-2'>
                     <button
                        type='submit'
                        className={`sign-confirm-button ${!isValid ? 'gray-out' : ''}`}
                        disabled={!isValid || isLoading}
                     >
                        {t('common.log_in')}
                     </button>
                  </div>
               </div>
            </form>

            <div className='mb-2.5'>
               <HorizontalDivider text={t('common.or')} dividerHeight={2} />
            </div>

            <div className='mb-2 flex w-full items-center justify-center'>
               <GoogleLoginRegisterPage mode='login' />
            </div>
            <div className='mt-2.5 mb-3.5'>
               <HorizontalDivider text={t('common.or')} dividerHeight={2} />
            </div>

            <div className={`text-coolGray-600 px-6 text-center text-sm ${isLoading ? 'cursor-wait' : ''}`}>
               {t('sign.don_not_have_an_account')}
               <a
                  role='button'
                  onClick={() => {
                     setErrorMessage('')
                     setShowPassword(false)
                     dispatch(hideLoginOpenRegisterModal())
                  }}
                  className='ml-1 text-blue-600 hover:underline'
               >
                  {isLoading && (
                     <div className='absolute inset-x-0 bottom-16 flex items-center justify-center'>
                        <LoadingBalls cursorWithWaitEffect={isLoading} />
                     </div>
                  )}
                  {t('sign.join_in')}
               </a>
            </div>
         </div>
      </AbstractWebModal>
   ) : (
      <div />
   )
}
