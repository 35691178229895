import 'react-toastify/dist/ReactToastify.css'

import React, { useEffect, useRef, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import { useAppDispatch, useAppSelector } from './config/store'
import Header from './layout/header/Header'
import CookiesComponent from '@/layout/cookies/CookiesComponent'
import { setupNotifications } from '@/service/notification-reducer'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { ScreenSize, ScreenSizeType } from '@/util/ScreenSize'
import { LoginModal } from '@/layout/login_register/LoginModal'
import { RegisterModal } from '@/layout/login_register/RegisterModal'
import { ForgotPasswordModal } from '@/layout/login_register/ForgotPasswordModal'
import { Seo } from '@/Seo'
import { initUserLocalizationAndAddress } from '@/util/UserLocalizations'
import { setupFCM } from '@/service/fcm/fcm-service'
import { AppRoutes } from '@/config/routes'
import { setupWebsocketConnection, WebSocketConnection } from '@/service/chat/websocket-reducer'

// @ts-ignore
export function updateAppTheme(darkMode: boolean) {
   let a, b, c
   if (darkMode) {
      document.documentElement.classList.add('dark')
      ;(a = parseInt('0')), (b = parseInt('0')), (c = parseInt('0'))
   } else {
      document.documentElement.classList.remove('dark')
      ;(a = parseInt('245')), (b = parseInt('242')), (c = parseInt('244'))
   }
   document.body.style.backgroundColor = 'rgb(' + [a, b, c].join() + ')'
}

export const AppDesktop = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const [screenSize, setScreenSize] = useState(ScreenSizeType.Small)
    const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated)
   const { currentUserLocation } = useAppSelector(state => state.search)

   useEffect(() => {
      if (!currentUserLocation) {
         initUserLocalizationAndAddress(dispatch, false, isAuthenticated).finally(() => {})
      }
   }, [isAuthenticated, currentUserLocation])

   useEffect(() => {
      if (isAuthenticated) {
         setupNotifications(t, dispatch, isAuthenticated)
         setupFCM()
      }
   }, [isAuthenticated])

   useEffect(() => {
      if (!isAuthenticated) return
      // setupWebsocketConnection(WebSocketConnection.CHAT)
   }, [isAuthenticated])

   useEffect(() => {}, [screenSize])

   function changeScreenSize(newScreenSize: ScreenSizeType) {
      if (newScreenSize != screenSize) setScreenSize(newScreenSize)
   }

   const darkMode = useAppSelector(state => state.themeModeReducer.darkTheme)
   updateAppTheme(darkMode)

   function getLayout() {
      return (
         <>
            <Seo title='Oh! les Chefs' description='' type='' name='Oh! les Chefs' />
            <Header />
            <Outlet />
            <CookiesComponent />
            <ToastContainer position='top-center' autoClose={1200} />
            <ScreenSize changeScreenSize={changeScreenSize} />
            <LoginModal />
            <RegisterModal />
            <ForgotPasswordModal />
         </>
      )
   }

   return <AppRoutes layout={getLayout()} />
}

export default AppDesktop
