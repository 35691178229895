import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
   deleteNotifications,
   readNotifications,
   unreadNotifications,
} from '@/service/notification/notification-service'
import FloatingUIWrapper from '@/layout/common/FloatingUIComponent'
import { isMobile } from 'react-device-detect'
import { MdOutlineMoreVert } from 'react-icons/md'
import { AiOutlineCheck } from 'react-icons/ai'
import { IoTrashOutline } from 'react-icons/io5'
import { UserLoginComponent, UserLoginComponentType } from '@/layout/common/UserLoginComponent'
import { TimeAgo } from '@/util/TimeAgo'

export const SingleNotification = ({ not, onDelete, collapseView = () => {} }) => {
   const navigate = useNavigate()
   const { t } = useTranslation()
   const [changedToRead, setChangedToRead] = useState(not?.read)
   const [showPopper, setShowPopper] = useState(false)

   useEffect(() => {}, [changedToRead])

   function onNotificationClicked() {
      return (event: any) => {
         event.preventDefault()
         readNotifications([not?.id]).then(() => {})
         setChangedToRead(true)
         // todo: navigate to the content
         // if (not?.contentId) {
         //    navigate(`/news/${not.contentId}?notificationId=${not.id}&fromCommentId=${not.subContentId}`, {
         //       replace: true,
         //    })
         // }
         collapseView()
      }
   }

   function onReadNotificationClicked(n) {
      return event => {
         if (event.target !== event.currentTarget) {
            return
         }
         if (changedToRead) unreadNotifications([n.id]).then(() => {})
         else readNotifications([n.id]).then(() => {})

         setChangedToRead(!changedToRead)
         setShowPopper(false)
      }
   }

   function onNotificationDeletedClicked(n) {
      return (event: { target: any; currentTarget: any }) => {
         if (event.target !== event.currentTarget) {
            return
         }
         deleteNotifications([n.id]).then(() => {})
         onDelete(n.id)
         setShowPopper(false)
      }
   }

   function getMenuForNotification() {
      return (
         <div className='more-actions-items text-color-normal ring-opacity-5 w-56 ring-1 ring-black'>
            <button className='more-actions-item whitespace-nowrap' onClick={onReadNotificationClicked(not)}>
               <AiOutlineCheck style={{ fontSize: '18px' }} className='mr-2 ml-0.5' />
               {changedToRead ? t('notifications.mark_as_unread') : t('notifications.mark_as_read')}
            </button>

            <button className='more-actions-item' onClick={onNotificationDeletedClicked(not)}>
               <IoTrashOutline style={{ fontSize: '18px' }} className='mr-2 ml-0.5' />
               {t('notifications.delete')}
            </button>
         </div>
      )
   }

   function getMenu() {
      return (
         <FloatingUIWrapper
            zIndex={1001}
            menuButton={({ ref, onClick }) => (
               <div
                  className={`mr-1 flex items-center justify-center ${isMobile ? 'pr-1' : ''}`}
                  onClick={onClick}
                  ref={ref}
               >
                  <MdOutlineMoreVert className={'tab-hover ml-1 h-[22px] w-[22px] rounded-full'} />
               </div>
            )}
         >
            {getMenuForNotification()}
         </FloatingUIWrapper>
      )
   }

   function getNotificationMessage() {
      return (
         <a
            className='flex w-full cursor-pointer flex-row items-center text-inherit'
            // href={`/news/${not?.contentId}?notificationId=${not?.id}&fromCommentId=${not?.subContentId}`}
            onClick={onNotificationClicked()}
         >
            <div className='mr-1'>
               {!!not?.user && <UserLoginComponent user={not?.user} type={UserLoginComponentType.ONLY_ICON} />}
            </div>
            <div className=''>
               <div className='flex flex-col'>
                  <div>
                     {not?.user?.login && <span className='font-semibold'>{not?.user?.login}&nbsp;</span>}
                     {not?.notificationMessage && (
                        <span className='font-semibold italic'>{not.notificationMessage}&nbsp;</span>
                     )}
                     {not?.notificationMessageContinue && (
                        <span className='italic'>{not.notificationMessageContinue}</span>
                     )}
                  </div>
               </div>
               <div className='text-xs'>
                  <TimeAgo key={'tI' + not.id} date={not?.createdDate} />
               </div>
            </div>
         </a>
      )
   }

   return (
      <div
         key={'notification-' + not?.id}
         className={`${changedToRead ? 'text-gray-700' : 'text-color-normal'} group flex w-full items-center rounded-none px-0 py-1 text-sm`}
      >
         <div className={`mb-0 flex w-full flex-row items-center justify-between`}>
            {getNotificationMessage()}
            <div className='flex flex-row items-center'>
               {getMenu()}
               <div className={`h-3 w-3 rounded-full ${!changedToRead ? 'bg-red-500' : 'bg-transparent'}`}></div>
            </div>
         </div>
      </div>
   )
}
