import React from 'react'

export const VerticalDivider = () => {
   return <div style={{ minWidth: '1px' }} className='shrink-0 rounded-full bg-gray-200 dark:bg-gray-600' />
}
export const HorizontalDivider = ({ additionalClass = '', dividerHeight = 1, text = '' }) => {
   return (
      <div className={`flex items-center ${additionalClass}`}>
         <div style={{ height: dividerHeight + 'px' }} className='grow rounded-full bg-gray-200 dark:bg-gray-600' />
         {text && <span className='mx-2 text-gray-500 dark:text-gray-300'>{text}</span>}
         <div style={{ height: dividerHeight + 'px' }} className='grow rounded-full bg-gray-200 dark:bg-gray-600' />
      </div>
   )
}
