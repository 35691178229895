import { Chef } from '@/model/chef.model'
import { useTranslation } from 'react-i18next'
import { useAppDispatch, useAppSelector } from '@/config/store'
import React, { useEffect, useState } from 'react'
import { PiHeart, PiHeartFill } from 'react-icons/pi'
import { showLoginModal } from '@/service/authentication'
import { starrChef, unstarrChef } from '@/service/chef.service'
import { toast } from 'react-toastify'
import { MoreActionsChef } from '@/layout/chef/MoreActionsChef'
import ShareChefModal from '../ShareChef'
import { SingleBackgroundPictureWithGallery } from '@/layout/chef/pictures/SingleBackgroundPictureWithGallery'
import { SliderPictures } from '@/layout/chef/pictures/SliderPictures'
import { SingleChefPicture } from '@/layout/chef/pictures/SingleChefPicture'

export interface BackgroundPictureWithHeartProps {
   chef: Chef
   showWriteReviewButton?: boolean
   children?: React.ReactNode[]
   starrClicked?: (starred: boolean) => void
   starredChef?: boolean
   additionalStyles?: string
   showHeart?: boolean
   showChefName?: boolean
   showMoreActions?: boolean
   pictureMode: PictureModeProps
   rounded?: string
}

export enum PictureMode {
   SLIDER = 'SLIDER',
   SINGLE = 'SINGLE',
   GALLERY = 'GALLERY',
}

export interface PictureModeProps {
   mode: PictureMode
   dark: boolean
   sliderNavigationOnHover?: boolean
   compactButtons?: boolean
}

export const BackgroundPictureWithHeart = ({
   chef,
   showWriteReviewButton = false,
   children,
   starrClicked = (starred: boolean) => {},
   starredChef,
   additionalStyles,
   rounded = 'rounded-lg',
   showHeart = true,
   showMoreActions = true,
   pictureMode,
}: BackgroundPictureWithHeartProps) => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const [starred, setStarred] = useState(chef?.starred)
   const [showWriteReviewModal, setShowWriteReviewModal] = useState(false)
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const loggedIn = Object.keys(loggedAccount)?.length !== 0
   const [showShareModal, setShowShareModal] = useState(false)

   useEffect(() => {
      if (starredChef) setStarred(starredChef.valueOf)
   }, [starredChef])

   return (
      <>
         {pictureMode.mode === PictureMode.GALLERY ? (
            <SingleBackgroundPictureWithGallery
               chef={chef}
               darken={pictureMode.dark}
               additionalStyles={additionalStyles}
               rounded={rounded}
            />
         ) : pictureMode.mode === PictureMode.SLIDER ? (
            <>
               {chef?.galleryImages && chef?.galleryImages?.length > 1 ? (
                  <SliderPictures chef={chef} additionalStyles={additionalStyles} pictureMode={pictureMode} />
               ) : (
                  <SingleChefPicture rounded={rounded} chef={chef} additionalStyles={additionalStyles} pictureMode={pictureMode} />
               )}
            </>
         ) : pictureMode.mode === PictureMode.SINGLE ? (
            <SingleChefPicture rounded={rounded} chef={chef} additionalStyles={additionalStyles} pictureMode={pictureMode} />
         ) : null}
         {/* Ensure PiHeart is absolutely positioned within the relative container */}
         {showHeart && (
            <div className='absolute top-3 right-14 z-10 select-none'>
               {starred ? (
                  <PiHeartFill
                     style={{ strokeWidth: '4px' }}
                     className={
                        'tab-hover h-[33px] min-h-[33px] w-[33px] min-w-[33px] rounded-full bg-white p-1 text-red-500 hover:cursor-pointer hover:text-red-600'
                     }
                     onClick={event => {
                        event.stopPropagation()
                        event.preventDefault()
                        if (!loggedIn) {
                           dispatch(showLoginModal())
                           return
                        }
                        unstarrChef(chef.id).then(() => {
                           toast.success(t('chef_map.unstarred'))
                        })
                        setStarred(false)
                        starrClicked(false)
                     }}
                  />
               ) : (
                  <PiHeart
                     style={{ strokeWidth: '1px' }}
                     className={
                        'tab-hover h-[33px] min-h-[33px] w-[33px] min-w-[33px] rounded-full bg-white p-1 text-gray-700 hover:cursor-pointer hover:text-gray-800'
                     }
                     onClick={event => {
                        event.stopPropagation()
                        event.preventDefault()
                        if (!loggedIn) {
                           dispatch(showLoginModal())
                           return
                        }
                        starrChef(chef.id).then(() => {
                           toast.success(t('chef_map.starred'))
                        })
                        setStarred(true)
                        starrClicked(true)
                     }}
                  />
               )}
            </div>
         )}

         {showMoreActions && (
            <div className='absolute top-3 right-1 z-10 select-none'>
               <MoreActionsChef
                  suspended={chef?.suspended}
                  pickUpPoint={chef?.pickUpPoint}
                  chefId={chef?.id}
                  starred={starred}
                  setShowShareModal={setShowShareModal}
               />
            </div>
         )}
         {children && children.map((child, index) => child)}
         {showWriteReviewButton && (
            <button
               className='absolute bottom-2.5 left-2.5 rounded-lg bg-blue-500 p-2 font-semibold text-white select-none hover:bg-blue-600'
               onClick={event => {
                  event.stopPropagation()
                  setShowWriteReviewModal(true)
               }}
            >
               {t('chef_profile.write_review')}
            </button>
         )}
         {showShareModal && <ShareChefModal chef={chef} setShowShareModal={setShowShareModal} />}
         {/*<WriteChefReviewComponent chefId={chef?.id} showWriteReviewModal={showWriteReviewModal} setShowWriteReviewModal={setShowWriteReviewModal} />*/}
      </>
   )
}
