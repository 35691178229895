import React from 'react'

export function Switch({ checked, onChange, disableAnimation = false }) {
   return (
      <label className='flex cursor-pointer items-center space-x-3'>
         <div>
            <input type='checkbox' className='hidden' checked={checked} onChange={e => onChange(e.target.checked)} />
            <div
               className={`relative h-5 w-10 cursor-pointer rounded-full ${
                  checked ? 'bg-green-600' : 'bg-red-600'
               } ${!disableAnimation ? 'transition-colors duration-300 ease-linear' : ''}`}
            >
               <div
                  className={`absolute left-0 h-5 w-5 transform rounded-full bg-white shadow ${
                     checked ? 'translate-x-full' : ''
                  } ${!disableAnimation ? 'transition-transform duration-200 ease-linear' : ''}`}
               ></div>
            </div>
         </div>
      </label>
   )
}
