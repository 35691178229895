import React from 'react'
import { Chef } from '@/model/chef.model'
import { ChefStars } from '@/layout/common/ChefStars'
import { ChefInfo, RenderDeliveryType } from '@/layout/home/maps/SingleChefCard'
import { GiKnifeFork } from 'react-icons/gi'
import { useTranslation } from 'react-i18next'
import { BackgroundPictureWithHeart, PictureMode } from '@/layout/chef/pictures/BackgroundPictureWithHeart'

interface ChefSwiperComponentProps {
   chef: Chef
   onChefClicked: () => void
}

export const ChefSwiperComponentMobile = ({ chef, onChefClicked }: ChefSwiperComponentProps) => {
   const { t } = useTranslation()
   const rounded = 'rounded-t-lg rounded-b-none'
   return (
      <div
         onClick={e => {
            console.log('Chef clicked', chef.id)
            e.stopPropagation()
            onChefClicked()
         }}
         className={`flex h-44 w-full items-center justify-center bg-cover bg-center hover:cursor-pointer ${rounded}`}
      >
         <div className={`relative h-44 w-full ${rounded} bg-gray-300`}>
            <BackgroundPictureWithHeart
               pictureMode={{ mode: PictureMode.SINGLE, dark: true }}
               chef={chef}
               showHeart={false}
               showMoreActions={false}
               showChefName={true}
               rounded={rounded}
            />
         </div>

         <div className='absolute top-[20px] left-3 flex max-w-[65%] flex-col items-center text-lg leading-5 text-white'>
            {chef.name}
         </div>

         <div className='absolute top-[20px] right-3 flex flex-col items-center text-white'>
            <ChefInfo chef={chef} />
            <ChefStars
               averageVote={chef?.averageVote}
               votesCount={chef?.votesCount}
               withCount={true}
               iconSize={16}
               emptyColor={'text-gray-100'}
            />
         </div>

         <div className='absolute bottom-4 left-2.5 space-y-1 text-white'>
            {chef.dietary?.length > 0 && (
               <div className='mt-1 flex flex-row flex-wrap items-center text-sm'>
                  <GiKnifeFork className='mr-1.5 h-[15px] w-[15px] text-gray-100' />
                  {chef.dietary?.map((d, index) => {
                     return (
                        <span key={d} className='flex items-center'>
                           {index > 0 && <span className='mx-1'>•</span>}
                           <p className=''>{t('dietary.' + d.toLowerCase())}</p>
                        </span>
                     )
                  })}
               </div>
            )}
            <div
               id={'deliveryTypeSwiperMobile'}
               onClick={e => {
                  e.stopPropagation()
                  onChefClicked()
               }}
            >
               <RenderDeliveryType deliveryTypes={chef?.deliveryTypes} isPickUpPoint={chef?.pickUpPoint} />
            </div>
         </div>
      </div>
   )
}
