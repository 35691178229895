import React, { useEffect, useState } from 'react'
import { MapContainer, Marker, TileLayer, useMapEvents, ZoomControl } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { useAppSelector } from '@/config/store'
import { CustomAddress } from '@/model/custom-address.model'
import { UseFormSetValue } from 'react-hook-form'
import { Chef } from '@/model/chef.model'
import L from 'leaflet'
import { useTranslation } from 'react-i18next'
import { UserLocation } from '@/util/UserLocalizations'

// Import marker icons from the leaflet package
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png'
import markerIcon from 'leaflet/dist/images/marker-icon.png'
import markerShadow from 'leaflet/dist/images/marker-shadow.png'

// Update default icon options
L.Icon.Default.mergeOptions({
   iconRetinaUrl: markerIcon2x,
   iconUrl: markerIcon,
   shadowUrl: markerShadow,
})

interface ChooseOnMapYourLocalizationProps {
   address?: CustomAddress | UserLocation
   setValue?: UseFormSetValue<Chef>
}

export const ChooseOnMapYourLocalization = ({ address, setValue }: ChooseOnMapYourLocalizationProps) => {
   const { t } = useTranslation()
   const { currentUserLocation } = useAppSelector(state => state.search)

   const initialCenter =
      address && address.latitude && address.longitude
         ? [address.latitude, address.longitude]
         : currentUserLocation?.latitude && currentUserLocation?.longitude
           ? [currentUserLocation.latitude, currentUserLocation.longitude]
           : [0, 0]

   const [pinPosition, setPinPosition] = useState<[number, number]>(
      address?.locationName ? (initialCenter as [number, number]) : null,
   )

   const [mapCenter, setMapCenter] = useState<[number, number]>(initialCenter as [number, number])

   useEffect(() => {
      if (address) {
         const newCenter = [address.latitude, address.longitude] as [number, number]
         console.log('address ', address)
         if (address.locationName) setPinPosition(newCenter)
         setMapCenter(newCenter)
      }
   }, [address])

   // Handle pin adjustment
   const handleAdjustPin = () => {
      if (setValue) {
         setValue('address.latitude', pinPosition[0])
         setValue('address.longitude', pinPosition[1])
      }
   }

   // Component that handles map click events
   function LocationMarker() {
      const map = useMapEvents({
         click(event: L.LeafletMouseEvent) {
            const newCenter = [event.latlng.lat, event.latlng.lng] as [number, number]
            console.log('newCenter location marker', newCenter)
            if (address.locationName) setPinPosition(newCenter)
            setMapCenter(newCenter)
         },
      })

      useEffect(() => {
         if (address) {
            map.flyTo([address.latitude, address.longitude], 13, {
               animate: true,
               duration: 1.5,
            })
         }
      }, [address])

      return pinPosition === null ? null : <Marker position={pinPosition}></Marker>
   }

   return (
      <div className='relative h-[282px]'>
         <div className='absolute top-0 left-0 z-0 h-[282px] w-full'>
            <MapContainer center={mapCenter} zoom={13} className='h-[282px] w-full rounded-md' zoomControl={false}>
               <TileLayer
                  url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
               />
               <LocationMarker />
               <ZoomControl position='bottomright' />
            </MapContainer>
         </div>
         {address && (
            <div className='absolute bottom-4 left-1/2 z-10 flex -translate-x-1/2 transform justify-center'>
               <button
                  type='button'
                  className='rounded-md bg-blue-500 px-4 py-2 text-white shadow-md'
                  onClick={handleAdjustPin}
               >
                  {t('chef_add_page.adjust_location')}
               </button>
            </div>
         )}
      </div>
   )
}
