import { RestClient } from '@/config/rest-client'
import { IUser } from '@/model/user.model'

const apiUrl = 'api/users-details'

export const getUserDetailsByLogin = (userLogin: string) => {
   return RestClient.get<IUser>(apiUrl + '/by-login/' + userLogin)
}
export const getUserDetailsById = (userId: string) => {
   return RestClient.get<IUser>(apiUrl + '/by-id/' + userId)
}
