import React from 'react'
import { haversineDistance } from '@/util/other-utils'
import { Link } from 'react-router-dom'
import { UserLoginComponent, UserLoginComponentType } from '@/layout/common/UserLoginComponent'
import { Chef } from '@/model/chef.model'
import { ChefStars } from '@/layout/common/ChefStars'

import { useAppSelector } from '@/config/store'
import { TfiLocationPin } from 'react-icons/tfi'
import { useTranslation } from 'react-i18next'
import { DeliveryType } from '@/model/enumerations/delivery-type.model'
import { GiKnifeFork } from 'react-icons/gi'
import { BackgroundPictureWithHeart, PictureMode } from '@/layout/chef/pictures/BackgroundPictureWithHeart'
import { GoPackage } from 'react-icons/go'
import { isDesktop, isMobile } from 'react-device-detect'

interface ChefInfoProps {
   chef: Chef
}

export const ChefInfo = ({ chef }: ChefInfoProps) => {
   return (
      <div className='flex flex-col items-center'>
         <UserLoginComponent
            addedStylesForIcon={'stroke-white'}
            userId={chef.creatorId}
            avatar={chef.avatar}
            type={UserLoginComponentType.ONLY_ICON}
         />
         <h2 className='text-base'>
            {chef.firstName} {chef.lastName}
         </h2>
      </div>
   )
}

interface SingleChefCardProps {
   chef: Chef
   starrClicked?: (starred: boolean) => void
   sliderNavigationOnHover?: boolean
   compactMode?: boolean
   fullWidth?: boolean
}

// todo compact mode
export const SingleChefCard = ({
   chef,
   starrClicked = (starred: boolean) => {},
   sliderNavigationOnHover = false,
   compactMode = false,
   fullWidth = false,
}: SingleChefCardProps) => {
   const { t } = useTranslation()
   const { currentUserLocation } = useAppSelector(state => state.search)
   const linkChefShortName = `/chef/${chef.shortName}`
   const locale = useAppSelector(state => state.localeSlice.lng)

   let BackgroundPictureWithUserAndStars = (
      <div
         key={chef?.id}
         className={`relative w-full rounded-t-lg bg-gray-300 ${compactMode ? 'h-24' : 'h-52 max-h-52 min-h-52'}`}
      >
         <BackgroundPictureWithHeart
            pictureMode={{
               mode: PictureMode.SLIDER,
               dark: false,
               sliderNavigationOnHover,
               compactButtons: compactMode,
            }}
            chef={chef}
            starrClicked={starrClicked}
            showHeart={true}
            rounded={'rounded-t-lg rounded-b-none'}
         />

         <div
            className={`absolute flex flex-col items-center space-y-1 ${
               compactMode ? '-bottom-6 left-2' : 'right-5 -bottom-16'
            }`}
            style={{ zIndex: 10 }}
         >
            {!compactMode && (
               <UserLoginComponent
                  userId={chef.creatorId}
                  avatar={chef.avatar}
                  type={UserLoginComponentType.ONLY_ICON}
                  addedStylesForIcon={`stroke-gray-100 ${compactMode ? 'min-w-[40px] min-h-[40px] w-[40px] h-[40px]' : 'min-w-[65px] min-h-[65px] w-[65px] h-[65px]'}`}
               />
            )}
            <div className={`mb-1 ${compactMode ? 'text-sm' : ''}`}>
               {chef.firstName} {chef.lastName}
            </div>
            {!compactMode && (
               <ChefStars
                  averageVote={chef?.averageVote}
                  votesCount={chef?.votesCount}
                  withCount={true}
                  emptyColor={'text-gray-400'}
                  iconSize={18}
               />
            )}
         </div>

         <div
            className={`group absolute top-3 left-2 z-10 flex max-w-[70%] flex-row items-center rounded-xl bg-white/80 px-1.5 py-1 pr-1.5 text-lg text-black select-none ${isMobile ? (chef.dietary?.length > 0 ? 'text-xs' : 'text-sm') : 'text-base'}`}
         >
            {chef.pickUpPoint && <GoPackage className='mr-1 h-5 w-5 text-black' />}
            {chef?.name}
         </div>
      </div>
   )

   let smallSpaces = locale == 'fr' && chef?.deliveryTypes?.length == 3
   return (
      // it is for in map popup
      <Link
         style={{ textDecoration: 'none', color: 'inherit' }}
         to={linkChefShortName}
         className={`relative flex flex-col justify-between rounded-lg bg-white shadow-lg hover:cursor-pointer ${compactMode ? 'h-[120px] min-h-[120px] w-[180px]' : 'h-[310px] w-[320px]'} ${fullWidth && 'w-full'}`}
      >
         {BackgroundPictureWithUserAndStars}
         <div
            className={`absolute bottom-0 flex h-[98px] w-full flex-col justify-between overflow-hidden ${isDesktop ? 'md: pb-0.5 pl-2' : 'xs:pl-1 pt-0.5 pb-0.5 pl-2'} `}
         >
            <div className='flex flex-col overflow-hidden'>
               {!compactMode && (
                  <div className='flex h-6 flex-row items-center overflow-hidden'>
                     <TfiLocationPin className='mr-0.5 ml-0.5 h-4 w-4 shrink-0 text-blue-800' />
                     {currentUserLocation ? (
                        <p className='text-[13px] font-semibold text-gray-700'>
                           {haversineDistance(
                              [currentUserLocation?.longitude, currentUserLocation?.latitude],
                              [chef.address?.longitude, chef.address?.latitude],
                           )}{' '}
                           km
                        </p>
                     ) : (
                        <p className='line-clamp-1 max-w-[60%] text-base font-semibold'>
                           {chef.address?.locationName && chef.address?.locationName.trim() !== ''
                              ? chef.address?.locationName
                              : chef?.address?.villageTownCity && chef?.address?.villageTownCity.trim() !== ''
                                ? chef?.address?.villageTownCity
                                : ''}
                        </p>
                     )}
                  </div>
               )}
               <div
                  className={`line-clamp-2 w-full pl-1 ${isMobile ? (chef.dietary?.length > 2 ? 'text-xs' : 'text-sm') : 'text-[15px]'}`}
               >
                  <div className={'max-h-[43px]'}>
                     {chef.dietary?.length > 0 && !compactMode && (
                        <div className=' flex max-w-[65%] flex-wrap items-center gap-x-1'>
                           <GiKnifeFork className='h-[14px] w-[14px] shrink-0 text-gray-900' />
                           {chef.cuisines.map((c, index) => (
                              <span key={c} className='flex h-[19px] items-center'>
                                 {index > 0 && <span className='mr-1'>•</span>}
                                 <p className='truncate'>{t('cuisine.' + c)}</p>
                              </span>
                           ))}
                           {chef.cuisines.length > 0 && chef.dietary.length > 0 && <span className='mr-0'>•</span>}
                           {chef.dietary.map((d, index) => (
                              <span key={d} className='flex h-[19px] items-center'>
                                 {index > 0 && <span className='mr-1'>•</span>}
                                 <p className='truncate'>{t('dietary.' + d.toLowerCase())}</p>
                              </span>
                           ))}
                        </div>
                     )}
                  </div>

                  {!compactMode && chef.pickUpPoint && chef.chefsOrdPointsIds?.length > 0 && (
                     <div className='flex items-center gap-1 -ml-[2px]'>
                        <GoPackage className='h-4 w-4 shrink-0 text-gray-900' />
                        <span className='truncate'>
                           {t('chef_profile.chefs_deliver_here', { count: chef.chefsOrdPointsIds?.length })}
                        </span>
                     </div>
                  )}
               </div>
            </div>
            <RenderDeliveryType
               deliveryTypes={chef?.deliveryTypes}
               isPickUpPoint={chef?.pickUpPoint}
               smallSpaces={smallSpaces}
               customStyles={`ml-1 text-customPurple mb-2 ${smallSpaces ? 'text-[13px]' : isMobile ? (chef?.deliveryTypes.length > 2 ? 'text-xs' : 'text-sm') : 'text-[15px]'}`}
            />
         </div>
      </Link>
   )
}

interface RenderDeliveryTypeProps {
   deliveryTypes: DeliveryType[]
   customStyles?: string
   onDeliveryTypeClicked?: (delivery: DeliveryType) => void
   isPickUpPoint: boolean
   smallSpaces?: boolean
}

export const RenderDeliveryType = ({
   deliveryTypes,
   customStyles,
   onDeliveryTypeClicked,
   isPickUpPoint,
   smallSpaces = false,
}: RenderDeliveryTypeProps) => {
   const { t } = useTranslation()

   if (deliveryTypes.length === 0) return <div></div>

   const renderType = (deliveryType: DeliveryType) => {
      switch (deliveryType) {
         case DeliveryType.PICKUP:
            return t(isPickUpPoint ? 'chef_add_page.collect_at_point' : 'chef_add_page.pickup')
         case DeliveryType.DELIVER_TO_PICKUP_POINT:
            return t('chef_add_page.deliver_to_pickup_point')
         case DeliveryType.DINE_IN:
            return t(isPickUpPoint ? 'chef_add_page.eat_at_point' : 'chef_add_page.dine_in')
         default:
            return null
      }
   }

   return (
      <span className={`${customStyles || `flex flex-row flex-wrap ${isMobile ? 'text-xs' : 'text-sm'}`} leading-none`}>
         {deliveryTypes.map((type, index) => (
            <span
               onClick={() => {
                  if (onDeliveryTypeClicked) {
                     onDeliveryTypeClicked(type)
                  }
               }}
               className={`inline-flex items-center ${onDeliveryTypeClicked ? 'hover:cursor-pointer hover:text-blue-300' : ''}`}
               key={type}
            >
               {index > 0 && <span className={`${smallSpaces ? 'mx-0.5': 'mx-1'}`}>•</span>}
               {renderType(type)}
            </span>
         ))}
      </span>
   )
}
