import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useRouteError } from 'react-router-dom'
import { notifyAboutError } from '@/service/notification/notification-service'
import { isMobile } from 'react-device-detect'

export const ErrorPage = () => {
   const { t } = useTranslation()
   const error = useRouteError()

   console.log('Error: ', error)

   if (
      // @ts-ignore
      error?.message.includes('Failed to fetch dynamically imported module') ||
      // @ts-ignore
      error?.message.includes('Importing a module script failed')
   ) {
      if (!window.location.pathname) {
         window.location.reload()
      } else {
         // @ts-ignore
         window.location = window.location.pathname
      }
   }
   // @ts-ignore
   notifyAboutError(error?.message).then(() => {})

   return (
      <div className='flex min-h-screen items-center justify-center bg-gray-100'>
         <div className='w-96 rounded-xl bg-white p-8 shadow-md'>
            <div className='flex justify-center'>
               <svg
                  className='mb-4 h-16 w-16 text-red-500'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
               >
                  <path
                     strokeLinecap='round'
                     strokeLinejoin='round'
                     strokeWidth='2'
                     d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L4.34 16c-.77 1.333.192 3 1.732 3z'
                  ></path>
               </svg>
            </div>
            <h2 className='mb-4 text-center text-xl font-bold'>{t('error_page.header')}</h2>
            <p className='mb-4 text-center text-gray-600'>{t('error_page.info')}</p>
            <div className='text-center'>
               <a href='/' className='text-indigo-500 hover:underline'>
                  {t('error_page.go_back')}
               </a>
            </div>
         </div>
      </div>
   )
}

export const NotFoundPage = () => {
   const { t } = useTranslation()
   const location = useLocation()

   notifyAboutError('Page not found' + location.pathname).then(() => {})

   return (
      <div className={`flex min-h-screen w-full bg-gray-100 ${isMobile ? 'mt-[60px]' : 'mt-[70px]'}`}>
         <div className='w-full rounded-xl bg-white p-8 shadow-md'>
            <div className='flex justify-center'>
               <svg
                  className='mb-4 h-16 w-16 text-red-500'
                  fill='none'
                  stroke='currentColor'
                  viewBox='0 0 24 24'
                  xmlns='http://www.w3.org/2000/svg'
               >
                  <path
                     strokeLinecap='round'
                     strokeLinejoin='round'
                     strokeWidth='2'
                     d='M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L4.34 16c-.77 1.333.192 3 1.732 3z'
                  ></path>
               </svg>
            </div>
            <h2 className='mb-4 text-center text-xl font-bold'>{t('error_page.header_not_found')}</h2>
            <p className='mb-4 text-center text-gray-600'>{t('error_page.info_not_found')}</p>
            <div className='text-center'>
               <a href='/' className='text-indigo-500 hover:underline'>
                  {t('error_page.go_back')}
               </a>
            </div>
         </div>
      </div>
   )
}
