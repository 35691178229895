import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getNotifications } from '@/service/notification/notification-service'
import { isUserLoggedIn } from '@/util/auth-util'
import { useAppSelector } from '@/config/store'
import { SingleNotification } from '@/layout/notification/SingleNotification'
import { LoadingBalls } from '@/layout/common/LoadingIndicator'
import { INotification } from '@/model/notification/notification.model'
import { isMobile } from 'react-device-detect'

export const NotificationsPage = () => {
   const { t } = useTranslation()
   const [nots, setNots] = useState<INotification[]>([])
   const [loading, setLoading] = useState(false)

   const [paginationState, setPaginationState] = useState({
      activePage: 0,
      sort: 'createDate',
      totalItems: 0,
   })

   const loggedAccount = useAppSelector(state => state.authentication.account)
   const [activeTab, setActiveTab] = useState('all')

   useEffect(() => {
      window.scrollTo(0, 0)
      if (!isUserLoggedIn(loggedAccount)) {
         return
      }
      setNots([])
      loadNotifications()
   }, [loggedAccount, activeTab])

   function loadNotifications() {
      setLoading(true)
      getNotifications({
         page: paginationState.activePage,
         onlyUnread: activeTab === 'unread',
      })
         .then((response: any) => {
            setNots([...nots, ...response.data])
            // todo decide if it should be used
            // let everyShowed = response.headers[HEADER_EVERY_SHOWED];
            // if (everyShowed != 'true') {
            // 	dispatch(setHasNewNotifications());
            // } else if (hasNewNotifications) {
            // 	dispatch(clearHasNewNotifications());
            // }

            setPaginationState(prevState => ({
               ...prevState,
               totalItems: response.headers['x-total-count'],
               activePage: prevState.activePage + 1,
            }))
         })
         .finally(() => {
            setLoading(false)
         })
   }

   const handleDeleteNotification = id => {
      setNots(nots.filter(n => n.id !== id))
   }

   function getFiltersForNotifications() {
      return (
         <div className='flex flex-row justify-start py-1.5 pl-2'>
            <div
               className={`cursor-pointer rounded-full px-2 py-1 ${activeTab === 'all' ? 'bg-blue-300 dark:bg-blue-500' : ''}`}
               onClick={() => {
                  setNots([])
                  setActiveTab('all')
                  setPaginationState(prevState => ({
                     ...prevState,
                     activePage: 0,
                     totalItems: 0,
                  }))
               }}
            >
               {t('notifications.all')}
            </div>

            <div
               className={`cursor-pointer rounded-full px-2 py-1 ${activeTab === 'unread' ? 'bg-blue-300 dark:bg-blue-500' : ''}`}
               onClick={() => {
                  setNots([])
                  setPaginationState(prevState => ({
                     ...prevState,
                     activePage: 0,
                     totalItems: 0,
                  }))
                  setActiveTab('unread')
               }}
            >
               {t('notifications.unread')}
            </div>
         </div>
      )
   }

   return (
      <div className={`rounded-normal background-normal mx-auto mb-4 flex w-full flex-col pr-2 pb-2 pl-2 shadow-md ${isMobile ? 'mt-[60px]' : ''}`}>
         <div className='mt-3 ml-2 text-2xl'>{t('notifications.nots')}</div>
         {getFiltersForNotifications()}

         <div className='w-full divide-y divide-gray-400 py-1 opacity-70 dark:divide-gray-500'>
            {nots.map(n => {
               return (
                  <div className='pl-1'>
                     <SingleNotification key={n.id} not={n} onDelete={handleDeleteNotification} />
                  </div>
               )
            })}
            {nots.length === 0 && (
               <div>
                  <div className='w-full items-center rounded-md px-2 py-2 text-sm break-words'>
                     {t('notifications.none')}
                  </div>
               </div>
            )}
            {paginationState.totalItems > nots.length && (
               <div className='mt-1 flex items-center justify-center py-2'>
                  <button
                     className={`hover-color rounded-normal px-2 py-1 text-xl`}
                     onClick={() => {
                        loadNotifications()
                     }}
                  >
                     {t('notifications.show_more')}
                  </button>
               </div>
            )}
            {loading && <LoadingBalls />}
         </div>
      </div>
   )
}

export default NotificationsPage
