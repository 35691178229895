import type {
   DOMConversionMap,
   DOMConversionOutput,
   DOMExportOutput,
   EditorConfig,
   LexicalEditor,
   LexicalNode,
   NodeKey,
   SerializedEditor,
   SerializedLexicalNode,
   Spread,
} from 'lexical'
import { $applyNodeReplacement, createEditor, DecoratorNode } from 'lexical'
import * as React from 'react'
import { Suspense, useState } from 'react'
import { isDesktop, isMobile } from 'react-device-detect'
import { CDN_ENDPOINT } from '@/env'
import { ImageRoundness } from '@/layout/common/lexical-editor/lexical-util/InsertImageModal'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import {useLexicalEditable} from '@lexical/react/useLexicalEditable'

export enum ImageMode {
   LEFT = 'LEFT',
   RIGHT = 'RIGHT',
   FULL_WIDTH = 'FULL_WIDTH',
}

export interface ImagePayload {
   altText: string
   caption?: LexicalEditor
   height?: number
   key?: NodeKey
   maxWidth?: number
   showCaption?: boolean
   imageURL: string
   width?: number
   captionsEnabled?: boolean
   imageMode: ImageMode
   roundness?: ImageRoundness
   additionalStylesMobile?: string
   additionalStylesWeb?: string
}

function convertImageElement(domNode: HTMLElement): null | DOMConversionOutput {
   if (domNode instanceof HTMLImageElement) {
      const altText = domNode.getAttribute('alt')?.trim() || ''
      const imageURL = domNode.getAttribute('image-url')?.trim() || ''
      const imageMode = (domNode.getAttribute('image-mode')?.trim() || ImageMode.FULL_WIDTH) as ImageMode
      const width = domNode.getAttribute('width') ? parseInt(domNode.getAttribute('width')!.trim()) : undefined
      const height = domNode.getAttribute('height') ? parseInt(domNode.getAttribute('height')!.trim()) : undefined
      const roundness = domNode.getAttribute('roundness') as ImageRoundness | ImageRoundness.NONE
      const additionalStylesWeb = domNode.getAttribute('data-additional-styles-web')?.trim() || ''
      const additionalStylesMobile = domNode.getAttribute('data-additional-styles-mobile')?.trim() || ''

      if (!imageURL) {
         console.error('ImageElement has no valid imageURL:', domNode.outerHTML)
         return null
      }

      const node = $createImageNode({
         altText,
         height,
         imageURL,
         width,
         roundness,
         imageMode,
         additionalStylesWeb,
         additionalStylesMobile,
      })

      return { node }
   }

   console.error('DOM Node is not an HTMLImageElement:', domNode.outerHTML)
   return null
}

export type SerializedImageNode = Spread<
   {
      altText: string
      caption: SerializedEditor
      height?: number
      maxWidth: number
      showCaption: boolean
      imageURL: string
      width?: number
      imageMode: ImageMode
      roundness: ImageRoundness
      additionalStylesWeb: string
      additionalStylesMobile: string
   },
   SerializedLexicalNode
>

export class ImageNode extends DecoratorNode<React.ReactNode> {
   __imageURL: string
   __altText: string
   __width: 'inherit' | number
   __height: 'inherit' | number
   __maxWidth: number
   __showCaption: boolean
   __caption: LexicalEditor
   __captionsEnabled: boolean
   __imageMode: ImageMode
   __roundness: ImageRoundness
   __additionalStylesWeb: string
   __additionalStylesMobile: string

   constructor(
      imageURL: string,
      altText: string,
      maxWidth: number,
      width?: 'inherit' | number,
      height?: 'inherit' | number,
      showCaption?: boolean,
      caption?: LexicalEditor,
      captionsEnabled?: boolean,
      imageMode: ImageMode = ImageMode.FULL_WIDTH,
      roundness: ImageRoundness = ImageRoundness.NONE,
      additionalStylesWeb: string = '',
      additionalStylesMobile: string = '',
      key?: NodeKey,
   ) {
      super(key)
      this.__imageURL = imageURL
      this.__altText = altText
      this.__maxWidth = maxWidth
      this.__width = width || 'inherit'
      this.__height = height || 'inherit'
      this.__showCaption = showCaption || false
      this.__caption = caption || createEditor()
      this.__imageMode = imageMode || ImageMode.FULL_WIDTH
      this.__captionsEnabled = captionsEnabled ?? true
      this.__roundness = roundness
      this.__additionalStylesWeb = additionalStylesWeb
      this.__additionalStylesMobile = additionalStylesMobile
   }

   static getType(): string {
      return 'image'
   }

   static clone(node: ImageNode): ImageNode {
      return new ImageNode(
         node.__imageURL,
         node.__altText,
         node.__maxWidth,
         node.__width,
         node.__height,
         node.__showCaption,
         node.__caption,
         node.__captionsEnabled,
         node.__imageMode,
         node.__roundness,
         node.__additionalStylesWeb,
         node.__additionalStylesMobile,
         node.__key,
      )
   }

   static importJSON(serializedNode: SerializedImageNode): ImageNode {
      const {
         altText,
         height,
         width,
         maxWidth,
         caption,
         imageURL,
         showCaption,
         imageMode,
         roundness,
         additionalStylesWeb,
         additionalStylesMobile,
      } = serializedNode
      const node = $createImageNode({
         altText,
         height,
         maxWidth,
         showCaption,
         imageURL,
         width,
         imageMode,
         roundness,
         additionalStylesWeb,
         additionalStylesMobile,
      })
      const nestedEditor = node.__caption
      const editorState = nestedEditor.parseEditorState(caption.editorState)

      if (!editorState.isEmpty()) nestedEditor.setEditorState(editorState)

      return node
   }

   static importDOM(): DOMConversionMap | null {
      return {
         img: (node: HTMLElement) => ({
            conversion: convertImageElement,
            priority: 2,
         }),
      }
   }

   exportDOM(): DOMExportOutput {
      const element = document.createElement('img')
      element.setAttribute('image-url', this.__imageURL.trim())
      element.setAttribute('alt', this.__altText.trim())
      element.setAttribute('width', this.__width === 'inherit' ? 'inherit' : this.__width.toString().trim())
      element.setAttribute('height', this.__height === 'inherit' ? 'inherit' : this.__height.toString().trim())
      element.setAttribute('image-mode', this.__imageMode.trim())
      element.setAttribute('roundness', this.__roundness)
      if (this.__additionalStylesWeb) {
         element.setAttribute('data-additional-styles-web', this.__additionalStylesWeb)
      }
      if (this.__additionalStylesMobile) {
         element.setAttribute('data-additional-styles-mobile', this.__additionalStylesMobile)
      }
      element.setAttribute('lexical-image-node', 'true')
      return { element }
   }

   exportJSON(): SerializedImageNode {
      return {
         altText: this.__altText,
         caption: this.__caption.toJSON(),
         height: this.__height === 'inherit' ? 0 : this.__height,
         maxWidth: this.__maxWidth,
         showCaption: this.__showCaption,
         imageURL: this.__imageURL,
         type: 'image',
         version: 1,
         imageMode: this.__imageMode,
         roundness: this.__roundness,
         additionalStylesWeb: this.__additionalStylesWeb,
         additionalStylesMobile: this.__additionalStylesMobile,
         width: this.__width === 'inherit' ? 0 : this.__width,
      }
   }

   createDOM(config: EditorConfig): HTMLElement {
      const span = document.createElement('span')
      const theme = config.theme
      const className = theme.image
      if (className !== undefined) {
         span.className = className
      }
      return span
   }

   updateDOM(): false {
      return false
   }

   getAltText(): string {
      return this.__altText
   }

   decorate(): React.ReactNode {
      let alignmentClass = ''
      switch (this.__imageMode) {
         case ImageMode.LEFT:
            alignmentClass = `float-left`
            break
         case ImageMode.RIGHT:
            alignmentClass = `float-right`
            break
         case ImageMode.FULL_WIDTH:
            alignmentClass = 'block mx-auto'
            break
      }

      return (
         <Suspense fallback={null}>
            <div>
               <ImageComponent
                  roundness={this.__roundness}
                  alignmentClass={alignmentClass}
                  imageURL={this.__imageURL}
                  altText={this.__altText}
                  width={this.__width}
                  height={this.__height}
                  imageMode={this.__imageMode}
                  additionalStylesWeb={this.__additionalStylesWeb}
                  additionalStylesMobile={this.__additionalStylesMobile}
               />
            </div>
         </Suspense>
      )
   }
}

export function $createImageNode({
   altText,
   height,
   maxWidth = isMobile ? 200 : 400,
   captionsEnabled,
   imageURL,
   width,
   showCaption,
   caption,
   key,
   imageMode,
   roundness = ImageRoundness.NONE,
   additionalStylesWeb = '',
   additionalStylesMobile = '',
}: ImagePayload): ImageNode {
   if (!imageURL) {
      console.error('Attempting to create ImageNode without a valid imageURL.')
   }

   return $applyNodeReplacement(
      new ImageNode(
         imageURL,
         altText,
         maxWidth,
         width,
         height,
         showCaption,
         caption,
         captionsEnabled,
         imageMode,
         roundness,
         additionalStylesWeb,
         additionalStylesMobile,
         key,
      ),
   )
}

export function $isImageNode(node: LexicalNode | null | undefined): node is ImageNode {
   return node instanceof ImageNode
}

function ImageComponent({
   roundness,
   alignmentClass,
   imageURL,
   altText,
   width,
   height,
   imageMode,
   additionalStylesWeb,
   additionalStylesMobile,
}) {
   const isEditable = useLexicalEditable()
   const [isModalOpen, setIsModalOpen] = useState(false)

   const handleEditClick = () => {
      setIsModalOpen(true)
   }

   const closeModal = () => {
      setIsModalOpen(false)
   }

   function parseUserStyles(styleString) {
      const styleObj = {}
      const declarations = styleString.split(';').filter(Boolean)

      for (const decl of declarations) {
         const [property, value] = decl.split(':').map(part => part.trim())
         if (property && value) {
            // Convert kebab-case CSS property to camelCase for React style
            const camelCasedProperty = property.replace(/-([a-z])/g, (_, char) => char.toUpperCase())
            styleObj[camelCasedProperty] = value
         }
      }

      return styleObj
   }

   return (
      <div className='relative'>
         {/*dummy div for precompiled tailwind classes*/}
         <div className='hidden rounded-2xl rounded-full rounded-lg rounded-md rounded-none rounded-xl rounded-xs'></div>
         {isEditable && (
            <div
               onClick={handleEditClick}
               className='absolute top-0 right-0 rounded-full bg-white p-2 text-black shadow-lg'
               aria-label='Edit Image'
            >
               ✏️
            </div>
         )}
         <div
            style={{ width, height, ...parseUserStyles(isDesktop ? additionalStylesWeb : additionalStylesMobile) }}
            className={`${alignmentClass} overflow-hidden ${roundness}`}
         >
            <img className={`h-auto w-full object-cover`} src={CDN_ENDPOINT + imageURL} alt={altText} />
         </div>
         {isModalOpen && (
            <EditImageModal
               imageURL={imageURL}
               altText={altText}
               width={width}
               height={height}
               onClose={closeModal}
               roundness={roundness}
               imageMode={imageMode}
               additionalStylesWeb={additionalStylesWeb}
               additionalStylesMobile={additionalStylesMobile}
            />
         )}
      </div>
   )
}

function EditImageModal({
   imageURL,
   altText,
   width,
   height,
   roundness,
   imageMode,
   additionalStylesWeb,
   additionalStylesMobile,
   onClose,
}: {
   imageURL: string
   altText: string
   width: number
   height: number
   roundness: ImageRoundness
   imageMode: ImageMode
   additionalStylesWeb: string
   additionalStylesMobile: string
   onClose: () => void
}) {
   const [newAltText, setNewAltText] = useState(altText)
   const [newWidth, setNewWidth] = useState(width)
   const [newHeight, setNewHeight] = useState(height)
   const [newRoundness, setNewRoundness] = useState<ImageRoundness>(roundness)
   const [newImageMode, setNewImageMode] = useState<ImageMode>(imageMode)
   const [newAdditionalStylesWeb, setNewAdditionalStylesWeb] = useState(additionalStylesWeb)
   const [newAdditionalStylesMobile, setNewAdditionalStylesMobile] = useState(additionalStylesMobile)
   const [editor] = useLexicalComposerContext()

   const handleSave = () => {
      editor.update(() => {
         const editorState = editor.getEditorState()
         const nodes = editorState.read(() => {
            const nodeMap = Array.from(editorState._nodeMap.values())
            const allNodes = nodeMap.filter(node => $isImageNode(node) && node.__imageURL === imageURL)
            return allNodes
         })

         if (nodes.length > 0) {
            const imageNode = nodes[0]

            const updatedNode = $createImageNode({
               altText: newAltText,
               height: newHeight,
               width: newWidth,
               imageURL,
               maxWidth: isMobile ? 200 : 400,
               roundness: newRoundness,
               imageMode: newImageMode,
               additionalStylesMobile: newAdditionalStylesMobile,
               additionalStylesWeb: newAdditionalStylesWeb,
            })

            imageNode.replace(updatedNode)
         } else {
            console.error('ImageNode not found in node map')
         }
      })

      onClose()
   }

   return (
      <div className='bg-opacity-50 fixed inset-0 z-50 flex items-center justify-center bg-black'>
         <div className='rounded-lg bg-white p-6 shadow-lg'>
            <h2 className='mb-4 text-xl font-bold'>Edit Image</h2>
            <label className='mb-2 block'>
               Alt Text:
               <input
                  type='text'
                  value={newAltText}
                  onChange={e => setNewAltText(e.target.value)}
                  className='w-full rounded-sm border p-2'
               />
            </label>
            <label className='mb-2 block'>
               Width:
               <input
                  type='number'
                  value={newWidth}
                  onChange={e => setNewWidth(Number(e.target.value))}
                  className='w-full rounded-sm border p-2'
               />
            </label>
            <label className='mb-2 block'>
               Height:
               <input
                  type='number'
                  value={newHeight}
                  onChange={e => setNewHeight(Number(e.target.value))}
                  className='w-full rounded-sm border p-2'
               />
            </label>

            {/* Image Position */}
            <div className='mb-4'>
               <label className='mb-2 block text-gray-700'>Image Position</label>
               <div className='flex space-x-2'>
                  {Object.values(ImageMode).map(mode => (
                     <button
                        key={mode}
                        type='button'
                        onClick={() => setNewImageMode(mode)}
                        className={`rounded px-4 py-2 ${
                           newImageMode === mode
                              ? 'bg-blue-500 text-white'
                              : 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-300'
                        }`}
                     >
                        {mode.replace('_', ' ')}
                     </button>
                  ))}
               </div>
            </div>

            {/* Image Roundness */}
            <div className='mb-4'>
               <label className='mb-2 block text-gray-700'>Image Roundness</label>
               <div className='flex flex-wrap space-x-2'>
                  {Object.values(ImageRoundness).map(round => (
                     <button
                        key={round}
                        type='button'
                        onClick={() => setNewRoundness(round)}
                        className={`mb-1 rounded px-4 py-2 ${
                           newRoundness === round
                              ? 'bg-blue-500 text-white'
                              : 'bg-gray-200 text-gray-800 dark:bg-gray-700 dark:text-gray-300'
                        }`}
                     >
                        {round.replace('rounded-', '').toUpperCase()}
                     </button>
                  ))}
               </div>
            </div>

            {/* Additional Styles Web*/}
            <div className='mb-4'>
               <label className='mb-2 block text-gray-700'>Additional Styles Web:</label>
               <input
                  type='text'
                  value={newAdditionalStylesWeb}
                  onChange={e => setNewAdditionalStylesWeb(e.target.value)}
                  className='w-full resize rounded-sm border p-2'
               />
            </div>

            {/* Additional Styles Mobile*/}
            <div className='mb-4'>
               <label className='mb-2 block text-gray-700'>Additional Styles Mobile:</label>
               <input
                  type='text'
                  value={newAdditionalStylesMobile}
                  onChange={e => setNewAdditionalStylesMobile(e.target.value)}
                  className='w-full resize rounded-sm border p-2'
               />
            </div>

            <div className='flex justify-end space-x-4'>
               <div onClick={onClose} className='cursor-pointer rounded-sm bg-gray-300 px-4 py-2'>
                  Cancel
               </div>
               <div onClick={handleSave} className='cursor-pointer rounded-sm bg-blue-500 px-4 py-2 text-white'>
                  Save
               </div>
            </div>
         </div>
      </div>
   )
}
