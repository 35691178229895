import React from 'react'
import { CDN_ENDPOINT } from '@/env'
import { HiOutlineUserCircle } from 'react-icons/hi' // import your default icon

interface UserIconComponentProps {
   picture: string
   addedStyles?: string
   filledBackground?: boolean
}

export const UserIconComponent = ({
   picture,
   addedStyles = null,
   filledBackground = false,
}: UserIconComponentProps) => {
   const [imgError, setImgError] = React.useState(false) // state to handle image error

   const handleImgError = () => {
      setImgError(true) // update the state when image error occurs
   }

   return (
      <>
         {picture && !imgError ? (
            <img
               className={`h-[38px] min-h-[38px] w-[38px] min-w-[38px] rounded-full border-2 border-white object-cover ${addedStyles}`}
               src={CDN_ENDPOINT + picture}
               onError={handleImgError} // handle image error
               alt={'user-icon'}
            />
         ) : (
            <HiOutlineUserCircle
               className={`h-[38px] min-h-[38px] w-[38px] min-w-[38px] stroke-gray-500 stroke-[1.5px] ${filledBackground ? 'rounded-full bg-gray-100' : ''} ${addedStyles}`}
            />
         )}
      </>
   )
}
