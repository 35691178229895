import { RestClient } from '@/config/rest-client'
import { UserLocation } from '@/util/UserLocalizations'

export const fetchShortAddressName = async (lat: number, lon: number) => {
   try {
      const response = await RestClient.get('https://nominatim.openstreetmap.org/reverse', {
         params: { lat, lon, format: 'json' },
      })

      // @ts-ignore
      const { address } = response.data
      const location = address.city || address.village || address.town || address.hamlet || ''
      const road = address.road || ''

      return location && road ? `${road}, ${location}` : location || ''
   } catch (error) {
      console.error('Error fetching address:', error.message || error)
      return 'Unknown location'
   }
}

export async function getIPGeolocation(): Promise<UserLocation | null> {
   try {
      const response = await RestClient.get<any>('http://ip-api.com/json')
      if (response.data && response.data.lat && response.data.lon) {
         const { lat, lon } = response.data
         const cityResponse = await RestClient.get<any>(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}&zoom=10`)
         return {
            longitude: lon,
            latitude: lat,
            locationName: cityResponse.data.address.city || cityResponse.data.address.town || cityResponse.data.address.state || '',
            coordinatesVerified: false,
         }
      }
   } catch (error) {
      console.error('Error fetching IP geolocation:', error)
   }
   return null
}
