import dayjs from 'dayjs'
import { Offer, OfferInfoFood, OfferPerWeekDaysFood, OfferType } from '@/model/order/food.model'
import { days } from '@/util/date-utils'
import { FoodOrderItem } from '@/model/order/FoodOrderItem'
import { Chef, OfferPerWeekdaysChef } from '@/model/chef.model'
import { DeliveryType } from '@/model/enumerations/delivery-type.model'
import { UnavailableInfo } from '@/layout/shopping_cart/DateTimePickerForOrder'

export const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

interface isAllAvailableForDayProps {
   day: dayjs.Dayjs
   orders: FoodOrderItem[]
}

// todo that should return UnavailableInfo[] too
export const isAllAvailableForDay = ({
                                        day,
                                        orders,
                                     }: isAllAvailableForDayProps): { isAllAvailable: boolean; unavailable: UnavailableInfo[] } => {
   const unavailable: UnavailableInfo[] = []
   console.log("day", day.format('YYYY-MM-DD'), 'orders', orders)

   for (const order of orders) {
      if (order.food.offer.offerType == OfferType.FIXED_OFFER) {
         const dayName = day.format('dddd').toLowerCase()
         // offer info should have type OfferInfoFood
         const offerInfo: OfferInfoFood = order.food.offer.operatingHours?.[dayName]

         if (!offerInfo || !offerInfo.amountServed) {
            unavailable.push({ foodId: order.food.id, reason: `Missing operating hours for ${dayName}` })
            continue
         }

         const dayDateStr = day.format('YYYY-MM-DD')

         let open: dayjs.Dayjs, close: dayjs.Dayjs
         // try {
         //    open = dayjs.tz(`${dayDateStr} ${offerInfo.amountServed}`, 'YYYY-MM-DD HH:mm', 'Europe/Paris')
         //    close = dayjs.tz(`${dayDateStr} ${offerInfo.close}`, 'YYYY-MM-DD HH:mm', 'Europe/Paris')
         // } catch (error) {
         //    unavailable.push({ foodId: order.food.id, reason: 'Error parsing open/close times' })
         //    continue
         // }

         // if (!open.isValid() || !close.isValid()) {
         //    unavailable.push({ foodId: order.food.id, reason: 'Invalid parsed open or close times' })
         //    continue
         // }
         //
         // if (day.isBefore(open) || day.isAfter(close)) {
         //    unavailable.push({ foodId: order.food.id, reason: 'Order time is outside operating hours' })
         // }
      } else if (order.food.offer.offerType == OfferType.ONE_TIME) {
         // Handle ONE_TIME offers
         const oneTimeOffers = order.food.offer.oneTimeOffers || []
         const matchingOffer = oneTimeOffers.find(o =>
            dayjs(o.date).format('YYYY-MM-DD') === day.format('YYYY-MM-DD')
         )

         if (!matchingOffer) {
            unavailable.push({ foodId: order.food.id, reason: 'No one-time offer available for this date' })
            continue
         }

         const offerInfo = matchingOffer.offerInfo
         if (!offerInfo || !offerInfo.amountServed) {
            unavailable.push({ foodId: order.food.id, reason: 'Missing operating hours for one-time offer' })
            continue
         }

         const dayDateStr = day.format('YYYY-MM-DD')
         let open: dayjs.Dayjs, close: dayjs.Dayjs
         // try {
         //    open = dayjs.tz(`${dayDateStr} ${offerInfo.open}`, 'YYYY-MM-DD HH:mm', 'Europe/Paris')
         //    close = dayjs.tz(`${dayDateStr} ${offerInfo.close}`, 'YYYY-MM-DD HH:mm', 'Europe/Paris')
         // } catch (error) {
         //    unavailable.push({ foodId: order.food.id, reason: 'Error parsing one-time offer times' })
         //    continue
         // }

         // if (!open.isValid() || !close.isValid()) {
         //    unavailable.push({ foodId: order.food.id, reason: 'Invalid parsed one-time offer times' })
         //    continue
         // }

         // if (day.isBefore(open) || day.isAfter(close)) {
         //    unavailable.push({ foodId: order.food.id, reason: 'Order time is outside one-time offer hours' })
         // }
      } else {
         // Unknown offer type
         unavailable.push({ foodId: order.food.id, reason: 'Unknown offer type' })
      }
   }

   return { isAllAvailable: unavailable.length === 0, unavailable }
}

export function getMaxServed(offer: Offer): number {
   let maxServed = 0

   if (offer.offerType === OfferType.FIXED_OFFER) {
      for (const day of days) {
         const offerInfo = offer.operatingHours?.[day]

         if (offerInfo) {
            maxServed = Math.max(maxServed, offerInfo.amountServed)
         }
      }
   } else if (offer.offerType === OfferType.ONE_TIME) {
      for (const oneTimeOffer of offer.oneTimeOffers) {
         maxServed = Math.max(maxServed, oneTimeOffer.offerInfo.amountServed)
      }
   }

   return maxServed
}

export const getOfferInfoChefForDeliveryType = (
   chef: Chef,
   deliveryType: DeliveryType,
): OfferPerWeekdaysChef | undefined => {
   if (deliveryType == DeliveryType.PICKUP) return chef.pickupHours
   else if (deliveryType == DeliveryType.DINE_IN) return chef.dineInHours
   else if (deliveryType == DeliveryType.DELIVER_TO_PICKUP_POINT) return chef.deliverToPickUpPointHours
   return undefined
}