import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import PaymentForm from './PaymentForm'
import { REACT_APP_STRIPE_PUBLISHABLE_KEY } from '@/env'
import { useAppSelector } from '@/config/store' // Your payment form component

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY)

const StripeContainer = ({ secret, paymentId }) => {
   const lng = useAppSelector(state => state.localeSlice.lng)
   return (
      // @ts-ignore
      <Elements stripe={stripePromise} options={{ clientSecret: secret, locale: lng }}>
         <PaymentForm paymentId={paymentId} />
      </Elements>
   )
}

export default StripeContainer
