import { Radio } from 'antd'
import { Food, OfferType } from '@/model/order/food.model'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { OperatingHoursInput } from '@/layout/add-chef/OpenHoursInput'
import { Control, FieldErrors, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import { OneTimeOfferInput } from '@/layout/add-chef/OneTimeOfferInput'
import { isMobile } from 'react-device-detect'
import { RadioChangeEvent } from 'antd/lib/radio'

interface FoodOfferSectionProps {
   watch: UseFormWatch<Food>
   errors: FieldErrors<Food>
   setValue: UseFormSetValue<Food>
   control: Control<Food>
   trigger?: any
   getValues: UseFormGetValues<Food>
}

export const FoodOfferSection = ({
   watch,
   errors,
   setValue,
   control,
   trigger,
   getValues,
}: FoodOfferSectionProps) => {
   const { t } = useTranslation()

   const handleOfferTypeChange = (e: RadioChangeEvent) => {
      setValue('offer.offerType', e.target.value as OfferType)
   }

   const renderOfferTypeFields = () => {
      switch (watch('offer.offerType')) {
         case OfferType.FIXED_OFFER:
            return (
               <div className='flex flex-col'>
                  <OperatingHoursInput
                     errors={errors}
                     watch={watch}
                     setValue={setValue}
                     trigger={trigger}
                     amountSection={true}
                     baseKey={'offer.'}
                     operatingHoursKey='operatingHours'
                     control={control}
                     getValues={getValues}
                     showOpenCloseSection={false}
                  />
               </div>
            )
         case OfferType.ONE_TIME:
            return (
               <div>
                  <OneTimeOfferInput errors={errors} setValue={setValue} />
               </div>
            )
         case OfferType.UNAVAILABLE:
            return <div className='w-full p-2 text-center font-semibold'>{t('food_add_page.unavailable_info')}</div>
         default:
            return null
      }
   }

   return (
      <div className={`flex w-full flex-col pt-2 ${isMobile ? 'text-xs' : ''}`}>
         <label className='mb-2 text-center text-lg font-semibold'>{t('food_add_page.offer_type')}</label>
         <div className='mb-4 flex justify-center'>
            <Radio.Group onChange={handleOfferTypeChange} value={watch('offer.offerType')}>
               <Radio value={OfferType.FIXED_OFFER}>{t('food_add_page.FIXED_OFFER')}</Radio>
               <Radio value={OfferType.ONE_TIME}>{t('food_add_page.one_time')}</Radio>
               <Radio value={OfferType.UNAVAILABLE}>{t('food_add_page.UNAVAILABLE')}</Radio>
            </Radio.Group>
         </div>
         {renderOfferTypeFields()}
      </div>
   )
}
