import React, { useEffect, useRef, useState } from 'react'
import { HiOutlineChevronDown } from 'react-icons/hi2'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { RiCheckboxBlankLine, RiCheckboxLine } from 'react-icons/ri'

interface DropdownFilterProps {
   selectedOptions?: string[]
   options: { [key: string]: string }
   onSelect: (value: string) => void
   onDeselect?: (value: string) => void
   label: string
   multiple?: boolean
   defaultOption?: string
   translatePrefix?: string
}

export const DropdownFilter = ({
   selectedOptions = [],
   options,
   onSelect,
   onDeselect,
   label,
   multiple = false,
   defaultOption,
   translatePrefix,
}: DropdownFilterProps) => {
   const { t } = useTranslation()
   const [isOpen, setIsOpen] = useState(false)
   const containerRef = useRef<HTMLDivElement>(null)
   const [internalSelectedOption, setInternalSelectedOption] = useState<string | undefined>(null)

   const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) setIsOpen(false)
   }

   useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
         document.removeEventListener('mousedown', handleClickOutside)
      }
   }, [])

   const handleSelect = (value: string) => {
      if (multiple) {
         if (selectedOptions?.includes(value)) {
            if (onDeselect) onDeselect(value)
         } else onSelect(value)
      } else {
         setInternalSelectedOption(value)
         onSelect(value)
      }
      if (!multiple) setIsOpen(false)
   }

   const optionsArray = Object.entries(options)

   const selectedLabel = multiple
      ? selectedOptions?.length > 0
         ? selectedOptions.map(option => t(translatePrefix + '.' + options[option])).join(' · ')
         : label
      : internalSelectedOption
        ? t(translatePrefix + '.' + options[internalSelectedOption])
        : label

   return (
      <div
         ref={containerRef}
         className={`xss:[text-15px] xss:min-w-[115px] relative mt-1 mb-1 flex min-w-[100px] flex-row bg-white text-[12px] shadow-md hover:cursor-pointer hover:bg-gray-100 ${isMobile ? 'items-start px-0.5' : 'mr-1.5 items-center justify-center px-1 lg:mr-3.5'} ${isOpen ? 'rounded-t-xl' : 'rounded-xl'}`}
         style={{ display: 'inline-block' }}
      >
         <div className='w-full' onClick={() => setIsOpen(!isOpen)}>
            <div className='rounded-normal flex h-9 w-full items-center justify-between px-2 py-1'>
               <div>{selectedLabel}</div>
               <HiOutlineChevronDown className='mr-[-4px] ml-0.5 h-5 w-5 shrink-0' />
            </div>
         </div>
         {isOpen && (
            <div
               className={`absolute -left-[0px] z-50 rounded-b-md bg-white shadow-xs transition duration-200 ease-in-out dark:bg-gray-800`}
               style={{
                  width: '100%',
                  maxHeight: '50vh',
                  overflowY: 'auto',
               }}
            >
               {optionsArray.map(([key, value], index) => {
                  const isLast = index === optionsArray.length - 1
                  const isSelected = multiple
                     ? selectedOptions.includes(key)
                     : internalSelectedOption === key || (!internalSelectedOption && defaultOption === key)

                  return (
                     <div
                        key={key}
                        className={`items-center py-2.5 hover:cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-700 ${!multiple && isSelected ? '' : ''} ${isLast ? 'rounded-b-md' : ''}`}
                        onClick={() => {
                           if (multiple) {
                              const isChecked = selectedOptions.includes(key)
                              if (isChecked) {
                                 if (onDeselect) {
                                    onDeselect(value)
                                 }
                              } else {
                                 onSelect(value)
                              }
                           } else {
                              handleSelect(key)
                           }
                        }}
                     >
                        <div className='flex w-full items-center'>
                           {multiple ? (
                              <div
                                 className={`mx-1 flex items-center justify-center ${isSelected ? 'text-customPurple' : 'text-black'} rounded-sm`}
                              >
                                 {isSelected ? (
                                    <RiCheckboxLine className='h-4 w-4' />
                                 ) : (
                                    <RiCheckboxBlankLine className='h-4 w-4' />
                                 )}
                              </div>
                           ) : (
                              <div
                                 className={`mx-1 flex items-center justify-center ${isSelected ? 'text-customPurple' : 'text-black'} rounded-full`}
                              >
                                 {isSelected ? (
                                    <RiCheckboxLine className='h-4 w-4' />
                                 ) : (
                                    <RiCheckboxBlankLine className='h-4 w-4' />
                                 )}
                              </div>
                           )}
                           <span className='w-full'>{t(translatePrefix + '.' + value)}</span>
                        </div>
                     </div>
                  )
               })}
            </div>
         )}
      </div>
   )
}
