import { Chef } from '@/model/chef.model'
import React from 'react'
import { CDN_ENDPOINT } from '@/env'
import { PictureModeProps } from '@/layout/chef/pictures/BackgroundPictureWithHeart'

interface SliderPicturesProps {
   chef: Chef
   additionalStyles?: string
   pictureMode: PictureModeProps
   rounded: string
}

export const SingleChefPicture = ({ chef, pictureMode, additionalStyles, rounded }: SliderPicturesProps) => {
   const pic = chef?.defaultPicture || chef?.galleryImages?.[0] || chef?.pictures?.[0]

   return (
      <div className={`group relative aspect-1/3 h-full w-full overflow-hidden select-none ${additionalStyles}`}>
         <div className={`relative h-full w-full ${rounded}`}>
            <div>
               {pic ? (
                  <img
                     id={`SingleChefPicture`}
                     className={`h-full w-full object-cover object-center ${rounded}`}
                     src={`${CDN_ENDPOINT}${pic}`}
                     alt='chef picture'
                  />
               ) : (
                  <div className='flex h-full w-full items-center justify-center bg-linear-to-r from-purple-900 to-purple-400' />
               )}
            </div>
            {pictureMode.dark && (
               <div className={`absolute inset-0 bg-linear-to-r from-black/60 via-black/50 to-black/20 ${rounded}`}></div>
            )}
         </div>
      </div>
   )
}
