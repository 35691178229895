import React, { useEffect, useRef } from 'react'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin'
import {
   $createParagraphNode,
   $createTextNode,
   $getRoot,
   $insertNodes,
   EditorState,
   LexicalEditor,
   TextNode,
} from 'lexical'
import { ListItemNode, ListNode } from '@lexical/list'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { HeadingNode, QuoteNode } from '@lexical/rich-text'
import { CodeHighlightNode, CodeNode } from '@lexical/code'
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table'
import { $generateNodesFromDOM } from '@lexical/html'
import NewMentionsPlugin from '@/layout/common/lexical-editor/lexical-util/MentionsPlugin'
import { ExtendedTextNode } from '@/layout/common/lexical-editor/lexical-util/ExtendedTextNode'
import { FullWidthBreakNode } from '@/layout/common/lexical-editor/lexical-util/FullWidthBreakNode'
import { YouTubeNode } from '@/layout/common/lexical-editor/lexical-util/YouTubeNode'
import { StreamableNode } from '@/layout/common/lexical-editor/lexical-util/StreamableNode'
import { MentionNode } from '@/layout/common/lexical-editor/lexical-util/MentionNode'
import { EmojiNode } from '@/layout/common/lexical-editor/lexical-util/EmojiNode'
import { ImageNode } from '@/layout/common/lexical-editor/lexical-util/ImageNode'
import { InstagramNode } from '@/layout/common/lexical-editor/lexical-util/InstagramNode'
import { TikTokNode } from '@/layout/common/lexical-editor/lexical-util/TikTokNode'
import { RumbleNode } from '@/layout/common/lexical-editor/lexical-util/RumbleNode'
import { HashtagNode } from '@/layout/common/lexical-editor/lexical-util/HashtagNode'
import CustomAutoLinkPlugin from '@/layout/common/lexical-editor/lexical-util/CustomAutoLinkPlugin'
import { TweetNode } from '@/layout/common/lexical-editor/lexical-util/TweetNode'

interface ReadOnlyLexicalProps {
   htmlState: string | null
   plainTextMode?: boolean
   maxLetters?: number | null
   maxLines?: number | null
}

const ReadOnlyLexical = ({
   htmlState,
   plainTextMode = false,
   maxLetters = null,
   maxLines = null,
}: ReadOnlyLexicalProps) => {
   if (!htmlState) return null

   const editorRef = useRef<LexicalEditor | null>(null)

   useEffect(() => {
      if (htmlState && htmlState.length > 0) {
         const editor = editorRef.current
         editor?.update(() => {
            try {
               const parser = new DOMParser()
               const dom = parser.parseFromString(htmlState, 'text/html')

               if (plainTextMode) {
                  let textContent = dom.body.textContent || ''
                  // Truncate text if maxLetters is defined and text length exceeds maxLetters
                  if (maxLetters !== null && textContent.length > maxLetters) {
                     // Truncate the text to maxLetters and append "..."
                     textContent = textContent.substring(0, maxLetters) + '...'
                  }

                  $getRoot().clear()
                  const paragraphNode = $createParagraphNode()
                  paragraphNode.append($createTextNode(textContent))
                  $getRoot().append(paragraphNode)
               } else {
                  const nodes = $generateNodesFromDOM(editor, dom)
                  $getRoot().clear()
                  $getRoot().select()
                  $insertNodes(nodes)
               }
            } catch (e) {
               console.log('Error parsing HTML:', e)
            }
         })
      }
   }, [htmlState, plainTextMode, maxLetters])

   const editorConfig = {
      namespace: 'MyEditor',
      editable: false,
      theme: {
         root: 'pl-0 border-slate-500 border-0 rounded-sm h-full min-h-[1em] w-full focus:outline-hidden focus-visible:border-black',
         link: 'cursor-pointer',
         text: {
            bold: 'font-semibold',
            underline: 'underline',
            italic: 'italic',
            strikethrough: 'line-through',
            underlineStrikethrough: 'underlined-line-through',
         },
      },
      onError(error: any) {
         throw error
      },
      // Any custom nodes go here
      nodes: [
         HeadingNode,
         ListNode,
         ListItemNode,
         QuoteNode,
         CodeNode,
         ExtendedTextNode,
         {
            replace: TextNode,
            with: (node: TextNode) => new ExtendedTextNode(node.__text),
            withKlass: ExtendedTextNode,
         },
         FullWidthBreakNode,
         YouTubeNode,
         StreamableNode,
         CodeHighlightNode,
         TableNode,
         TableCellNode,
         TableRowNode,
         AutoLinkNode,
         LinkNode,
         TweetNode,
         MentionNode,
         EmojiNode,
         ImageNode,
         InstagramNode,
         TikTokNode,
         RumbleNode,
         HashtagNode,
      ],
   }

   function onChangeState(editorState: EditorState, editor: LexicalEditor) {
      editorState.read(() => {})
   }

   const containerStyle = {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      wordBreak: 'break-word',
      whiteSpace: 'pre-wrap',
      WebkitLineClamp: maxLines != null ? maxLines : 'none',
   } as React.CSSProperties

   return (
      <LexicalComposer initialConfig={editorConfig}>
         <div style={containerStyle}>
            <RichTextPlugin
               contentEditable={<ContentEditable className='border-red-500' />}
               placeholder={<></>}
               ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <ListPlugin />
            <LinkPlugin />
            <CustomAutoLinkPlugin />
            <TabIndentationPlugin />
            <OnChangePlugin onChange={onChangeState} />
            <NewMentionsPlugin />
            <EditorRefPlugin editorRef={editorRef} />
         </div>
      </LexicalComposer>
   )
}

export default ReadOnlyLexical
