import { Suggestion } from '@/layout/add-chef/CreateChefPage'
import { useEffect, useState } from 'react'
import { IoLocationOutline } from 'react-icons/io5'

interface SuggestionComponentProps {
   suggestions: Suggestion[]
   handleSuggestionClick: (suggestion: Suggestion) => void
}

export const SuggestionComponent = ({ suggestions, handleSuggestionClick }: SuggestionComponentProps) => {
   const [selectedIndex, setSelectedIndex] = useState(-1)

   useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
         if (event.key === 'ArrowDown') {
            setSelectedIndex(prevIndex => Math.min(prevIndex + 1, suggestions.length - 1))
         } else if (event.key === 'ArrowUp') {
            setSelectedIndex(prevIndex => Math.max(prevIndex - 1, 0))
         } else if (event.key === 'Enter' && selectedIndex >= 0) {
            handleSuggestionClick(suggestions[selectedIndex])
         }
      }

      window.addEventListener('keydown', handleKeyDown)
      return () => {
         window.removeEventListener('keydown', handleKeyDown)
      }
   }, [selectedIndex, suggestions])

   return (
      <>
         {suggestions.length > 0 && (
            <div data-cy='suggestion-list' className={`z-50 flex flex-col overflow-y-auto`}>
               {suggestions.map((suggestion: Suggestion, index: number) => {
                  const isLastItem = index === suggestions.length - 1
                  return (
                     <div
                        data-cy={`suggestion-item-${index}`}
                        className={`background-normal flex cursor-pointer flex-row items-center ${isLastItem ? 'rounded-b-lg' : 'rounded-none'} ${!isLastItem ? 'border-b border-gray-300' : ''} p-1 py-1 pl-3 shadow-xs hover:bg-gray-200 dark:hover:bg-gray-700 ${index === selectedIndex ? 'bg-gray-200 dark:bg-gray-700' : 'bg-gray-100 dark:bg-gray-900'}`}
                        key={index}
                        onClick={() => handleSuggestionClick(suggestion)}
                     >
                        <IoLocationOutline className='h-5 max-h-5 min-h-5 w-5 min-w-5' />
                        <div className='ml-2 flex flex-col'>
                           <div className='mb-0.5 text-base'>{suggestion.header1}</div>
                           <div className='text-sm opacity-70'>{suggestion.header2}</div>
                        </div>
                     </div>
                  )
               })}
            </div>
         )}
      </>
   )
}
