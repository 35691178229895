import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import React, { useEffect } from 'react'
import { AutoLinkPlugin, createLinkMatcherWithRegExp } from '@lexical/react/LexicalAutoLinkPlugin'
import { AutoLinkNode } from '@lexical/link'

const URL_REGEX =
   /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/

const EMAIL_REGEX =
   /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/

export const MATCHERS = [
   createLinkMatcherWithRegExp(URL_REGEX, text => {
      return text.startsWith('http') ? text : `https://${text}`
   }),
   createLinkMatcherWithRegExp(EMAIL_REGEX, text => {
      return `mailto:${text}`
   }),
]

export default function CustomAutoLinkPlugin() {
   const [editor] = useLexicalComposerContext()

   useEffect(() => {
      if (!editor) return
      const removeNodeListener = editor.registerNodeTransform(AutoLinkNode, node => {
         if (!node || node.__type !== 'autolink') return

         // If the editor API allows direct property modification:
         node.__target = '_blank'

         // Or if you still need to access the DOM (assuming the node has been rendered):
         const dom = editor.getElementByKey(node.__key)
         if (dom && dom.setAttribute) {
            dom.setAttribute('target', '_blank')
         }
      })

      return () => removeNodeListener()
   }, [editor])

   return <AutoLinkPlugin matchers={MATCHERS} />
}
