import React from 'react'

export interface TabPanelProps {
   tabKey: number
   openTabKey: number
   content: any
   setOpenTab: (tabKey: number) => void
   addedStyles?: string
}

export const PanelPlaceholder = ({ content }) => {
   return (
      <div className='bg-normal rounded-normal background-normal mb-2 flex w-full justify-center rounded-b-none'>
         <ul className='mb-0 flex w-full list-none flex-row flex-wrap pb-0 select-none ' role='tablist'>
            {content}
         </ul>
      </div>
   )
}

export const PanelOption = ({ tabKey, openTabKey, setOpenTab, content, addedStyles }: TabPanelProps) => {
   return (
      <li className={`-mb-px flex flex-1 last:mr-0 hover:cursor-pointer ${addedStyles}`}>
         <a
            className={
               'inline-block grow rounded-t-lg border-b-[6px] p-4 text-center font-medium ' +
               (tabKey === openTabKey
                  ? 'border-customPurpleDark text-customPurpleDark'
                  : 'text-gray-500 hover:border-gray-400 hover:text-gray-600 ' +
                    'dark:border-gray-400 dark:text-gray-100 dark:hover:text-gray-300')
            }
            onClick={e => {
               e.preventDefault()
               setOpenTab(tabKey)
            }}
            role='tablist'
         >
            {content}
         </a>
      </li>
   )
}
