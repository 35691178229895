export enum PhotoType {
   FOR_POST_LG = 'FOR_POST_LG',
   FOR_MEME = 'FOR_MEME',
   FOR_COMMENT = 'FOR_COMMENT',
   FOR_COMMUNITY_PICTURE = 'FOR_COMMUNITY_PICTURE',
   FOR_COMMUNITY_ICON = 'FOR_COMMUNITY_ICON',
   FOR_CHAT = 'FOR_CHAT',
   FOR_COMMUNITY_CATEGORY = 'FOR_COMMUNITY_CATEGORY',

   FOR_FOOD = 'FOR_FOOD',
   FOR_CHEF = 'FOR_CHEF',
   FOR_CHEF_GALLERY = 'FOR_CHEF_GALLERY',

   LANDING_PAGE = 'LANDING_PAGE',
   LANDING_PAGE_LQ = 'LANDING_PAGE_LQ',
   OUR_PARTNERS = 'OUR_PARTNERS',
}
