import React from 'react'
import ReactDOM from 'react-dom/client'
import getStore from '@/config/store'
import { Provider } from 'react-redux'
import { bindActionCreators } from 'redux'
import { clearAuthentication } from '@/service/authentication'
import setupAxiosInterceptors from '@/config/axios-interceptor'
import './Main.css'
import { isDesktop } from 'react-device-detect'
import { HelmetProvider } from 'react-helmet-async'
import { initializeLanguage } from '@/i18n/LocaleSlice'
import AppDesktop from '@/AppDesktop'
import AppMobile from '@/AppMobile'

const store = getStore()
initializeLanguage(store)

const actions = bindActionCreators({ clearAuthentication }, store.dispatch)
setupAxiosInterceptors(() => actions.clearAuthentication())

// const App = React.lazy(() => (isDesktop ? import('./AppDesktop') : import('./AppMobile')))
const App = isDesktop ? AppDesktop : AppMobile

ReactDOM.createRoot(document.getElementById('root')!).render(
   <React.StrictMode>
      <Provider store={store}>
         <HelmetProvider>
            <App />
         </HelmetProvider>
      </Provider>
   </React.StrictMode>,
)
