import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MainCommentWeb } from './desktop/MainCommentWeb'
import { getCommentsForUserId } from '@/service/comment/comment-reducer'
import { loadingIndicator } from '@/layout/common/LoadingIndicator'
import { COMMENT_SORT_BY } from '@/service/search'

export const CommentsForUserComponent = ({ userId }) => {
   const { t } = useTranslation()
   const [pagination] = useState({
      page: 0,
      sort: 'createDate',
      total: 0,
   })

   const [loadedAll, setLoadedAll] = useState(true)
   const [loading, setLoading] = useState(false)
   const [comments, setComments] = useState([])

   useEffect(() => {
      if (userId == null) return

      if (comments.length == 0 && loading == false) loadComments()
   }, [userId])

   function loadComments() {
      setLoading(true)
      getCommentsForUserId(userId, pagination.page, pagination.sort)
         .then(result => {
            setLoading(false)
            pagination.total = Number(result.headers['x-total-count'])
            setComments([...comments, ...result.data])
         })
         .catch(_ => {
            setLoading(false)
         })
   }

   useEffect(() => {
      const total = pagination.total

      if (total == null) return

      const loadedEvery = total == comments.length
      setLoadedAll(loadedEvery)
   }, [comments])

   return (
      <div className='background-normal rounded-normal flex flex-col pt-2 pb-4'>
         {comments &&
            comments.length > 0 &&
            comments.map(comment => (
               <MainCommentWeb
                  key={comment.id}
                  comment={comment}
                  actionsVisible={true}
                  fromDate={null}
                  canGoToPost={true}
                  subcommentsSortBy={COMMENT_SORT_BY.NEW}
               />
            ))}

         {comments.length == 0 && !loading && (
            <div className='flex flex-row justify-center'>
               <p>{t('comments.no_comments')}</p>
            </div>
         )}

         <div className='mt-1 flex flex-row justify-start'>
            {!loadedAll && (
               <div
                  role='button'
                  hidden={loadedAll}
                  className='hover-color rounded-normal mb-1.5 ml-1 pr-1 pl-1'
                  onClick={() => {
                     pagination.page = pagination.page + 1
                     loadComments()
                  }}
               >
                  <p className='commentMoreButton'>{t('comments.show_more')}</p>
               </div>
            )}
         </div>
         {loading && loadingIndicator(loading)}
      </div>
   )
}
export default CommentsForUserComponent
