import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { updateUserLocation } from '@/service/search'
import { AbstractMobileModal } from '@/layout/common/AbstractMobileModal'
import { fetchSuggestions, mapCustomAddressToSuggestion } from '@/service/open-street-map.service'
import { debouncedHandleAddressChange } from '@/layout/add-chef/CreateChefPageSecondStep'
import { CustomAddress } from '@/model/custom-address.model'
import { Suggestion } from '@/layout/add-chef/CreateChefPage'
import { ClipLoader } from 'react-spinners'
import { IoLocationOutline } from 'react-icons/io5'
import { CiLocationArrow1 } from 'react-icons/ci'
import { getCurrentPosition, updateAddress } from '@/util/UserLocalizations'

interface SearchComponentMobileProps {
   additionalClassesStyles?: string
   landingPageMode?: boolean
}

export const LandingPageSearchLocationMobile = ({ additionalClassesStyles = '' }: SearchComponentMobileProps) => {
   const { t } = useTranslation()
   const navigate = useNavigate()
   const dispatch = useAppDispatch()

   const [loading, setLoading] = useState(false)
   const locale = useAppSelector(state => state.localeSlice.lng)
   const [showSearchModal, setShowSearchModal] = useState(false)
   const [addresses, setAddresses] = useState<CustomAddress[]>([])
   const [suggestions, setSuggestions] = useState<Suggestion[]>([])
   const [selectedIndex, setSelectedIndex] = useState<number>(-1)
   const [currentSearch, setCurrentSearch] = useState<string>('')
   const [currentLocationFetching, setCurrentLocationFetching] = useState<boolean>(false)

   useEffect(() => {
      if (addresses.length > 0) {
         setSuggestions(addresses.map(s => mapCustomAddressToSuggestion(s)))
      }
   }, [addresses])

   const handleSearch = (e: string) => {
      if (e == currentSearch) return
      debouncedHandleAddressChange(e, setCurrentSearch, fetchSuggestions, setAddresses, locale, setLoading)
   }

   const handleSuggestionClick = (suggestion: Suggestion) => {
      const customAddress = addresses.find(a => a.id === suggestion.id)
      dispatch(
         updateUserLocation({
            location: {
               latitude: customAddress.latitude,
               longitude: customAddress.longitude,
               locationName: suggestion.header1,
               radius: 100,
            },
            shouldSaveLocation: false,
         }),
      )
      navigate('/')
   }
   const handleUseCurrentLocation = () => {
      setCurrentLocationFetching(true)
      getCurrentPosition()
         .then(position => {
            updateAddress(position.coords.latitude, position.coords.longitude)
               .then(location => {
                  const queryParams = new URLSearchParams({
                     lat: location.latitude.toFixed(3),
                     lon: location.longitude.toFixed(4),
                     radius: '25',
                  }).toString()

                  dispatch(
                     updateUserLocation({
                        location: {
                           latitude: location.latitude,
                           longitude: location.longitude,
                           locationName: location.locationName,
                        },
                        shouldSaveLocation: false,
                     }),
                  )
                  navigate(`/?${queryParams}`) // Navigate with the query params
               })
               .finally(() => {
                  setCurrentLocationFetching(false)
               })

            navigate('/')
         })
         .catch(error => {
            console.error('Error fetching current location:', error)
            // Optionally handle errors here, e.g., show a toast or alert
         })
         .finally(() => {})
   }

   return (
      <div className='flex flex-col'>
         <div className={`relative mr-2 w-full p-4`}>
            <div
               onClick={() => setShowSearchModal(true)}
               className={`border-gray-custom mr-1 flex w-full min-w-[20px] flex-1 items-center space-x-1 rounded-3xl bg-white px-3 py-1 md:mr-2 ${additionalClassesStyles} cursor-pointer`}
            >
               <button>
                  <HiMagnifyingGlass className={`ml-0.5 h-6 w-6 shrink-0 cursor-pointer text-gray-500`} />
               </button>
               <div
                  className={`mr-4 flex h-[38px] w-full items-center bg-transparent pb-0.5 text-base text-gray-600 outline-hidden`}
               >
                  {t('landing_page.enter_location')}
               </div>
            </div>
         </div>

         {/* Use Current Location Button */}
         <button className='-mt-6 mb-5 flex items-center justify-center' onClick={handleUseCurrentLocation}>
            <div
               className={`flex items-center rounded-full ${currentLocationFetching ? 'bg-customPurpleDark' : 'bg-customPurple'} px-4 py-2 text-white shadow-md`}
            >
               <CiLocationArrow1 className='mt-[1px] mr-2 h-5 w-5 stroke-[2px]' />
               {t('landing_page.use_current_location')}
            </div>
         </button>

         {showSearchModal && (
            <AbstractMobileModal
               increaseZIndex={true}
               title={<p>{t('landing_page.enter_location_header')}</p>}
               customNavigation={() => setShowSearchModal(false)}
            >
               <div className='max-h-screen-safe-offset-14 flex w-full flex-col p-4'>
                  <form className=''>
                     <div
                        className={`mr-1 flex w-full min-w-[20px] flex-row items-center space-x-1 rounded-xl border border-gray-400 bg-white px-3 py-1 md:mr-2`}
                     >
                        <button type='submit'>
                           <HiMagnifyingGlass className='ml-0.5 h-6 w-6 cursor-pointer text-gray-500' />
                        </button>

                        <input
                           onChange={e => handleSearch(e.target.value)}
                           className='mr-4 h-[38px] w-full bg-transparent pb-0.5 text-base outline-hidden'
                           type='text'
                           autoFocus={true}
                           placeholder={t('landing_page.enter_location_placeholder')}
                        />
                        {loading && (
                           <ClipLoader size={30} color='#2D84CB' loading={true} className='top-2 right-3' />
                        )}
                     </div>
                     {/*<button type='submit' hidden />*/}
                  </form>
                  {/*Suggestions List Container with Constrained Height*/}
                  <div className='mt-4 max-h-[370px]'>
                     {suggestions.map((suggestion: Suggestion, index: number) => (
                        <div
                           className={`border-gray-custom background-normal mb-2.5 flex cursor-pointer flex-row items-center rounded-md border-1 p-1 py-1 shadow-xs hover:bg-gray-200 dark:hover:bg-gray-700 ${index === selectedIndex ? 'bg-gray-200 dark:bg-gray-700' : 'bg-gray-100 dark:bg-gray-900'}`}
                           key={index}
                           onClick={() => handleSuggestionClick(suggestion)}
                        >
                           <IoLocationOutline className='h-5 max-h-5 min-h-5 w-5 min-w-5' />
                           <div className='ml-2 flex flex-col text-sm'>
                              <div>{suggestion.header1}</div>
                              <div className='opacity-70'>{suggestion.header2}</div>
                           </div>
                        </div>
                     ))}
                  </div>
               </div>
            </AbstractMobileModal>
         )}
      </div>
   )
}
