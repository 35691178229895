import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useForm } from 'react-hook-form'

import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../config/store'
import { logout } from '../../../service/authentication'
import { scheduleToDeleteTheAccount } from '../../../service/user-settings-reducer'
import { LoadingIndicator } from '../../common/LoadingIndicator'

export const DeleteAccountForm = ({ onConfirm }) => {
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm<any>()
   const dispatch = useAppDispatch()
   const [num1, setNum1] = useState<number>(0)
   const [num2, setNum2] = useState<number>(0)
   const navigate = useNavigate()

   useEffect(() => {
      generateNumbers()
   }, [])

   const onFinish = (values: any) => {
      const result = parseInt(values.equation)
      const num1MinusNum2 = num1 - num2

      if (result != num1MinusNum2) {
         toast.error(t('profile_settings.delete_account_wrong_result'))
         return
      }

      setIsLoading(true)
      scheduleToDeleteTheAccount({ reason: values.reason })
         .then(_ => {
            toast.success(t('profile_settings.delete_account_success'))
            dispatch(logout())
            navigate('/deleted')
            onConfirm()
         })
         .catch(_ => {
            toast.error(t('common.error_contact_us'))
            // onConfirm();
         })
         .finally(() => {
            setIsLoading(false)
         })
   }

   const generateNumbers = () => {
      let secondNum = Math.floor(Math.random() * 900) + 100
      let firstNum = Math.floor(Math.random() * 900) + 100

      while (secondNum >= firstNum) {
         firstNum = Math.floor(Math.random() * 900) + 100
         secondNum = Math.floor(Math.random() * 900) + 100
      }

      setNum1(firstNum)
      setNum2(secondNum)
   }

   return (
      <form className='border-gray-custom rounded-normal mb-2 max-w-xl' onSubmit={handleSubmit(onFinish)}>
         <p className='mt-4 text-center font-semibold'>{t('profile_settings.delete_account_reason')}</p>
         <div className='flex flex-1 justify-start px-2'>
            <p className='paragraph-common mt-4'>{t('profile_settings.delete_account_reason_short')}</p>
            <div className='flex flex-1 flex-col'>
               <textarea
                  style={{ resize: 'none' }}
                  className='background-gray-dark-normal m-2 flex-1 rounded-2xl p-2 outline-hidden'
                  name='reason'
                  {...register('reason')}
               />
            </div>
         </div>
         <p className='mt-4 text-center font-semibold'>
            {t('profile_settings.equation_result')}&nbsp;
            {num1} - {num2}
         </p>
         <div className='flex flex-1 justify-start px-2'>
            <p className='paragraph-common mt-4'>{t('profile_settings.result')}</p>
            <div className='flex flex-1 flex-col'>
               <textarea
                  style={{ resize: 'none' }}
                  className='background-gray-dark-normal m-2 flex-1 rounded-2xl p-2 outline-hidden'
                  name='equation'
                  {...register('equation')}
               />
            </div>
         </div>
         <button type='submit' className='small-confirm-button'>
            {t('common.submit')}
         </button>
         {isLoading && <LoadingIndicator />}
      </form>
   )
}
