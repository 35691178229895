import moment from 'moment'
import dayjs from 'dayjs'

export const formatDate = (date?: string | dayjs.Dayjs | moment.Moment | Date | number) => {
   if (!date) return ''
   if (dayjs.isDayjs(date)) {
      return date.format('DD/MM/YYYY HH:mm')
   } else if (moment.isMoment(date)) {
      return dayjs(date.toDate()).format('DD/MM/YYYY HH:mm')
   } else {
      return dayjs(date).format('DD/MM/YYYY HH:mm')
   }
}

export const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

export const getCurrentDayName = () => {
   const currentDayIndex = new Date().getDay()
   return days[(currentDayIndex + 6) % 7]
}

// yyyy-MM-dd date format
export const getDayName = (date: string) => {
   const dayIndex = new Date(date).getDay()
   return days[dayIndex]
}
