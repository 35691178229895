import { useTranslation } from 'react-i18next'
import { LoadingBalls } from '@/layout/common/LoadingIndicator'
import React from 'react'

interface ProgressBarStep {
   label: string
}

interface ProgressBarProps {
   steps: ProgressBarStep[]
   setStep?: (step: number) => void
   currentStepIdx: number
   includeStepZero?: boolean
}

const StepsBarComponent = ({ steps, setStep, currentStepIdx, includeStepZero = false }: ProgressBarProps) => {
   return (
      <div className={`flex w-full items-center justify-center select-none ${setStep && 'hover:cursor-pointer'}`}>
         <div className='flex w-full items-center'>
            {steps.map((step, index) => {
               if (!includeStepZero && index === 0) return null
               return (
                  <div
                     key={index}
                     className={`flex-1 text-xs sm:text-base ${setStep && 'hover:cursor-pointer'} ${
                        index !== 0 && 'pl-0.5 md:pl-1'
                     } ${index !== steps.length - 1 && 'pr-0.5 md:pr-1'}`}
                     onClick={() => {
                        if (setStep) {
                           setStep(index) // Use the exact index for setStep
                        }
                     }}
                  >
                     <span
                        className={`text-xs font-normal sm:text-base ${
                           index === currentStepIdx ? 'text-customPurple' : 'text-gray-400'
                        }`}
                     >
                        {step.label}
                     </span>
                     <div
                        className={`mt-2 h-1.5 rounded-md ${index === currentStepIdx ? 'bg-customPurple' : 'bg-gray-300'}`}
                     />
                  </div>
               )
            })}
         </div>
      </div>
   )
}

export default StepsBarComponent

interface NextStepButtonProps {
   step: number
   setStep: (step: number) => void
   isValid: boolean
   triggerValidation: () => void
}

export const NextStepButton = ({ step, setStep, isValid, triggerValidation }: NextStepButtonProps) => {
   const { t } = useTranslation()

   return (
      <div className='mt-4 flex justify-end'>
         <button
            type='button'
            data-cy={`next-step-button-${step}`}
            onClick={() => {
               if (!isValid) {
                  triggerValidation()
                  return
               }
               setStep(step)
               window.scrollTo({ top: 0, behavior: 'smooth' })
            //    todo scroll to top
            }}
            className={`confirm-button px-4 ${!isValid && 'gray-out hover:gray-out'}`}
         >
            {t('chef_add_page.next')}
         </button>
      </div>
   )
}

interface BackStepButtonProps {
   step: number
   setStep: (step: number) => void
}

export const BackStepButton = ({ step, setStep }: BackStepButtonProps) => {
   const { t } = useTranslation()

   return (
      <div className='mt-4 flex justify-end'>
         <button type='button' onClick={() => setStep(step)} className='confirm-button bg-red-500 px-4'>
            {t('chef_add_page.back')}
         </button>
      </div>
   )
}

interface FinishStepButtonProps {
   isValid: boolean
   triggerValidation: () => void
   isLoading?: boolean
}

export const FinishStepButton = ({ isValid, isLoading = false }: FinishStepButtonProps) => {
   const { t } = useTranslation()

   return (
      <div className='mt-4 flex justify-end'>
         <button type='submit' className={`confirm-button px-4 ${!isValid || isLoading ? 'gray-out' : ''}`}>
            {t('chef_add_page.confirm')}
            {isLoading && (
               <div style={{ position: 'absolute' }}>
                  <LoadingBalls />
               </div>
            )}
         </button>
      </div>
   )
}
