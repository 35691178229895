import 'react-toastify/dist/ReactToastify.css'

import React, { useEffect, useRef } from 'react'

import { ToastContainer } from 'react-toastify'
import { useAppDispatch, useAppSelector } from './config/store'
import Header from './layout/header/Header'
import CookiesComponent from '@/layout/cookies/CookiesComponent'
import { setupNotifications } from '@/service/notification-reducer'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { updateAppTheme } from '@/AppDesktop'
import { initUserLocalizationAndAddress } from '@/util/UserLocalizations'
import { Seo } from '@/Seo'
import { setupFCM } from '@/service/fcm/fcm-service'
import { isPWAOnIphone } from '@/util/other-utils'
import { AppRoutes } from '@/config/routes'

const BottomMenuMobile = React.lazy(() => import('@/layout/mobile/BottomMenuMobile'))

export const AppMobile = () => {
   const { t } = useTranslation()
   const dispatch = useAppDispatch()
   const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated)

   useEffect(() => {
      initUserLocalizationAndAddress(dispatch, false, isAuthenticated)
   }, [isAuthenticated])

   useEffect(() => {
      if (isAuthenticated) {
         setupNotifications(t, dispatch, isAuthenticated)
         setupFCM()
      }
   }, [isAuthenticated])

   const darkMode = useAppSelector(state => state.themeModeReducer.darkTheme)
   updateAppTheme(darkMode)

   function getLayout() {
      return (
         <>
            <Header />
            <Seo title='Oh! les Chefs' description='' type='' name='Oh! les Chefs' />
            <Outlet />
            <CookiesComponent />
            <ToastContainer position='top-center' autoClose={1200} />
            <nav className='pb-safe fixed bottom-0 z-10 w-full'>
               <React.Suspense fallback={<BottomMenuPlaceholder />}>
                  <BottomMenuMobile />
               </React.Suspense>
            </nav>
         </>
      )
   }

   return <AppRoutes layout={getLayout()} />
}

export default AppMobile

const BottomMenuPlaceholder = () => {
   return (
      <div className='border-gray-custom fixed inset-x-0 bottom-0 z-10 shadow-md'>
         <div
            className={`background-normal flex items-center justify-around px-3 py-1 ${isPWAOnIphone() ? 'pb-3' : ''}`}
         >
            <div className='grid h-[40px] grid-cols-5 items-center gap-4 text-center'></div>
         </div>
      </div>
   )
}
