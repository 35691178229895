import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FiMinus, FiPlus } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { FoodOrderItem, OrderType } from '@/model/order/FoodOrderItem'
import { formatPrice } from '@/util/text-utils'
import { CDN_ENDPOINT } from '@/env'
import { getMaxServed } from '@/util/food-availability-utils'
import { addItemsIdsToSC } from '@/service/notification-reducer'
import { handleFoodOrder } from '@/service/shopping-card.service'
import { Food } from '@/model/order/food.model'
import { useAppSelector } from '@/config/store'
import { isUserLoggedIn } from '@/util/auth-util'
import { showLogin } from '@/service/authentication'
import { isMobile } from 'react-device-detect'
import { PiBowlFood } from 'react-icons/pi'
import { toast } from 'react-toastify'

interface AddFoodProps {
   food: Food
   onClose: (event: any) => void
   chefSuspended?: any
}

export interface AddFoodModalProps {
   food: Food
   onClose: (event: any) => void
   chefSuspended?: boolean
}

export const AddFood: React.FC<AddFoodProps> = ({ food, onClose, chefSuspended }) => {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const { itemsIdsInShoppingCard } = useAppSelector(state => state.notificationReducer)
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const {
      register,
      handleSubmit,
      setValue,
      getValues,
      watch,
      formState: { isValid, errors },
   } = useForm<FoodOrderItem>({
      defaultValues: {
         quantity: 1,
         orderType: OrderType.CREATE,
      },
      mode: 'onBlur',
   })
   const quantity = watch('quantity')
   const [canIncreaseQuantity, setCanIncreaseQuantity] = useState(false)

   useEffect(() => {
      setCanIncreaseQuantity(getValues('quantity') < getMaxServed(food?.offer))
   }, [watch('quantity'), food, getValues])

   useEffect(() => {}, [food?.offer, setValue])
   useEffect(() => {
      setValue('quantity', 1)
   }, [food, setValue])

   useEffect(() => {
      // Save the current scroll position
      const scrollY = window.scrollY

      // Apply styles to prevent scrolling without changing the scroll position
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollY}px`
      document.body.style.width = '100%'

      // Clean up function to remove the styles and restore scroll position when modal is closed
      return () => {
         document.body.style.position = ''
         document.body.style.top = ''
         document.body.style.width = ''
         window.scrollTo(0, scrollY) // Restore the scroll position
      }
   }, [])

   const onSubmit = (foodOrder: FoodOrderItem) => {
      if (!isUserLoggedIn(loggedAccount)) {
         onClose(null)
         dispatch(showLogin())
         return
      }
      foodOrder.food = food

      // Figure out how many are already in the cart
      const alreadyInCart = itemsIdsInShoppingCard.filter(id => id === food.id).length
      const maxAllowed = getMaxServed(food.offer)
      const totalIfAdded = alreadyInCart + foodOrder.quantity

      if (totalIfAdded > maxAllowed) {
         const canAdd = maxAllowed - alreadyInCart

         // If the user is already at or above max, show a toast + do nothing
         if (canAdd <= 0) {
            toast.info(t('add_food_page.max_items_message', { autoClose: 5000 }))
            onClose(null)
            return
         }

         // Otherwise, partially add the difference — (no toast).
         foodOrder.quantity = canAdd
      }

      handleFoodOrder(foodOrder)
         .then(() => {
            const foodsIds = Array(foodOrder.quantity).fill(foodOrder.food.id)
            dispatch(addItemsIdsToSC(foodsIds))
            onClose(null)
         })
         .catch(error => {
            console.error(err => console.error(err))
         })
   }
   const getFoodInfo = () => (
      <div className='flex w-full flex-col'>
         {food?.image ? (
            <img
               src={`${CDN_ENDPOINT}${food?.image}`}
               alt={food.name}
               className='h-48 w-full rounded-md object-cover'
            />
         ) : (
            <div className='flex h-48 w-full items-center justify-center rounded-r-md bg-gray-200'>
               <PiBowlFood className='mb-10 h-28 w-28 text-gray-500' />
            </div>
         )}
         <div className='flex w-full flex-col md:w-1/2'>
            <div className='flex flex-row items-center justify-between'>
               <h2 className='mb-1 text-lg font-semibold text-gray-800'>{food.name}</h2>
            </div>
            <div className='mr-1 mb-1 font-semibold text-gray-800'>€{formatPrice(food?.price)}</div>
            <p className='mb-4 text-base text-gray-600'>{food.description}</p>
         </div>
      </div>
   )

   const getFoodAmountDateTimePicker = () => (
      <>
         {/*todo decide if it makes sense*/}
         {/*<>*/}
         {/*   <div className='mb-4'>*/}
         {/*      <strong>{t('add_food_page.availability')}</strong>*/}
         {/*      <button className='w-full mt-2 bg-blue-500 text-white py-2 px-4 rounded-sm' onClick={getAvailability}>*/}
         {/*         {t('add_food_page.check_availability')}*/}
         {/*      </button>*/}
         {/*   </div>*/}
         {/*   {showAvailability && availabilityData.length > 0 && (*/}
         {/*      <AvailabilityDisplay*/}
         {/*         data={availabilityData}*/}
         {/*         onSelectDateTime={(date, time) => {*/}
         {/*            // setValue('orderForDay', date)*/}
         {/*            // setValue('orderForTime', time)*/}
         {/*            setShowAvailability(false)*/}
         {/*         }}*/}
         {/*      />*/}
         {/*   )}*/}
         {/*</>*/}
         <div className='mb-6 flex items-center'>
            <button
               type='button'
               onClick={() => quantity > 1 && setValue('quantity', quantity - 1)}
               className={`rounded-l-md border bg-gray-100 p-2 ${quantity === 1 ? 'cursor-not-allowed opacity-50' : ''}`}
               disabled={quantity === 1}
            >
               <FiMinus />
            </button>
            <div className='border-t border-b bg-gray-100 px-4 py-1 font-semibold'>{quantity}</div>
            <button
               type='button'
               onClick={() => canIncreaseQuantity && setValue('quantity', quantity + 1)}
               className={`rounded-r-md border bg-gray-100 p-2 ${!canIncreaseQuantity ? 'cursor-not-allowed opacity-50' : ''}`}
               disabled={!canIncreaseQuantity}
            >
               <FiPlus />
            </button>
         </div>
      </>
   )

   const instructionsForChef = () => (
      <div className='mt-4 mb-4'>
         <strong className='ml-0.5'>{t('add_food_page.special_instructions')}</strong>
         <textarea
            {...register('instructions')}
            className='mt-2 w-full rounded-md border p-2'
            placeholder={t('add_food_page.not_too_spicy')}
         />
      </div>
   )

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <div className={`mt-1.5 flex flex-col p-3 pt-0 ${isMobile ? 'mb-24' : ''} `}>
            {getFoodInfo()}
            {instructionsForChef()}
            {getFoodAmountDateTimePicker()}
            {chefSuspended && <div className={`mb-2 text-sm text-red-500`}>{t('add_food_page.chef_suspended')}</div>}
            <button
               type='submit'
               disabled={!isValid || chefSuspended}
               className={`${isValid && !chefSuspended ? 'bg-customPurple hover:bg-customPurpleDark hover:cursor-pointer' : 'bg-gray-700'} w-full rounded-md py-2 font-semibold text-white`}
            >
               {t('add_food_page.add_to_bag')} €{formatPrice(food.price * quantity)}
            </button>
         </div>
      </form>
   )
}

interface AvailableDateTime {
   date: string
   times: string[]
}

// Component to display availability data
interface AvailabilityDisplayProps {
   data: AvailableDateTime[]
   onSelectDateTime: (date: string, time: string) => void
}

const AvailabilityDisplay: React.FC<AvailabilityDisplayProps> = ({ data, onSelectDateTime }) => (
   <div className='mt-4'>
      <h3>{'Available Dates and Times:'}</h3>
      {data.map(item => (
         <div key={item.date} className='mb-4'>
            <strong>{item.date}</strong>
            <div className='mt-2 flex flex-wrap'>
               {item.times.map(time => (
                  <button
                     key={time}
                     className='m-1 rounded-sm bg-gray-200 px-3 py-1 text-gray-800'
                     onClick={() => onSelectDateTime(item.date, time)}
                  >
                     {time}
                  </button>
               ))}
            </div>
         </div>
      ))}
   </div>
)
