import type { Spread } from 'lexical'
import {
   $applyNodeReplacement,
   type DOMConversionMap,
   type DOMConversionOutput,
   type DOMExportOutput,
   type EditorConfig,
   type LexicalNode,
   type NodeKey,
   type SerializedTextNode,
   TextNode,
} from 'lexical'

export type SerializedHashtagNode = Spread<
   {
      hashtagName: string
      link: string
   },
   SerializedTextNode
>

function convertMentionElement(domNode: HTMLElement): DOMConversionOutput | null {
   const textContent = domNode.textContent
   const link = domNode.getAttribute('data-hashtag-link')

   if (textContent !== null && link !== null) {
      const node = $createHashtagNode(textContent, link)
      return { node }
   }

   return null
}

export class HashtagNode extends TextNode {
   __hashtag: string
   __link: string

   constructor(hashtagName: string, link: string, text?: string, key?: NodeKey) {
      super(text ?? hashtagName, key)
      this.__hashtag = hashtagName
      this.__link = link
      this.__text = hashtagName // Ensure that the text content is set to hashtagName
   }

   static getType(): string {
      return 'hashtag'
   }

   static clone(node: HashtagNode): HashtagNode {
      return new HashtagNode(node.__hashtag, node.__text, node.__key)
   }

   static importJSON(serializedNode: SerializedHashtagNode): HashtagNode {
      const node = $createHashtagNode(serializedNode.hashtagName, serializedNode.link)
      node.setTextContent(serializedNode.text)
      node.setFormat(serializedNode.format)
      node.setDetail(serializedNode.detail)
      node.setMode(serializedNode.mode)
      node.setStyle(serializedNode.style)
      return node
   }

   static importDOM(): DOMConversionMap | null {
      return {
         span: (domNode: HTMLElement) => {
            if (!domNode.hasAttribute('data-lexical-hashtag')) {
               return null
            }
            return {
               conversion: convertMentionElement,
               priority: 1,
            }
         },
      }
   }

   exportJSON(): SerializedHashtagNode {
      return {
         ...super.exportJSON(),
         hashtagName: this.__hashtag,
         link: this.__link,
         type: 'hashtag',
         version: 1,
      }
   }

   createDOM(config: EditorConfig): HTMLElement {
      const dom = document.createElement('a') // Change this to create an anchor element
      dom.href = '/tag/' + this.__link
      dom.className = 'hashtag'
      dom.textContent = '#' + this.__hashtag
      return dom
   }

   exportDOM(): DOMExportOutput {
      const element = document.createElement('span')
      element.setAttribute('data-lexical-hashtag', 'true')

      element.setAttribute('data-hashtag-link', this.__link)

      element.textContent = this.__hashtag
      return { element }
   }

   isTextEntity(): true {
      return true
   }

   canInsertTextBefore(): boolean {
      return false
   }

   canInsertTextAfter(): boolean {
      return false
   }
}

export function $createHashtagNode(hashtagName: string, link: string): HashtagNode {
   const hashtagNode = new HashtagNode(hashtagName, link)
   hashtagNode.setMode('segmented').toggleDirectionless()
   return $applyNodeReplacement(hashtagNode)
}

export function $isHashtagNode(node: LexicalNode | null | undefined): node is HashtagNode {
   return node instanceof HashtagNode
}
