import React from 'react'
import { YesOrNoConfirm } from '@/layout/common/YesOrNoConfirm'
import { useTranslation } from 'react-i18next'
import { CustomAddress } from '@/model/custom-address.model'
import { LocationMarkerByCoordinates } from '@/layout/common/LocationMarkerByCoordinates'
import { isMobile } from 'react-device-detect'

interface ChefAddressProps {
   onClose?: () => void
   address?: CustomAddress | undefined
   pickUpPoint?: boolean
}

export const ChefAddress = ({ onClose, address, pickUpPoint }: ChefAddressProps) => {
   const { t } = useTranslation()

   // Safe onClose handler
   const handleClose = () => {
      if (onClose) onClose()
   }

   if (!address) {
      return (
         <YesOrNoConfirm
            title={t('chef_address.no_address')}
            onConfirm={handleClose}
            onCancel={handleClose}
            showCancelConfirm={false}
            additionalElement={<div className='mb-4 text-center'>{t('chef_address.no_address_available')}</div>}
         />
      )
   }

   const addressDetails = (
      <div className='flex flex-col space-y-4 rounded-lg bg-white p-4'>
         {address?.locationName && address?.locationName?.trim() !== ''
            ? address?.locationName
            : address?.villageTownCity && address?.villageTownCity.trim() !== ''
              ? address?.villageTownCity
              : ''}
         <div className={`pointer-events-none ${isMobile ? 'h-[60vh]' : 'h-[40vh]'} mt-6 opacity-90 select-none`}>
            <LocationMarkerByCoordinates
               coordinates={[address?.latitude, address?.longitude]}
               isPickupPoint={pickUpPoint}
            />
         </div>
      </div>
   )

   return (
      <YesOrNoConfirm
         title={t('chef_profile.address')}
         onConfirm={handleClose}
         onCancel={handleClose}
         showCancelConfirm={false}
         additionalElement={addressDetails}
      />
   )
}
