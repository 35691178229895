import { FaRegStar, FaStar } from 'react-icons/fa'
import React, { useState } from 'react'

interface ChefStarsProps {
   averageVote?: number
   votesCount?: number
   editMode?: boolean
   onRatingChange?: (rating: number) => void
   withCount?: boolean
   filledIcons?: boolean
   iconSize?: number
   marginTopForRatings?: number
   filledColor?: string
   emptyColor?: string
}

export const ChefStars = ({
   averageVote = 0,
   votesCount = 0,
   editMode = false,
   onRatingChange,
   withCount = false,
   filledIcons = false,
   iconSize = 22,
   marginTopForRatings = 6,
   filledColor = 'text-yellow-500', // Default Tailwind class for filled star color
   emptyColor = 'text-gray-500', // Default Tailwind class for empty star color
}: ChefStarsProps) => {
   const [rating, setRating] = useState(averageVote)
   const [hoverRating, setHoverRating] = useState(0)

   const handleRatingClick = (star: number) => {
      setRating(star)

      if (onRatingChange) onRatingChange(star)
   }

   const stars = []
   for (let i = 1; i <= 5; i++) {
      if (editMode) {
         stars.push(
            <div
               key={i}
               onMouseEnter={() => setHoverRating(i)}
               onMouseLeave={() => setHoverRating(0)}
               onClick={() => handleRatingClick(i)}
               style={{ cursor: 'pointer' }}
            >
               {i <= (hoverRating || rating) ? (
                  <FaStar size={iconSize} className={filledColor} />
               ) : filledIcons ? (
                  <FaStar size={iconSize} className={emptyColor} />
               ) : (
                  <FaRegStar size={iconSize} className={emptyColor} />
               )}
            </div>,
         )
      } else {
         if (i <= rating) {
            stars.push(<FaStar key={i} size={iconSize} className={filledColor} />)
         } else if (i - rating < 1) {
            const fraction = rating - Math.floor(rating)
            const percentage = fraction * 100

            stars.push(
               <div
                  key={i}
                  style={{ position: 'relative', display: 'inline-block', width: iconSize, height: iconSize }}
               >
                  <FaStar style={{ position: 'absolute', width: '100%', height: '100%' }} className='text-white' />
                  <FaStar
                     style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        clipPath: `polygon(0 0, ${percentage}% 0, ${percentage}% 100%, 0 100%)`,
                     }}
                     className={filledColor}
                  />
               </div>,
            )
         } else {
            stars.push(
               filledIcons ? (
                  <FaStar key={i} size={iconSize} className={emptyColor} />
               ) : (
                  <FaRegStar key={i} size={iconSize} className={emptyColor} />
               ),
            )
         }
      }
   }

   return (
      <div className='relative flex flex-col items-center'>
         <div className='flex flex-row'>{stars}</div>
         {withCount && votesCount > 0 && !editMode && (
            <div className={`absolute text-xs mt-${marginTopForRatings} `}>({votesCount} ratings)</div>
         )}
      </div>
   )
}
