import { RestClient } from '@/config/rest-client'
import { INotice } from '@/model/notice.model'
import { IPost } from '@/model/post.model'

const apiUrl = '/api/notice'

export const searchNoticeAxios = ({ page, size, sort }) => {
   return RestClient.get<INotice[]>(`${apiUrl}?page=${page}&size=${size}&sort=${sort}`)
}

export const createNotice = (notice: any) => {
   return RestClient.post<INotice>(apiUrl, notice)
}

export const closeNotice = (id: string) => {
   const requestUrl = `${apiUrl}/${id}`
   return RestClient.delete<IPost>(requestUrl)
}
