import React, { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { isUserLoggedIn } from '@/util/auth-util'
import { useAppSelector } from '@/config/store'
import { createComment, updateComment } from '@/service/comment/comment-reducer'
import { CommentType } from '@/model/enumerations/comment-type.model'
import { IComment } from '@/model/comment.model'
import { PhotoType } from '@/model/enumerations/photo-type'
import { MIN_COMMENT_LENGTH } from '@/layout/post/comment/desktop/AddEditCommentComponentWeb'
import { ISubcomment } from '@/model/subcomment.model'
import { LexicalEditorWrapper, SubmitLexicalButton } from '@/layout/common/lexical-editor/LexicalEditorWrapper'

interface AddEditCommentComponentMobileProps {
   postId: string
   afterCommentAddEdit?: (comment: IComment, add: boolean) => void
   parentCommentId?: string
   key?: string
   editedComment?: IComment | ISubcomment
   userToReply?: any
}

export const AddEditCommentComponentMobile = ({
   postId,
   afterCommentAddEdit = (comment: IComment | ISubcomment, add: boolean) => {},
   parentCommentId,
   key = 'cc',
   editedComment,
   userToReply,
}: AddEditCommentComponentMobileProps) => {
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const { t } = useTranslation()
   const [loading, setLoading] = useState(false)
   const loggedIn = isUserLoggedIn(loggedAccount)
   const editorStateHTML = useRef(null)
   const clearEditorContentRef = useRef(null)
   const [isEditorStateValid, setIsEditorStateValid] = useState(false)
   const [usersToMention, setUsersToMention] = useState([])

   useEffect(() => {
      setUsersToMention(userToReply ? [userToReply] : [])
   }, [userToReply])

   function clearEditorContent(callback: any) {
      clearEditorContentRef.current = callback
   }

   const onSubmit = () => {
      if (!isEditorStateValid) return
      if (loading) return
      setLoading(true)
      const html = editorStateHTML.current

      const addComment = editedComment == null
      const promise = addComment
         ? createComment({
              content: html,
              parentCommentId: parentCommentId,
              postId: postId,
              commentType: parentCommentId != null ? CommentType.SUB_COMMENT : CommentType.NORMAL,
           })
         : updateComment({
              id: editedComment.id,
              parentCommentId: editedComment.parentCommentId,
              content: html,
              postId: editedComment.postId,
           })

      promise
         .then(value => {
            afterCommentAddEdit(value.data, addComment)
            if (clearEditorContentRef.current) clearEditorContentRef.current()
         })
         .finally(() => {
            setLoading(false)
         })
   }

   return loggedIn ? (
      <div className='fixed inset-x-0 bottom-0 z-30 flex flex-col'>
         <div className='flex w-full flex-row items-center border-t border-gray-200 bg-white px-2 py-0.5 shadow-md dark:border-gray-700 dark:bg-black'>
            <LexicalEditorWrapper
               placeholder={
                  <p className='lexical-placeholder'>
                     {t('what-about-post') + (!loggedIn ? t('sign-to-comment') : '')}
                  </p>
               }
               photoType={PhotoType.FOR_COMMENT}
               initialHtml={editedComment?.content != null ? editedComment.content : ''}
               htmlStateUpdateFunction={(newState: string, size: number, hasImage: boolean) => {
                  if (hasImage || size >= MIN_COMMENT_LENGTH) setIsEditorStateValid(true)
                  else setIsEditorStateValid(false)
                  editorStateHTML.current = newState
               }}
               addedMentionUsers={usersToMention}
               clearAddedMentionUser={() => {
                  setUsersToMention([])
               }}
               addImageEnabled={true}
               clearEditorContent={clearEditorContent}
            />
            <SubmitLexicalButton loading={loading} isValid={isEditorStateValid} onSubmit={onSubmit} />
         </div>
      </div>
   ) : (
      <div />
   )
}
export default AddEditCommentComponentMobile
