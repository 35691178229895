import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { toast } from 'react-toastify'
import { AUTHORITIES } from '@/config/constants'
import { AbstractWebModal } from '@/layout/common/AbstractWebModal'
import { updateAuthorities } from '@/service/user-service'

export const AssignAuthoritiesModal = ({ showModal, setShowModal, userLogin, currentAuthorities }) => {
   const { t } = useTranslation()
   const [selectedAuthority, setSelectedAuthority] = useState()

   function onChange(event) {
      setSelectedAuthority(event.target.value)
   }

   return showModal ? (
      <AbstractWebModal title={t('assign_user.header_1')} onClose={() => setShowModal(false)}>
         <div className='mb-2 text-center'>
            <p className='text-coolGray-600 text-sm'>
               {t('assign_user.header_2') + userLogin + ' [' + currentAuthorities + ']'}
            </p>
         </div>
         <div onChange={onChange}>
            {Object.values(AUTHORITIES).map((value, idx) => {
               return (
                  <>
                     <input
                        type='checkbox'
                        name={value}
                        className='mr-1 ml-1'
                        value={value}
                        checked={selectedAuthority == value}
                     />
                     <label className='text-sm'>{value}</label>
                  </>
               )
            })}
         </div>
         <div className='mt-6 space-y-2'>
            <div className='flex gap-x-2'>
               <button
                  role='button'
                  type='submit'
                  className='sign-confirm-button'
                  onClick={() => {
                     updateAuthorities({
                        userLogin: userLogin,
                        authorities: [selectedAuthority],
                     })
                        .then(() => {
                           toast.success(t('assign_user.success'))
                           setShowModal(false)
                        })
                        .catch(reason => {
                           toast.error('' + reason.message)
                        })
                  }}
               >
                  {t('common.submit')}
               </button>
            </div>
         </div>
      </AbstractWebModal>
   ) : (
      <div></div>
   )
}
