export interface Food {
   id?: string
   creatorId?: string
   chefId?: string

   name?: string
   description?: string

   image?: string

   isVegan?: boolean

   starred?: boolean

   active?: boolean

   price?: number
   votesCount?: number
   averageVote?: number

   offer?: Offer

   surpriseBag?: boolean
   realFoodWithQuantity?: { [key: string]: number }
}

export interface Offer {
   offerType?: OfferType
   // for fixed offer
   prepareTimeMinutes?: number
   operatingHours?: OfferPerWeekDaysFood

   // for one time offer
   oneTimeOffers: OneTimeOffer[]
}

export interface OneTimeOffer {
   date?: string
   offerInfo?: OfferInfoFood
}

export interface OfferPerWeekDaysFood {
   monday: OfferInfoFood
   tuesday: OfferInfoFood
   wednesday: OfferInfoFood
   thursday: OfferInfoFood
   friday: OfferInfoFood
   saturday: OfferInfoFood
   sunday: OfferInfoFood
}

export interface OfferInfoFood {
   amountServed: number
   amountSold?: number
}

export enum OfferType {
   FIXED_OFFER = 'FIXED_OFFER',
   ONE_TIME = 'ONE_TIME',
   UNAVAILABLE = 'UNAVAILABLE',
}
