import { useTranslation } from 'react-i18next'
import { Switch, TimePicker } from 'antd'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { days } from '@/util/date-utils'
import { useEffect, useState } from 'react'
import { isDesktop } from 'react-device-detect'
import { Control, Controller, UseFormGetValues, UseFormTrigger } from 'react-hook-form'
import get from 'lodash/get'

dayjs.extend(customParseFormat)

interface OpeningHoursInputProps {
   setValue: any
   watch: any
   errors: any
   amountSection?: boolean
   baseKey?: string
   operatingHoursKey: string
   control: Control<any>
   getValues: UseFormGetValues<any>
   trigger?: UseFormTrigger<any>
   showOpenCloseSection?: boolean
}

interface OpenStatus {
   [key: string]: boolean
}

export const OperatingHoursInput = ({
   setValue,
   watch,
   errors,
   amountSection,
   baseKey = '',
   operatingHoursKey,
   control,
   trigger,
   getValues,
   showOpenCloseSection = true,
}: OpeningHoursInputProps) => {
   const { t } = useTranslation()

   // const [openStatus, setOpenStatus] = useState<OpenStatus>(days.reduce((acc, day) => ({ ...acc, [day]: false }), {}))
   const [openStatus, setOpenStatus] = useState<OpenStatus>(days.reduce((acc, day) => ({ ...acc, [day]: false }), {}))

   useEffect(() => {
      if (!getValues(`${baseKey}${operatingHoursKey}`)) return
      setOpenStatus(prevState => {
         const newStatus = { ...prevState }
         days.forEach(day => {
            const dayOperatingHours = getValues(`${baseKey}${operatingHoursKey}.${day}`)
            if (dayOperatingHours && (dayOperatingHours.open || dayOperatingHours.close || dayOperatingHours.amountServed)) {
               newStatus[day] = true
            } else if (dayOperatingHours && dayOperatingHours.open === '' && dayOperatingHours.close === '') {
               newStatus[day] = false
            }
            // Otherwise, don't change the openStatus for this day
         })
         return newStatus
      })
   }, [getValues(`${baseKey}${operatingHoursKey}`)])

   const handleApplyToAll = (day: string) => {
      const openTime = watch(`${baseKey}${operatingHoursKey}.${day}.open`)
      const closeTime = watch(`${baseKey}${operatingHoursKey}.${day}.close`)
      const amountServed = watch(`${baseKey}${operatingHoursKey}.${day}.amountServed`) || null

      days.forEach(d => {
         if (openStatus[d] && d !== day) {
            setValue(`${baseKey}${operatingHoursKey}.${d}.open`, openTime, { shouldValidate: true })
            setValue(`${baseKey}${operatingHoursKey}.${d}.close`, closeTime, { shouldValidate: true })
            if (amountSection) {
               setValue(`${baseKey}${operatingHoursKey}.${d}.amountServed`, amountServed, { shouldValidate: true })
            }
         }
      })

      if (trigger) {
         trigger(`${baseKey}${operatingHoursKey}`)
      }
   }

   const handleToggleOpenStatus = (day: string) => {
      setOpenStatus(prevState => {
         const newStatus = !prevState[day]
         if (!newStatus) {
            setValue(`${baseKey}${operatingHoursKey}.${day}.open`, '')
            setValue(`${baseKey}${operatingHoursKey}.${day}.close`, '')
            if (amountSection) {
               setValue(`${baseKey}${operatingHoursKey}.${day}.amountServed`, '')
            }
         }
         return { ...prevState, [day]: newStatus }
      })
   }

   const firstEnabledDay = days.find(
      day =>
         openStatus[day] &&
         watch(`${baseKey}${operatingHoursKey}.${day}.open`) &&
         watch(`${baseKey}${operatingHoursKey}.${day}.close`),
   )

   const handleTimeChange = (timeString: string | string[], day: string, type: 'open' | 'close') => {
      setValue(`${baseKey}${operatingHoursKey}.${day}.${type}`, timeString)
   }

   return (
      <div className={`flex flex-col space-y-4 pt-2 text-xs sm:space-y-4 sm:text-base ${isDesktop ? 'pl-2' : ''}`}>
         {days.map(day => (
            <div
               key={day}
               className='flex flex-col space-y-2 opacity-90 sm:flex-row sm:items-center sm:space-y-0 sm:space-x-2.5'
            >
               {/* First column: Day label and Open switch */}
               <div className='flex flex-row items-center space-x-1 sm:space-x-2.5'>
                  <label className='flex min-w-22 items-center text-base font-semibold'>
                     {t(`chef_add_page.${day}`)}
                  </label>
                  <label className='rounded-normal flex items-center space-x-1 bg-gray-100 p-1 sm:space-x-2.5'>
                     <span>{t('chef_add_page.open')}</span>
                     <Switch
                        data-cy={`switch-open-${day}`}
                        size={window.innerWidth >= 340 ? 'default' : 'small'}
                        checked={openStatus[day]}
                        onChange={() => handleToggleOpenStatus(day)}
                     />
                  </label>
               </div>

               {/* Second column: Open and Close time pickers */}
               {showOpenCloseSection && (
                  <div className='flex flex-row items-center space-x-2.5'>
                     <div>{t('chef_add_page.from')}</div>
                     <div className='flex flex-col'>
                        <Controller
                           name={`${baseKey}${operatingHoursKey}.${day}.open`}
                           control={control}
                           rules={{ required: openStatus[day] ? t('error.required') : false }}
                           render={({ field }) => (
                              <TimePicker
                                 data-cy={`timepicker-open-${day}`}
                                 size={window.innerWidth >= 640 ? 'middle' : 'small'}
                                 className='w-full'
                                 defaultOpenValue={dayjs('10:00', 'HH:mm')}
                                 format='HH:mm'
                                 minuteStep={15}
                                 onChange={(time, timeString) => {
                                    field.onChange(timeString)
                                    handleTimeChange(timeString, day, 'open')
                                 }}
                                 disabled={!openStatus[day]}
                                 value={field.value ? dayjs(field.value, 'HH:mm') : null}
                              />
                           )}
                        />
                        {get(errors, `${baseKey}${operatingHoursKey}.${day}.open`) && (
                           <p className='mt-1 text-xs text-red-500'>
                              {get(errors, `${baseKey}${operatingHoursKey}.${day}.open.message`)}
                           </p>
                        )}
                     </div>
                     <div>{t('chef_add_page.to')}</div>
                     <div className='flex flex-col'>
                        <Controller
                           name={`${baseKey}${operatingHoursKey}.${day}.close`}
                           control={control}
                           rules={{ required: openStatus[day] ? t('error.required') : false }}
                           render={({ field }) => (
                              <TimePicker
                                 data-cy={`timepicker-close-${day}`}
                                 size={window.innerWidth >= 640 ? 'middle' : 'small'}
                                 className='w-full'
                                 defaultOpenValue={dayjs('18:00', 'HH:mm')}
                                 format='HH:mm'
                                 minuteStep={15}
                                 onChange={(time, timeString) => {
                                    field.onChange(timeString)
                                    handleTimeChange(timeString, day, 'close')
                                 }}
                                 disabled={!openStatus[day]}
                                 value={field.value ? dayjs(field.value, 'HH:mm') : null}
                              />
                           )}
                        />
                        {get(errors, `${baseKey}${operatingHoursKey}.${day}.close`) && (
                           <p className='mt-1 text-xs text-red-500'>
                              {get(errors, `${baseKey}${operatingHoursKey}.${day}.close.message`)}
                           </p>
                        )}
                     </div>
                  </div>
               )}
               {amountSection && (
                  <AmountSection
                     control={control}
                     day={day}
                     errors={errors}
                     baseKey={baseKey}
                     operatingHoursKey={operatingHoursKey}
                     openStatus={openStatus[day]}
                  />
               )}
               {day === firstEnabledDay && showOpenCloseSection && (
                  <button
                     data-cy='applyHoursToAll'
                     type='button'
                     className='rounded-normal ml-4 bg-blue-500 px-2 py-1 text-xs text-white hover:cursor-pointer'
                     onClick={() => handleApplyToAll(day)}
                  >
                     {t('chef_add_page.apply_to_all')}
                  </button>
               )}
            </div>
         ))}
      </div>
   )
}

interface AmountSectionProps {
   day: string
   errors: any
   baseKey: string
   openStatus: boolean
   operatingHoursKey: string
   control?: Control<any>
}

const AmountSection = ({ day, errors, baseKey, operatingHoursKey, openStatus, control }: AmountSectionProps) => {
   const { t } = useTranslation()

   return (
      <div className='ml-4 flex max-w-[75px] flex-col'>
         <Controller
            name={`${baseKey}${operatingHoursKey}.${day}.amountServed`}
            data-cy={`amountServed-${day}`}
            control={control}
            defaultValue=''
            rules={{
               required: openStatus ? { value: true, message: t('error.required') } : false,
               min: {
                  value: 1,
                  message: t('error.min_value', { value: 1 }),
               },
            }}
            render={({ field }) => (
               <input
                  {...field}
                  data-cy={`amount-${day}`}
                  type='number'
                  placeholder={t('food_add_page.amount')}
                  disabled={!openStatus}
                  value={field.value ?? ''}
                  className='block w-full rounded-md border border-gray-300 p-1 shadow-xs focus:border-blue-500 focus:ring-blue-500 focus:outline-hidden sm:text-sm'
               />
            )}
         />
         {get(errors, `${baseKey}${operatingHoursKey}.${day}.amountServed`) && (
            <p className='text-xs text-red-500'>
               {get(errors, `${baseKey}${operatingHoursKey}.${day}.amountServed.message`)}
            </p>
         )}
      </div>
   )
}
