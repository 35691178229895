import { AUTHORITIES } from '@/config/constants'
import { ContentSettingsType } from '@/model/enumerations/content-settings-type'

function filterForAuthority(account, authority) {
   if (account == undefined || account.authorities == undefined) {
      return false
   }
   return account.authorities.filter(auth => auth === authority).length > 0
}

export const isAdmin = account => {
   if (Object.keys(account).length === 0) {
      return false
   }
   return filterForAuthority(account, AUTHORITIES.ADMIN)
}

export const isUserLoggedIn = account => {
   return Object.keys(account).length > 0
}

export const isSettingEnabled = (account, setting: ContentSettingsType) => {
   return account?.contentSettings?.[setting] || false
}

export const isModOrHigher = account => {
   if (Object.keys(account).length === 0) {
      return false
   }
   return isAdmin(account) || isMod(account) || isSeniorMod(account)
}
export const isAModOrHigherOrOwner = (account, userLogin) => {
   if (account.login == userLogin) {
      return true
   }

   if (Object.keys(account).length === 0) {
      return false
   }
   return isAdmin(account) || isMod(account) || isSeniorMod(account)
}

export const isMod = account => {
   if (Object.keys(account).length === 0) {
      return false
   }
   return filterForAuthority(account, AUTHORITIES.MOD)
}
export const isSeniorMod = account => {
   if (Object.keys(account).length === 0) {
      return false
   }
   return filterForAuthority(account, AUTHORITIES.SENIOR_MOD)
}
export const isAdult = account => {
   if (Object.keys(account).length === 0) {
      return false
   }
   // todo
   return true
}

export function isOlderThan18(birthDate: string): boolean {
   if (birthDate === undefined || birthDate === null) {
      return false
   }

   const dateOfBirth = new Date(birthDate)
   const currentDate = new Date()
   const eighteenYearsAgo = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate())
   return dateOfBirth <= eighteenYearsAgo
}
