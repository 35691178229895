import { StaticContentType } from '@/layout/static-page/StaticContentType'
import { RestClient } from '@/config/rest-client'
import { Key } from 'react'

const apiUrl = '/api/static-content'

export interface ContentConfig {
   locale: string
   type: string
   value: string
   userRichText: boolean
}

export const getAllConfigs = (locale: string) => {
   return RestClient.get<ContentConfig[]>(`${apiUrl}/all-configs-by-locale/${locale}`)
}

export const getConfigsByTypes = (types: StaticContentType[], locale: string) => {
   return RestClient.post<ContentConfig[]>(`${apiUrl}/by-types/${locale}`, types)
}

export const getConfigByType = (configType: StaticContentType, locale: string) => {
   return RestClient.get<string>(`${apiUrl}/by-type/${configType}/${locale}`)
}

export interface UpdateTermsParams {
   locale: string
   type: string
   value: string
}

export const updateTerms = (entity: UpdateTermsParams) => {
   const requestUrl = `${apiUrl}/update-content`
   return RestClient.post<any>(requestUrl, entity)
}

export const createSuggestedPosts = (entity: any) => {
   return RestClient.post<any>(apiUrl + '/suggested-posts', entity)
}
export const createDistinguishedPosts = (entity: any) => {
   return RestClient.post<any>(apiUrl + '/distinguished-posts', entity)
}

export const addSuggestedPost = (entity: any) => {
   return RestClient.post<any>(apiUrl + '/suggested-post-add', entity)
}
export const addDistinguishedPost = (entity: any) => {
   return RestClient.post<any>(apiUrl + '/distinguished-post-add', entity)
}

export const clearCache = (cacheName: string) => {
   return RestClient.post<any>('/api/admin/clear-cache', cacheName)
}

export const refreshElasticPosts = () => {
   return RestClient.get<any>('/api/admin/refresh-elastic-chefs')
}
export const refreshKarma = () => {
   return RestClient.get<any>('/api/admin/refresh-karma')
}
export const getCaches = () => {
   return RestClient.get<any>('/api/admin/get-caches')
}
export const getNotificationSettings = (locale: string) => {
   return RestClient.get<any>(`/api/admin/get-notifications-types/${locale}`)
}
export const saveNotificationSetting = (key: Key, value: any, locale: string) => {
   return RestClient.post<any>('/api/admin/get-notifications-types', { key, value, locale })
}
export const updateContentSettings = entity => {
   return RestClient.post('/api/admin/admin-settings', entity)
}
export const getAdminSettings = () => {
   return RestClient.get<any>('/api/admin/admin-settings')
}
export const initializeEmptyUserSettings = () => {
   return RestClient.post('/api/admin/initialize-admin-settings')
}
export const getFCMConfig = () => {
   return RestClient.get<Record<string, string>>('/api/fcm/get-fcm-config')
}
export const setFCMConfig = (entity: Record<string, string>) => {
   return RestClient.post<Record<string, string>>('/api/fcm/set-fcm-config', entity)
}
export const updateLandingPageChefs = (chefIds: string[]) => {
    return RestClient.post<any>('/api/chef/update-landing-page-chefs', chefIds)
}