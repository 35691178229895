import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldErrors, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useAppDispatch } from '../../../config/store'
import { changePassword } from '../../../service/user-settings-reducer'
import { getSession } from '../../../service/authentication'
import { LoadingIndicator } from '../../common/LoadingIndicator'

interface ChangePassFormProps {
   onConfirm: () => void
}

interface ChangePassRequest {
   newPassword: string
   newPasswordConfirm: string
   currentPassword: string
}

export const ChangePassForm = ({ onConfirm }: ChangePassFormProps) => {
   const { t } = useTranslation()
   const {
      register,
      handleSubmit,
      formState: { errors },
   } = useForm<ChangePassRequest>()
   const [isLoading, setIsLoading] = useState(false)
   const dispatch = useAppDispatch()

   const onFinish = (values: ChangePassRequest) => {
      const { currentPassword, newPassword, newPasswordConfirm } = values

      if (newPassword !== newPasswordConfirm) {
         toast.error(t('change_pass.do_not_match'))
         return
      }

      setIsLoading(true)
      changePassword({ currentPassword: currentPassword, newPassword: newPassword })
         .then(() => {
            toast.success(t('change_pass.success'))
            dispatch(getSession).then(() => {
               setIsLoading(false)
               onConfirm()
            })
         })
         .catch(reason => {
            setIsLoading(false)
            const error = reason?.response?.data?.title || t('change_pass.error')
            if (error === 'Incorrect password') {
               toast.error(t('change_pass.incorrect'))
            } else {
               toast.error(t('change_pass.error'))
            }
         })
   }

   const onError = (errors: FieldErrors<ChangePassRequest>) => {
      Object.values(errors).forEach(error => {
         toast.error(error.message)
      })
   }

   return (
      <form className='border-gray-custom rounded-normal mb-2 max-w-xl' onSubmit={handleSubmit(onFinish, onError)}>
         <div className='flex flex-1 items-center justify-start px-2'>
            <p className='paragraph-common'>{t('change_pass.current')}</p>
            <div className='flex flex-1 flex-col'>
               <input
                  className='background-gray-dark-normal rounded-normal m-2 flex-1 p-2 outline-hidden'
                  {...register('currentPassword', { required: t('error.required') })}
                  type='password'
                  placeholder={t('change_pass.current_placeholder')}
               />
               {errors.currentPassword && <span className='text-red-500'>{errors.currentPassword.message}</span>}
            </div>
         </div>

         <div className='flex flex-1 items-center justify-start px-2'>
            <p className='paragraph-common'>{t('change_pass.new')}</p>
            <div className='flex flex-1 flex-col'>
               <input
                  className='background-gray-dark-normal rounded-normal m-2 flex-1 p-2 outline-hidden'
                  {...register('newPassword', {
                     required: t('error.required'),
                     minLength: {
                        value: 8,
                        message: t('error.password_min_length', { length: 8 }),
                     },
                     maxLength: {
                        value: 64,
                        message: t('error.password_max_length', { length: 64 }),
                     },
                     pattern: {
                        value: /^(?=.*[^A-Za-z0-9]).{8,64}$/,
                        message: t('error.password_special_char'),
                     },
                  })}
                  type='password'
                  placeholder={t('change_pass.new_placeholder')}
               />
               {errors.newPassword && <span className='text-red-500'>{errors.newPassword.message}</span>}
            </div>
         </div>

         <div className='flex flex-1 items-center justify-start px-2'>
            <p className='paragraph-common'>{t('change_pass.new')}*</p>
            <div className='flex flex-1 flex-col'>
               <input
                  className='background-gray-dark-normal rounded-normal m-2 flex-1 p-2 outline-hidden'
                  {...register('newPasswordConfirm', {
                     required: t('error.required'),
                  })}
                  type='password'
                  placeholder={t('change_pass.new_confirm_placeholder')}
               />
               {errors.newPasswordConfirm && <span className='text-red-500'>{errors.newPasswordConfirm.message}</span>}
            </div>
         </div>

         <button type='submit' className='small-confirm-button'>
            {t('common.submit')}
         </button>
         {isLoading && <LoadingIndicator />}
      </form>
   )
}
