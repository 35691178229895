export enum CuisineType {
   AFRICAN = 'AFRICAN',
   AMERICAN = 'AMERICAN',
   ARABIC = 'ARABIC',
   BRAZILIAN = 'BRAZILIAN',
   CARIBBEAN = 'CARIBBEAN',
   CHINESE = 'CHINESE',
   ETHIOPIAN = 'ETHIOPIAN',
   FRENCH = 'FRENCH',
   GERMAN = 'GERMAN',
   GREEK = 'GREEK',
   INDIAN = 'INDIAN',
   ITALIAN = 'ITALIAN',
   JAPANESE = 'JAPANESE',
   KOREAN = 'KOREAN',
   LEBANESE = 'LEBANESE',
   MEDITERRANEAN = 'MEDITERRANEAN',
   MEXICAN = 'MEXICAN',
   MOROCCAN = 'MOROCCAN',
   PAKISTANI = 'PAKISTANI',
   SPANISH = 'SPANISH',
   SWEDISH = 'SWEDISH',
   THAI = 'THAI',
   TURKISH = 'TURKISH',
   VIETNAMESE = 'VIETNAMESE',
}
