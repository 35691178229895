import en from 'javascript-time-ago/locale/en'
import pl from 'javascript-time-ago/locale/pl'
import fr from 'javascript-time-ago/locale/fr'
import TimeAgoLib from 'javascript-time-ago'

import { useAppSelector } from '@/config/store'
import ReactTimeAgo from 'react-time-ago'

TimeAgoLib.addLocale(en)
TimeAgoLib.addLocale(pl)
TimeAgoLib.addLocale(fr)

export const TimeAgo = ({ date }: { date: Date }) => {
   return date && <ReactTimeAgo date={date} locale={useAppSelector(state => state.localeSlice.lng)} />
}
