import React, { useState } from 'react'
import { Chef } from '@/model/chef.model'
import { useTranslation } from 'react-i18next'
import { isDesktop, isMobile } from 'react-device-detect'
import { RenderDeliveryType } from '@/layout/home/maps/SingleChefCard'
import { GiKnifeFork } from 'react-icons/gi'
import { ChefStars } from '@/layout/common/ChefStars'
import { UserLoginComponent, UserLoginComponentType } from '@/layout/common/UserLoginComponent'
import { BackgroundPictureWithHeart, PictureMode } from '@/layout/chef/pictures/BackgroundPictureWithHeart'
import { DeliveryDetails } from '@/layout/chef/DeliveryDetails'
import { TfiLocationPin } from 'react-icons/tfi'
import { DeliveryType } from '@/model/enumerations/delivery-type.model'
import { days } from '@/util/date-utils'

const deliveryTypeOrder = [DeliveryType.PICKUP, DeliveryType.DINE_IN, DeliveryType.DELIVER_TO_PICKUP_POINT]

interface ChefBannerInfoProps {
   chef: Chef
   showAddressClicked: () => void
}

export const ChefBannerInfoInChefDetails = ({ chef, showAddressClicked }: ChefBannerInfoProps) => {
   const { t } = useTranslation()
   const [showDeliveryInfo, setShowDeliveryInfo] = useState<DeliveryType>(null)

   let chefFirstDayOffer = (
      <div>
         {(() => {
            if (!chef) return null

            // Check which hours are defined
            const hours = chef.pickupHours || chef.dineInHours || chef.deliverToPickUpPointHours

            if (!hours) return null

            const currentDate = new Date()
            const currentDay = days[currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1]
            const currentHour = currentDate.getHours()
            const currentMinute = currentDate.getMinutes()
            const currentTimeString = `${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`

            // Function to check if current time is after closing time
            const isAfterClosingTime = (day, closeTime) => {
               if (day !== currentDay) return false
               return currentTimeString > closeTime
            }

            // Find first day with open hours, starting from current day
            let firstWorkingDay = null
            let dayDisplayKey = null

            // First check today if hours are available and not closed yet
            if (
               hours[currentDay]?.open &&
               hours[currentDay]?.close &&
               hours[currentDay].open !== '' &&
               hours[currentDay].close !== '' &&
               !isAfterClosingTime(currentDay, hours[currentDay].close)
            ) {
               firstWorkingDay = currentDay
               dayDisplayKey = 'TODAY'
            } else {
               // If today is not available or already closed, find next available day
               const currentDayIndex = days.indexOf(currentDay)
               const orderedDays = [...days.slice(currentDayIndex + 1), ...days.slice(0, currentDayIndex)]

               // Find first available day
               const nextDayIndex = orderedDays.findIndex(
                  day => hours[day]?.open && hours[day]?.close && hours[day].open !== '' && hours[day].close !== '',
               )

               if (nextDayIndex !== -1) {
                  firstWorkingDay = orderedDays[nextDayIndex]
                  // If the first available day is tomorrow
                  dayDisplayKey = nextDayIndex === 0 ? 'TOMORROW' : firstWorkingDay
               }
            }

            if (!firstWorkingDay) return null

            // Display first working day with hours
            return (
               <p
                  className='inline-block rounded-full bg-white px-2 py-0.5 text-sm font-semibold text-black hover:cursor-pointer'
                  onClick={() => {
                     setShowDeliveryInfo(
                        chef.deliveryTypes?.sort(
                           (a, b) => deliveryTypeOrder.indexOf(a) - deliveryTypeOrder.indexOf(b)
                        )[0] || null
                     )
                  }}
               >
                  {t(`days.${dayDisplayKey || firstWorkingDay}`)}: {hours[firstWorkingDay].open} -{' '}
                  {hours[firstWorkingDay].close}
               </p>
            )
         })()}
      </div>
   )

   return (
      <>
         <div
            id={chef?.id}
            key={chef?.id}
            className={`rounded-normal relative mx-auto h-80 w-full max-w-[1030px] bg-gray-300`}
         >
            <BackgroundPictureWithHeart
               chef={chef}
               showWriteReviewButton={false}
               pictureMode={{ mode: PictureMode.GALLERY, dark: true }}
               showHeart={true}
               additionalStyles={'rounded-normal h-80'}
               children={[
                  <div
                     key='location'
                     className={`absolute flex flex-col ${isMobile ? 'bottom-1.5 left-2.5 min-w-[120px]' : 'bottom-3 left-4'}`}
                  >
                     <div className={`z-10 flex flex-col pb-1 select-none`}>
                        {chef?.deliveryTypes?.sort(
                           (a, b) => deliveryTypeOrder.indexOf(a) - deliveryTypeOrder.indexOf(b),
                        ) && (
                           <div className='w-full pt-2.5'>
                              <RenderDeliveryType
                                 isPickUpPoint={chef?.pickUpPoint}
                                 onDeliveryTypeClicked={delivery => {
                                    setShowDeliveryInfo(delivery)
                                 }}
                                 deliveryTypes={chef?.deliveryTypes}
                                 customStyles={`text-white ${isMobile ? 'text-[15px]' : 'text-[18px] '} flex flex-row flex-wrap whitespace-nowrap`}
                              />
                           </div>
                        )}
                     </div>
                  </div>,
                  <div
                     id='chefName'
                     key='chefName'
                     className={`absolute ${isDesktop ? 'top-4 left-4' : 'top-4 left-2.5 max-w-[73%]'} flex flex-col`}
                  >
                     {chefFirstDayOffer}
                     <p
                        data-cy='chef-name'
                        className={`xss:text-[25px] text-[18px] font-semibold text-white sm:text-[28px] lg:text-[32px]`}
                     >
                        {chef?.name}
                     </p>
                     <div
                        className='mt-0.5 mb-1 flex flex-row items-center text-white hover:cursor-pointer hover:text-blue-300'
                        onClick={() => {
                           showAddressClicked()
                        }}
                     >
                        <TfiLocationPin className='mr-0.5 -ml-[2px] h-5 w-5 shrink-0' />
                        <p className='line-clamp-2 max-w-[73%] text-base'>
                           {chef?.address?.locationName && chef?.address?.locationName?.trim() !== ''
                              ? chef?.address?.locationName
                              : chef?.address?.villageTownCity && chef?.address?.villageTownCity.trim() !== ''
                                ? chef?.address?.villageTownCity
                                : ''}
                        </p>
                     </div>
                     {chef?.dietary?.length > 0 && (
                        <div className='z-10 mt-1 flex max-w-[75%] flex-row items-center'>
                           <div className='flex flex-row flex-wrap'>
                              {/* GiKnifeFork as part of the row */}
                              <span className='ml-[2px] flex items-center'>
                                 <GiKnifeFork className='mr-2 h-5 w-5 shrink-0 text-white' />
                              </span>
                              {chef?.cuisines?.map((c, index) => {
                                 return (
                                    <span key={c} className='flex items-center'>
                                       {index > 0 && <span className='mx-1 text-lg text-white'>•</span>}
                                       <p className='xss:text-base text-sm whitespace-nowrap text-white'>
                                          {t('cuisine.' + c)}
                                       </p>
                                    </span>
                                 )
                              })}
                              {chef?.cuisines?.length > 0 && chef?.dietary?.length > 0 && (
                                 <span className='mx-1 text-lg text-white'>•</span>
                              )}
                              {chef?.dietary?.map((d, index) => {
                                 return (
                                    <span key={d} className='flex items-center'>
                                       {index > 0 && <span className='mx-1 text-lg text-white'>•</span>}
                                       <p className='xss:text-base text-sm whitespace-nowrap text-white sm:text-lg lg:text-xl'>
                                          {t('dietary.' + d.toLowerCase())}
                                       </p>
                                    </span>
                                 )
                              })}
                           </div>
                        </div>
                     )}
                  </div>,
                  <div
                     id='profileInfo'
                     key={'profileInfo'}
                     className={`absolute top-20 ${isMobile ? 'right-4' : 'right-6'} flex flex-col items-center`}
                  >
                     <UserLoginComponent
                        filledBackground={false}
                        userId={chef?.creatorId}
                        avatar={chef?.avatar}
                        type={UserLoginComponentType.ONLY_ICON}
                        addedStylesForIcon={`min-w-[65px] w-[65px] min-h-[65px] h-[65px] ${isMobile ? 'stroke-gray-300' : 'stroke-gray-100'} `}
                     />
                     <h2 className='text-lg text-white'>
                        {chef?.firstName} {chef?.lastName}
                     </h2>
                     <div className='flex items-center'>
                        <ChefStars
                           averageVote={chef?.averageVote}
                           votesCount={chef?.votesCount}
                           filledIcons={true}
                           emptyColor={'text-gray-300'}
                        />
                     </div>
                     {/*todo uncomment after improving chat*/}
                     {/*{!chef?.pickUpPoint && (*/}
                     {/*   <button*/}
                     {/*      className={`bg-customPurple hover:bg-customPurpleDark mt-2 rounded-sm px-4 py-2 font-bold text-white ${canManage ? 'cursor-not-allowed' : 'hover:cursor-pointer'}`}*/}
                     {/*      onClick={() => {*/}
                     {/*         if (!canManage) navigate('/chat-with-user/' + chef.id)*/}
                     {/*      }}*/}
                     {/*   >*/}
                     {/*      {t('chef_profile.write_to_me')}*/}
                     {/*   </button>*/}
                     {/*)}*/}
                  </div>,
               ]}
            />
         </div>
         <DeliveryDetails chef={chef} chosenDelivery={showDeliveryInfo} setChosenDelivery={setShowDeliveryInfo} />
      </>
   )
}
