import React, { useCallback, useEffect, useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { toast } from 'react-toastify'
import { confirmPayment, logStripeError } from '@/service/shopping-card.service'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/config/store'

const PaymentForm = ({ paymentId }) => {
   const { t } = useTranslation()
   const navigate = useNavigate()
   const stripe = useStripe()
   const elements = useElements()
   const loggedAccount = useAppSelector(state => state.authentication.account)

   const [errorMessage, setErrorMessage] = useState('')
   const [loading, setLoading] = useState(false)
   const [isFormComplete, setIsFormComplete] = useState(false)

   // Check form completion status
   const checkFormCompletion = useCallback(() => {
      if (!elements) return

      const paymentElement = elements.getElement(PaymentElement)
      if (paymentElement) {
         // @ts-ignore
         paymentElement.on('change', event => {
            setIsFormComplete(event.complete) // Update form completeness based on Stripe event
         })
      }
   }, [elements])

   useEffect(() => {
      checkFormCompletion()
   }, [checkFormCompletion])

   const handleSubmit = useCallback(
      async event => {
         event.preventDefault()

         if (loading) return
         if (!stripe || !elements) return

         setLoading(true)

         try {
            const { error, paymentIntent } = await stripe.confirmPayment({
               elements,
               confirmParams: {
                  return_url: `${window.location.origin}/confirmation/${paymentId}`,
                  payment_method_data: {
                     billing_details: {
                        name: loggedAccount?.name,
                        email: loggedAccount?.email,
                     },
                  },
               },
               redirect: 'if_required',
            })

            if (error) {
               console.log('Payment error:', error)
               logStripeError(JSON.stringify(error))
               toast.error(error.message)
               setErrorMessage(error.message)
            } else if (paymentIntent && paymentIntent.status === 'succeeded') {
               console.log('Payment succeeded!')
               const res = await confirmPayment({
                  paymentIntentId: paymentId,
                  stripePaymentId: paymentIntent.id,
               })
               navigate('/confirmation/' + res.data.id)
            } else if (paymentIntent && paymentIntent.status === 'processing') {
               console.log('Payment processing.')
            } else {
               console.log('Payment status:', paymentIntent.status)
            }
         } catch (err) {
            console.error('Payment processing error:', err)
            toast.error(t('shopping_cart.payment_failed'))
            setErrorMessage(t('shopping_cart.payment_failed'))
         } finally {
            setLoading(false)
         }
      },
      [stripe, elements, loading, navigate, paymentId, loggedAccount, t],
   )

   return (
      <div className='background-normal mx-auto my-8 w-full rounded-lg border p-4 shadow-md'>
         <h1 className='my-3 text-center text-xl font-bold'>{t('shopping_cart.pay_with_stripe')}</h1>
         <form onSubmit={handleSubmit} className='flex flex-col space-y-4'>
            <div className='my-2 rounded-md border p-2'>
               <PaymentElement options={{ layout: 'tabs' }} />
            </div>
            {errorMessage && <div className='mt-2 text-sm text-red-500'>{errorMessage}</div>}
            <button
               type='submit'
               disabled={!stripe || loading || !isFormComplete} // Disable until the form is complete
               className={`w-full rounded-md p-2 font-semibold text-white transition-colors ${
                  !stripe || loading || !isFormComplete
                     ? 'gray-out cursor-not-allowed opacity-50'
                     : 'bg-customPurple hover:bg-customPurpleDark'
               }`}
               onClick={() => {
                  if (!stripe) toast.error(t('shopping_cart.stripe_not_initialized'))
                  else if (!isFormComplete) toast.error(t('shopping_cart.form_incomplete'))
               }}
            >
               {loading ? t('shopping_cart.processing') : t('shopping_cart.pay')}
            </button>
         </form>
      </div>
   )
}

export default PaymentForm
