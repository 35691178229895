import React from 'react'

import { AbstractMobileModal } from '@/layout/common/AbstractMobileModal'
import { AddFood, AddFoodModalProps } from './AddFood'
import { useTranslation } from 'react-i18next'

export const AddFoodModalMobile: React.FC<AddFoodModalProps> = ({ food, onClose }) => {
   const { t } = useTranslation()
   return (
      <AbstractMobileModal
         title={<div className=''>{t('add_food_page.title')}</div>}
         customNavigation={() => onClose(null)}
         increaseZIndex={true}
      >
         <AddFood food={food} onClose={onClose} />
      </AbstractMobileModal>
   )
}
