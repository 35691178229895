import {useLexicalEditable} from '@lexical/react/useLexicalEditable'
import {
   DecoratorNode,
   DOMConversion,
   DOMConversionOutput,
   DOMExportOutput,
   LexicalNode,
   NodeKey,
   SerializedLexicalNode,
} from 'lexical'
import React from 'react'

// Define the serialized format for FullWidthBreakNode
export interface SerializedFullWidthBreakNode extends SerializedLexicalNode {
   type: 'fullWidthBreak'
   version: 1
}

// React component for rendering in the editor
function FullWidthBreakDecorator() {
   const isEditable = useLexicalEditable()
   return (
      <div
         style={{
            display: 'block',
            clear: 'both',
            width: '100%',
            backgroundColor: isEditable ? 'red' : 'transparent',
            height: '1px',
         }}
      />
   )
}

export class FullWidthBreakNode extends DecoratorNode<React.ReactNode> {
   constructor(key?: NodeKey) {
      super(key)
   }

   static getType(): string {
      return 'fullWidthBreak'
   }

   static clone(node: FullWidthBreakNode): FullWidthBreakNode {
      return new FullWidthBreakNode(node.__key)
   }

   // Specify how this node is imported from HTML
   static importDOM(): Record<string, (node: Node) => DOMConversion | null> {
      return {
         div: (node: HTMLElement): DOMConversion | null => {
            if (node.getAttribute('data-lexical-type') === 'fullWidthBreak') {
               return {
                  conversion: (): DOMConversionOutput => ({ node: new FullWidthBreakNode() }),
                  priority: 4 as const,
               }
            }
            return null
         },
      }
   }

   // Specify how this node is exported to HTML
   static exportDOM(): DOMExportOutput {
      const div = document.createElement('div')
      div.setAttribute('data-lexical-type', 'fullWidthBreak')
      return { element: div }
   }

   // Specify how this node is deserialized from JSON
   static importJSON(serializedNode: SerializedFullWidthBreakNode): FullWidthBreakNode {
      return new FullWidthBreakNode()
   }

   // Create a DOM element to represent the node in the editor
   createDOM(): HTMLElement {
      const div = document.createElement('div')
      div.setAttribute('data-lexical-type', 'fullWidthBreak')
      div.style.display = 'block'
      div.style.clear = 'both'
      div.style.width = '100%'
      div.style.height = '1px'
      return div
   }

   // Ensure DOM reconciliation doesn't re-render unnecessarily
   updateDOM(prevNode: FullWidthBreakNode, dom: HTMLElement): boolean {
      return false
   }

   // Specify how this node is serialized to JSON
   exportJSON(): SerializedFullWidthBreakNode {
      return {
         type: 'fullWidthBreak',
         version: 1,
      }
   }

   // Specify how this node is rendered in the editor
   decorate(): React.ReactNode {
      return <FullWidthBreakDecorator />
   }
}

// Helper functions for creating and identifying the node
export function $createFullWidthBreakNode(): FullWidthBreakNode {
   return new FullWidthBreakNode()
}

export function $isFullWidthBreakNode(node: LexicalNode | null | undefined): node is FullWidthBreakNode {
   return node instanceof FullWidthBreakNode
}
