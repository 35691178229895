import { CloseButton } from '@/util/CloseButtons'
import React, { MouseEvent, ReactNode, useEffect, useRef } from 'react'
import { OuterClick } from 'react-outer-click'

interface AbstractWebModalProps {
   children: ReactNode
   onClose: (event: KeyboardEvent | MouseEvent) => void
   additionalRefs?: React.RefObject<HTMLElement>[]
   // title: string | React.ReactNode
   title: string
   rightElement?: React.ReactNode
}

export const AbstractWebModal = ({
   children,
   onClose,
   additionalRefs = [],
   title,
   rightElement,
}: AbstractWebModalProps) => {
   const modalRef = useRef<HTMLDivElement>(null)

   useEffect(() => {
      // Function to handle the escape key press
      const handleEscKey = (event: KeyboardEvent) => {
         if (event.key === 'Escape') onClose(event)
      }

      // Attach the event listener
      document.addEventListener('keydown', handleEscKey)

      // Cleanup function to remove the event listener
      return () => {
         document.removeEventListener('keydown', handleEscKey)
      }
   }, [onClose])

   useEffect(() => {
      const scrollY = window.scrollY
      const bodyStyle = document.body.style

      // Save the original styles to restore them later
      const originalOverflow = bodyStyle.overflow
      const originalPaddingRight = bodyStyle.paddingRight

      // Calculate the scrollbar width
      const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth

      // Lock the scroll
      bodyStyle.overflow = 'hidden'
      bodyStyle.paddingRight = `${scrollbarWidth}px`

      return () => {
         // Unlock the scroll
         bodyStyle.overflow = originalOverflow
         bodyStyle.paddingRight = originalPaddingRight
         window.scrollTo(0, scrollY)
      }
   }, [])

   const handleOuterClick = (event: MouseEvent) => {
      // Function to check if the click is outside the provided ref
      const isClickOutside = (ref: React.RefObject<HTMLElement>) =>
         ref.current && !ref.current.contains(event.target as Node)

      console.log('isClickOutside', isClickOutside)
      // First, check if the click is outside the modal
      const isOutsideModal = modalRef.current && !modalRef.current.contains(event.target as Node)

      console.log('isOutsideModal', isOutsideModal)
      // Then, check if it's outside any additional refs provided
      const hasAdditionalRefs = additionalRefs.length > 0
      console.log('hasAdditionalRefs', hasAdditionalRefs)
      const isOutsideAdditionalRefs = additionalRefs.every(ref => isClickOutside(ref))

      console.log('isOutsideAdditionalRefs', isOutsideAdditionalRefs)
      // If the click is outside both the modal and the additional refs, close the modal
      if (isOutsideModal && isOutsideAdditionalRefs) {
         onClose(event)
      }
   }

   return (
      <div className='modal-outer'>
         <OuterClick onOuterClick={handleOuterClick}>
            <div className='modal-inner' ref={modalRef}>
               <div className='modal-content'>
                  <div className='flex flex-row justify-between pr-1'>
                     <CloseButton onButtonClicked={onClose} />
                     <div className='grow text-center'>
                        {typeof title === 'string' ? (
                           <h1 className='text-color-normal w-full text-center text-2xl font-semibold'>{title}</h1>
                        ) : (
                           title
                        )}
                     </div>
                     {rightElement ? (
                        <div>{rightElement}</div>
                     ) : (
                        // Placeholder to balance the layout
                        <div style={{ width: '36px' }} />
                     )}
                  </div>
                  {children}
               </div>
            </div>
         </OuterClick>
      </div>
   )
}
