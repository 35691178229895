import React, { lazy, ReactNode, Suspense, useEffect, useState } from 'react'
import {
   autoPlacement,
   autoUpdate,
   flip,
   offset,
   Placement,
   shift,
   useClick,
   useDismiss,
   useFloating,
   useId,
   useInteractions,
   useRole,
} from '@floating-ui/react'
import { showLoginModal } from '@/service/authentication'
import { useAppDispatch } from '@/config/store'

const FloatingFocusManager = lazy(() =>
   import('@floating-ui/react').then(module => ({ default: module.FloatingFocusManager })),
)

export const FloatingUIComponent = ({
   menuButton,
   insideElement,
   offsetX = 0,
   offsetY = 9,
   shouldTriggerShowLogin = false,
   isOpen,
   setIsOpen,
   isLoggedIn = true,
   allowedPlacements = [],
   triggerOnHover = false,
   mouseEnterDelay = 500,
   mouseLeaveDelay = 200,
}) => {
   const { refs, floatingStyles, context } = useFloating({
      open: isOpen,
      onOpenChange: setIsOpen,
      middleware: [
         offset({
            mainAxis: offsetX,
            crossAxis: offsetY,
         }),
         allowedPlacements.length === 0
            ? autoPlacement()
            : autoPlacement({
                 allowedPlacements: allowedPlacements,
              }),
         shift(),
      ],
      whileElementsMounted: autoUpdate,
   })

   const [hoverTimeout, setHoverTimeout] = useState(null)
   const { getReferenceProps, getFloatingProps } = useInteractions([
      useClick(context),
      useDismiss(context),
      useRole(context),
   ])
   const dispatch = useAppDispatch()
   const headingId = useId()
   const [leaveTimeout, setLeaveTimeout] = useState(null)

   useEffect(() => {
      if (isOpen && !isLoggedIn && shouldTriggerShowLogin) dispatch(showLoginModal())
   }, [isOpen, isLoggedIn, dispatch, shouldTriggerShowLogin])

   const handleMouseEnter = () => {
      if (leaveTimeout) {
         clearTimeout(leaveTimeout)
         setLeaveTimeout(null)
      }
      const timeout = setTimeout(() => {
         setIsOpen(true)
      }, mouseEnterDelay)
      setHoverTimeout(timeout)
   }

   const handleMouseLeave = () => {
      if (hoverTimeout) {
         clearTimeout(hoverTimeout)
         setHoverTimeout(null)
      }
      const timeout = setTimeout(() => {
         setIsOpen(false)
      }, mouseLeaveDelay)
      setLeaveTimeout(timeout)
   }

   useEffect(() => {
      return () => {
         if (hoverTimeout) clearTimeout(hoverTimeout)
         if (leaveTimeout) clearTimeout(leaveTimeout)
      }
   }, [hoverTimeout, leaveTimeout])

   return (
      <div
         onMouseEnter={triggerOnHover ? handleMouseEnter : undefined}
         onMouseLeave={triggerOnHover ? handleMouseLeave : undefined}
      >
         <div {...getReferenceProps()} ref={refs.setReference}>
            {menuButton}
         </div>
         {isOpen && isLoggedIn && (
            <Suspense fallback={<></>}>
               <FloatingFocusManager context={context} modal={false} disabled={true}>
                  <div
                     className='z-50 w-full select-none'
                     ref={refs.setFloating}
                     style={floatingStyles}
                     aria-labelledby={headingId}
                     {...getFloatingProps()}
                  >
                     {insideElement}
                  </div>
               </FloatingFocusManager>
            </Suspense>
         )}
      </div>
   )
}

interface FloatingUIWrapperProps {
   menuButton: (props: { ref: React.Ref<any>; onClick: (e: React.MouseEvent) => void }) => ReactNode
   closeOnClickInside?: boolean
   addedStyles?: React.CSSProperties
   zIndex?: number
   placement?: Placement
   offsetValue?: number
   children?: React.ReactNode
   disableDefaultStyles?: boolean
}

const FloatingUIWrapper: React.FC<FloatingUIWrapperProps> = ({
   menuButton,
   children,
   closeOnClickInside = true,
   addedStyles = {},
   zIndex = 30,
   placement = 'bottom-end',
   offsetValue = 5,
   disableDefaultStyles = false,
}) => {
   const [visible, setVisible] = useState(false)

   const { refs, floatingStyles } = useFloating({
      placement,
      middleware: [offset(offsetValue), flip(), shift()],
      whileElementsMounted: autoUpdate,
   })

   const closePopper = () => {
      setVisible(false)
   }

   // Type guard to check if an element is a Node (DOM element)
   const isNode = (element: any): element is Element => {
      return element instanceof Element
   }

   // Close the popper when clicking outside of it
   useEffect(() => {
      function handleClickOutside(event: MouseEvent) {
         const referenceElement = refs.reference.current
         const floatingElement = refs.floating.current

         if (
            isNode(referenceElement) &&
            !referenceElement.contains(event.target as Node) &&
            isNode(floatingElement) &&
            !floatingElement.contains(event.target as Node)
         ) {
            closePopper()
         }
      }

      if (visible) {
         document.addEventListener('mousedown', handleClickOutside)
      } else {
         document.removeEventListener('mousedown', handleClickOutside)
      }

      return () => {
         document.removeEventListener('mousedown', handleClickOutside)
      }
   }, [visible, refs])

   return (
      <>
         {menuButton({
            ref: refs.setReference,
            onClick: (e: React.MouseEvent) => {
               e.stopPropagation() // Prevent event bubbling
               setVisible(prev => !prev)
            },
         })}
         {visible && (
            <div
               ref={refs.setFloating}
               style={{ ...floatingStyles, zIndex, ...addedStyles }}
               className={`${!disableDefaultStyles && 'ring-opacity-5 mt-1 w-56 rounded-lg bg-white ring-1 shadow-lg ring-black/40 select-none'}`}
            >
               <div
                  onClick={e => {
                     if (closeOnClickInside) {
                        e.stopPropagation()
                        e.preventDefault()
                        closePopper()
                     }
                  }}
               >
                  {children}
               </div>
            </div>
         )}
      </>
   )
}

export default FloatingUIWrapper
