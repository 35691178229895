import { DeleteOutlined, EditOutlined, StarFilled, StarOutlined } from '@ant-design/icons'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import {
   deleteComment,
   saveCommentForTheUser,
   undoDeleteComment,
   undoSaveComment,
} from '@/service/comment/comment-reducer'
import { useAppSelector } from '@/config/store'
import { isModOrHigher, isUserLoggedIn } from '@/util/auth-util'
import { FloatingUIComponent } from '../common/FloatingUIComponent'
import { ConfirmModal } from '../common/ConfirmModal'
import { ReportModal } from './ReportModal'
import { LoadingIndicator } from '@/layout/common/LoadingIndicator'
import { HiFire } from 'react-icons/hi2'
import { MdOutlineMoreVert } from 'react-icons/md'
import { TiDocumentDelete } from 'react-icons/ti'

function onCancelTriggered(setShowDeleteConfirmModal: (value: ((prevState: boolean) => boolean) | boolean) => void) {
   return () => {
      setShowDeleteConfirmModal(false)
   }
}

export const MoreActionsComment = ({
   deleted,
   userLogin,
   commentId,
   commentDeletedFunction,
   setEditMode,
   canDeleteFromFavourites = false,
   deleteFromFavouritesFunction = (commentId: string) => {},
}) => {
   const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)
   const loggedAccount = useAppSelector(state => state.authentication.account)
   const { t } = useTranslation()
   const [showReportModal, setShowReportModal] = useState(false)
   const [triggerNotShow, setTriggerNotShow] = useState(0)
   const [saved, setSaved] = useState(canDeleteFromFavourites)
   const [loading, setLoading] = useState(false)

   const isMod = isModOrHigher(loggedAccount)
   const [popperVisible, setPopperVisible] = useState(false)

   function getMenuButton() {
      return (
         <div className='mr-1'>
            <MdOutlineMoreVert
               className={
                  'tab-hover ml-1 h-[22px] w-[22px] rounded-full p-0 text-gray-400 hover:cursor-pointer hover:text-gray-800'
               }
            />
         </div>
      )
   }

   function saveComment() {
      setSaved(!saved)

      if (!saved) {
         saveCommentForTheUser(commentId)
            .then(_ => {
               toast.success(t('comments.saved'))
            })
            .catch(reason => {
               toast.error(t('common.error_contact_us'))
            })
            .finally(() => {
               setTriggerNotShow(triggerNotShow + 1)
               setPopperVisible(false)
            })
      } else {
         deleteFromFavouritesFunction(commentId)
         undoSaveComment(commentId)
            .then(_ => {
               toast.success(t('comments.undo_saved'))
            })
            .catch(() => {
               toast.error(t('common.error_contact_us'))
            })
            .finally(() => {
               setTriggerNotShow(triggerNotShow + 1)
               setPopperVisible(false)
            })
      }
   }

   function onConfirmTriggered(
      deleted: boolean,
      commentId: string,
      commentDeletedFunction,
      setShowDeleteConfirmModal: (value: ((prevState: boolean) => boolean) | boolean) => void,
      t,
   ) {
      return () => {
         setLoading(true)
         const promise = deleted ? undoDeleteComment(commentId) : deleteComment(commentId)
         promise
            .then(value => {
               toast.success(t(deleted ? 'comments.undo_deleted' : 'comments.deleted'))
               commentDeletedFunction()
               setShowDeleteConfirmModal(false)
            })
            .finally(() => {
               setLoading(false)
            })
      }
   }

   function getInnerElement() {
      function onClickEditComment() {
         setEditMode()
         setTriggerNotShow(triggerNotShow + 1)
         setPopperVisible(false)
      }

      function onClickDeleteFromFavouritesComment() {
         deleteFromFavouritesFunction(commentId)
         undoSaveComment(commentId).then(_ => {
            toast.success(t('comments.undo_saved'))
         })
      }

      function onClickReportComment() {
         setShowReportModal(true)
         setTriggerNotShow(triggerNotShow + 1)
         setPopperVisible(false)
      }

      function onClickDeleteConfirm() {
         setShowDeleteConfirmModal(true)
         setTriggerNotShow(triggerNotShow + 1)
         setPopperVisible(false)
      }

      return (
         <div className='more-actions-items ring-opacity-5 z-30 mt-1 w-56 ring-1 ring-black'>
            {(isMod || userLogin === loggedAccount.login) && !deleted && (
               <div className='z-50'>
                  <button className='more-actions-item' onClick={onClickDeleteConfirm}>
                     <DeleteOutlined className='mr-2 h-5 w-5 pt-1' />
                     {isMod ? t('comments.mark_as_deleted') : t('comments.delete_own')}
                  </button>
               </div>
            )}
            {(isMod || userLogin === loggedAccount.login) && deleted && (
               <div className='z-50'>
                  <button className='more-actions-item' onClick={onClickDeleteConfirm}>
                     <DeleteOutlined className='mr-2 h-5 w-5 pt-1' />
                     {t('comments.undo_delete')}
                  </button>
               </div>
            )}
            {(isMod || userLogin === loggedAccount.login) && (
               <div>
                  <button className='more-actions-item' onClick={onClickEditComment}>
                     <EditOutlined className='mr-2 h-5 w-5 pt-1' />
                     {t('comments.edit')}
                  </button>
               </div>
            )}
            {canDeleteFromFavourites && (
               <div>
                  <button className='more-actions-item' onClick={onClickDeleteFromFavouritesComment}>
                     <TiDocumentDelete className='mr-2 h-5 w-5 pt-1' />
                     {t('comments.delete_from_favorite')}
                  </button>
               </div>
            )}
            <div>
               <button className='more-actions-item' onClick={onClickReportComment}>
                  <HiFire className='mr-2 h-5 w-5' />
                  {t('comments.report')}
               </button>
            </div>
            <div>
               <button style={{ fontSize: '17px' }} className='more-actions-item w' onClick={saveComment}>
                  {saved ? (
                     <StarFilled className='ml-0.5 text-amber-400' />
                  ) : (
                     <StarOutlined className='ml-0.5 text-amber-400' />
                  )}
                  <p className='ml-2 text-sm'>{t(saved ? 'comments.undo_save' : 'comments.save')}</p>
               </button>
            </div>
            {/*<div>*/}
            {/*  <button*/}
            {/*    style={{fontSize: '17px'}}*/}
            {/*    className="more-actions-item w"*/}
            {/*    onClick={saveComment}*/}
            {/*  >*/}
            {/*    <AiOutlineStop className="ml-0.5 text-amber-400"/>*/}
            {/*    <p className="ml-2 text-sm">{t('comments.do_not_show_from_user')}</p>*/}
            {/*  </button>*/}
            {/*</div>*/}
         </div>
      )
   }

   return (
      <div>
         <FloatingUIComponent
            insideElement={getInnerElement()}
            menuButton={getMenuButton()}
            isOpen={popperVisible}
            setIsOpen={setPopperVisible}
            shouldTriggerShowLogin={true}
            isLoggedIn={isUserLoggedIn(loggedAccount)}
         />
         <ConfirmModal
            showModal={showDeleteConfirmModal}
            confirmTranslated={t('delete_comment.confirm')}
            headerTranslated={t(deleted ? 'delete_comment.undo_header1' : 'delete_comment.header1')}
            secondHeaderTranslated={t('delete_comment.header2')}
            onConfirm={onConfirmTriggered(deleted, commentId, commentDeletedFunction, setShowDeleteConfirmModal, t)}
            onCancel={onCancelTriggered(setShowDeleteConfirmModal)}
         />
         <ReportModal
            showModal={showReportModal}
            entityId={commentId}
            type={'comment'}
            onCancel={() => {
               setShowReportModal(false)
            }}
         />
         {loading && <LoadingIndicator />}
      </div>
   )
}
