import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { ProfileSettings } from './tabs/ProfileSettings'
import { PanelOption, PanelPlaceholder } from '@/layout/common/TabPanel'
import { isDesktop } from 'react-device-detect'

export interface UserPanelProps {
   dashboard?: boolean
}

export const UserPanel = ({ dashboard = false }: UserPanelProps) => {
   const { t } = useTranslation()

   const [openTab, setOpenTab] = useState<number>(dashboard ? 1 : 0)

   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   useEffect(() => {
      console.log('dashboard', dashboard, 'openTab', openTab)
   }, [dashboard])

   const items = [
      {
         label: t('user_panel.overview'),
         tabKey: 0,
         content: <ProfileSettings />,
      },
   ]

   return (
      <div className={`rounded-normal flex w-full flex-wrap space-y-2 ${isDesktop ? 'mt-[70px]' : 'mt-[60px]'}`}>
         <PanelPlaceholder
            content={items.map(it => (
               <PanelOption
                  addedStyles='text-sm'
                  key={it.tabKey}
                  tabKey={it.tabKey}
                  openTabKey={openTab}
                  setOpenTab={setOpenTab}
                  content={it.label}
               />
            ))}
         />
         {items.map((tab, index) => (
            <div className={tab.tabKey === openTab ? 'block w-full' : 'hidden'} key={'t' + index}>
               {tab.content}
            </div>
         ))}
      </div>
   )
}
export default UserPanel
