import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import React from 'react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { HowItWorksStep } from '@/layout/landing_page/HowItWorksLandingPage'

export const HowItWorksStepsMobile = ({ steps }) => {
   return (
      <div className={'h-[270px]'}>
         <Swiper
            className='h-full w-full'
            style={{
               // @ts-ignore
               '--swiper-pagination-color': '#461179',
               '--swiper-pagination-bullet-inactive-color': '#a887e3',
               '--swiper-pagination-bullet-inactive-opacity': '0.9',
               '--swiper-pagination-bullet-size': '10px',
               '--swiper-pagination-bullet-horizontal-gap': '6px',
               '--swiper-pagination-bottom': '0px',
            }}
            pagination={{ clickable: true }}
            modules={[Pagination]}
         >
            {steps.map(
               (step: { id: number; animationData: any; title: string; description: string }, index: React.Key) => (
                  <SwiperSlide key={index}>{HowItWorksStep(step)}</SwiperSlide>
               ),
            )}
            <div className='swiper-pagination'></div>
         </Swiper>
      </div>
   )
}
