import { IPost } from '@/model/post.model'
import { RestClient } from '@/config/rest-client'

const apiUrl = '/api/posts'
export const searchPostsAxios = ({
   page,
   sort,
   tag = '',
   userId = '',

   contentType = '',
   topic = '',
   searchedPeriod = '',
   forMobile = false,
}) => {
   console.log(userId)

   const requestUrl = `${apiUrl}?page=${page}&sort=${sort}&contentType=${contentType}&tag=${tag}
	&topic=${topic}&period=${searchedPeriod}&forMobile=${forMobile}`

   return RestClient.get<IPost[]>(requestUrl)
}
export const searchPostsForUser = ({ page, sort, likedBy = '', unlikedBy = '' }) => {
   const requestUrl = `${apiUrl}-for-user?page=${page}&sort=${sort}&likedBy=${likedBy}&unlikedBy=${unlikedBy}`
   return RestClient.get<IPost[]>(requestUrl)
}

export const getLastSeenPosts = (page: number) => {
   const requestUrl = `${apiUrl}/last-viewed-posts?page=${page}`
   return RestClient.get<IPost[]>(requestUrl)
}

export const searchPostsWithPhraseAxios = ({
   page,
   sort,
   tag = '',
   userId = '',
   phrase = '',
   communityName = '',
   contentType = '',
   topic = '',
   searchedPeriod = '',
}) => {
   const requestUrl = `${apiUrl}/search?page=${page}&sort=${sort}&phrase=${phrase}&contentType=${contentType}&tag=${tag}&communityName=${communityName}&userId=${userId}&topic=${topic}&period=${searchedPeriod}`
   return RestClient.get<IPost[]>(requestUrl)
}
export const getFavouritesPostsForUser = (page: number) => {
   const requestUrl = `${apiUrl}/favourites?page=${page}`
   return RestClient.get<IPost[]>(requestUrl)
}
export const getObservedForUser = (page, sortBy, contentType, searchedPeriod, searchedUserId: string) => {
   const requestUrl = `${apiUrl}/observed?page=${page}&sort=${sortBy}&contentType=${contentType}&period=${searchedPeriod}&userId=${searchedUserId}`
   return RestClient.get<IPost[]>(requestUrl)
}
export const getPost = (id: string): Promise<{ data: IPost; status: number; headers: Record<string, string> }> => {
   const requestUrl = `${apiUrl}/${id}`
   return RestClient.get<IPost>(requestUrl)
}
export const createPost = (entity: IPost) => {
   return RestClient.post<IPost>(apiUrl, entity)
}
export const voteForPost = (body: any) => {
   return RestClient.post<any>(apiUrl + '/vote', body)
}
export const updatePost = (entity: IPost) => {
   return RestClient.put<IPost>(`${apiUrl}/${entity.id}`, entity)
}
export const deletePost = (id: string) => {
   const requestUrl = `${apiUrl}/${id}`
   return RestClient.delete<IPost>(requestUrl)
}
export const hidePost = (id: string) => {
   const requestUrl = `${apiUrl}/hide/${id}`
   return RestClient.delete<IPost>(requestUrl)
}
export const savePost = (postId: string) => {
   return RestClient.post<any>(apiUrl + '/save/' + postId)
}
export const turnNotifications = (postId: string) => {
   return RestClient.post<any>(apiUrl + '/turn-notifications/' + postId)
}
export const turnNotificationsOff = (postId: string) => {
   return RestClient.post<any>(apiUrl + '/turn-off-notifications/' + postId)
}
export const undoSavePost = (postId: string) => {
   return RestClient.post<any>(apiUrl + '/undo-save/' + postId)
}
export const fixPost = (postId: string) => {
   return RestClient.post<any>(apiUrl + '/fix-post/' + postId)
}
export const undoDelete = (postId: string) => {
   return RestClient.post<any>(apiUrl + '/undo-delete/' + postId)
}
export const voteForPoll = (postId: string, optionId: number) => {
   return RestClient.post<any>(apiUrl + '/vote-for-poll/' + postId + '/' + optionId)
}
