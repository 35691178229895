import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import { useAppDispatch, useAppSelector } from '@/config/store'
import { updateUserLocation } from '@/service/search'
import { fetchSuggestions, mapCustomAddressToSuggestion } from '@/service/open-street-map.service'
import { debouncedHandleAddressChange } from '@/layout/add-chef/CreateChefPageSecondStep'
import { CustomAddress } from '@/model/custom-address.model'
import { Suggestion } from '@/layout/add-chef/CreateChefPage'
import { isDesktop } from 'react-device-detect'
import { ClipLoader } from 'react-spinners'
import { SuggestionComponent } from '../common/SuggestionComponent'

interface SearchComponentDesktopProps {
   additionalClassesStyles?: string
   landingPageMode?: boolean
}

export const LandingPageSearchLocationDesktop = ({ additionalClassesStyles = '' }: SearchComponentDesktopProps) => {
   const { t } = useTranslation()
   const navigate = useNavigate()
   const dispatch = useAppDispatch()

   const [loading, setLoading] = useState(false)
   const locale = useAppSelector(state => state.localeSlice.lng)
   const [addresses, setAddresses] = useState<CustomAddress[]>([])
   const [suggestions, setSuggestions] = useState<Suggestion[]>([])

   const [currentSearch, setCurrentSearch] = useState<string>('')
   const [showSuggestions, setShowSuggestions] = useState(false)
   const inputRef = useRef<HTMLDivElement>(null)

   useEffect(() => {
      const newSuggestions = addresses.map(s => mapCustomAddressToSuggestion(s))
      setSuggestions(newSuggestions)

      if (newSuggestions.length > 0 || currentSearch === '') {
         setLoading(false) // Stop loading once suggestions are updated
      }
   }, [addresses])

   useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
         if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
            setShowSuggestions(false) // Always close suggestions on outside click
            setLoading(false)
         }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
         document.removeEventListener('mousedown', handleClickOutside)
      }
   }, [setLoading])

   const handleSearch = (e: string) => {
      if (e == currentSearch) {
         setLoading(false)
         return
      }
      setLoading(true)
      debouncedHandleAddressChange(e, setCurrentSearch, fetchSuggestions, setAddresses, locale, setLoading)
   }

   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      handleSearch(value)

      if (value) {
         setShowSuggestions(true)
         setLoading(true)
      } else {
         setShowSuggestions(false)
         setLoading(false)
      }
   }

   function handleSuggestionClick(suggestion: Suggestion) {
      const customAddress = addresses.find(a => a.id === suggestion.id)
      console.log('handleSuggestionClick', suggestion, 'CA: ', customAddress)
      dispatch(
         updateUserLocation({
            location: {
               latitude: customAddress.latitude,
               longitude: customAddress.longitude,
               locationName: suggestion.header1,
               radius: 100,
            },
            shouldSaveLocation: false,
         }),
      )
      navigate('/')
   }

   return (
      <>
         <div className={`relative mr-2 flex w-full flex-row justify-center p-4`}>
            <div
               ref={inputRef}
               className={`border-gray-custom relative mr-1 flex w-full min-w-[20px] flex-1 flex-col space-y-1 rounded-3xl bg-white py-1 shadow-lg md:mr-2 ${additionalClassesStyles}`}
            >
               <div className='ml-2 flex items-center justify-center space-x-1'>
                  <button>
                     <HiMagnifyingGlass className={`ml-0.5 h-6 w-6 shrink-0 cursor-pointer text-gray-500`} />
                  </button>
                  <input
                     onChange={handleInputChange}
                     onKeyDown={e => {
                        if (e.key === 'Enter' && suggestions.length > 0) {
                           handleSuggestionClick(suggestions[0]) // Pick the first suggestion
                        }
                     }}
                     className={`my-[1px] mr-4 h-[36px] w-full bg-transparent text-lg outline-hidden`}
                     type='text'
                     placeholder={t('landing_page.enter_location')}
                  />
                  {loading && (
                     <ClipLoader
                        size={30}
                        color='#2D84CB'
                        loading={true}
                        className='top-1 right-2'
                     />
                  )}
               </div>
               {/* Suggestions list */}
               {showSuggestions && (
                  <div className='absolute top-10 left-0 z-50 w-full'>
                     <SuggestionComponent
                        suggestions={suggestions}
                        handleSuggestionClick={suggestion => {
                           handleSuggestionClick(suggestion)
                           setShowSuggestions(false)
                           setLoading(false)
                        }}
                     />
                  </div>
               )}
            </div>
            {isDesktop && (
               <button
                  onClick={() => {
                     if (suggestions.length > 0) {
                        handleSuggestionClick(suggestions[0]) // Pick the first suggestion
                     }
                  }}
                  className={`bg-customPurple hover:bg-customPurpleDark ml-2 h-[47px] w-[108px] rounded-lg border-[1px] text-white shadow-lg hover:cursor-pointer ${locale == 'fr' ? 'text-base' : 'text-lg'} font-semibold`}
               >
                  {t('landing_page.search')}
               </button>
            )}
         </div>
      </>
   )
}
