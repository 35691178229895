import React from 'react'
import { AbstractWebModal } from '@/layout/common/AbstractWebModal'
import { AbstractMobileModal } from '@/layout/common/AbstractMobileModal'
import { isDesktop } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

interface YesOrNoConfirmProps {
   title: string
   onConfirm: () => void
   onCancel: () => void
   additionalElement?: React.ReactNode
   showCancelConfirm?: boolean
}

export const YesOrNoConfirm = ({
   title,
   onConfirm,
   onCancel,
   additionalElement,
   showCancelConfirm = true,
}: YesOrNoConfirmProps) => {
   const { t } = useTranslation()

   function content() {
      return (
         <div className={'flex flex-col gap-y-2'}>
            {additionalElement}
            {showCancelConfirm && (
               <div className='flex justify-center gap-x-2'>
                  {/*todo button*/}
                  <button
                     role='button'
                     type='submit'
                     className='rounded-normal mx-auto mt-0.5 w-full bg-red-700 px-2.5 py-2 text-lg font-bold text-gray-200 hover:cursor-pointer'
                     onClick={() => {
                        onConfirm()
                     }}
                  >
                     {t('common.confirm')}
                  </button>
                  <button
                     role='button'
                     type='submit'
                     className='rounded-normal mt-0.5 w-full bg-blue-600 px-2 py-2 text-lg font-bold text-gray-200 hover:cursor-pointer'
                     onClick={() => {
                        onCancel()
                     }}
                  >
                     {t('common.cancel')}
                  </button>
               </div>
            )}
         </div>
      )
   }

   return isDesktop ? (
      <AbstractWebModal title={title} onClose={onCancel}>
         {content()}
      </AbstractWebModal>
   ) : (
      <AbstractMobileModal increaseZIndex={true} title={title} customNavigation={onCancel}>
         {content()}
      </AbstractMobileModal>
   )
}
