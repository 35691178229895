import { FoodOrderItem } from '@/model/order/FoodOrderItem'
import { FaMinus, FaPlus, FaTrash } from 'react-icons/fa'
import { formatPrice } from '@/util/text-utils'
import { CDN_ENDPOINT } from '@/env'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { isDesktop, isMobile } from 'react-device-detect'
import { Link, useNavigate } from 'react-router-dom'
import { PiBowlFood } from 'react-icons/pi'

interface SingleItemInSCProps {
   foodOrder: FoodOrderItem
   deleteFromShoppingCard?: (foodOrderToDelete: FoodOrderItem) => void
   buttonsEnabled?: boolean
   updateIncreaseQuantity?: () => void
   updateDecreaseQuantity?: () => void
   compactVersion?: boolean
}

export const SingleItemInSC = ({
   foodOrder,
   deleteFromShoppingCard,
   buttonsEnabled = true,
   updateIncreaseQuantity,
   updateDecreaseQuantity,
   compactVersion = false,
}: SingleItemInSCProps) => {
   const { t } = useTranslation()
   // const currentOfferInfo = getCurrentOfferInfo(foodOrder)
   // // if (!currentOfferInfo) {
   // //    return null
   // // }
   // const maxQuantity = currentOfferInfo?.amountServed - currentOfferInfo?.amountSold || 0
   const maxQuantity = 10

   // todo while user clicks on dissabled button then show toast messages
   const increaseQuantity = (event: { preventDefault: () => void }) => {
      event.preventDefault()
      if (foodOrder.quantity < maxQuantity) {
         updateIncreaseQuantity()
      } else {
         toast.error(t('shopping_cart.max_quantity_reached', { count: maxQuantity }))
      }
   }

   const decreaseQuantity = (event: { preventDefault: () => void }) => {
      event.preventDefault()
      if (foodOrder.quantity > 1) {
         updateDecreaseQuantity()
      } else {
         toast.error(t('shopping_cart.min_quantity_reached'))
      }
   }

   return (
      <div key={'FO ' + foodOrder?.id} className='flex w-full items-center justify-between select-none'>
         <Link to={`/chef/${foodOrder.food.chefId}`} className="flex items-center space-x-0.5 md:space-x-1">
            {foodOrder.food?.image ? (
               <img
                  src={CDN_ENDPOINT + foodOrder.food?.image}
                  alt={foodOrder.food.name}
                  className={` ${compactVersion ? (isDesktop ? 'h-16 w-16' : 'h-10 w-10') : isDesktop ? 'h-24 w-24' : 'h-12 w-12'} mr-2 shrink-0 rounded-md object-cover`}
               />
            ) : (
               <PiBowlFood
                  className={` ${compactVersion ? (isDesktop ? 'h-16 w-16' : 'h-10 w-10') : isDesktop ? 'h-24 w-24' : 'min-w-10 h-12 w-12'}`}
               />
            )}

            <div>
               <div className={`${isMobile ? 'text-sm' : 'text-md'} font-medium text-black`}>{foodOrder.food.name}</div>
               {!compactVersion && (
                  <div className={`${isMobile ? 'text-xs' : 'text-sm'} line-clamp-3 text-gray-500`}>
                     {foodOrder.food.description}
                  </div>
               )}
            </div>
         </Link>
         <div className='flex items-center'>
            {buttonsEnabled && (
               <button
                  onClick={decreaseQuantity}
                  className={`mr-2 flex h-8 w-8 items-center justify-center rounded-full border bg-gray-100 p-1.5 hover:cursor-pointer text-gray-700 hover:bg-gray-200 ${foodOrder.quantity <= 1 ? 'cursor-not-allowed' : ''}`}
                  disabled={foodOrder.quantity <= 1}
               >
                  <FaMinus size={isMobile ? 12 : 16} />
               </button>
            )}
            <div className={`${isMobile ? 'text-sm' : 'text-md'} font-medium`}>{foodOrder.quantity}&nbsp;</div>
            {buttonsEnabled && (
               <button
                  onClick={increaseQuantity}
                  className={`ml-1 flex items-center justify-center rounded-full border bg-gray-100 text-gray-700 hover:bg-gray-200 hover:cursor-pointer ${isMobile ? 'mr-1' : 'mr-2'} h-8 w-8 p-1.5 ${foodOrder.quantity >= maxQuantity ? 'cursor-not-allowed' : ''}`}
                  disabled={foodOrder.quantity >= maxQuantity}
               >
                  <FaPlus size={isMobile ? 12 : 16} />
               </button>
            )}
            <div className={`${isMobile ? 'text-sm' : 'text-md'} font-medium whitespace-nowrap`}>
               x €{formatPrice(foodOrder.food.price)}
            </div>
            {buttonsEnabled && (
               <button
                  onClick={() => deleteFromShoppingCard(foodOrder)}
                  className={`ml-2 flex h-8 w-8 items-center justify-center rounded-full border bg-gray-100 p-1.5 text-gray-700 hover:bg-gray-200 hover:cursor-pointer`}
               >
                  <FaTrash size={isMobile ? 12 : 16} />
               </button>
            )}
         </div>
      </div>
   )
}
