import type {
   DOMConversionMap,
   DOMConversionOutput,
   DOMExportOutput,
   EditorConfig,
   ElementFormatType,
   LexicalEditor,
   LexicalNode,
   NodeKey,
   Spread,
} from 'lexical'

import { BlockWithAlignableContents } from '@lexical/react/LexicalBlockWithAlignableContents'
import { DecoratorBlockNode, SerializedDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode'
import * as React from 'react'
import { ReactElement } from 'react'

type StreamableComponentProps = Readonly<{
   className: Readonly<{
      base: string
      focus: string
   }>
   format: ElementFormatType | null
   nodeKey: NodeKey
   videoID: string
}>

function StreamableComponent({ className, format, nodeKey, videoID }: StreamableComponentProps) {
   return (
      <BlockWithAlignableContents className={className} format={format} nodeKey={nodeKey}>
         <div className='video-responsive'>
            <iframe
               width='560'
               height='315'
               src={`https://streamable.com/e/${videoID}`}
               allowFullScreen={true}
               title='Streamable video'
            />
         </div>
      </BlockWithAlignableContents>
   )
}

export type SerializedStreamableNode = Spread<
   {
      videoID: string
   },
   SerializedDecoratorBlockNode
>

export class StreamableNode extends DecoratorBlockNode {
   __id: string

   constructor(id: string, format?: ElementFormatType, key?: NodeKey) {
      super(format, key)
      this.__id = id
   }

   static getType(): string {
      return 'streamable'
   }

   static clone(node: StreamableNode): StreamableNode {
      return new StreamableNode(node.__id, node.__format, node.__key)
   }

   static importJSON(serializedNode: SerializedStreamableNode): StreamableNode {
      const node = $createStreamableNode(serializedNode.videoID)
      node.setFormat(serializedNode.format)
      return node
   }

   static importDOM(): DOMConversionMap | null {
      return {
         iframe: (domNode: HTMLElement) => {
            if (!domNode.hasAttribute('data-lexical-streamable')) {
               return null
            }
            return {
               conversion: convertStreamableElement,
               priority: 1,
            }
         },
      }
   }

   exportJSON(): SerializedStreamableNode {
      return {
         ...super.exportJSON(),
         type: 'streamable',
         version: 1,
         videoID: this.__id,
      }
   }

   exportDOM(): DOMExportOutput {
      const element = document.createElement('iframe')
      element.setAttribute('data-lexical-streamable', this.__id)
      element.setAttribute('width', '560')
      element.setAttribute('height', '315')
      element.setAttribute('src', `https://streamable.com/e/${this.__id}`)
      element.setAttribute('allowfullscreen', 'true')
      element.setAttribute('title', 'Streamable video')
      return { element }
   }

   updateDOM(): false {
      return false
   }

   getId(): string {
      return this.__id
   }

   getTextContent(_includeInert?: boolean | undefined, _includeDirectionless?: false | undefined): string {
      return `https://streamable.com/${this.__id}`
   }

   decorate(_editor: LexicalEditor, config: EditorConfig): ReactElement {
      const embedBlockTheme = config.theme.embedBlock || {}
      const className = {
         base: embedBlockTheme.base || '',
         focus: embedBlockTheme.focus || '',
      }
      return (
         <div className='w-full'>
            <StreamableComponent
               className={className}
               format={this.__format}
               nodeKey={this.getKey()}
               videoID={this.__id}
            />
         </div>
      )
   }
}

function convertStreamableElement(domNode: HTMLElement): null | DOMConversionOutput {
   const videoID = domNode.getAttribute('data-lexical-streamable')
   if (videoID) {
      const node = $createStreamableNode(videoID)
      return { node }
   }
   return null
}

export function $createStreamableNode(videoID: string): StreamableNode {
   return new StreamableNode(videoID)
}

export function $isStreamableNode(node: StreamableNode | LexicalNode | null | undefined): node is StreamableNode {
   return node instanceof StreamableNode
}
